import React, { useEffect, useState, useRef } from 'react';
import Page from '../../components/Page';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import axios from 'axios';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import { Table, Tag, Space, Popconfirm, Progress } from 'antd';
import 'antd/dist/antd.min.css';
import moment from 'moment';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import greyBanner from '../../assets/img/logo/Banner Grey.png';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/react';
import _ from 'lodash';
import DatePicker, { registerLocale } from 'react-datepicker';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
const override = css`
  display: block;
  margin: 20% auto;
  border-color: #fff;
`;

const RequestInfoTable = props => {
  const [value, setValue] = React.useState('1');
  const searchInput = useRef(null);
  const [searchedColumn, setSearchedColumn] = useState('');
  const [searchText, setSearchText] = useState('');
  const [dataAlls, setDataAlls] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const [color, setColor] = React.useState('#ffffff');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [taskData, setTaskData] = React.useState([]);
  const [dataTask, setDataTask] = React.useState([]);
  const [showModal, setShowModal] = useState(false);

  const [supplierDetail, setSupplierDetail] = useState({});
  const [dateRange, setDateRange] = useState({
    startDate: '',
    endDate: ''
  });
  const [requiredError, setRequiredError] = useState('');

  const [filteredDataTask, setFilteredDataTask] = React.useState([]);

  const listTask = () => {
    setLoading(true);
    var supplierList;
    axios({
      method: 'GET',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: process.env.REACT_APP_API_BASE + 'admin-portal',
    }).then(response => {
      // console.log('reponse====>', response.data.body);
      supplierList = response.data.body;
      // console.log(supplierList);

      axios({
        method: 'GET',
        url: `${process.env.REACT_APP_CDN}archived_tasks/tasks.json`,
      })
        .then(response => {
          // console.log('reponse====>', response);
          // const data = response.data.body.Items;
          const data = response.data;
          var dataD = [];

          let curSupplierDetail = '';
          const curUsername = sessionStorage.getItem('parentSupUsername')
            ? sessionStorage.getItem('parentSupUsername')
            : sessionStorage.getItem('username');

          supplierList.map(rec1 => {
            if (curUsername == rec1.username && rec1.role == 'supplier') {
              // set the current supplier detail if found
              // console.log('set the current supplier detail if found', rec1);
              curSupplierDetail = { ...rec1 };
            }
          });

          // console.log('curSupplierDetail', curSupplierDetail.id);

          Array.isArray(data) &&
            data.map(rec => {
              if (
                (rec.task_status == 'approved' ||
                  (rec.task_status === 'done' &&
                    rec.task_approval === 'not_required')) &&
                rec.supplier_id == curSupplierDetail.id
              ) {
                // console.log('find task for supplier ', rec.supplier_id);
                dataD.push(rec);
              }
            });
          // console.log('curSupplierDetail dataD', dataD);

          // const groupTaskId = _.groupBy(dataD, 'task_name');
          // var taskGG = [];
          // Object.entries(groupTaskId).map(([key, value]) => {
          //   taskGG.push({ [key]: value });
          // });

          const taskGroups = getTaskGroups(dataD);

          // added sorting by createdDate, so the latest task will be on top
          const sortedData =
            Array.isArray(taskGroups) &&
            taskGroups.length > 0 &&
            taskGroups.sort((a, b) => {
              return (
                new Date(b.createdDate).getTime() -
                new Date(a.createdDate).getTime()
              );
            });

          setDataTask(sortedData || []);
          setFilteredDataTask(sortedData || []);
          // setDataTask(taskGG);
          setTaskData(data || []);
          setLoading(false);

          setSupplierDetail(curSupplierDetail);
        })
        .catch(err => {
          // console.log('get fail...', err);
          setLoading(false);
        });
    });
  };

  const getTaskGroups = taskData => {
    var taskGG = [];
    const groupTaskId = _.groupBy(taskData, 'task_name');

    Object.entries(groupTaskId).map(([key, value]) => {
      console.log('key::', key, value);

      taskGG.push({
        key_based_records: value,
        task_name: key,
        total_stores: value.length,
        createdDate: value[0].recurring == "false" ? value[0].week_startdate : value[0].startdate,
      });

    });

    console.log('taskGG==>>', taskGG)

    return taskGG;
  };

  // api listTask version
  // const listTask = () => {
  //   setLoading(true);
  //   var supplierList;
  //   axios({
  //     method: 'GET',
  //     headers: {
  //       Authorization: sessionStorage.getItem('idtoken'),
  //     },
  //     url: process.env.REACT_APP_API_BASE + 'admin-portal',
  //   }).then(response => {
  //     console.log('reponse====>', response.data.body);
  //     supplierList = response.data.body;
  //     console.log(supplierList);

  //     // })
  //     axios({
  //       method: 'GET',
  //       // headers: {
  //       //   Authorization: sessionStorage.getItem('idtoken'),
  //       // },
  //       // url: process.env.REACT_APP_API_BASE + 'task-management?view=UNARCHIVED',
  //       // url: process.env.REACT_APP_API_BASE + 'task-management/unarchived',
  //       url: `${process.env.REACT_APP_CDN}unarchived_tasks/tasks.json`,
  //       // url: `${process.env.REACT_APP_TASKS_BASE}unarchived_tasks`,
  //     })
  //       .then(response => {
  //         console.log('reponse====>', response);
  //         // const data = response.data.body.Items;
  //         const data = response.data.Items;
  //         var dataD = [];
  //         var supplierID = '';

  //         var curSupplierDetail = ''
  //         data.map(rec => {
  //           console.log('supplierListing--', supplierList);
  //           supplierList.map(rec1 => {
  //             const curUsername = sessionStorage.getItem('parentSupUsername')
  //               ? sessionStorage.getItem('parentSupUsername')
  //               : sessionStorage.getItem('username');
  //             if (curUsername == rec1.username && rec1.role == 'supplier') {
  //               if (curSupplierDetail == '') {
  //                 // set the current supplier detail if found
  //                 console.log('set the current supplier detail if found', rec1)
  //                 curSupplierDetail = { ...rec1 }
  //               }
  //               // supplierID = rec1.supplier_id;
  //               console.log('session', curUsername);
  //               console.log('erc1', rec1.username);
  //               console.log('rec1 id', rec1.id);
  //               console.log('rec supis', rec.supplier_id);
  //               if (
  //                 rec.task_status == 'approved' &&
  //                 rec.supplier_id == rec1.id
  //               ) {
  //                 console.log(curUsername);
  //                 console.log(rec1.username);
  //                 console.log(rec1.supplier_id);
  //                 console.log(rec.supplier_id);
  //                 console.log(rec);
  //                 dataD.push(rec);
  //               }
  //             }
  //             // console.log('supplierID', supplierID);
  //           });
  //         });
  //         const groupTaskId = _.groupBy(dataD, 'task_name');
  //         var taskGG = [];
  //         Object.entries(groupTaskId).map(([key, value]) => {
  //           console.log(value);
  //           console.log(key);
  //           taskGG.push({ [key]: value });
  //         });
  //         setDataTask(taskGG);
  //         console.log(groupTaskId);
  //         setTaskData(data);
  //         setLoading(false);

  //         setSupplierDetail(curSupplierDetail)
  //       })
  //       .catch(err => {
  //         console.log('get fail...', err);
  //         setLoading(false);
  //       });
  //   });
  // };
  // console.log('newData===>', taskData);

  useEffect(() => {
    listTask();
  }, []);
  const [recordData, setRecordData] = useState([]);

  const dateStringInDateRange = (dateString, startDate, endDate) => {
    const dateToCheck = new Date(dateString);
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Extract only the date part
    const dateToCheckWithoutTime = new Date(dateToCheck.getFullYear(), dateToCheck.getMonth(), dateToCheck.getDate());
    const startWithoutTime = new Date(start.getFullYear(), start.getMonth(), start.getDate());
    const endWithoutTime = new Date(end.getFullYear(), end.getMonth(), end.getDate());

    return dateToCheckWithoutTime >= startWithoutTime && dateToCheckWithoutTime <= endWithoutTime;
  };

  const searchTask = () => {
    console.log('dates search for ', dateRange)
    if (!dateRange.startDate && !dateRange.endDate) {
      setFilteredDataTask(dataTask)
      return

    } else if (!dateRange.startDate || !dateRange.endDate) {
      setRequiredError('Please select both start date and end date.')
      return
    }
    console.log('origin format tasks ', dataTask)

    const result = dataTask.filter(taskItem => {
      return dateStringInDateRange(taskItem.createdDate, dateRange.startDate, dateRange.endDate)
    })

    console.log('origin format tasks filter result', result)
    setFilteredDataTask(result)

  }

  const viewModal = (e, data) => {
    e.preventDefault();
    const record = data;
    const time_spent = record && record.time_spent ? record.time_spent : 0;
    var hours = time_spent / (1000 * 60 * 60);
    var absoluteHours = Math.floor(hours);
    var h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours;

    //Get remainder from hours and convert to minutes
    var minutes = (hours - absoluteHours) * 60;
    var absoluteMinutes = Math.floor(minutes);
    var m = absoluteMinutes > 9 ? absoluteMinutes : '0' + absoluteMinutes;

    //Get remainder from minutes and convert to seconds
    var seconds = (minutes - absoluteMinutes) * 60;
    var absoluteSeconds = Math.floor(seconds);
    var s = absoluteSeconds > 9 ? absoluteSeconds : '0' + absoluteSeconds;

    const timmee = h + ':' + m + ':' + s;
    // console.log(h + ':' + m + ':' + s);
    // }
    // setRecordData({ ...record, time_spent: timmee });
    if (timmee != '0NaN:0NaN:0NaN') {
      setRecordData({ ...record, time_spent: timmee });
    } else {
      setRecordData({ ...record, time_spent: '0' });
    }
    // console.log(record);
    setShowModal(true);
  };

  // api version of viewModal
  // const viewModal = (e, data) => {
  //   e.preventDefault();
  //   const url2 = `${process.env.REACT_APP_API_BASE}task-management?PK=${data.id}&SK=${data.PK}`;
  //   axios({
  //     method: 'GET',
  //     headers: {
  //       Authorization: sessionStorage.getItem('idtoken'),
  //     },
  //     url: url2,
  //     // data: [{ ...reAssignPayload, task_status: 'reassigned' }],
  //   }).then(response => {
  //     console.log(response.data.body.Item);
  //     const record = response.data.body.Item;
  //     const time_spent = (record && record.time_spent) ? record.time_spent : 0
  //     var hours = time_spent / (1000 * 60 * 60);
  //     var absoluteHours = Math.floor(hours);
  //     var h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours;

  //     //Get remainder from hours and convert to minutes
  //     var minutes = (hours - absoluteHours) * 60;
  //     var absoluteMinutes = Math.floor(minutes);
  //     var m = absoluteMinutes > 9 ? absoluteMinutes : '0' + absoluteMinutes;

  //     //Get remainder from minutes and convert to seconds
  //     var seconds = (minutes - absoluteMinutes) * 60;
  //     var absoluteSeconds = Math.floor(seconds);
  //     var s = absoluteSeconds > 9 ? absoluteSeconds : '0' + absoluteSeconds;

  //     const timmee = h + ':' + m + ':' + s;
  //     console.log(h + ':' + m + ':' + s);
  //     // }
  //     // setRecordData({ ...record, time_spent: timmee });
  //     if (timmee != '0NaN:0NaN:0NaN') {
  //       setRecordData({ ...record, time_spent: timmee });
  //     } else {
  //       setRecordData({ ...record, time_spent: '0' });
  //     }
  //     console.log(record);
  //     setShowModal(true);
  //   });
  // };
  // console.log(recordData, 'record====>');
  const handleClose = () => {
    setShowModal(false);
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => {
            // console.log('cahngeddd', e.target.value);
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : '',
    // TODO: if in future, no need, remove this
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => { }, 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const filter = datas => {
    datas.map(rec => {
      // console.log('rec====>', rec);
      setFilteredData(rec);
    });
  };
  const [tableD, setTableD] = useState([]);
  const [viewData, setViewData] = React.useState([]);
  const [viewDataBool, setViewDataBool] = React.useState(false);
  const [storesSelected, setSelectedStores] = useState([]);
  const anotherPage = (real_data, index) => {
    console.log('anotherPage => ', real_data);
    let data = real_data;

    setViewDataBool(true);
    setTableD(data);
    var stor = [];
    data.map(rec => {
      stor.push(rec.stores);
    });
    // console.log(stor);
    let pp = stor.filter(
      (ele, ind) =>
        ind === stor.findIndex(elem => elem.store_id === ele.store_id),
    );

    // console.log(pp);
    setSelectedStores(pp);
  };

  // api version of anotherPage
  // const anotherPage = async (real_data, index) => {
  //   let data;
  //   await axios({
  //     method: 'GET',
  //     headers: {
  //       Authorization: sessionStorage.getItem('idtoken'),
  //     },
  //     url:
  //       process.env.REACT_APP_API_BASE +
  //       'task-management?task_id=' +
  //       real_data[0].task_name +
  //       '&supplier_id=' +
  //       real_data[0].supplier_id,
  //   }).then(async response => {
  //     console.log('progress task supplier table', response);
  //     const responseData = response.data.body;
  //     console.log(responseData);

  //     // when state manager login, show the tasks for that state only
  //     const userState = localStorage.getItem('userState')
  //     const userRole = localStorage.getItem('userRole')
  //     if (userRole && userRole == 'manager' && userState && userState != '') {
  //       // console.log('state manager login, filter by state', userState);
  //       data = response.data.body.filter(item => {
  //         if (item.task_status == 'approved' && item.stores && item.stores.state && item.stores.state == userState) {
  //           return item
  //         }
  //       })
  //     } else {
  //       data = response.data.body.filter(item => {
  //         if (item.task_status == 'approved') {
  //           return item
  //         }
  //       })

  //     }
  //     setViewDataBool(true);
  //     setTableD(data);
  //     var stor = [];
  //     data.map(rec => {
  //       stor.push(rec.stores);
  //     });
  //     console.log(stor);
  //     let pp = stor.filter(
  //       (ele, ind) =>
  //         ind === stor.findIndex(elem => elem.store_id === ele.store_id),
  //     );

  //     console.log(pp);
  //     setSelectedStores(pp);
  //   });
  // };
  const columns = [
    {
      title: 'Creation Start Date',
      dataIndex: 'createdDate',
      align: 'left',
      editable: true,
      sorter: (a, b) => a.createdDate.localeCompare(b.createdDate),
      render: (text, record) => {
        return (
          <>
            {/* <p>{moment('2023-11-19T16:00:00.000Z').format('DD/MM/YYYY')}</p> */}
            <p>{moment(record.createdDate).format('DD/MM/YYYY')}</p>
          </>
        );
      },
    },
    // {
    //   title: 'Supplier',
    //   dataIndex: 'supplier_id',
    //   align: 'left',
    //   editable: true,
    //   render: (text, record) => {
    //     console.log('record', record.key_based_records);

    //     return (
    //       <>
    //         <p>{record.key_based_records[0].supplier_name}</p>
    //       </>
    //     );
    //   },
    // },
    {
      title: 'Task Name',
      dataIndex: 'task_name',
      sorter: (a, b) => a.createdDate.localeCompare(b.createdDate),
      align: 'left',
      editable: true,
      render: (text, record) => {
        return (
          <>
            <p
              style={{ cursor: 'pointer' }}
              onClick={() => anotherPage(record.key_based_records)}
            >
              {record.task_name}
            </p>
          </>
        );
      },
    },
    {
      title: 'No. of Stores', // as required by Brad from Gforce, just change the word, the logic will not be changed
      dataIndex: 'total_stores',
      align: 'left',
      editable: true,
      render: (text, record) => {
        return (
          <>
            <p
              style={{ cursor: 'pointer' }}
              onClick={() => anotherPage(record.key_based_records)}
            >
              {record.total_stores}
            </p>
          </>
        );
      },
    },
    // {
    //   title: 'Supplier',
    //   dataIndex: 'supplier_id',
    //   align: 'left',
    //   editable: true,
    //   // key: 'supplier_id',
    //   // ...getColumnSearchProps('supplier_id'),
    //   render: (text, record) => {
    //     // console.log(record);
    //     Object.entries(record).map(([key, value]) => {
    //       {
    //         value.map((rec, index) => {
    //           if (value.indexOf(rec) == 0) {
    //             // console.log(rec.supplier_name);
    //           }
    //         });
    //       }
    //     });
    //     return Object.entries(record).map(([key, value]) => (
    //       <>
    //         <>
    //           {value.map((rec, index) =>
    //             value.indexOf(rec) == 0 ? <p>{rec.supplier_id}</p> : null,
    //           )}
    //           {/* {value.reduce((unique, o) =>
    //             console.log(unique)(
    //               !unique.some(obj => obj.supplier_id == o.supplier_id),
    //             ) ? (
    //               <p>{o.supplier_id}</p>
    //             ) : null,
    //           )} */}
    //         </>
    //         {/* ))} */}
    //       </>
    //     ));
    //     // return record.map(([key, value]) => <p>{key}</p>);
    //     // return <p>{record}</p>;
    //   },
    // },
    // {
    //   title: 'Task ID',
    //   dataIndex: 'task_id',
    //   align: 'left',
    //   // editable: true,
    //   // ...getColumnSearchProps('task_id'),
    //   render: (text, record) => {
    //     console.log(record);
    //     return Object.entries(record).map(([key, value]) =>
    //       value.map(rec => <p> {rec.task_id}</p>),
    //     );
    //     // return record.map(([key, value]) => <p>{key}</p>);
    //     // return <p>{record}</p>;
    //   },
    // },

    // {
    //   title: 'Task Name',
    //   dataIndex: 'task_name',
    //   align: 'left',
    //   editable: true,
    //   // ...getColumnSearchProps('task_name'),
    //   render: (text, record) => {
    //     // console.log(record);
    //     return Object.entries(record).map(([key, value], i) => (
    //       // <Link to={{ pathname: '/ViewAllTasksTable', state: value }}>
    //       //   {key}
    //       // </Link>
    //       <p
    //         style={{ cursor: 'pointer' }}
    //         onClick={() => anotherPage(value, i)}
    //       >
    //         {key}
    //       </p>
    //     ));
    //     // return record.map(([key, value]) => <p>{key}</p>);
    //     // return <p>{record}</p>;
    //   },
    // },

    // {
    //   title: 'No. of Stores', // as required by Brad from Gforce, just change the word, the logic will not be changed
    //   // title: 'No. of Tasks',
    //   dataIndex: 'No of Tasks',
    //   align: 'left',
    //   editable: true,
    //   render: (text, record) => {
    //     // console.log(record);
    //     return Object.entries(record).map(([key, value], i) => (
    //       <p
    //         style={{ cursor: 'pointer' }}
    //         onClick={() => anotherPage(value, i)}
    //       >
    //         {value.length}
    //       </p>
    //     ));
    //     // return record.map(([key, value]) => <p>{key}</p>);
    //     // return <p>{record}</p>;
    //   },
    // },
    // {
    //   title: 'Progress',
    //   dataIndex: 'task_status',
    //   align: 'left',
    //   editable: true,

    //   render: (text, record) => {
    //     console.log(record);
    //     var perc = '0';
    //     var lengthRec = [];
    //     return Object.entries(record).map(
    //       ([key, value], i) => (
    //         value.map(rec => {
    //           if (rec.task_status == 'done') {
    //             lengthRec.push(rec);
    //             console.log('manage', rec.task_status);
    //             console.log('manage', lengthRec.length);
    //             console.log('manage', value.length);
    //             console.log((lengthRec.length / value.length) * 100);
    //           }
    //         }),
    //         (
    //           <>
    //             <p style={{ width: '25%', float: 'right' }}>
    //               {lengthRec.length + '/' + value.length}
    //             </p>
    //             <Progress
    //               percent={((lengthRec.length / value.length) * 100).toFixed(2)}
    //               status="active"
    //               style={{
    //                 width: '65%',
    //                 fontSize: '14px',
    //                 marginRight: '12px',
    //               }}
    //             />
    //             <div style={{ clear: 'both' }}></div>
    //           </>
    //         )
    //       ),
    //     );

    //     // return record.map(([key, value]) => <p>{key}</p>);
    //     // return <p>{record}</p>;
    //   },
    // },
  ];
  const columnss = [
    {
      title: 'Created Date',
      dataIndex: 'created_date',
      align: 'center',
      render: (text, record) => (
        <>{moment(record.created_date).format('DD/MM/YYYY')}</>
      ),
      // ...getColumnSearchProps('created_date'),
    },
    {
      title: 'Task ID',
      dataIndex: 'task_id',
      align: 'center',
      ...getColumnSearchProps('task_id'),
    },
    {
      title: 'Task Name',
      dataIndex: 'task_name',
      align: 'center',
      ...getColumnSearchProps('task_name'),
    },
    {
      title: 'Supplier ',
      dataIndex: 'supplier_name',
      align: 'center',
      ...getColumnSearchProps('supplier_name'),
    },
    {
      title: 'Store ID',
      dataIndex: 'store_id',
      align: 'left',
      editable: true,
      ...getColumnSearchProps('store_id'),
    },
    {
      title: 'Store Name',
      dataIndex: 'store_name',
      align: 'left',
      editable: true,
      ...getColumnSearchProps('store_name'),
    },
    {
      title: 'State',
      align: 'left',
      editable: true,
      filters: [
        { text: 'VIC', value: 'VIC' },
        { text: 'ACT', value: 'ACT' },
        { text: 'NSW', value: 'NSW' },
        { text: 'NT', value: 'NT' },
        { text: 'QLD', value: 'QLD' },
        { text: 'SA', value: 'SA' },
        { text: 'TAS', value: 'TAS' },
        { text: 'WA', value: 'WA' },
        { text: 'NI', value: 'NI' },
        { text: 'SI', value: 'SI' },
      ],
      onFilter: (value, record) => record.stores.state === value,
      render: (text, record) => {
        var stateF = '';
        const state = record.state;
        // state.map(rec => {
        //   stateF = rec.value;
        // });
        stateF = record.stores.state;
        return (
          <>
            <p>{stateF}</p>
          </>
        );
      },
    },
    {
      title: 'Task Status',
      key: 'task_status',
      dataIndex: 'status',
      align: 'center',
      render: (text, record) => {
        return (
          <>
            {record.task_status == 'created' ? <p>Created</p> : null}
            {record.task_status == 'in_progress' ? <p>In Progress</p> : null}
            {record.task_status == 'approved' ? <p>Approved</p> : null}
            {record.task_status == 'cannot_complete' ? (
              <p>Can not complete</p>
            ) : null}
            {record.task_status == 'done' ? <p> Done</p> : null}
            {/* {record.task_status == 'pushed' ? <p> Pushed</p> : null} */}
            {record.task_status == 'avoided' ? <p> Voided</p> : null}
            {record.task_status == 'paused' ? <p> Paused</p> : null}
            {record.task_status == 'reassigned' ? <p> Reassigned</p> : null}
            {record.task_status == 'rolled_over' ? <p> Rolled Over</p> : null}
          </>
        );
      },
      // filters : [
      //   {text: 'Created', value: 'created'},
      //   {text: 'In Progress', value: 'in_progress'},
      //   {text: 'Done', value: 'done'},
      // ],
      // onFilter: (value, record) => {
      //   console.log('vvvvv', value == record.task_status, value, record.task_status);
      //   return record['task_status'] == value;
      // },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      width: '100px',
      align: 'center',
      render: (text, record) => {
        return (
          <>
            <button
              onClick={e => viewModal(e, record)}
              style={{ backgroundColor: 'transparent' }}
            >
              <svg
                height="18"
                width="18"
                fill="#000000"
                xmlns="http://www.w3.org/2000/svg"
                data-name="Layer 3"
                viewBox="0 0 24 24"
                x="0px"
                y="0px"
              >
                <path d="M20.5,9.873A11.123,11.123,0,0,0,12,6,11.123,11.123,0,0,0,3.5,9.873L2.239,11.351a1,1,0,0,0,0,1.3L3.5,14.127A11.123,11.123,0,0,0,12,18a11.125,11.125,0,0,0,8.5-3.872l1.261-1.479a1,1,0,0,0,0-1.3Zm-1.521,2.958A9.134,9.134,0,0,1,12,16a9.131,9.131,0,0,1-6.979-3.17L4.314,12l.707-.83h0A9.134,9.134,0,0,1,12,8a9.131,9.131,0,0,1,6.979,3.17l.707.83Z"></path>
                <path d="M12,9a3,3,0,1,0,3,3A3,3,0,0,0,12,9Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,12,13Z"></path>
              </svg>
            </button>
          </>
        );
      },
    },
  ];
  var dataAll = [];
  var dataCreated = [];
  var dataProgress = [];
  var dataDone = [];
  const fileDownloadHandler = async pictures => {
    var urlss = [];
    pictures.map(rec => {
      if (rec && rec.key && rec.key.length > 0) {
        urlss.push(
          process.env.REACT_APP_CDN + rec.key,
          // 'admin-documents/taskDocuments/' +
          // recordData.task_id +
          // '/rep/images/' +
          // recordData.taskDate.replace(/[^\d]/g, '') +
          // '/' +
          // rec,
        );
      }

      // urlss = [
      //   process.env.REACT_APP_CDN +
      //     'admin-documents/taskDocuments/' +
      //     recordData.task_id +
      //     '/rep/images/' +
      //     recordData.taskDate.replace(/[^\d]/g, '') +
      //     '/' +
      //     rec,
      //   process.env.REACT_APP_CDN +
      //     'admin-documents/taskDocuments/' +
      //     recordData.task_id +
      //     '/rep/images/' +
      //     recordData.taskDate.replace(/[^\d]/g, '') +
      //     '/' +
      //     rec,
      // ];
    });
    // console.log(urlss);
    for (var i = 0; i < urlss.length; i++) {
      // console.log(urlss[i]);
      const response = await fetch(urlss[i]);
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = 'task.jpeg';
        a.click();
      });
    }
  };
  return (
    <>
      {loading ? (
        <div className="overlay-box">
          <ClipLoader
            color={color}
            loading={loading}
            css={override}
            size={150}
          />
        </div>
      ) : null}
      <Modal isOpen={showModal} className="plan-modal-dialog">
        <ModalHeader style={{ justifyContent: 'center' }}>
          Task Details
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={6}>
              <label>Created Date</label>
              <Input
                type="text"
                name="created_date"
                disabled
                value={moment(recordData.created_date).format('DD/MM/YYYY')}
                className="admin-input"
              ></Input>
            </Col>
            <Col md={6}>
              <label>Task ID</label>
              <Input
                type="text"
                name="task_id"
                disabled
                value={recordData.task_id}
                className="admin-input"
              ></Input>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <label>Task Name</label>
              <Input
                type="text"
                name="task_name"
                disabled
                value={recordData.task_name}
                className="admin-input"
              ></Input>
            </Col>
            <Col md={6}>
              <label>Task Description</label>
              <div
                className="admin-input form-control task-detail-desc"
                style={{ backgroundColor: '#e9ecef', height: 'auto' }}
                dangerouslySetInnerHTML={{
                  __html: recordData.task_description,
                }}
              />
              {/* <Input
                type="text"
                name="task_description"
                disabled
                value={recordData.task_description}
                className="admin-input"
              >

              </Input> */}
            </Col>
          </Row>

          <Row>
            {/* <Col md={6}>
              <label>Task Type</label>
              <Input
                type="text"
                name="task_type"
                disabled

                value={recordData.task_type}
                className="admin-input"
              >

              </Input>
            </Col> */}
            <Col md={6}>
              <label>Task Priority</label>
              <Input
                type="text"
                name="task_name"
                disabled
                value={recordData.task_priority}
                className="admin-input"
              ></Input>
            </Col>
            {/* <Col md={6}>
              <label>Rep Name</label>
              <Input
                type="text"
                name="rep_name"
                disabled

                value={recordData.rep_name}
                className="admin-input"
              >

              </Input>
            </Col> */}
            {/* </Row>

          <Row> */}
            <Col md={6}>
              <label>Store Name</label>
              <Input
                type="text"
                name="task_type"
                disabled
                value={recordData.store_name}
                className="admin-input"
              ></Input>
            </Col>
            <Col md={6}>
              <label>Supplier Name</label>
              <Input
                type="text"
                name="task_name"
                disabled
                value={recordData.supplier_name}
                className="admin-input"
              ></Input>
            </Col>
            {/* </Row>

          <Row> */}
            <Col md={6}>
              <label>Task Status</label>
              <Input
                type="text"
                name="task_type"
                disabled
                value={recordData.task_status}
                className="admin-input"
              ></Input>
            </Col>
            <Col md={6}>
              <label>Time Spent</label>
              <Input
                type="text"
                name="task_type"
                disabled
                value={recordData.time_spent}
                className="admin-input"
              ></Input>
            </Col>
            {/* </Row>
          <Row> */}
            <Col md={6}>
              <label>Rep's Comment</label>
              <Input
                type="text"
                name="task_type"
                disabled
                value={recordData.comments_from_rep}
                className="admin-input"
              ></Input>
            </Col>

            {recordData.rep_images && recordData.rep_images.length > 0 ? (
              <Col md={6}>
                <label>Photos From Rep</label>

                <button
                  style={{
                    float: 'right',
                    color: 'blue',
                    textDecoration: 'underline',
                    fontSize: '12px',
                  }}
                  type="button"
                  onClick={() => fileDownloadHandler(recordData.rep_images)}
                >
                  Download All
                </button>
                <div style={{ clear: 'both' }}></div>
                <br />
                {/* <a style={{ marginRight: '10px' }} href={process.env.REACT_APP_CDN + 'admin-documents/taskDocuments/' + recordData.task_id + "/rep/" + rec} >
     <img style={{ width: '60px' }} src={process.env.REACT_APP_CDN + 'admin-documents/taskDocuments/0306/rep/Image0.jpg'} />
   </a></> */}
                {recordData.rep_images &&
                  recordData.rep_images.map(
                    rec =>
                      rec &&
                      rec.key &&
                      rec.key.length > 0 && (
                        <a
                          style={{ marginRight: '10px' }}
                          target="_blank"
                          href={
                            process.env.REACT_APP_CDN + rec.key
                            // 'admin-documents/taskDocuments/' +
                            // recordData.task_id +
                            // '/rep/images/' +
                            // recordData.taskDate.replace(/[^\d]/g, '') +
                            // '/' +
                            // rec
                          }
                        >
                          <img
                            style={{ width: '60px' }}
                            alt="rep photos"
                            src={
                              process.env.REACT_APP_CDN + rec.key
                              // 'admin-documents/taskDocuments/' +
                              // recordData.task_id +
                              // '/rep/images/' +
                              // recordData.taskDate.replace(/[^\d]/g, '') +
                              // '/' +
                              // rec
                            }
                          />
                        </a>
                      ),
                  )}
              </Col>
            ) : null}

            {recordData.docs_from_rep && recordData.docs_from_rep.length > 0 ? (
              <>
                {/* <Col md={6}>
                  <label>Rep's Documents </label><br />

                  {recordData.docs_from_rep && ( recordData.docs_from_rep ).map( rec => (
                    <a style={{ marginRight: '10px' }} href={process.env.REACT_APP_CDN + 'admin-documents/taskDocuments/' + recordData.task_id + "/rep/" + rec.document} >
                      {rec.document}
                    </a>

                  ) )}

                </Col> */}

                <Col md={6}>
                  <label>Document Description </label>
                  <br />

                  {recordData.docs_from_rep &&
                    recordData.docs_from_rep.map(rec => (
                      <Input
                        type="text"
                        name="task_type"
                        disabled
                        value={rec.description}
                        className="admin-input"
                      ></Input>
                    ))}
                </Col>
              </>
            ) : null}
            {recordData.rep_images_cannot_complete &&
              recordData.rep_images_cannot_complete.length > 0 ? (
              <Col md={6}>
                <label>Can not complete Photos</label>
                <br />
                {/* <a style={{ marginRight: '10px' }} href={process.env.REACT_APP_CDN + 'admin-documents/taskDocuments/' + recordData.task_id + "/rep/" + rec} >
     <img style={{ width: '60px' }} src={process.env.REACT_APP_CDN + 'admin-documents/taskDocuments/0306/rep/Image0.jpg'} />
   </a></> */}
                {recordData.rep_images_cannot_complete &&
                  recordData.rep_images_cannot_complete.map(
                    rec =>
                      rec &&
                      rec.key &&
                      rec.key.length > 0 && (
                        <a
                          style={{ marginRight: '10px' }}
                          target="_blank"
                          href={
                            process.env.REACT_APP_CDN + rec.key
                            // 'admin-documents/taskDocuments/' +
                            // recordData.task_id +
                            // '/rep/cannot-complete/images/' +
                            // recordData.taskDate.replace(/[^\d]/g, '') +
                            // '/' +
                            // rec
                          }
                        >
                          <img
                            style={{ width: '60px' }}
                            alt="rep roll over photos"
                            src={
                              process.env.REACT_APP_CDN + rec.key
                              // 'admin-documents/taskDocuments/' +
                              // recordData.task_id +
                              // '/rep/cannot-complete/images/' +
                              // recordData.taskDate.replace(/[^\d]/g, '') +
                              // '/' +
                              // rec
                            }
                          />
                        </a>
                      ),
                  )}
              </Col>
            ) : null}
            {recordData.cannot_complete_comments != '' ? (
              <Col md={6}>
                <label>Can not complete comments</label>
                <br />
                {/* <a style={{ marginRight: '10px' }} href={process.env.REACT_APP_CDN + 'admin-documents/taskDocuments/' + recordData.task_id + "/rep/" + rec} >
     <img style={{ width: '60px' }} src={process.env.REACT_APP_CDN + 'admin-documents/taskDocuments/0306/rep/Image0.jpg'} />
   </a></> */}
                <Input
                  type="text"
                  name="task_type"
                  disabled
                  value={recordData.cannot_complete_comments}
                  className="admin-input"
                ></Input>
              </Col>
            ) : null}
            {recordData.cannot_complete_reason != '' ? (
              <Col md={6}>
                <label>Can not complete reason</label>
                <br />
                {/* <a style={{ marginRight: '10px' }} href={process.env.REACT_APP_CDN + 'admin-documents/taskDocuments/' + recordData.task_id + "/rep/" + rec} >
     <img style={{ width: '60px' }} src={process.env.REACT_APP_CDN + 'admin-documents/taskDocuments/0306/rep/Image0.jpg'} />
   </a></> */}
                <Input
                  type="text"
                  name="task_type"
                  disabled
                  value={recordData.cannot_complete_reason}
                  className="admin-input"
                ></Input>
              </Col>
            ) : null}

            {/* </> */}

            <Col md={6}>
              <label>Delegated</label>
              <Input
                type="text"
                name="task_type"
                disabled
                value={recordData.delegated ? 'Yes' : 'No'}
                className="admin-input"
              ></Input>
            </Col>
            {recordData.delegated ? (
              <>
                <Col md={6}>
                  <label>Delegated Support rep</label>
                  <Input
                    type="text"
                    name="task_type"
                    disabled
                    value={recordData.support_rep_username}
                    className="admin-input"
                  ></Input>
                </Col>

                <Col md={6}>
                  <label>Delegated Comments</label>
                  <Input
                    type="text"
                    name="task_type"
                    disabled
                    value={recordData.delegated_comments}
                    className="admin-input"
                  ></Input>
                </Col>
              </>
            ) : null}
            {recordData.push_task_comments != '' ? (
              <Col md={6}>
                <label>Push Task Comments</label>
                <Input
                  type="text"
                  name="task_type"
                  disabled
                  value={recordData.push_task_comments}
                  className="admin-input"
                ></Input>
              </Col>
            ) : null}
            {/* {recordData.task_status == 'cannot_complete' ? (
              <>
              

                <Col md={6}>
                  <label>Can not Complete Photos</label>

                  {recordData.rep_images_cannot_complete &&
                    recordData.rep_images_cannot_complete.map(rec => (
                      <a
                        style={{ marginRight: '10px' }}
                        href={
                          process.env.REACT_APP_CDN +
                          'admin-documents/taskDocuments/' +
                          recordData.task_id +
                          '/rep/cannot-complete/' +
                          rec
                        }
                      >
                        <img
                          src={
                            process.env.REACT_APP_CDN +
                            'admin-documents/taskDocuments/' +
                            recordData.task_id +
                            '/rep/cannot-complete/' +
                            rec
                          }
                        />
                      </a>
                    ))}
                </Col>
              </>
            ) : null} */}
            {recordData.pause_task_reason != '' ? (
              <Col md={6}>
                <label> Paused Reason</label>
                <Input
                  type="text"
                  disabled
                  value={recordData.pause_task_reason}
                  className="admin-input"
                ></Input>
              </Col>
            ) : null}

            {recordData.questions &&
              recordData.questions.map(rec => (
                <>
                  <Col md={6}>
                    <label> Question</label>
                    <Input
                      type="text"
                      disabled
                      value={rec.question}
                      className="admin-input"
                    ></Input>
                  </Col>

                  <Col md={6}>
                    <label> Rep's Answer</label>

                    <Input
                      disabled
                      value={rec.answer_from_rep}
                      className="admin-input"
                    ></Input>
                  </Col>
                </>
              ))}
          </Row>
          <Row className="modalRow">
            <Col>
              <Button onClick={handleClose} variant="outlined" color="primary">
                Close
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <Dialog open={requiredError ? true : false}>
        <DialogContent>
          <DialogContentText>
            <Row>
              <Col md={12}>
                {/* <b style={{ color: 'red' }}>Thank you</b>
                <br />
                <br /> */}
                <label style={{ color: 'red' }}>{requiredError}</label>
              </Col>
            </Row>
            <br />
          </DialogContentText>
          <Button
            onClick={() => setRequiredError('')}
            variant="outlined"
            color="primary"
          >
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Page>
        <Row>
          <Col xl={12} lg={12} md={12} style={{ paddingLeft: '0' }}>
            <Card id="userManagement">
              <CardHeader className="card-header-main">
                Task Progress
              </CardHeader>
              <CardBody style={{ background: '#fff', padding: '1.25rem' }}>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                        TabIndicatorProps={{ style: { background: '#D71920' } }}
                        className="tabb"
                      >
                        <Tab
                          label="Approved Tasks"
                          value="1"
                          name="approvedTask"
                        />
                        {/* <Tab label="Vi Task" value="2" /> */}
                        {/* <Tab label="In Progress" value="3" />
                        <Tab label="Completed" value="4" /> */}
                      </TabList>
                    </Box>
                    <TabPanel value="1" style={{ padding: '24px 0px 0px 0px' }}>
                      <div className="antdTable">
                        {taskData
                          .sort((a, b) =>
                            a.task_name.localeCompare(b.task_name),
                          )
                          .map(rec => {
                            const curUsername = sessionStorage.getItem(
                              'parentSupUsername',
                            )
                              ? sessionStorage.getItem('parentSupUsername')
                              : sessionStorage.getItem('username');
                            // console.log('curUsername', curUsername);
                            if (
                              rec.supplier_name == curUsername &&
                              (rec.task_status == 'approved' ||
                                (rec.task_status === 'done' &&
                                  rec.task_approval === 'not_required'))
                            ) {
                              // console.log(rec);
                              dataAll.push(rec);
                            }
                          })}
                        {viewDataBool ? (
                          <>
                            <Button
                              style={{
                                cursor: 'pointer',
                                fontWeight: 'bold',
                                marginLeft: '10px',
                                marginBottom: '10px',
                              }}
                              onClick={() => {
                                setViewDataBool(false);
                              }}
                            >
                              Back
                            </Button>
                            <div style={{ clear: 'both' }}></div>
                            <Table
                              columns={columnss}
                              dataSource={tableD}
                              onChange={(
                                pagination,
                                filters,
                                sorter,
                                currentPageData,
                              ) => filter(currentPageData.currentDataSource)}
                              pagination={{
                                total: filteredData.length,
                                showTotal: total => `Total ${total} items`,
                                pageSizeOptions: [
                                  '10',
                                  '50',
                                  '100',
                                  '200',
                                  '500',
                                ],
                              }}
                            // bordered
                            />
                          </>
                        ) : (
                          <>
                            <Row style={{ marginBottom: '20px' }}>
                              <Col md="3">
                                <label>
                                  Start Date
                                  <span
                                    style={{ color: 'red' }}
                                  >
                                    {' '}
                                    *
                                  </span>
                                </label>
                                <DatePicker
                                  className="textBox"
                                  dateFormat={'dd/MM/yyyy'}
                                  style={{
                                    border: '1px solid #ced4da',
                                    zIndex: 3
                                  }}
                                  maxDate={dateRange.endDate}
                                  // disabled={suppDis}
                                  selected={dateRange.startDate}
                                  locale="en-gb"
                                  onChange={date => {
                                    console.log(
                                      'datess', date,
                                    );
                                    setDateRange({
                                      ...dateRange,
                                      startDate: date
                                    })

                                  }}

                                  // maxDate={
                                  //   taskInfo[i].endDateDis
                                  // }
                                  placeholderText="Start Date"
                                />

                              </Col>
                              <Col md="3">
                                <label>
                                  End Date
                                  <span
                                    style={{ color: 'red' }}
                                  >
                                    {' '}
                                    *
                                  </span>
                                </label>
                                <DatePicker
                                  className="textBox"
                                  dateFormat={'dd/MM/yyyy'}
                                  // disabled={suppDis}
                                  style={{
                                    border: '1px solid #ced4da',
                                    zIndex: 3
                                  }}
                                  selected={dateRange.endDate}
                                  locale="en-gb"
                                  minDate={dateRange.startDate}
                                  onChange={date => {
                                    // setRequiredError({
                                    //   weekEndDate: '',
                                    // });
                                    // taskInfo[i].endDate = date;
                                    // setTaskInfo([...taskInfo]);
                                    setDateRange({
                                      ...dateRange,
                                      endDate: date
                                    })
                                  }}
                                  placeholderText="End Date"
                                />

                              </Col>
                              <Col md="3" style={{ display: 'flex', alignItems: 'center', marginTop: 'auto' }}>
                                {/* <div style={{ display: 'flex', alignItems: 'center', marginTop: '30px' }}> */}
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={e => {
                                    e.preventDefault()
                                    searchTask();
                                  }}
                                >
                                  <span>
                                    <b> Search</b>
                                  </span>
                                </Button>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={e => {
                                    e.preventDefault()
                                    setDateRange({
                                      startDate: null,
                                      endDate: null,
                                    })
                                  }}
                                >
                                  <span>
                                    <b> Clear</b>
                                  </span>
                                </Button>
                                {/* </div> */}



                              </Col>


                            </Row>
                            <Table
                              columns={columns}
                              dataSource={filteredDataTask}
                              // dataSource={dataTask}
                              onChange={(
                                pagination,
                                filters,
                                sorter,
                                currentPageData,
                              ) => filter(currentPageData.currentDataSource)}
                              pagination={{
                                total: filteredData.length,
                                showTotal: total => `Total ${total} items`,
                                pageSizeOptions: [
                                  '10',
                                  '50',
                                  '100',
                                  '200',
                                  '500',
                                ],
                              }}
                            // bordered
                            />

                          </>

                        )}
                      </div>
                    </TabPanel>
                    <TabPanel value="2" style={{ padding: '24px 0px 0px 0px' }}>
                      <div className="antdTable">
                        {taskData
                          .sort((a, b) =>
                            a.task_name.localeCompare(b.task_name),
                          )
                          .map(rec => {
                            const curUsername = sessionStorage.getItem(
                              'parentSupUsername',
                            )
                              ? sessionStorage.getItem('parentSupUsername')
                              : sessionStorage.getItem('username');
                            if (
                              rec.task_status == 'created' &&
                              rec.supplier_name == curUsername
                            ) {
                              // console.log(rec);
                              dataCreated.push(rec);
                            }
                          })}
                        <Table
                          columns={columns}
                          dataSource={dataCreated}
                          onChange={(
                            pagination,
                            filters,
                            sorter,
                            currentPageData,
                          ) => filter(currentPageData.currentDataSource)}
                          pagination={{
                            total: filteredData.length,
                            showTotal: total => `Total ${total} items`,
                            pageSizeOptions: ['10', '50', '100', '200', '500'],
                          }}
                        // bordered
                        />
                      </div>
                    </TabPanel>
                    <TabPanel value="3" style={{ padding: '24px 0px 0px 0px' }}>
                      <div className="antdTable">
                        {taskData
                          .sort((a, b) =>
                            a.task_name.localeCompare(b.task_name),
                          )
                          .map(rec => {
                            const curUsername = sessionStorage.getItem(
                              'parentSupUsername',
                            )
                              ? sessionStorage.getItem('parentSupUsername')
                              : sessionStorage.getItem('username');
                            if (
                              rec.task_status == 'in_progress' &&
                              rec.supplier_name == curUsername
                            ) {
                              // console.log(rec);
                              dataProgress.push(rec);
                            }
                          })}
                        <Table
                          columns={columns}
                          dataSource={dataProgress}
                          onChange={(
                            pagination,
                            filters,
                            sorter,
                            currentPageData,
                          ) => filter(currentPageData.currentDataSource)}
                          pagination={{
                            total: filteredData.length,
                            showTotal: total => `Total ${total} items`,
                            pageSizeOptions: ['10', '50', '100', '200', '500'],
                          }}
                        // bordered
                        />
                      </div>
                    </TabPanel>
                    <TabPanel value="4" style={{ padding: '24px 0px 0px 0px' }}>
                      <div className="antdTable">
                        {taskData
                          .sort((a, b) =>
                            a.task_name.localeCompare(b.task_name),
                          )
                          .map(rec => {
                            const curUsername = sessionStorage.getItem(
                              'parentSupUsername',
                            )
                              ? sessionStorage.getItem('parentSupUsername')
                              : sessionStorage.getItem('username');
                            if (
                              rec.task_status == 'done' &&
                              rec.supplier_name == curUsername
                            ) {
                              // console.log(rec);
                              dataDone.push(rec);
                            }
                          })}
                        <Table
                          columns={columns}
                          dataSource={tableD}
                          onChange={(
                            pagination,
                            filters,
                            sorter,
                            currentPageData,
                          ) => filter(currentPageData.currentDataSource)}
                          pagination={{
                            total: filteredData.length,
                            showTotal: total => `Total ${total} items`,
                            pageSizeOptions: ['10', '50', '100', '200', '500'],
                          }}
                        // bordered
                        />
                      </div>
                    </TabPanel>
                  </TabContext>
                </Box>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <img src={greyBanner} className="greyBanner"></img>
          </Col>
        </Row>
      </Page>
    </>
  );
};

export default RequestInfoTable;
