
import Page from '../../components/Page';
import React, { useState, useRef } from 'react';
import { useEffect } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    // Table,
} from 'reactstrap';
import Upload from 'rc-upload';
import { BiUpload } from 'react-icons/bi';
import AWS from 'aws-sdk';
import axios from 'axios';

const AdminUploadCate = (props) => {

    const { renderSupplierOptions, setUploadCataHint, uploadCataHint, hasChosen, setHasChosen } = props

    const [ProductCatalogueField, setProductCatalogueField] = React.useState({
        supplier_id: '',
        // supplier_name: '',
        catalogues: [],
    });

    //////// onchange input handler function
    const ProductCatalogueInputHandler = e => {
        e.preventDefault();
        setProductCatalogueField({
            ...ProductCatalogueField,
            [e.target.name]: e.target.value,
            catalogues: [],
        });
        setHasChosen({
            ...hasChosen,
            catalogueChose: false,
        });
    };

    const cancelDoc = e => {
        e.preventDefault();
        setProductCatalogueField({
            ...ProductCatalogueField,
            catalogues: [],
        });
        setHasChosen({
            catalogueChose: false,
            rangelistChose: false,
        });
    };
    const uploadDoc = async e => {
        e.preventDefault();

        // uploadCataToDB();
        // first upload to S3 then upload to DB
        uploadCataToS3();
    };

    const uploadCataToDB = () => {
        console.log('uploading cata to DB', ProductCatalogueField);
        const docNameList = ProductCatalogueField.catalogues.map(item => {
            return {
                document: item.document.name,
                comments: item.comments,
            };
        });

        const supplierInfoArr = ProductCatalogueField.supplier_id.split(',');

        const data = {
            ...ProductCatalogueField,
            supplier_id: supplierInfoArr[0],
            supplier_name: supplierInfoArr[1],
            document: ProductCatalogueField.catalogues[0].document.name,
            catalogues: docNameList,
            datetime: new Date(),
            notes: '',
            status: 'Approved',
            doc_type: 'Catalogue',
            upload_by: 'admin',
        };
        // console.log('uploadDoc payload', data);
        // save data to DB
        //  TODO: issue is data will be overwrite instead of just add the catalogue to current catalogues array
        axios({
            method: 'POST',
            headers: {
                Authorization: sessionStorage.getItem('idtoken'),
            },
            url: process.env.REACT_APP_API_BASE + 'product-catalogue',
            data: data,
        })
            .then(response => {
                // console.log(response);
                if (response.status == 200) {
                    console.log('doc for supplier added to s3');
                    setProductCatalogueField({
                        ...ProductCatalogueField,
                        catalogues: [],
                        supplier_id: '',
                    });
                    setHasChosen({
                        ...hasChosen,
                        catalogueChose: false,
                    });
                    setUploadCataHint({
                        ...uploadCataHint,
                        open: true,
                        text: 'Catalogue successfully uploaded',
                        title: 'Thank you',
                    });
                    setTimeout(() => {
                        setUploadCataHint({
                            ...uploadCataHint,
                            open: false,
                        });
                    }, 6000);
                } else if (response.status == 401) {
                    console.log('500,,,,', response);
                }
            })
            .catch(error => {
                console.log(error.response.data.status);
                if (error.response.data.status == 'Document already exists') {
                    setProductCatalogueField({
                        ...ProductCatalogueField,
                        catalogues: [],
                    });
                    setHasChosen({
                        ...hasChosen,
                        catalogueChose: false,
                    });
                    setUploadCataHint({
                        ...uploadCataHint,
                        open: true,
                        text: 'Document already exists',
                        title: 'Sorry',
                    });
                    setTimeout(() => {
                        setUploadCataHint({
                            ...uploadCataHint,
                            open: false,
                        });
                    }, 6000);
                }
                if (
                    error.response.status == 401 &&
                    error.response.data.message == 'The incoming token has expired'
                ) {
                    alert('Session timed out, please login again.');
                    localStorage.clear();
                    sessionStorage.clear();
                    localStorage.clear();
                    // localStorage.removeItem( "accessLevel" );
                    // reset page location
                    // console.log('logout succesfully');
                    document.location.replace('/');
                } else {
                    console.log('cata for supplier fail...', error.status);
                }
            });
    };

    const uploadCataToS3 = () => {
        // console.log('uploading files to S3');
        const supplierInfoArr = ProductCatalogueField.supplier_id.split(',');
        const supplier_id = supplierInfoArr[0];
        // ----------------------S3 process start ----------------

        const uploadFilesList = ProductCatalogueField.catalogues.map(
            item => item.document,
        );
        // console.log('uploadFilesList', uploadFilesList);

        AWS.config.update({
            accessKeyId: sessionStorage.getItem('AccessKeyId'),
            secretAccessKey: sessionStorage.getItem('AccessKeySec'),
            sessionToken: sessionStorage.getItem('sessionToken'),
        });
        const myBucket = new AWS.S3({
            params: { Bucket: process.env.REACT_APP_BUCKET_NAME },
            region: 'ap-southeast-2',
        });

        const checkExistParams = {
            Bucket: process.env.REACT_APP_BUCKET_NAME,
        };
        myBucket.listObjectsV2(checkExistParams, function (err, data) {
            if (err) {
                console.log('get all list obj err', err);
                // TODO: token expire err will be erased from backend
                // TODO: remove this part when backend resolve the issue
                setUploadCataHint({
                    ...uploadCataHint,
                    open: true,
                    text: err.message,
                    title: 'Sorry',
                });
                setTimeout(() => {
                    setUploadCataHint({
                        ...uploadCataHint,
                        open: false,
                    });
                }, 6000);
            } else {
                let fileExist = false;
                const allFileList = data.Contents;
                for (let i = 0; i < uploadFilesList.length; i++) {
                    const file = uploadFilesList[i];
                    const curKey = `admin-documents/supplier/${supplier_id}/product-catalogues/${file.name}`;
                    // console.log('curKey,,', curKey);
                    allFileList.forEach(item => {
                        if (item.Key == curKey) {
                            fileExist = true;
                            return;
                        }
                    });

                    // console.log('exist', fileExist);

                    if (fileExist) {
                        console.log('cannot upload file already exist');
                        setUploadCataHint({
                            ...uploadCataHint,
                            open: true,
                            text: 'File already exist, please change file name',
                            title: 'Sorry',
                        });
                        setProductCatalogueField({
                            ...ProductCatalogueField,
                            catalogues: [],
                        });
                        setHasChosen({
                            ...hasChosen,
                            catalogueChose: false,
                        });
                        setTimeout(() => {
                            setUploadCataHint({
                                ...uploadCataHint,
                                text: 'File already exist, please change file name',
                                title: 'Sorry',
                                open: false,
                            });
                        }, 6000);
                        return;
                    } else {
                        // upload the file if file not exist in bucket
                        const params = {
                            ACL: 'bucket-owner-full-control',
                            Body: file,
                            Bucket: process.env.REACT_APP_BUCKET_NAME,
                            Key: curKey,
                        };
                        myBucket
                            .putObject(params)
                            .on('httpUploadProgress', (evt, response) => {
                                console.log(Math.round((evt.loaded / evt.total) * 100));
                            })
                            .send((err, data) => {
                                if (err) {
                                    console.log(err);
                                } else {
                                    console.log(data);
                                    if (data) {
                                        console.log('file uploaded successfully data:=>', data);
                                        // if doc upload to S3 then update the DB
                                        uploadCataToDB();
                                    }
                                }
                            });
                    }
                }
            }
        });
    };


    // const renderSupplierOptions = () => {
    //     return supplierList
    //         .sort((a, b) => a.full_company_name.localeCompare(b.full_company_name))
    //         .map((item, i) => (
    //             <option key={i} value={`${item.id},${item.full_company_name}`}>
    //                 {item.id} - {item.full_company_name}
    //             </option>
    //         ));
    // };

    const beforeUploadHandler = file => {
        // console.log('>>> beforeUpload', file);
        // check if has that file already
        // const curFileName = file.name;
        ProductCatalogueField.catalogues.push({
            document: file,
            comments: '',
        });
        // console.log('product cat', ProductCatalogueField);
        setProductCatalogueField({
            ...ProductCatalogueField,
            catalogues: [...ProductCatalogueField.catalogues],
        });
    };
    return (
        <Form>
            <FormGroup>
                <Row style={{marginBottom: "1rem"}}>
                    <Col style={{ maxWidth: '25%', minWidth: '15rem' }}>
                        <label>Supplier</label>
                        <Input
                            type="select"
                            name="supplier_id"
                            onChange={ProductCatalogueInputHandler}
                            value={ProductCatalogueField.supplier_id}
                            className="admin-input"
                            required
                            style={{
                                fontSize: "0.7rem",
                                width: "100%",
                                height:"80%",
                                padding: "0.3rem 0.5rem",
                                border: "1px solid #ced4da",
                              }}
                        >
                            <option selected value="">
                                Select Supplier
                            </option>
                            {renderSupplierOptions()}
                        </Input>
                    </Col>
                    <Col style={{ width: '10%' }} lg="1">
                        <label
                            style={{ padding: '4px', visibility: 'hidden' }}
                        >
                            Upload{' '}
                        </label>
                        <Upload
                            style={{ padding: '30%' }}
                            disabled={
                                ProductCatalogueField.supplier_id == ''
                                    ? true
                                    : ProductCatalogueField.catalogues.length ==
                                        1
                                        ? true
                                        : false
                            }
                            beforeUpload={beforeUploadHandler}
                            accept=".pdf, .doc,.docx, .xls, .xlsx, .ppt, .pptx"
                        >
                            <BiUpload
                                style={{
                                    fontSize: '23px',
                                    color: `${ProductCatalogueField.supplier_id == ''
                                        ? 'gray'
                                        : ProductCatalogueField.catalogues
                                            .length == 1
                                            ? 'gray'
                                            : 'rgba(0, 0, 0, 0.85)'
                                        }`,
                                }}
                            />
                        </Upload>
                    </Col>
                </Row>
                <Row style={{ marginBottom: 0 }}>
                    <Col style={{ marginBottom: 0 }}>
                        <label style={{ color: 'gray' }}>
                            Please only upload PDF or Office file here.
                        </label>
                    </Col>
                </Row>

                {ProductCatalogueField.catalogues.length > 0 ? (
                    <>
                        <Row>
                            <Col md="5">
                                <label>Document Name</label>
                            </Col>
                            <Col md="3">
                                <label> Comments </label>
                            </Col>
                        </Row>
                        {ProductCatalogueField.catalogues.map(
                            (item, index) => (
                                <Row>
                                    <Col md="5">
                                        <Input
                                            type="text"
                                            placeHolder="Product Sheet"
                                            name="document_name"
                                            disabled
                                            value={
                                                ProductCatalogueField.catalogues[
                                                    index
                                                ].document.name
                                            }
                                            className="admin-input"
                                        />
                                    </Col>
                                    <Col md="3">
                                        <Input
                                            type="text"
                                            // style={{ marginTop: '7px' }}
                                            name="comments"
                                            onChange={e => {
                                                ProductCatalogueField.catalogues[
                                                    index
                                                ].comments = e.target.value;
                                                setProductCatalogueField({
                                                    ...ProductCatalogueField,
                                                    catalogues: [
                                                        ...ProductCatalogueField.catalogues,
                                                    ],
                                                });
                                            }}
                                            value={
                                                ProductCatalogueField.catalogues[
                                                    index
                                                ].comments
                                            }
                                            className="admin-input"
                                            placeHolder="Comments/Notes"
                                        />
                                    </Col>
                                </Row>
                            ),
                        )}
                    </>
                ) : null}
                <Row style={{ marginTop: '0px' }}>
                    <Col lg="8" md="8">
                        <Button
                            variant="outlined"
                            onClick={e => {
                                uploadDoc(e);
                            }}
                            color="primary"
                            disabled={
                                ProductCatalogueField.catalogues[0]
                                    ? false
                                    : true
                            }
                        >
                            {' '}
                            Upload
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={e => {
                                cancelDoc(e);
                            }}
                            color="primary"
                        >
                            Cancel
                        </Button>
                    </Col>
                </Row>
            </FormGroup>
        </Form>
    )
}

export default AdminUploadCate
