import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Table, Tag, Space, Progress } from 'antd';
import 'antd/dist/antd.min.css';
import { Button, Input, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment';
import AWS from 'aws-sdk';
import S3 from 'react-aws-s3';
import { confirmAlert } from 'react-confirm-alert';

import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import axios from 'axios';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import Multiselect from 'multiselect-react-dropdown';
import { DialogTitle } from '@material-ui/core';
import greyBanner from '../../assets/img/logo/Banner Grey.png';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/react';
import _ from 'lodash';
import * as MIME from 'mime';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
const override = css`
  display: block;
  margin: 20% auto;
  border-color: #fff;
`;

const TaskManagementTable = (props) => {
  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState('');
  const [searchColText, setSearchColText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [loading, setLoading] = React.useState(false);
  const [color, setColor] = React.useState('#ffffff');
  const searchInput = useRef(null);
  let [filteredData] = useState();
  console.log(props);
  const taskManagData = [
    {
      // 'date': '02/06/2022',
      // 'supplier': 'Supp 1',
      // 'country': 'AU',
      // 'state': 'VIC',
      // 'stores': 'Store 1',
      // 'frequency': 'One Off',
      // 'task_id': 'Task 1',
      // 'task_name': 'Name of task',
      // 'task_type': 'Task type',
      // 'task_priority': 'Priority 1',
      // 'task_description': 'desc',
    },
  ];

  console.log(taskManagData);

  const handleRowClick = (e) => {
    e.preventDefault();
    console.log('You clicked.');
  };
  const rec = taskManagData.map(({ ...item }) => ({
    ...item,
  }));

  const [taskData, setTaskData] = React.useState([]);
  const [reassignOpen, setReassignOpen] = React.useState(false);
  const [reAssignPayload, setReassignPayload] = useState({});
  const [dataTask, setDataTask] = useState([]);
  const [supplierN, setSupplier] = useState([]);
  const getTasks = () => {
    setLoading(true);
    const curUserRole = localStorage.getItem('userRole');
    // let url = `${process.env.REACT_APP_API_BASE}task-management/unarchived`;
    let url = `${process.env.REACT_APP_CDN}unarchived_tasks/tasks.json`;
    // let url = `${process.env.REACT_APP_TASKS_BASE}unarchived_tasks`;
    if (curUserRole == 'manager') {
      const curManagerState = localStorage.getItem('userState');
      console.log(' manager login , get only for that state task');

      // url = `${process.env.REACT_APP_CDN}task-management/manager/unarchive/${curManagerState}`;
      url = `${process.env.REACT_APP_CDN}tasks/unarchived/${curManagerState}/tasks.json`;
      // url = `${process.env.REACT_APP_TASKS_BASE}unarchived_tasks?state=${curManagerState}`;
    }

    axios({
      method: 'GET',
      // headers: {
      //   Authorization: sessionStorage.getItem('idtoken'),
      // },
      url: url,
    })
      .then((response) => {
        console.log('task management data get res,', response);
        // console.log('task management data get res,',  response.data.Items);
        let data;
        if (curUserRole == 'manager') {
          data = response.data;
        } else {
          data = response.data.Items;
          // axios({
          //   method: 'GET',
          //   headers: {
          //     Authorization: sessionStorage.getItem('idtoken'),
          //   },
          //   url: process.env.REACT_APP_API_BASE + 'admin-portal/store',
          // }).then(async response => {
          //   console.log(response.data.body.Items);
          //   const store_data = response.data.body.Items;
          //   data.map(rec => {
          //     store_data.map(rec1 => {
          //       if (rec.store_id == rec1.store_id) {
          //         rec.store_state = rec1.state;
          //         console.log('store first condition', rec);
          //       } else {
          //         console.log('store second condition', rec);
          //         rec.store_state = '';
          //       }
          //     });
          //   });
          // });
          //     data.push(rec);
        }

        // data = response.data.body.Items || [];

        const modifyData = data.sort((a, b) => a.task_name.localeCompare(b.task_name));
        // setTaskData(modifyData)
        var suppData = [];
        var suppData1 = {};
        modifyData.map((rec) => {
          rec.checked = false;
          if (props.supplierIdP != '' && props.supplierIdP == rec.supplier_id) {
            console.log(rec);
            suppData.push(rec);
          }
          if (props.supplierIdP == '' || props.supplierIdP == null) {
            suppData = modifyData;
          }
          // else {
          //   suppData.push(data)
          // }
        });

        const groupTaskId = _.groupBy(suppData, 'task_id');
        // const groupTaskId = _.groupBy(suppData, 'task_name');
        var taskGG = [];
        Object.entries(groupTaskId).map(([key, value]) => {
          console.log('okkkk', value);
          console.log(key);

          taskGG.push({ [key]: value });
        });
        setDataTask(taskGG);
        console.log(groupTaskId);
        setTaskData(suppData);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error, error.response);
        // console.log(error.response.status);s
        if (
          error.response &&
          error.response.status == 401 &&
          error.response.data.message == 'The incoming token has expired'
        ) {
          alert('Session timed out, please login again.');
          localStorage.clear();
          sessionStorage.clear();
          localStorage.clear();
          // localStorage.removeItem( "accessLevel" );
          // reset page location
          console.log('logout succesfully');
          document.location.replace('/');
        }

        setLoading(false);
      });
  };
  useEffect(() => {
    getTasks();
  }, []);
  console.log('modifyTaskManage', rec);
  // const getColumnSearchProps = (dataIndex) => ({
  //   // filterDropdown: ({
  //   //   setSelectedkeys,
  //   //   selectedKeys,
  //   //   confirm,
  //   //   clearFilters,
  //   // }) => (
  //   //   <div style={{ padding: 0 }}>
  //   //     <br />
  //   //     <Input
  //   //       placeholder={`Search ${dataIndex}`}
  //   //       value={selectedKeys[0]}
  //   //       onChange={(e) =>
  //   //         setSelectedkeys(e.target.value ? [e.target.value] : [])}
  //   //       onPressEnter={() => handleSearchCol(selectedKeys, confirm, dataIndex)}
  //   //       style={{ width: 167, marginLeft: '7px', display: "block", marginTop: '-10px', height: '35px' }}
  //   //     />
  //   //     <Space className='btnGap'>
  //   //       <Button onClick={() => handleSearchCol(selectedKeys, confirm, dataIndex)}
  //   //         icon={<SearchOutlined />}
  //   //         size="small"
  //   //         style={{ width: 82, height: 36 }}
  //   //       >
  //   //         Search
  //   //       </Button>
  //   //       <Button onClick={() => handleResetCol(clearFilters)}
  //   //         size="small"
  //   //         style={{ width: 82, height: 36 }}
  //   //       >
  //   //         Reset
  //   //       </Button>
  //   //     </Space>
  //   //   </div>
  //   // ),
  //   // filterIcon: (filtered) => (
  //   //   <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
  //   // ),
  //   // onFilter: (value, record) =>
  //   //   record[dataIndex]
  //   //     ? record[dataIndex]
  //   //       .toString()
  //   //       .toLowerCase()
  //   //       .includes(value.toLowerCase())
  //   //     : "",
  //   // render: (text) =>
  //   //   searchedColumn === dataIndex ? (
  //   //     <Highlighter
  //   //       highlightStyle={{ backgroundColor: "#ffc69", padding: 0 }}
  //   //       searchWords={[searchColText]}
  //   //       autoEscape
  //   //       textToHighlight={text ? text.toString() : ""}
  //   //     />
  //   //   ) : (
  //   //     text
  //   //   ),
  // });

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    // TODO: if in future, no need, remove this
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {}, 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  // const handleSearchCol = (selectedKeys, confirm, dataIndex) => {
  //   confirm();
  //   setSearchColText(selectedKeys[0]);
  //   setSearchedCol(dataIndex);
  // };

  // const handleResetCol = (clearFilters) => {
  //   clearFilters();
  //   setSearchText("");
  // };

  const handleChange = (...sorter) => {
    console.log('sorter', sorter);
    const { order, field } = sorter[2];
    setSortedInfo({ columnKey: field, order });
    const currentDataSource = sorter[3].currentDataSource;
    setTaskData(currentDataSource);
  };
  const [selectedList, setSelectedList] = React.useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (selectedRowKeys, selectedRows) => {
    console.log('selectedRowKeys changed: ', selectedRows);
    setSelectedRowKeys(selectedRowKeys);
    setSelectedList(selectedRows);
    selectedRows.map((rec) => {
      if (rec.task_status == 'done') {
        setApprovedTask(false);
        console.log('false');
        // dataR.push({
        //   ...rec,
        //   task_status: 'approved',
        // });
      } else {
        setApprovedTask(true);
        return;
      }
    });
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;
  // const clearAll = () => {
  //   setSortedInfo({});
  //   setSearchText("");
  //   // taskManagData();
  // };

  // const handleSearch = (e) => {
  //   setSearchText(e.target.value);
  //   if (e.target.value === "") {
  //     //  loadData();
  //   }
  // }

  const globalSearch = () => {
    filteredData = rec.filter((value) => {
      return (
        value.supplier.toLowerCase().includes(searchText.toLowerCase()) ||
        value.country.toLowerCase().includes(searchText.toLowerCase()) ||
        value.state.toLowerCase().includes(searchText.toLowerCase())
      );
    });
    // setTaskManageData(filteredData);
  };
  const approveTask = (e, data) => {
    console.log(data);
    const taskInfo = [{ ...data, task_status: 'approved' }];

    console.log(taskInfo);

    // return;
    confirmAlert({
      title: 'Confirm to approve the task',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            setLoading(true);
            const taskInfo = [{ ...data, task_status: 'approved' }];
            console.log(data);
            console.log(taskInfo);

            // return;
            axios({
              method: 'PUT',
              headers: {
                Authorization: sessionStorage.getItem('idtoken'),
              },
              url: process.env.REACT_APP_API_BASE + 'task-management',
              data: taskInfo,
            })
              .then((response) => {
                console.log(response);
                // getTasks();
                console.log(tableD);
                var dat = [];
                tableD.map((rec) => {
                  if (rec.task_id == data.task_id && rec.SK == data.SK) {
                    dat.push({ ...rec, task_status: 'approved' });
                    return (rec.task_status = 'approved');
                  } else {
                    dat.push({ ...rec, task_status: rec.task_status });
                  }
                });
                setTableD(dat);
                setLoading(false);
                console.log(dataTask);

                setLoading(false);
              })
              .catch((error) => {
                console.log(error.response);
                console.log(error.response.status);
                if (
                  error.response.status == 401 &&
                  error.response.data.message == 'The incoming token has expired'
                ) {
                  alert('Session timed out, please login again.');
                  localStorage.clear();
                  sessionStorage.clear();
                  localStorage.clear();
                  // localStorage.removeItem( "accessLevel" );
                  // reset page location
                  console.log('logout succesfully');
                  document.location.replace('/');
                }
                if (error.response.status == 504) {
                  var dat = [];
                  tableD.map((rec) => {
                    if (rec.task_id == data.task_id && rec.SK == data.SK) {
                      dat.push({ ...rec, task_status: 'approved' });
                      return (rec.task_status = 'approved');
                    } else {
                      dat.push({ ...rec, task_status: rec.task_status });
                    }
                  });
                  setTableD(dat);
                  setLoading(false);
                  console.log(dataTask);

                  setLoading(false);
                }
              });
          },
        },
        {
          label: 'No',
          onClick: () => {
            setLoading(false);
            return;
          },
        },
      ],
    });
  };
  const handleCloseRe = () => {
    setReassignOpen(false);
  };
  const [errorFailed, setErrorFailed] = useState('');

  const handleReassign = () => {
    console.log('reassign data', reAssignPayload, dataTask);

    console.log(dataTask);
    // return;

    // return;
    setLoading(true);
    axios({
      method: 'PUT',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: process.env.REACT_APP_API_BASE + 'task-management',
      data: [{ ...reAssignPayload, task_status: 'reassigned' }],
    })
      .then((response) => {
        console.log('response.data.body.Item', response, response.data.body.Item);
        const responseData = response.data.body[0];
        // const responseData = response.data.body[0].Item;

        setReassignPayload([{ ...reAssignPayload, task_status: 'reassigned' }]);
        console.log('reassign task', [{ ...reAssignPayload, task_status: 'reassigned' }]);
        console.log('taskDdd', tableD);
        //  getTasks();
        setLoading(false);

        // dataTask.map(rec => {
        //   // console.log(rec);
        //   Object.entries(rec).map(([key, value]) => {
        //     // console.log(value);
        //     console.log(reAssignPayload);
        //     // console.log('reassigned');
        //     value.map(rec2 => {
        //       console.log(rec2);
        //       if (
        //         rec2.task_id == reAssignPayload.task_id &&
        //         rec2.SK == reAssignPayload.SK
        //       ) {
        //         console.log(rec2);
        //         console.log('reassigned');
        //         return (rec2.task_status = 'reassigned');
        //       }
        //     });
        //   });
        // });
        var dat = [];
        // getTasks();
        tableD.map(async (rec) => {
          if (rec.task_id == reAssignPayload.task_id && rec.SK == reAssignPayload.SK) {
            const urlEncodePK = encodeURIComponent(rec.PK);
            const urlEncodeSK = encodeURIComponent(rec.SK);
            // await axios({
            //   method: 'GET',
            //   headers: {
            //     'x-api-key': sessionStorage.getItem('apikey'),
            //   },
            //   url:
            //     process.env.REACT_APP_API_BASE +
            //     'task-management?PK=' +
            //     urlEncodePK +
            //     '&SK=' +
            //     urlEncodeSK,
            // }).then(response => {
            //   console.log(response.data.body.Item);
            //   const data = response.data.body.Item;
            //   dat.push({
            //     ...rec,
            //     task_status: data.task_status,
            //     taskDate: data.taskDate,
            //   });
            // });
            if (response.data.statusCode == '401') {
              console.log(response.data.body);
              setErrorFailed(response.data.body);
              dat.push({ ...rec, task_status: rec.task_status });
              setOpenUpd(true);
              setTimeout(() => {
                setOpenUpd(false);
              }, 3500);
              return;
            } else {
              dat.push({
                ...rec,
                task_status: responseData.task_status,
                taskDate: responseData.taskDate,
              });
            }
          } else {
            dat.push({ ...rec, task_status: rec.task_status });
          }
        });
        console.log(dat);
        setTableD(dat);
        setReassignOpen(false);
        setReassignPayload({});
      })
      .catch((error) => {
        console.log('error', error);
        if (error.response.status == 504) {
          var dat = [];
          tableD.map((rec) => {
            if (rec.task_id == reAssignPayload.task_id && rec.SK == reAssignPayload.SK) {
              dat.push({ ...rec, task_status: 'reassigned' });
              return (rec.task_status = 'reassigned');
            } else {
              dat.push({ ...rec, task_status: rec.task_status });
            }
          });
          setTableD(dat);
          setLoading(false);
          console.log(dataTask);
          setReassignOpen(false);
          setLoading(false);
          // setOpenUpd(true);
          //     setTimeout(() => {
          //       setOpenUpd(false);
          //     }, 3500);
        }
      });
  };

  const editTask = (e, real_data) => {
    console.log('in edsit', real_data);
    //api.dev.itrazoadi.com/gforce/task-management?PK=SP%23TIM_SUPPLIER&SK=TK%23T-8655%23ST%23QT0607WA%23DT%2305/06/2023
    // console.log(
    //   process.env.REACT_APP_API_BASE +
    //     'task-management?PK=' +
    //     data.PK +
    //     '&SK=' +
    //     data.SK,
    // );
    const url2 = `${process.env.REACT_APP_API_BASE}task-management?PK=${real_data.id}&SK=${real_data.PK}`;
    axios({
      method: 'GET',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: url2,
      // data: [{ ...reAssignPayload, task_status: 'reassigned' }],
    }).then((response) => {
      console.log(response.data.body.Item);
      const data = response.data.body.Item;
      const taskDat = props.taskInfo;
      // return;
      // var taskDte = data.taskDate && data.taskDate.split('/');

      // var d1 = new Date(
      //   Number(taskDte[2]),
      //   Number(taskDte[1]) - 1,
      //   Number(taskDte[0]),
      // );
      data.documentAdd &&
        data.documentAdd.map((rec) => {
          if (rec.document != '') {
            props.setEditView({ edit_adhoc: true, edit_task: true });
          } else {
            props.setEditView({ edit_adhoc: false, edit_task: true });
          }
        });
      // data.task_photos && (data.task_photos).map(rec => {
      //   props.setTaskPhotos([{
      //     ...rec,
      //     photo_name: rec.photo_name,
      //     task_photos_notes: rec.task_photos_notes,
      //     client_photos_shareable: rec.client_photos_shareable == 'yes' ? true : false
      //   }])
      // })
      // props.setEditView({ 'edit_task': true });
      console.log(data);
      // return;
      var caalc = [];
      caalc.push(data.callCycle);
      console.log(caalc);
      var statee = [];
      data.country.map((rec) => {
        if (rec.value == 'Australia') {
          statee.push(
            { label: 'ACT', value: 'ACT' },
            { label: 'NSW', value: 'NSW' },
            { label: 'NT', value: 'NT' },
            { label: 'QLD', value: 'QLD' },
            { label: 'SA', value: 'SA' },
            { label: 'TAS', value: 'TAS' },
            { label: 'VIC', value: 'VIC' },
            { label: 'WA', value: 'WA' }
          );
        } else if (rec.value == 'New Zealand') {
          statee.push({ label: 'NI', value: 'NI' }, { label: 'SI', value: 'SI' });
        }
      });
      console.log(props.filteredRetailer);
      var retailer = [];
      var suppliere = [];
      data.state.map((rec1) => {
        props.retailerList.map((rec) => {
          if (rec.state == rec1.value && rec.status == 'active') {
            retailer.push(rec);
          }
        });
        props.supplierListing.map((rec) => {
          if (rec.state == rec1.value) {
            suppliere.push(rec);
          }
        });
      });
      console.log(retailer);
      var storeIdList = [];
      var storeIdListVal = [];
      axios({
        method: 'GET',
        headers: {
          Authorization: sessionStorage.getItem('idtoken'),
        },
        url:
          process.env.REACT_APP_API_BASE +
          'call-cycle/list-by-supplier?supplier_username=' +
          data.supplier_name,
      }).then((response) => {
        console.log(response.data);
        if (response.data != null) {
          const admindata = response.data.body;

          admindata.map((rec) => {
            return (rec.checked = true);
          });
          console.log(admindata);
          var arr = [];
          data.callCycle.map((rec) => {
            console.log(rec);
            if (rec.call_status == 'active') {
              arr.push(rec.call_id);
            }
          });
          console.log(arr);
          var strId = arr.toString();
          console.log(strId);

          var cl = [];
          // if (taskInfo[i].recurring == true) {
          admindata.map((rec) => {
            console.log(rec);
            // if (taskInfo[i].recurringValue == rec.call_cycle_freq && rec.call_status == 'active') {
            if (rec.call_status == 'active') {
              console.log(rec);
              cl.push(rec);
              // storeIdList = [];
              rec.storeList.map((rec1) => {
                storeIdList.push(rec1);
                console.log('dattaa', rec1);
                console.log('dattaa1', rec);
                storeIdListVal.push({
                  value: rec1.store_id,
                  label: rec1.store_name,
                  store_name: rec1.store_name,
                  store_id: rec1.store_id,
                  // checked: true,
                });
              });
            }
          });
          console.log(cl);
          console.log(storeIdList);
          console.log(data);
        }
      });
      const stor = [];
      tableD.map((rec) => {
        if (data.task_id == rec.task_id) {
          stor.push(rec.stores);
        }
      });

      console.log(storesSelected, 'data.documentAdd');
      taskDat.map((rec) => {
        props.setTaskInfo([
          {
            ...rec,
            id: data.id,
            PK: data.PK,
            SK: data.SK,
            appsync_id: data.appsync_id,
            created_date: data.created_date ? new Date(data.created_date) : '',
            action: data.action,
            task_id: data.task_id,
            task_description: data.task_description,
            task_name: data.task_name,
            callCycle: caalc[0],
            storeOp: storeIdListVal,
            storeOptions: storeIdListVal,
            country: data.country,
            documentAdd: data.documentAdd,
            retailer_name: data.retailer_name,
            state: data.state,
            stateOptions: statee,
            store_id: data.store_id,
            store_name: data.store_name,
            fine_line: data.fine_line,
            supplier_id: data.supplier_id,
            supplier_name: data.supplier_name,
            supplier_name: data.supplier_name,
            taskDate: data.taskDate,
            task_approval: data.task_approval,
            task_approval_notes: data.task_approval_notes,
            task_priority: data.task_priority,
            task_type: data.task_type,
            dayOffCheck: data.oneOff == true ? true : false,
            recurringCheck: data.recurring == true ? true : false,
            recurring: data.recurring == true ? true : false,
            recurringValue: data.recurringValue ? data.recurringValue : '',
            startDate: data.startDate ? new Date(data.startDate) : '',
            endDate: data.endDate ? new Date(data.endDate) : '',
            week_startDate: data.week_startDate ? new Date(data.week_startDate) : '',
            questions: data.questions,
            stores: [data.stores],
            // storeOptions: [data.stores]
            task_photos: [
              {
                photo_name: data.task_photos[0].photo_name,
                task_photos_notes: data.task_photos[0].task_photos_notes,
                client_photos_shareable:
                  data.task_photos[0].client_photos_shareable == 'yes' ? true : false,
              },
            ],
            task_comments: [
              {
                comment: data.task_photos[0].comment,
                task_comments_notes: data.task_photos[0].task_comments_notes,
                client_comments_shareable:
                  data.task_photos[0].client_comments_shareable == 'yes' ? true : false,
              },
            ],
          },
        ]);
        // props.setCallCycleList(caalc[0])
        console.log(props.setRetailerList);
        props.setFilteredRetailer(retailer);
        props.setFilteredSupplier(suppliere);
        console.log(props.retailerList);
        props.setCallCycleList([{ callCycleOptions: caalc[0] }]);
      });

      console.log(props.taskInfo);
      props.setValue('1');

      // console.log({
      //   ...data,
      //   taskDate: new Date(data.taskDate), endDate: new Date(data.endDate), created_date: new Date(data.created_date), startDate: new Date(data.startDate),
      //   'taskDate': new Date(d1)
      // })
    });
  };
  const [viewData, setViewData] = React.useState([]);
  const [viewDataBool, setViewDataBool] = React.useState(false);
  const detailsTask = (e, data) => {
    console.log(data);
    const url2 = `${process.env.REACT_APP_API_BASE}task-management?PK=${data.id}&SK=${data.PK}`;
    axios({
      method: 'GET',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: url2,
      // data: [{ ...reAssignPayload, task_status: 'reassigned' }],
    }).then((response) => {
      console.log(response.data.body.Item);
      const record = response.data.body.Item;
      // const data = response.data.body.Item;
      // const taskDat = props.taskInfo;
      console.log(record.time_spent);
      if (record.time_spent != 'NaN' || record.time_spent != '') {
        console.log('in if');
        var hours = record.time_spent / (1000 * 60 * 60);
        var absoluteHours = Math.floor(hours);
        var h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours;

        //Get remainder from hours and convert to minutes
        var minutes = (hours - absoluteHours) * 60;
        var absoluteMinutes = Math.floor(minutes);
        var m = absoluteMinutes > 9 ? absoluteMinutes : '0' + absoluteMinutes;

        //Get remainder from minutes and convert to seconds
        var seconds = (minutes - absoluteMinutes) * 60;
        var absoluteSeconds = Math.floor(seconds);
        var s = absoluteSeconds > 9 ? absoluteSeconds : '0' + absoluteSeconds;

        const timmee = h + ':' + m + ':' + s;
        console.log(h + ':' + m + ':' + s);
        if (timmee != '0NaN:0NaN:0NaN') {
          setViewData({ ...record, time_spent: timmee });
        } else {
          setViewData({ ...record, time_spent: '0' });
        }
      }

      setOpen(true);
    });
  };
  const [taskD, setTaskD] = useState([]);
  const [approvedTask, setApprovedTask] = useState(true);
  const voidRecords = () => {
    var dataR = [];
    console.log(selectedList);
    selectedList.map((rec) => {
      dataR.push({
        PK: rec.PK,
        SK: rec.SK,
        id: rec.id,
        task_status: 'avoided',
      });
    });

    console.log(dataR);

    confirmAlert({
      title: 'Confirm to Void the task',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            setLoading(true);
            axios({
              method: 'PUT',
              headers: {
                Authorization: sessionStorage.getItem('idtoken'),
              },
              url: process.env.REACT_APP_API_BASE + 'task-management',
              data: dataR,
            })
              .then((response1) => {
                console.log(response1);
                var dataAvoid = [];
                tableD.map((rec) => {
                  console.log('tableD', rec);
                  dataR.map((rec1) => {
                    if (rec.taskDate == rec1.taskDate && rec.SK == rec1.SK) {
                      console.log('tableData', rec1);
                      rec.task_status = 'avoided';
                    }
                  });
                });
                console.log(tableD);
                setLoading(false);
                getTasks();
                //  const suppData = []
                //               dataTask.map((rec, value) => {
                //                 console.log('val', rec[tableD[0].task_name]);
                //                 const supDat = rec[tableD[0].task_name];
                //                 suppData =
                //                 // tableD[0].supplier_id =
                //                 //   rec[tableD[0].task_name][0].supplier_id;
                //               });
                console.log(tableD, 'qqqqq');
                anotherPage(tableD);
                setSelectedList([]);
                setSelectedRowKeys([]);
              })
              .catch((error) => {
                console.log(error.response);
                console.log(error.response.status);
                if (
                  error.response.status == 401 &&
                  error.response.data.message == 'The incoming token has expired'
                ) {
                  alert('Session timed out, please login again.');
                  localStorage.clear();
                  sessionStorage.clear();
                  localStorage.clear();
                  // localStorage.removeItem( "accessLevel" );
                  // reset page location
                  console.log('logout succesfully');
                  document.location.replace('/');
                }
                if (error.response.status == 504) {
                  var dataAvoid = [];
                  tableD.map((rec) => {
                    console.log('tableD', rec);
                    dataR.map((rec1) => {
                      if (rec.taskDate == rec1.taskDate && rec.SK == rec1.SK) {
                        console.log('tableData', rec1);
                        rec.task_status = 'avoided';
                      }
                    });
                  });
                  console.log(tableD);
                  setLoading(false);
                  getTasks();
                  anotherPage(tableD);
                  setSelectedList([]);
                  setSelectedRowKeys([]);
                }
              });
          },
        },
        {
          label: 'No',
          onClick: () => {
            return;
          },
        },
      ],
    });
  };
  const approveRecords = () => {
    var dataR = [];
    console.log(selectedList);
    selectedList.map((rec) => {
      dataR.push({
        PK: rec.PK,
        SK: rec.SK,
        id: rec.id,
        task_status: 'approved',
      });
    });
    console.log(dataR);
    confirmAlert({
      title: 'Confirm to Approve the task',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            setLoading(true);
            axios({
              method: 'PUT',
              headers: {
                Authorization: sessionStorage.getItem('idtoken'),
              },
              url: process.env.REACT_APP_API_BASE + 'task-management',
              data: dataR,
            })
              .then((response1) => {
                console.log(response1);
                var dataAvoid = [];
                const response = response1.data.body;

                var dat = [];
                dataR.map((rec2) => {
                  tableD.map((rec) => {
                    if (rec.id == rec2.id && rec.SK == rec2.SK) {
                      // rec.status == 'approved';
                      // dat.push({ ...rec, task_status: 'approved' });
                      return (rec.task_status = 'approved');
                    }
                  });
                });
                // setTableD(dat);
                console.log('tableD', rec);
                //   dataR.map(rec1 => {
                //     if (rec.taskDate == rec1.taskDate && rec.SK == rec1.SK) {

                //       console.log('tableData', rec1);
                //       rec.task_status = 'approved';
                //     }

                //   // });
                // });
                console.log(tableD);
                setLoading(false);
                getTasks();
                // anotherPage(tableD);
                setSelectedList([]);
                setSelectedRowKeys([]);
              })
              .catch((error) => {
                console.log(error.response);
                console.log(error.response.status);
                if (
                  error.response.status == 401 &&
                  error.response.data.message == 'The incoming token has expired'
                ) {
                  alert('Session timed out, please login again.');
                  localStorage.clear();
                  sessionStorage.clear();
                  localStorage.clear();
                  // localStorage.removeItem( "accessLevel" );
                  // reset page location
                  console.log('logout succesfully');
                  document.location.replace('/');
                }
                if (error.response.status == 504) {
                  var dat = [];
                  dataR.map((rec2) => {
                    tableD.map((rec) => {
                      if (rec.id == rec2.id && rec.SK == rec2.SK) {
                        // dat.push({ ...rec, task_status: 'approved' });
                        return (rec.task_status = 'approved');
                      }
                    });
                  });
                  console.log(tableD);
                  setLoading(false);
                  getTasks();
                  // anotherPage(tableD);
                  setSelectedList([]);
                  setSelectedRowKeys([]);
                }
              });
          },
        },
        {
          label: 'No',
          onClick: () => {
            return;
          },
        },
      ],
    });
  };
  const [tableD, setTableD] = useState([]);
  const [storesSelected, setSelectedStores] = useState([]);
  const anotherPage = async (real_data, index) => {
    console.log('dataqqq', real_data);
    let data;
    setLoading(true);
    await axios({
      method: 'GET',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url:
        process.env.REACT_APP_API_BASE +
        'task-management?task_id=' +
        real_data[0].task_id +
        '&supplier_id=' +
        real_data[0].supplier_id,
    }).then(async (response) => {
      console.log('view task  table', response);
      data = response.data.body;
      console.log(data);
      //   const store_data = response.data.body.Items;
      //   await real_data.map(async rec => {
      //     await store_data.map(rec1 => {
      //       if (rec.store_id == rec1.store_id) {
      //         rec.stores.state = rec1.state;
      //       } else {
      //         rec.stores.state = '';
      //       }
      //     });
      //     console.log('rec', rec);
      //     data.push(rec);

      // when state manager login, show the tasks for that state only
      const userState = localStorage.getItem('userState');
      const userRole = localStorage.getItem('userRole');
      if (userRole && userRole == 'manager' && userState && userState != '') {
        // console.log('state manager login, filter by state', userState);
        data = response.data.body.filter((item) => {
          if (item.stores && item.stores.state && item.stores.state == userState) {
            return item;
          }
        });
      }

      setLoading(false);
      //   });
      setTableD(data);
      setViewDataBool(true);

      var stor = [];
      data.map((rec) => {
        stor.push(rec.stores);
      });
      console.log(stor);
      let pp = stor.filter(
        (ele, ind) => ind === stor.findIndex((elem) => elem.store_id === ele.store_id)
      );

      console.log(pp);
      setSelectedStores(pp);
      // console.log('real_data', data);
      // });

      // axios({
      //   method: 'GET',
      //   headers: {
      //     Authorization: sessionStorage.getItem('idtoken'),
      //   },
      //   url: process.env.REACT_APP_API_BASE + 'admin-portal/store',
      // }).then(async response => {
      //   console.log(response.data.body.Items);
      //   const store_data = response.data.body.Items;
      //   real_data.map(rec => {
      //     store_data.map(rec1 => {
      //       if (rec.store_id == rec1.store_id) {
      //         rec.store_state = rec1.state;
      //         console.log('store first condition', rec);
      //       } else {
      //         console.log('store second condition', rec);
      //         rec.store_state = '';
      //       }
      //     });
      //     data.push(rec);

      //   });
      // });
      // setLoading(true);
      // setTimeout(() => {

      // setLoading(false);
      // }, 3000);
    });
  };
  const columns = [
    {
      title: 'Supplier',
      dataIndex: 'supplier_id',
      align: 'left',
      editable: true,
      // key: 'supplier_id',
      // ...getColumnSearchProps('supplier_id'),
      render: (text, record) => {
        console.log(record);
        Object.entries(record).map(([key, value]) => {
          {
            value.map((rec, index) => {
              if (value.indexOf(rec) == 0) {
                console.log(rec.supplier_name);
              }
            });
          }
        });
        return Object.entries(record).map(([key, value]) => (
          <>
            <>
              {value.map((rec, index) =>
                value.indexOf(rec) == 0 ? <p>{rec.supplier_id}</p> : null
              )}
              {/* {value.reduce((unique, o) =>
                console.log(unique)(
                  !unique.some(obj => obj.supplier_id == o.supplier_id),
                ) ? (
                  <p>{o.supplier_id}</p>
                ) : null,
              )} */}
            </>
            {/* ))} */}
          </>
        ));
        // return record.map(([key, value]) => <p>{key}</p>);
        // return <p>{record}</p>;
      },
    },
    // {
    //   title: 'Task ID',
    //   dataIndex: 'task_id',
    //   align: 'left',
    //   // editable: true,
    //   // ...getColumnSearchProps('task_id'),
    //   render: (text, record) => {
    //     console.log(record);
    //     return Object.entries(record).map(([key, value]) =>
    //       value.map(rec => <p> {rec.task_id}</p>),
    //     );
    //     // return record.map(([key, value]) => <p>{key}</p>);
    //     // return <p>{record}</p>;
    //   },
    // },
    {
      title: 'Task Name',
      dataIndex: 'task_name',
      align: 'left',
      editable: true,
      // ...getColumnSearchProps('task_name'),
      render: (text, record) => {
        console.log(record);
        return Object.entries(record).map(([key, value], i) => (
          // <Link to={{ pathname: '/ViewAllTasksTable', state: value }}>
          //   {key}
          // </Link>
          <p style={{ cursor: 'pointer' }} onClick={() => anotherPage(value, i)}>
            {value[0]['task_name']}
            {/* {key} */}
          </p>
        ));
        // return record.map(([key, value]) => <p>{key}</p>);
        // return <p>{record}</p>;
      },
    },

    {
      title: 'No. of Stores', // as required by Brad from Gforce, just change the word, the logic will not be changed
      // title: 'No. of Tasks',
      dataIndex: 'No of stores',
      align: 'left',
      editable: true,
      render: (text, record) => {
        console.log('record', record);
        return Object.entries(record).map(([key, value], i) => (
          <p style={{ cursor: 'pointer' }} onClick={() => anotherPage(value, i)}>
            {value.length}
          </p>
        ));
        // return record.map(([key, value]) => <p>{key}</p>);
        // return <p>{record}</p>;
      },
      // sorter: (a, b) => {
      //   const order = {'Critical': 4, 'High': 3, 'Medium': 2, 'Low': 1};
      //   return order[a.task_priority] - order[b.task_priority]
      // },
      // sortOrder: sortedInfo.columnKey === "task_priority" && sortedInfo.order,
    },
    {
      title: 'Progress',
      dataIndex: 'task_status',
      align: 'left',
      editable: true,

      render: (text, record) => {
        console.log(record);
        var perc = '0';
        var lengthRec = [];
        return Object.entries(record).map(
          ([key, value], i) => (
            value.map((rec) => {
              if (rec.task_status == 'done' || rec.task_status == 'approved') {
                lengthRec.push(rec);
                console.log('manage', rec.task_status);
                console.log('manage', lengthRec.length);
                console.log('manage', value.length);
                console.log((lengthRec.length / value.length) * 100);
              }
            }),
            (
              <>
                <p style={{ width: '25%', float: 'right' }}>
                  {lengthRec.length + '/' + value.length}
                </p>
                <Progress
                  percent={((lengthRec.length / value.length) * 100).toFixed(0)}
                  // percent={((lengthRec.length / value.length) * 100).toFixed(2)}
                  status="active"
                  style={{
                    width: '65%',
                    fontSize: '14px',
                    marginRight: '12px',
                  }}
                />
                <div style={{ clear: 'both' }}></div>
              </>
            )
          )
        );

        // return record.map(([key, value]) => <p>{key}</p>);
        // return <p>{record}</p>;
      },
    },
  ];

  // const getAllDownloadLinksOfOneFolderPromise = (prefix) => {
  //   AWS.config.update({
  //     accessKeyId: sessionStorage.getItem('AccessKeyId'),
  //     secretAccessKey: sessionStorage.getItem('AccessKeySec'),
  //     sessionToken: sessionStorage.getItem('sessionToken'),
  //   });
  //   const myBucket = new AWS.S3({
  //     params: { Bucket: process.env.REACT_APP_BUCKET_NAME },
  //     region: 'ap-southeast-2',
  //   });
  //   var paramss = {
  //     Bucket: process.env.REACT_APP_BUCKET_NAME,
  //     Prefix: prefix,
  //     Delimiter: '/',
  //     // MaxKeys: ''
  //   };

  //   return new Promise((resolve, reject) => {
  //     myBucket.listObjectsV2(paramss, function (err, data) {
  //       if (err) {
  //         console.log(err, err.stack);
  //         return reject(err)
  //       } // an error occurred
  //       else {
  //         console.log('list obj data', data);
  //         console.log(data.Contents);
  //         const aa = data.Contents;
  //         var array = [];
  //         aa.map(rec => {
  //           if (!rec.Key.includes('undefined')) {
  //             array.push(process.env.REACT_APP_CDN + rec.Key);
  //           }
  //         });
  //         console.log(array);
  //         return resolve(array)
  //       }
  //     });

  //   })

  // }

  const downloadZipFromS3 = async (e) => {
    // AWS.config.update({
    //   accessKeyId: sessionStorage.getItem('AccessKeyId'),
    //   secretAccessKey: sessionStorage.getItem('AccessKeySec'),
    //   sessionToken: sessionStorage.getItem('sessionToken'),
    // });
    // const myBucket = new AWS.S3({
    //   params: { Bucket: process.env.REACT_APP_BUCKET_NAME },
    //   region: 'ap-southeast-2',
    // });
    console.log('tableD', tableD);

    // rep images are based on task id and task date
    // rep cannot complete images are based on task id and task date
    // admin document are based on task id, including document not required signature and required signature
    var taskID = tableD[0].task_id;

    const zipUrl = `${process.env.REACT_APP_CDN}admin-documents/taskDocuments/zipFiles/${taskID}.zip`;
    const response = await fetch(zipUrl);
    response
      .blob()
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = `${taskID}.zip`;
        a.click();
      })
      .catch((e) => {
        console.log('download zip error ', e);
      });

    //  zip version
    // const keysObj = {
    //   adminDocuments: [...allDocsOfOneTask.adminDocsKeys.sigNotRequired],
    //   repSignedDocuments: [...allDocsOfOneTask.adminDocsKeys.sigRequired],
    //   taskDocuments: [...allDocsOfOneTask.repDocsKeys.images],
    //   incompleteTaskDocuments: [...allDocsOfOneTask.repDocsKeys.rep_images_cannot_complete],
    // }

    // console.log('final keys obj', keysObj)

    // downloadFiles(keysObj)

    //  zip version end -----------

    // if (allDocsOfOneTask.adminDocsKeys.sigNotRequired.length > 0) {
    //   // downloadFiles(allDocsOfOneTask.adminDocsKeys.sigNotRequired, '')
    //   finalKeyList = finalKeyList.concat([...allDocsOfOneTask.adminDocsKeys.sigNotRequired])
    // }

    // if (allDocsOfOneTask.adminDocsKeys.sigRequired.length > 0) {
    //   // downloadFiles(allDocsOfOneTask.adminDocsKeys.sigRequired, 'signed')
    //   finalKeyList = finalKeyList.concat([...allDocsOfOneTask.adminDocsKeys.sigRequired])
    // }

    // if (allDocsOfOneTask.repDocsKeys.images.length > 0) {
    //   // downloadFiles(allDocsOfOneTask.repDocsKeys.images, 'taskPhotos')
    //   finalKeyList = finalKeyList.concat([...allDocsOfOneTask.repDocsKeys.images])

    // }
    // if (allDocsOfOneTask.repDocsKeys.rep_images_cannot_complete.length > 0) {
    //   // downloadFiles(allDocsOfOneTask.repDocsKeys.rep_images_cannot_complete, 'taskIncompletePhotos')
    //   finalKeyList = finalKeyList.concat([...allDocsOfOneTask.repDocsKeys.rep_images_cannot_complete])

    // }

    // handleDownloadClick(e, finalKeyList)
  };

  // Old version of download file and created zip
  const downloadData = (e) => {
    // AWS.config.update({
    //   accessKeyId: sessionStorage.getItem('AccessKeyId'),
    //   secretAccessKey: sessionStorage.getItem('AccessKeySec'),
    //   sessionToken: sessionStorage.getItem('sessionToken'),
    // });
    // const myBucket = new AWS.S3({
    //   params: { Bucket: process.env.REACT_APP_BUCKET_NAME },
    //   region: 'ap-southeast-2',
    // });
    console.log('tableD', tableD);

    // rep images are based on task id and task date
    // rep cannot complete images are based on task id and task date
    // admin document are based on task id, including document not required signature and required signature
    var taskID = tableD[0].task_id;

    // const allDocsOfOneTask = {
    //   adminDocsKeys: {
    //     sigNotRequired: [],
    //     sigRequired: []
    //   },
    //   repDocsKeys: {
    //     images: [],
    //     rep_images_cannot_complete: []
    //   }
    // }
    let finalKeyList = [];

    // get keys for admin document not required signature
    if (tableD[0].documentAdd.length > 0) {
      tableD[0].documentAdd.forEach((item) => {
        if (item.document) {
          const curKey = `admin-documents/taskDocuments/${taskID}/${item.document}`;
          finalKeyList.push(curKey);
          // allDocsOfOneTask.adminDocsKeys.sigNotRequired.push(curKey)
        }
      });
    }

    tableD.map((rec) => {
      taskID = rec.task_id;
      const taskStatus = rec.task_status;

      // filter out the done tasks documents

      if (
        taskStatus == 'in_progress' ||
        taskStatus == 'paused' ||
        taskStatus == 'done' ||
        taskStatus == 'approved' ||
        taskStatus == 'rolled_over'
      ) {
        const taskDateNoSlash = rec.taskDate.replaceAll('/', '');
        console.log('taskDateNoSlash', taskDateNoSlash, rec.task_id);

        // get admin signed document keys
        if (rec.documentAdd.length > 0) {
          rec.documentAdd.forEach((item) => {
            // only get the signed and uploaded docs
            if (item.document && item.requiredDoc && item.signed && item.uploaded) {
              const curKey = item.key;
              finalKeyList.push(curKey);
              // allDocsOfOneTask.adminDocsKeys.sigRequired.push(curKey)
            }
          });
        }

        // get the reps docu keys
        if (rec.rep_images.length > 0) {
          rec.rep_images.forEach((item) => {
            if (item && item.key && item.key.length > 0) {
              const curKey = `${item.key}`;
              finalKeyList.push(curKey);
            }

            // allDocsOfOneTask.repDocsKeys.images.push(curKey)
          });
        }
        // if (rec.rep_images.length > 0) {
        //   rec.rep_images.forEach(item => {
        //     const curKey = `admin-documents/taskDocuments/${taskID}/rep/images/${taskDateNoSlash}/${item}`;
        //     finalKeyList.push(curKey);
        //     // allDocsOfOneTask.repDocsKeys.images.push(curKey)
        //   });
        // }

        if (rec.rep_images_cannot_complete.length > 0) {
          rec.rep_images_cannot_complete.forEach((item) => {
            if (item && item.key && item.key.length > 0) {
              const currKey = `${item.key}`;
              finalKeyList.push(currKey);
            }

            // allDocsOfOneTask.repDocsKeys.rep_images_cannot_complete.push(currKey)
          });
        }
        // if (rec.rep_images_cannot_complete.length > 0) {
        //   rec.rep_images_cannot_complete.forEach(item => {
        //     const currKey = `admin-documents/taskDocuments/${taskID}/rep/cannot-complete/images/${taskDateNoSlash}/${item}`;
        //     finalKeyList.push(currKey);
        //     // allDocsOfOneTask.repDocsKeys.rep_images_cannot_complete.push(currKey)
        //   });
        // }
      }
    });

    // console.log('tableD allDocsOfOneTask', finalKeyList)
    console.log(taskID);

    console.log('finalKeyList', finalKeyList);
    if (finalKeyList.length > 0) {
      downloadFiles(finalKeyList);
    }

    //  zip version
    // const keysObj = {
    //   adminDocuments: [...allDocsOfOneTask.adminDocsKeys.sigNotRequired],
    //   repSignedDocuments: [...allDocsOfOneTask.adminDocsKeys.sigRequired],
    //   taskDocuments: [...allDocsOfOneTask.repDocsKeys.images],
    //   incompleteTaskDocuments: [...allDocsOfOneTask.repDocsKeys.rep_images_cannot_complete],
    // }

    // console.log('final keys obj', keysObj)

    // downloadFiles(keysObj)

    //  zip version end -----------

    // if (allDocsOfOneTask.adminDocsKeys.sigNotRequired.length > 0) {
    //   // downloadFiles(allDocsOfOneTask.adminDocsKeys.sigNotRequired, '')
    //   finalKeyList = finalKeyList.concat([...allDocsOfOneTask.adminDocsKeys.sigNotRequired])
    // }

    // if (allDocsOfOneTask.adminDocsKeys.sigRequired.length > 0) {
    //   // downloadFiles(allDocsOfOneTask.adminDocsKeys.sigRequired, 'signed')
    //   finalKeyList = finalKeyList.concat([...allDocsOfOneTask.adminDocsKeys.sigRequired])
    // }

    // if (allDocsOfOneTask.repDocsKeys.images.length > 0) {
    //   // downloadFiles(allDocsOfOneTask.repDocsKeys.images, 'taskPhotos')
    //   finalKeyList = finalKeyList.concat([...allDocsOfOneTask.repDocsKeys.images])

    // }
    // if (allDocsOfOneTask.repDocsKeys.rep_images_cannot_complete.length > 0) {
    //   // downloadFiles(allDocsOfOneTask.repDocsKeys.rep_images_cannot_complete, 'taskIncompletePhotos')
    //   finalKeyList = finalKeyList.concat([...allDocsOfOneTask.repDocsKeys.rep_images_cannot_complete])

    // }

    // handleDownloadClick(e, finalKeyList)
  };

  let zip = new JSZip();

  const downloadFileFromS3 = (fileToDownload, myBucket) => {
    console.log(' key', fileToDownload);

    // const result = await fetch(fileToDownload)
    return new Promise((resolve, reject) => {
      myBucket.getObject({ Key: fileToDownload }, (error, data) => {
        if (error != null) {
          // alert('Failed to retrieve an object: ' + error);
          console.log('Failed to retrieve an object: ' + error);
          // return reject(error);
          // when there is error, still return resolve to allow download other docs
          return resolve(`failed to get ${fileToDownload}, ${error}`);
        } else {
          console.log(' data', data);
          let blob = new Blob([data.Body], { type: data.ContentType });

          const fileNameList = fileToDownload.split('/');
          let fileSavePath = fileNameList[fileNameList.length - 1];

          // create different folder structure for different docs
          if (fileToDownload.includes('signed')) {
            fileSavePath = 'signedDoc/' + fileSavePath;
          } else if (fileToDownload.includes('rep/images')) {
            fileSavePath = 'repDoc/' + fileSavePath;
          } else if (fileToDownload.includes('rep/cannot-complete')) {
            fileSavePath = 'repIncompleteTaskDoc/' + fileSavePath;
          }
          console.log(' file name final is', fileSavePath);

          // write the blob to the zip
          zip.file(fileSavePath, blob);
          return resolve(data);
        }
      });
    });
  };

  const downloadFiles = async (keyList) => {
    setLoading(true);
    AWS.config.update({
      accessKeyId: sessionStorage.getItem('AccessKeyId'),
      secretAccessKey: sessionStorage.getItem('AccessKeySec'),
      sessionToken: sessionStorage.getItem('sessionToken'),
    });
    const myBucket = new AWS.S3({
      params: { Bucket: process.env.REACT_APP_BUCKET_NAME },
      region: 'ap-southeast-2',
    });
    // const keys = Object.keys(keysObj)

    const promList = [];
    for (var i = 0; i < keyList.length; i++) {
      console.log(`keyList[${i}]`, keyList[i]);

      const prom = downloadFileFromS3(keyList[i], myBucket);
      promList.push(prom);
    }

    // after write all the files to the zip, download the zip
    Promise.all(promList)
      .then((res) => {
        console.log(` promList res`, res);
        var taskID = tableD[0].task_id;
        console.log(` promList taskID `, taskID);
        zip.generateAsync({ type: 'blob' }).then(function (content) {
          saveAs(content, `${taskID}Docs.zip`);
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(` promList res err`, err);
        setLoading(false);
      });
  };

  // version origin
  // const downloadData = () => {
  //   AWS.config.update({
  //     accessKeyId: sessionStorage.getItem('AccessKeyId'),
  //     secretAccessKey: sessionStorage.getItem('AccessKeySec'),
  //     sessionToken: sessionStorage.getItem('sessionToken'),
  //   });
  //   const myBucket = new AWS.S3({
  //     params: { Bucket: process.env.REACT_APP_BUCKET_NAME },
  //     region: 'ap-southeast-2',
  //   });
  //   var taskID = '';
  //   tableD.map(rec => {
  //     console.log(rec.task_id);
  //     taskID = rec.task_id;
  //   });
  //   console.log(taskID);
  //   var paramss = {
  //     Bucket: process.env.REACT_APP_BUCKET_NAME,
  //     Prefix: 'admin-documents/taskDocuments/' + taskID + '/',
  //     Delimiter: '/',
  //     // MaxKeys: ''
  //   };
  //   var arrList = [];

  //   myBucket.listObjectsV2(paramss, function (err, data) {
  //     if (err) console.log(err, err.stack); // an error occurred
  //     else {
  //       console.log(data.Contents);
  //       const aa = data.Contents;
  //       var array = [];
  //       aa.map(rec => {
  //         // if (!rec.includes('undefined')) {
  //         array.push(process.env.REACT_APP_CDN + rec.Key);
  //         // }
  //       });
  //       console.log(array);
  //     }
  //   });
  // };
  const fileDownloadHandler = async (pictures) => {
    console.log(pictures);
    var urlss = [];
    pictures.map((rec) => {
      if (rec && rec.key && rec.key.length > 0) {
        urlss.push(
          process.env.REACT_APP_CDN + rec.key
          // 'admin-documents/taskDocuments/' +
          // viewData.task_id +
          // '/rep/images/' +
          // viewData.taskDate.replace(/[^\d]/g, '') +
          // '/' +
          // rec,
        );
      }
    });
    console.log(urlss);
    for (var i = 0; i < urlss.length; i++) {
      console.log(urlss[i]);
      const response = await fetch(urlss[i]);
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = 'task.jpeg';
        a.click();
      });
    }
  };
  const fileDownloadHandler1 = async (pictures) => {
    var urlss = [];
    pictures.map((rec) => {
      console.log(rec);
      urlss.push(process.env.REACT_APP_CDN + rec.key);
    });
    console.log(urlss);
    for (var i = 0; i < urlss.length; i++) {
      console.log(urlss[i]);
      const response = await fetch(urlss[i]);
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = 'signedDocument.pdf';
        a.click();
      });
    }
  };

  const columnss = [
    {
      title: 'Task ID',
      dataIndex: 'task_id',
      align: 'left',
      editable: true,
      key: 'task_id',
      ...getColumnSearchProps('task_id'),
    },
    {
      title: 'Task Name',
      dataIndex: 'task_name',
      align: 'left',
      editable: true,
      ...getColumnSearchProps('task_name'),
    },
    // {
    //   title: "Task Type",
    //   dataIndex: "task_type",
    //   align: "left",
    //   editable: true,
    //   filters: [
    //     { text: 'Order Task', value: 'Order Task' },
    //     { text: 'Merch Task', value: 'Merch Task' },
    //     { text: 'Yes/No Task', value: 'Yes/No Task' },
    //   ],
    //   onFilter: (value, record) => record.task_type === value
    // },
    {
      title: 'Task Priority',
      dataIndex: 'task_priority',
      align: 'left',
      editable: true,
      filters: [
        { text: 'Critical', value: 'Critical' },
        { text: 'High', value: 'High' },
        { text: 'Medium', value: 'Medium' },
        { text: 'Low', value: 'Low' },
      ],
      onFilter: (value, record) => record.task_priority === value,
      // sorter: (a, b) => {
      //   const order = {'Critical': 4, 'High': 3, 'Medium': 2, 'Low': 1};
      //   return order[a.task_priority] - order[b.task_priority]
      // },
      // sortOrder: sortedInfo.columnKey === "task_priority" && sortedInfo.order,
    },
    {
      title: 'Task Status',
      dataIndex: 'task_status',
      align: 'left',
      editable: true,
      filters: [
        { text: 'Created', value: 'created' },
        { text: 'In Progress', value: 'in_progress' },
        { text: 'Cannot complete', value: 'cannot_complete' },
        { text: 'Done', value: 'done' },
        { text: 'Voided', value: 'avoided' },
        { text: 'Paused', value: 'paused' },
        { text: 'Reassigned', value: 'reassigned' },
        { text: 'Approved', value: 'approved' },
        { text: 'Rolled Over', value: 'rolled_over' },
      ],
      onFilter: (value, record) => record.task_status === value,
      render: (text, record) => {
        return (
          <>
            {record.task_status == 'created' ? <p>Created</p> : null}
            {record.task_status == 'in_progress' ? <p>In Progress</p> : null}
            {record.task_status == 'cannot_complete' ? <p>Cannot complete</p> : null}
            {record.task_status == 'done' ? <p> Done</p> : null}
            {record.task_status == 'avoided' ? <p> Voided</p> : null}
            {record.task_status == 'paused' ? <p> Paused</p> : null}
            {record.task_status == 'reassigned' ? <p> Reassigned</p> : null}
            {record.task_status == 'approved' ? <p> Approved</p> : null}
            {record.task_status == 'rolled_over' ? <p> Rolled Over</p> : null}
          </>
        );
      },
    },
    // {
    //   title: 'Task Description',
    //   dataIndex: 'task_description',
    //   align: 'left',
    //   editable: true,
    //   width: 300,
    //   render: (text, record) => (
    //     <div dangerouslySetInnerHTML={{ __html: record.task_description }} />
    //   ),
    // },
    {
      title: 'Task Date',
      dataIndex: 'taskDate',
      align: 'left',
      editable: true,
      key: 'taskDate',
      sorter: (a, b) => {
        const newA = a.taskDate.split('/').reverse().join('-');
        const newB = b.taskDate.split('/').reverse().join('-');
        return +new Date(newA) - +new Date(newB);
      },
      sortOrder: sortedInfo.columnKey === 'taskDate' && sortedInfo.order,
    },

    {
      title: 'Store ID',
      dataIndex: 'store_id',
      align: 'left',
      editable: true,
      ...getColumnSearchProps('store_id'),

      // render: (text, record) => {
      //   console.log('record12', record);
      //   return <>{record.stores.store_id}</>;
      // },
    },
    {
      title: 'Store name',
      dataIndex: 'store_name',
      align: 'left',
      editable: true,
      width: 300,
      ...getColumnSearchProps('store_name'),
      // ...getColumnSearchProps(['stores', 'store_name']),
      // render: (text, record) => {
      //   console.log('record12', record);
      //   return <>{record.stores.store_name}</>;
      // },
      // render: (text, record) => (
      //   <p>{record.store_name}</p>
      //   // <div dangerouslySetInnerHTML={{ __html: record.task_description }} />
      // ),
    },
    {
      title: 'State',
      dataIndex: 'state',
      align: 'left',
      editable: true,
      filters: [
        { text: 'VIC', value: 'VIC' },
        { text: 'ACT', value: 'ACT' },
        { text: 'NSW', value: 'NSW' },
        { text: 'NT', value: 'NT' },
        { text: 'QLD', value: 'QLD' },
        { text: 'SA', value: 'SA' },
        { text: 'TAS', value: 'TAS' },
        { text: 'WA', value: 'WA' },
        { text: 'NI', value: 'NI' },
        { text: 'SI', value: 'SI' },
      ],
      onFilter: (value, record) => record.stores.state === value,
      render: (text, record) => {
        console.log('record12', record);
        return <>{record.stores && record.stores.state}</>;
      },
      // sorter: (a, b) => {
      //   const order = {'Critical': 4, 'High': 3, 'Medium': 2, 'Low': 1};
      //   return order[a.task_priority] - order[b.task_priority]
      // },
      // sortOrder: sortedInfo.columnKey === "task_priority" && sortedInfo.order,
    },
    // {
    //   title: 'Store Name',
    //   dataIndex: 'store_name',
    //   align: 'left',
    //   editable: true,
    //   width: 200,
    //   ...getColumnSearchProps('store_name'),
    // },
    {
      title: 'Actions',
      dataIndex: 'actions',
      align: 'right',
      render: (text, record) => {
        return (
          <>
            {/* <Link to={{ pathname: '/tasktableview', state: { rec: record, tabValue: props.value }, page: '/task' }}><svg height='18' width='18' fill="#000000" xmlns="http://www.w3.org/2000/svg" data-name="Layer 3" viewBox="0 0 24 24" x="0px" y="0px"><path d="M20.5,9.873A11.123,11.123,0,0,0,12,6,11.123,11.123,0,0,0,3.5,9.873L2.239,11.351a1,1,0,0,0,0,1.3L3.5,14.127A11.123,11.123,0,0,0,12,18a11.125,11.125,0,0,0,8.5-3.872l1.261-1.479a1,1,0,0,0,0-1.3Zm-1.521,2.958A9.134,9.134,0,0,1,12,16a9.131,9.131,0,0,1-6.979-3.17L4.314,12l.707-.83h0A9.134,9.134,0,0,1,12,8a9.131,9.131,0,0,1,6.979,3.17l.707.83Z"></path>
                <path d="M12,9a3,3,0,1,0,3,3A3,3,0,0,0,12,9Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,12,13Z"></path></svg></Link> */}
            {record.task_status == 'created' && localStorage.getItem('userRole') != 'manager' ? (
              <button
                style={{
                  marginRight: '0px',
                  marginLeft: '5px',
                  backgroundColor: 'transparent',
                }}
                onClick={(e) => editTask(e, record)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#000"
                  className="bi bi-pencil"
                  viewBox="0 0 16 16"
                >
                  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                </svg>{' '}
              </button>
            ) : null}
            {/* {record.task_status ? ( */}
            <button
              onClick={(e) => {
                detailsTask(e, record);
              }}
              style={{
                marginRight: '0px',
                marginLeft: '5px',
                backgroundColor: 'transparent',
                // float: 'right',
              }}
            >
              <svg
                height="18"
                width="18"
                fill="#000000"
                xmlns="http://www.w3.org/2000/svg"
                data-name="Layer 3"
                viewBox="0 0 24 24"
                x="0px"
                y="0px"
              >
                <path d="M20.5,9.873A11.123,11.123,0,0,0,12,6,11.123,11.123,0,0,0,3.5,9.873L2.239,11.351a1,1,0,0,0,0,1.3L3.5,14.127A11.123,11.123,0,0,0,12,18a11.125,11.125,0,0,0,8.5-3.872l1.261-1.479a1,1,0,0,0,0-1.3Zm-1.521,2.958A9.134,9.134,0,0,1,12,16a9.131,9.131,0,0,1-6.979-3.17L4.314,12l.707-.83h0A9.134,9.134,0,0,1,12,8a9.131,9.131,0,0,1,6.979,3.17l.707.83Z"></path>
                <path d="M12,9a3,3,0,1,0,3,3A3,3,0,0,0,12,9Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,12,13Z"></path>
              </svg>{' '}
            </button>
            {record.task_status == 'done' &&
            (record.task_approval.toLowerCase() == 'required' || record.task_approval == '') ? (
              <div style={{ textAlign: 'left' }}>
                <Button
                  style={{ marginRight: '5px', marginBottom: '5px' }}
                  onClick={(e) => {
                    approveTask(e, record);
                  }}
                >
                  Approve Task{' '}
                </Button>
                <Button
                  style={{ marginRight: '0', marginBottom: '5px' }}
                  onClick={() => {
                    setReassignOpen(true);
                    console.log(record);
                    setReassignPayload({
                      ...reAssignPayload,
                      PK: record.PK,
                      SK: record.SK,
                      store_name: record.store_name,
                      store_id: record.store_id,
                      task_id: record.task_id,
                      feedback_reassign: record.feedback_reassign,
                      appsync_id: record.appsync_id,
                      id: record.id,
                    });
                    console.log('Record', record);
                  }}
                >
                  Re assign Task{' '}
                </Button>
              </div>
            ) : null}
            {/* ) : null} */}
          </>
        );
      },
    },
  ];

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const hasDocToDownload = () => {
    // setTimeout(() => {
    console.log(tableD, 'ddddd hasDocToDownload');
    let result = false;
    // has admin doc
    if (
      tableD[0] &&
      tableD[0].documentAdd &&
      tableD[0].documentAdd.length > 0 &&
      tableD[0].documentAdd[0].document.length > 0
    ) {
      console.log(tableD, 'ddddd hasDocToDownload had admin doc');
      return true;
    }
    // has no admin docu, check if has rep doc for each task
    else {
      result = tableD.some(
        (item) => item.rep_images.length > 0 || item.rep_images_cannot_complete.length > 0
      );
      console.log(tableD, 'ddddd hasDocToDownload had rep doc', result);
    }

    console.log('result for download disabled', result);

    return result;
    // }, 4000);
  };
  const [openUpd, setOpenUpd] = React.useState(false);
  const handleClose1 = () => {
    setOpenUpd(false);
  };

  const hasSingedDocList = () => {
    let result = [];
    if (viewData.documentAdd && viewData.documentAdd.length > 0) {
      result = viewData.documentAdd.filter((docItem) => {
        console.log('docItem', docItem);
        if (docItem.signed && docItem.signed == true) {
          return { ...docItem };
        }
      });
    }

    console.log('hasSingedDocList', result);
    console.log('hasSingedDocList', viewData);

    return result;
  };

  return (
    <div>
      {loading ? (
        <div className="overlay-box">
          <ClipLoader color={color} loading={loading} css={override} size={150} />
        </div>
      ) : null}
      <Dialog open={openUpd}>
        <DialogContent>
          <DialogContentText>
            <Row>
              <Col md={12}>
                {/* <b style={{ color: 'red' }}>Thank you</b> */}
                {/* <br />
                <br /> */}
                <label style={{ color: 'red' }}>{errorFailed}</label>
              </Col>
            </Row>
            <br />
          </DialogContentText>
          <Button onClick={handleClose1} variant="outlined" color="primary">
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Modal isOpen={reassignOpen} className="plan-modal-dialog">
        <ModalHeader style={{ justifyContent: 'center' }}>Task Details</ModalHeader>
        <ModalBody>
          <Row>
            <Col md={6}>
              <label>Store Name</label>
              <Input
                type="text"
                name="store_name"
                disabled
                value={reAssignPayload.store_name}
                className="admin-input"
              ></Input>
            </Col>
            <Col md={6}>
              <label> Feedback</label>
              <Input
                type="textarea"
                name="task_name"
                // disabled
                value={reAssignPayload.feedback_reassign}
                className="admin-input"
                onChange={(e) => {
                  setReassignPayload({
                    ...reAssignPayload,
                    feedback_reassign: e.target.value,
                  });
                }}
              ></Input>
            </Col>
          </Row>

          <Row className="modalRow">
            <Col>
              <Button onClick={handleReassign} variant="outlined" color="primary">
                Submit
              </Button>
              <Button onClick={handleCloseRe} variant="outlined" color="primary">
                Close
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <Modal isOpen={open} className="plan-modal-dialog">
        <ModalHeader style={{ justifyContent: 'center' }}>Task Details</ModalHeader>
        <ModalBody>
          <Row>
            <Col md={6}>
              <label>Created Date</label>
              <Input
                type="text"
                name="created_date"
                disabled
                value={moment(viewData.created_date).format('DD/MM/YYYY')}
                className="admin-input"
              ></Input>
            </Col>
            <Col md={6}>
              <label>Task ID</label>
              <Input
                type="text"
                name="task_id"
                disabled
                value={viewData.task_id}
                className="admin-input"
              ></Input>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <label>Task Name</label>
              <Input
                type="text"
                name="task_name"
                disabled
                value={viewData.task_name}
                className="admin-input"
              ></Input>
            </Col>
            <Col md={6}>
              <label>Task Description</label>
              {viewData.task_description != '' ? (
                <div
                  className="admin-input form-control task-detail-desc"
                  style={{
                    backgroundColor: '#e9ecef',
                    height: 'auto',
                    color: 'gray',
                    fontSize: '0.7rem',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: viewData.task_description,
                  }}
                />
              ) : (
                <div
                  className="admin-input form-control task-detail-desc"
                  style={{
                    backgroundColor: '#e9ecef',
                    height: 'auto',
                    color: 'gray',
                    fontSize: '0.7rem',
                    padding: '11px',
                  }}
                >
                  -
                </div>
              )}

              {/* <Input
                type="text"
                name="task_description"
                disabled
                value={viewData.task_description}
                className="admin-input"
              >

              </Input> */}
            </Col>
          </Row>

          <Row>
            {/* <Col md={6}>
              <label>Task Type</label>
              <Input
                type="text"
                name="task_type"
                disabled

                value={viewData.task_type}
                className="admin-input"
              >

              </Input>
            </Col> */}
            <Col md={6}>
              <label>Task Priority</label>
              <Input
                type="text"
                name="task_name"
                disabled
                value={viewData.task_priority}
                className="admin-input"
              ></Input>
            </Col>
            {/* <Col md={6}>
              <label>Rep Name</label>
              <Input
                type="text"
                name="rep_name"
                disabled

                value={viewData.rep_name}
                className="admin-input"
              >

              </Input>
            </Col> */}
            {/* </Row>

          <Row> */}
            <Col md={6}>
              <label>Store Name</label>
              <Input
                type="text"
                name="task_type"
                disabled
                value={viewData.store_name}
                className="admin-input"
              ></Input>
            </Col>
            <Col md={6}>
              <label>Supplier Name</label>
              <Input
                type="text"
                name="task_name"
                disabled
                value={viewData.supplier_name}
                className="admin-input"
              ></Input>
            </Col>
            {/* </Row>

          <Row> */}
            <Col md={6}>
              <label>Task Status</label>
              <Input
                type="text"
                name="task_type"
                disabled
                value={viewData.task_status}
                className="admin-input"
              ></Input>
            </Col>
            <Col md={6}>
              <label>Time Spent</label>
              <Input
                type="text"
                name="task_type"
                disabled
                value={viewData.time_spent}
                className="admin-input"
              ></Input>
            </Col>
            {/* </Row>
          <Row> */}
            <Col md={6}>
              <label>Rep's Comment</label>
              <Input
                type="text"
                name="task_type"
                disabled
                value={viewData.comments_from_rep}
                className="admin-input"
              ></Input>
            </Col>
            {/* Untill the task has been completed the download all should not show */}
            {viewData.documentAdd &&
            viewData.documentAdd.length > 0 &&
            viewData.task_status == 'done' ? (
              <Col md={6}>
                <label>Signed Documents from Rep</label>
                <button
                  style={{
                    float: 'right',
                    color: 'blue',
                    textDecoration: 'underline',
                    fontSize: '12px',
                    visibility: hasSingedDocList().length == 0 ? 'hidden' : 'visible',
                  }}
                  type="button"
                  onClick={() => fileDownloadHandler1(viewData.documentAdd)}
                  disabled={hasSingedDocList().length == 0}
                >
                  Download All
                </button>

                <div style={{ clear: 'both' }}></div>
                {/* <br /> */}

                {hasSingedDocList().length == 0 ? (
                  <label> None </label>
                ) : (
                  <>
                    <br />
                    {viewData.documentAdd &&
                      viewData.documentAdd.map((rec) => (
                        <>
                          {rec.signed && rec.signed == true ? (
                            <a
                              style={{ marginRight: '10px' }}
                              target="_blank"
                              href={
                                process.env.REACT_APP_CDN +
                                'admin-documents/taskDocuments/' +
                                viewData.task_id +
                                '/signedDoc/' +
                                viewData.taskDate.split('/')[0] +
                                viewData.taskDate.split('/')[1] +
                                viewData.taskDate.split('/')[2] +
                                '/' +
                                viewData.store_id +
                                '/' +
                                rec.document
                              }
                            >
                              <p> {rec.document}</p>
                            </a>
                          ) : null}
                        </>
                      ))}
                  </>
                )}
              </Col>
            ) : null}

            {viewData.rep_images && viewData.rep_images.length > 0 ? (
              <Col md={6}>
                <label>Photos From Rep</label>
                <button
                  style={{
                    float: 'right',
                    color: 'blue',
                    textDecoration: 'underline',
                    fontSize: '12px',
                  }}
                  type="button"
                  onClick={() => fileDownloadHandler(viewData.rep_images)}
                >
                  Download All
                </button>
                <div style={{ clear: 'both' }}></div>
                <br />
                {/* <a style={{ marginRight: '10px' }} href={process.env.REACT_APP_CDN + 'admin-documents/taskDocuments/' + viewData.task_id + "/rep/" + rec} >
     <img style={{ width: '60px' }} src={process.env.REACT_APP_CDN + 'admin-documents/taskDocuments/0306/rep/Image0.jpg'} />
   </a></> */}
                {viewData.rep_images &&
                  viewData.rep_images.map(
                    (rec) =>
                      rec &&
                      rec.key &&
                      rec.key.length > 0 && (
                        <a
                          style={{ marginRight: '10px' }}
                          target="_blank"
                          href={
                            process.env.REACT_APP_CDN + rec.key
                            // 'admin-documents/taskDocuments/' +
                            // viewData.task_id +
                            // '/rep/images/' +
                            // viewData.taskDate.replace(/[^\d]/g, '') +
                            // '/' +
                            // rec
                          }
                        >
                          <img
                            style={{ width: '60px' }}
                            alt="rep photos"
                            src={
                              process.env.REACT_APP_CDN + rec.key
                              // 'admin-documents/taskDocuments/' +
                              // viewData.task_id +
                              // '/rep/images/' +
                              // viewData.taskDate.replace(/[^\d]/g, '') +
                              // '/' +
                              // rec
                            }
                          />
                        </a>
                      )
                  )}
              </Col>
            ) : null}

            {viewData.docs_from_rep && viewData.docs_from_rep.length > 0 ? (
              <>
                {/* <Col md={6}>
                  <label>Rep's Documents </label><br />

                  {viewData.docs_from_rep && ( viewData.docs_from_rep ).map( rec => (
                    <a style={{ marginRight: '10px' }} href={process.env.REACT_APP_CDN + 'admin-documents/taskDocuments/' + viewData.task_id + "/rep/" + rec.document} >
                      {rec.document}
                    </a>

                  ) )}

                </Col> */}

                <Col md={6}>
                  <label>Document Description </label>
                  <br />

                  {viewData.docs_from_rep &&
                    viewData.docs_from_rep.map((rec) => (
                      <Input
                        type="text"
                        name="task_type"
                        disabled
                        value={rec.description}
                        className="admin-input"
                      ></Input>
                    ))}
                </Col>
              </>
            ) : null}
            {viewData.rep_images_cannot_complete &&
            viewData.rep_images_cannot_complete.length > 0 ? (
              <Col md={6}>
                <label>Can not complete Photos</label>
                <br />
                {/* <a style={{ marginRight: '10px' }} href={process.env.REACT_APP_CDN + 'admin-documents/taskDocuments/' + viewData.task_id + "/rep/" + rec} >
     <img style={{ width: '60px' }} src={process.env.REACT_APP_CDN + 'admin-documents/taskDocuments/0306/rep/Image0.jpg'} />
   </a></> */}
                {viewData.rep_images_cannot_complete &&
                  viewData.rep_images_cannot_complete.map(
                    (rec) =>
                      rec &&
                      rec.key &&
                      rec.key.length > 0 && (
                        <a
                          style={{ marginRight: '10px' }}
                          target="_blank"
                          href={
                            process.env.REACT_APP_CDN + rec.key
                            // 'admin-documents/taskDocuments/' +
                            // viewData.task_id +
                            // '/rep/cannot-complete/images/' +
                            // viewData.taskDate.replace(/[^\d]/g, '') +
                            // '/' +
                            // rec
                          }
                        >
                          <img
                            style={{ width: '60px' }}
                            alt="rolled over photos"
                            src={
                              process.env.REACT_APP_CDN + rec.key
                              // 'admin-documents/taskDocuments/' +
                              // viewData.task_id +
                              // '/rep/cannot-complete/images/' +
                              // viewData.taskDate.replace(/[^\d]/g, '') +
                              // '/' +
                              // rec
                            }
                          />
                        </a>
                      )
                  )}
              </Col>
            ) : null}
            {viewData.cannot_complete_comments != '' ? (
              <Col md={6}>
                <label>Can not complete comments</label>
                <br />
                {/* <a style={{ marginRight: '10px' }} href={process.env.REACT_APP_CDN + 'admin-documents/taskDocuments/' + viewData.task_id + "/rep/" + rec} >
     <img style={{ width: '60px' }} src={process.env.REACT_APP_CDN + 'admin-documents/taskDocuments/0306/rep/Image0.jpg'} />
   </a></> */}
                <Input
                  type="text"
                  name="task_type"
                  disabled
                  value={viewData.cannot_complete_comments}
                  className="admin-input"
                ></Input>
              </Col>
            ) : null}
            {viewData.cannot_complete_reason != '' ? (
              <Col md={6}>
                <label>Can not complete reason</label>
                <br />
                {/* <a style={{ marginRight: '10px' }} href={process.env.REACT_APP_CDN + 'admin-documents/taskDocuments/' + viewData.task_id + "/rep/" + rec} >
     <img style={{ width: '60px' }} src={process.env.REACT_APP_CDN + 'admin-documents/taskDocuments/0306/rep/Image0.jpg'} />
   </a></> */}
                <Input
                  type="text"
                  name="task_type"
                  disabled
                  value={viewData.cannot_complete_reason}
                  className="admin-input"
                ></Input>
              </Col>
            ) : null}

            {/* </> */}

            <Col md={6}>
              <label>Delegated</label>
              <Input
                type="text"
                name="task_type"
                disabled
                value={viewData.delegated ? 'Yes' : 'No'}
                className="admin-input"
              ></Input>
            </Col>
            {viewData.delegated ? (
              <>
                <Col md={6}>
                  <label>Delegated Support rep</label>
                  <Input
                    type="text"
                    name="task_type"
                    disabled
                    value={viewData.support_rep_username}
                    className="admin-input"
                  ></Input>
                </Col>

                <Col md={6}>
                  <label>Delegated Comments</label>
                  <Input
                    type="text"
                    name="task_type"
                    disabled
                    value={viewData.delegated_comments}
                    className="admin-input"
                  ></Input>
                </Col>
              </>
            ) : null}
            {viewData.push_task_comments != '' ? (
              <Col md={6}>
                <label>Push Task Comments</label>
                <Input
                  type="text"
                  name="task_type"
                  disabled
                  value={viewData.push_task_comments}
                  className="admin-input"
                ></Input>
              </Col>
            ) : null}
            {/* {viewData.task_status == 'cannot_complete' ? (
              <>
              

                <Col md={6}>
                  <label>Can not Complete Photos</label>

                  {viewData.rep_images_cannot_complete &&
                    viewData.rep_images_cannot_complete.map(rec => (
                      <a
                        style={{ marginRight: '10px' }}
                        href={
                          process.env.REACT_APP_CDN +
                          'admin-documents/taskDocuments/' +
                          viewData.task_id +
                          '/rep/cannot-complete/' +
                          rec
                        }
                      >
                        <img
                          src={
                            process.env.REACT_APP_CDN +
                            'admin-documents/taskDocuments/' +
                            viewData.task_id +
                            '/rep/cannot-complete/' +
                            rec
                          }
                        />
                      </a>
                    ))}
                </Col>
              </>
            ) : null} */}
            {viewData.pause_task_reason != '' ? (
              <Col md={6}>
                <label> Paused Reason</label>
                <Input
                  type="text"
                  disabled
                  value={viewData.pause_task_reason}
                  className="admin-input"
                ></Input>
              </Col>
            ) : null}

            {viewData.questions &&
              viewData.questions.map((rec) => (
                <>
                  <Col md={6}>
                    <label> Question</label>
                    <Input
                      type="text"
                      disabled
                      value={rec.question}
                      className="admin-input"
                    ></Input>
                  </Col>

                  <Col md={6}>
                    <label> Rep's Answer</label>

                    <Input disabled value={rec.answer_from_rep} className="admin-input"></Input>
                  </Col>
                </>
              ))}
          </Row>
          <Row className="modalRow">
            <Col>
              <Button onClick={handleClose} variant="outlined" color="primary">
                Close
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      {/* <Space style={{ float: 'right', margin: '15px 0px', padding: '0px 20px 0px 0px' }}>
        <Input
          placeholder="Enter Search Text"
          onChange={handleSearch}
          type="text"
          allowClear
          value={searchText}
          className='admin-input'
        />
        <Button
          type="primary" onClick={globalSearch} style={{ borderRadius: '32px' }}>Search</Button>
        <Button
          onClick={clearAll} variant="outlined" style={{ borderRadius: '32px' }}>Clear All</Button>
      </Space>
      <div style={{ clear: 'both' }}></div> */}

      {viewDataBool ? (
        <>
          <Button
            style={{
              cursor: 'pointer',
              fontWeight: 'bold',
              marginLeft: '10px',
            }}
            onClick={() => {
              setViewDataBool(false);
            }}
          >
            Back
          </Button>
          <Button
            style={{
              cursor: 'pointer',
              fontWeight: 'bold',
              marginLeft: '10px',
            }}
            className="voidBtn"
            disabled={selectedList.length == 0}
            onClick={() => {
              voidRecords();
            }}
          >
            Void
          </Button>
          <Button
            style={{
              cursor: 'pointer',
              fontWeight: 'bold',
              marginLeft: '10px',
            }}
            className="voidBtn"
            disabled={approvedTask || selectedList.length == 0}
            onClick={() => {
              approveRecords();
            }}
          >
            Approve
          </Button>
          <Button
            style={{
              cursor: 'pointer',
              fontWeight: 'bold',
              marginLeft: '10px',
            }}
            className="voidBtn"
            // disabled={approvedTask || selectedList.length == 0}
            onClick={(e) => {
              // downloadData(e);
              downloadZipFromS3(e);
            }}
            disabled={hasDocToDownload() ? false : true}
          >
            Download
          </Button>
          <div style={{ clear: 'both' }}></div>
          <div className="antdTable">
            {console.log('data', tableD)}
            {/* {tableD ? ( */}
            <Table
              columns={columnss}
              // scroll={{ x: 1500 }}
              dataSource={tableD && tableD.length ? tableD : rec}
              // bordered
              rowSelection={rowSelection}
              rowKey={(record) => record.task_id + record.taskDate + record.store_id + record.SK}
              onChange={handleChange}
              pagination={{
                total: tableD.length,
                showTotal: (total) => `Total ${total} items`,
                pageSizeOptions: ['10', '50', '100', '200', '500'],
              }}
            />
            {/* ) : null} */}
          </div>
        </>
      ) : (
        <div className="antdTable">
          {console.log('data', tableD)}
          {/* {dataTask ? ( */}
          <Table
            columns={columns}
            // scroll={{ x: 1500 }}
            dataSource={dataTask && dataTask.length ? dataTask : rec}
            // bordered
            onChange={handleChange}
            pagination={{
              total: dataTask.length,
              showTotal: (total) => `Total ${total} items`,
              pageSizeOptions: ['10', '50', '100', '200', '500'],
            }}
          />
          {/* ) : null} */}
        </div>
      )}

      <Row>
        <Col>
          <img src={greyBanner} className="greyBanner"></img>
        </Col>
      </Row>
    </div>
  );
};

export default TaskManagementTable;
