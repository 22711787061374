import withBadge from '../../hocs/withBadge';
import React from 'react';
import {
  MdArrowBack,
  MdArrowForward,
  MdExitToApp,
  MdNotificationsActive,
} from 'react-icons/md';
import {
  Button,
  ListGroup,
  // NavbarToggler,
  Nav,
  Navbar,
  NavItem,
} from 'reactstrap';
import bn from '../../utils/bemnames';
import Amplify, { Auth } from 'aws-amplify';
const bem = bn.create('header');

const MdNotificationsActiveWithBadge = withBadge({
  size: 'md',
  color: 'primary',
  style: {
    top: -10,
    right: -10,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  children: <small>5</small>,
})(MdNotificationsActive);

class Header extends React.Component {
  state = {
    isOpenNotificationPopover: false,
    isNotificationConfirmed: false,
    isOpenUserCardPopover: false,
  };
  signout = event => {
    event.preventDefault();
    event.stopPropagation();
    // {global: true}
    Auth.signOut({ global: true })
      .then(() => {
        // clearTimeout( this.timer );
        // Clear local storage when api is available
        // localStorage.clear();

        sessionStorage.clear();
        // localStorage.clear();
        // localStorage.removeItem( "accessLevel" );
        // reset page location
        console.log('logout succesfully');
        document.location.replace('/');
      })
      .catch(e => {
        console.log(`Sign out failed. Please try again.\n${e.message}`);
      })
      .finally(() => {
        // this.setState( {
        //   loading: false,
        // } );
      });
  };
  toggleNotificationPopover = () => {
    this.setState({
      isOpenNotificationPopover: !this.state.isOpenNotificationPopover,
    });

    if (!this.state.isNotificationConfirmed) {
      this.setState({ isNotificationConfirmed: true });
    }
  };

  toggleUserCardPopover = () => {
    this.setState({
      isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
    });
  };
  constructor(props) {
    super(props);
    this.state = {
      isbackbtn: false,
    };
  }

  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ isbackbtn: !this.state.isbackbtn });
    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
    document.querySelector('.cr-sidebar').toggleAttribute('removeBorderRadius');
    const navItem = document.querySelectorAll('.nav-item');
    for (let i = 0; i < navItem.length; i++) {
      navItem[i].classList.toggle('square-border');
      navItem[i].classList.toggle('nav-item-closed');
    }
  };

  render() {
    const { isNotificationConfirmed } = this.state;

    return (
      <Navbar light expand className={bem.b('bg-white')}>
        <Nav navbar className="mr-2">
          <Button outline onClick={this.handleSidebarControlButton}>
            {this.state.isbackbtn ? (
              <MdArrowBack size={25} />
            ) : (
              <MdArrowForward size={25} />
            )}
          </Button>
        </Nav>
        {/* <Nav navbar>
          <SearchInput />
        </Nav> */}
        <Nav navbar className={bem.e('nav-right')}>
          <NavItem className="d-inline-flex">
            <ListGroup flush>
              <button onClick={this.signout} className="signout">
                <MdExitToApp size={25} />
              </button>
            </ListGroup>
          </NavItem>
        </Nav>
        {/* <Nav navbar className={bem.e('nav-right')}>
          <NavItem className="d-inline-flex">
            <NavLink id="Popover1" className="position-relative">
              {isNotificationConfirmed ? (
                <MdNotificationsNone
                  size={25}
                  className="text-secondary can-click"
                  onClick={this.toggleNotificationPopover}
                />
              ) : (
                <MdNotificationsActiveWithBadge
                  size={25}
                  className="text-secondary can-click animated swing infinite"
                  onClick={this.toggleNotificationPopover}
                />
              )}
            </NavLink> */}
        {/* <Popover
              placement="bottom"
              isOpen={this.state.isOpenNotificationPopover}
              toggle={this.toggleNotificationPopover}
              target="Popover1"
            >
              <PopoverBody>
                <Notifications notificationsData={notificationsData} />
              </PopoverBody>
            </Popover> */}
        {/* </NavItem> */}

        {/* <NavItem>
            <NavLink id="Popover2">
              <img src={Anonyper} style={{width:'30px'}}
                onClick={this.toggleUserCardPopover}
                className="can-click"
              /> */}
        {/* </NavLink>
            <Popover
              placement="bottom-end"
              isOpen={this.state.isOpenUserCardPopover}
              toggle={this.toggleUserCardPopover}
              target="Popover2"
              className="p-0 border-0"
              style={{ minWidth: 250 }}
            > */}
        {/* <PopoverBody className="p-0 border-light" id="cardPop">
                <UserCard
                  title="Name"
                  subtitle="name@email.com"
                  text="Member Code: 0123456"
                  className="border-light"
                style={{backgroundColor: '#458F88'}}>
                  <ListGroup flush >
                    <ListGroupItem tag="a" href="/profile" action className="border-light">
                      <MdPersonPin /> Profile
                    </ListGroupItem> */}
        {/* <ListGroupItem tag="button" action className="border-light">
                      <MdInsertChart /> Stats
                    </ListGroupItem>
                    <ListGroupItem tag="button" action className="border-light">
                      <MdMessage /> Messages
                    </ListGroupItem>
                    <ListGroupItem tag="button" action className="border-light">
                      <MdSettingsApplications /> Settings
                    </ListGroupItem> */}
        {/* <ListGroupItem tag="button" action className="border-light">
                      <MdHelp /> Get Help
                    </ListGroupItem>
                    <ListGroupItem tag="button" action className="border-light">
                      <MdExitToApp /> Signout
                    </ListGroupItem>
                  </ListGroup>
                </UserCard>
              </PopoverBody> */}
        {/* </Popover>
          </NavItem> */}
        {/* </Nav> */}
      </Navbar>
    );
  }
}

export default Header;
