import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Table, Tag, Space, Popconfirm } from 'antd';
import { confirmAlert } from 'react-confirm-alert';
import 'antd/dist/antd.min.css';
import axios from 'axios';
import S3 from 'react-aws-s3';
import AWS from 'aws-sdk';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { Button, Col, Row, Input } from 'reactstrap';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/react';

const override = css`
  display: block;
  margin: 20% auto;
  border-color: #fff;
`;

const SupplierTable = props => {
  const [tableData, setTableData] = useState();
  const [userData, setUserData] = useState();
  const [loading, setLoading] = React.useState(false);
  const [color, setColor] = React.useState('#ffffff');
  const [templateExcelData, setTemplateExcelData] = React.useState([]);
  const [listingExcelData, setListingExcelData] = React.useState([]);
  const [openUpd, setOpenUpd] = React.useState(false);
  const searchInput = useRef(null);
  const [searchedColumn, setSearchedColumn] = useState('');
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  console.log(props);
  const handleClose = () => {
    setOpenUpd(false);
    // setBtnUpdate(false);
  };

  console.log('manager table', props);

  const listAdmin = () => {
    setLoading(true);
    axios({
      method: 'GET',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: process.env.REACT_APP_API_BASE + 'admin-portal',
    })
      .then(response => {
        const admindata = response.data.body;
        const roles = admindata.filter(item => {
          if (item.role && item.role == 'supplier') {
            return item;
          }
        });
        const userRole = admindata.filter(item => {
          if (item.role && item.role == 'user') {
            return item;
          }
        });
        console.log('supplierRoles', roles);
        var key;
        roles
          .sort((a, b) =>
            a.full_company_name.localeCompare(b.full_company_name),
          )
          .map((rec, index) => {
            rec.key = index + 1;
            // console.log(rec, key, newRoles);
          });
        console.log('userRole', roles);
        setTableData(roles);
        // console.log('userRole sub user', userRole);
        setUserData(userRole);
        filterD(roles);
        setLoading(false);
      })
      .catch(error => {
        console.log(error.response);
        console.log(error.response.status);
        if (
          error.response.status == 401 &&
          error.response.data.message == 'The incoming token has expired'
        ) {
          alert('Session timed out, please login again.');
          localStorage.clear();
          sessionStorage.clear();
          localStorage.clear();
          // localStorage.removeItem( "accessLevel" );
          // reset page location
          console.log('logout succesfully');
          document.location.replace('/');
        }
      });
  };
  const filterD = data => {
    var storeList = [];
    console.log(data);
    data.map(rec => {
      const aa = {
        'Supplier ID*': rec.id,
        Status: rec.status,
        'Username*': rec.username,
        'Short Name': rec.short_name,
        'Full Company Name*': rec.full_company_name,
        'ABN/ACN': rec.abn_no,
        'Mob Number': rec.mob_number,
        'Minimum Order Value': rec.mini_ordr_value,
        'Contract Type': rec.contract_type,
        'Email*': rec.business_email,
        Address: rec.address,
        'State*': rec.state,
        'Country*': rec.country,
        Notes: rec.notes,
      };

      storeList.push(aa);
    });
    console.log(storeList);
    setListingExcelData(storeList);
    setFilteredData(storeList);
  };
  useEffect(() => {
    listAdmin();
    const head = {
      'Supplier ID*': '',
      'Username*': '',
      Status: '',
      'Short Name': '',
      'Full Company Name*': '',
      'ABN/ACN': '',
      'Mob Number': '',
      'Minimum Order Value': '',
      'Contract Type': '',
      'Email*': '',
      Address: '',
      'State*': '',
      'Country*': '',
      Notes: '',
    };
    setTemplateExcelData([head]);
  }, []);
  const [errorMsgBulk, setErrorMsgBulk] = React.useState('');

  const deleteSupplier = (record, e) => {
    e.preventDefault();
    console.log(record);

    ////// To delete logo
    AWS.config.update({
      accessKeyId: sessionStorage.getItem('AccessKeyId'),
      secretAccessKey: sessionStorage.getItem('AccessKeySec'),
      sessionToken: sessionStorage.getItem('sessionToken'),
    });
    const myBucket = new AWS.S3({
      params: { Bucket: process.env.REACT_APP_BUCKET_NAME },
      region: 'ap-southeast-2',
    });
    var params = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: `supplier-documents/logo/${record.id}/${record.logo_img}`,
    };
    try {
      myBucket.deleteObject(params, function (err, data) {
        if (err) console.log(err, err.stack); // error
        else console.log('deleted Data', data); // deleted
      });
    } catch (e) {
      console.log('Error ', e);
    }
    //// To delete file
    var files =
      record.supplierDoc && record.supplierDoc.map(item => item.document);
    console.log('file===>', files);
    // console.log(record.supplierDoc.map(item => item.document))
    record.supplierDoc &&
      record.supplierDoc.map(item => {
        var params = {
          Bucket: process.env.REACT_APP_BUCKET_NAME,
          Key: `admin-documents/supplier-docs/${record.id}/documents/${item.document}`,
        };
        try {
          myBucket.deleteObject(params, function (err, data) {
            if (err) console.log(err, err.stack); // error
            else console.log('deleted Data', data); // deleted
          });
        } catch (e) {
          console.log('Error ', e);
        }
      });

    const supplierID = record.id;
    const usrName = record.username;
    console.log(usrName);
    // setLoading(true);
    confirmAlert({
      title: 'Confirm to Delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            axios({
              method: 'DELETE',
              headers: {
                Authorization: sessionStorage.getItem('idtoken'),
              },
              url:
                process.env.REACT_APP_API_BASE +
                'admin-portal?id=' +
                supplierID +
                '&username=' +
                usrName,
            })
              .then(response => {
                console.log('supplier deleted !', response);
                listAdmin();
              })
              .catch(error => {
                console.log(error.response);
                console.log(error.response.status);
                if (
                  error.response.status == 401 &&
                  error.response.data.message ==
                    'The incoming token has expired'
                ) {
                  alert('Session timed out, please login again.');
                  localStorage.clear();
                  sessionStorage.clear();
                  localStorage.clear();
                  // localStorage.removeItem( "accessLevel" );
                  // reset page location
                  console.log('logout succesfully');
                  document.location.replace('/');
                }
              });
          },
        },
        {
          label: 'No',
          onClick: () => {
            return;
          },
        },
      ],
    });
  };
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const exportToCSV = (csvData, fileName) => {
    console.log(csvData);
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  // const [callList, setCallList] = useState([]);
  const handleView = (record, e) => {
    console.log('recccc', record);
    console.log('recccc', record.contactInfo);
    var suppUsername = record.username;
    props.setValue('1');
    console.log({ ...record, suppUsern: suppUsername });

    props.setmobNums({
      supplier_mob_number: record.mob_number,
      supplier_ph_number: record.contactInfo && record.contactInfo.ph_number,
    });
    var dashhI = [];
    record.dashIDs
      ? record.dashIDs.map(rec => dashhI.push({ dashIDs: rec }))
      : dashhI.push({ dashIDs: '' });
    var docSup = [];
    record.supplierDoc
      ? record.supplierDoc.map(rec =>
          docSup.push({
            document_type: rec.document_type,
            document: rec.document,
          }),
        )
      : docSup.push({ document_type: '', document: '' });
    console.log(docSup);
    var contactInfoData;
    if (
      record.contactInfo == '' ||
      record.contactInfo == undefined ||
      record.contactInfo.length == 0
    ) {
      contactInfoData = [
        {
          first_name: '',
          last_name: '',
          email: '',
          ph_number: '',
        },
      ];
    } else {
      contactInfoData = record.contactInfo;
    }
    console.log('contactInfoData', contactInfoData);

    props.setSupplierInputField({
      ...record,
      suppUsern: suppUsername,
      dashId: dashhI,
      supplierDoc: docSup,
      contactInfo: contactInfoData,
    });

    props.selectedRole({ value: 'Supplier', label: 'Supplier' });
    props.setIsViewModes({
      ...props.isViewModes,
      isViewMode_supplier: true,
    });
    props.setFieldDisabled({
      ...props.fieldDisabled,
      fieldDisabled_supplier: true,
    });
    if (record.status == 'active') {
      console.log('true active');
      props.setStatusValue(true);
      props.setToggleText('Active');
    } else if (record.status == 'inactive') {
      props.setStatusValue(false);
      props.setToggleText('Inactive');
    }
  };
  // console.log(callList)
  const handleUpload = event => {
    event.preventDefault();
    var file = event.target.files,
      f = file[0]; //file info, get file
    console.log(f.name);
    const newArr = [];

    var reader = new FileReader();
    console.log('data');
    reader.onload = function (e) {
      console.log('data');
      // console.log(e.target.result)
      var data = e.target.result;

      let readedData = XLSX.read(data, { type: 'binary' }); // convert to binary, make readed data
      const wsname = readedData.SheetNames[0]; // to find sheet name
      const ws = readedData.Sheets[wsname]; // To get first sheet

      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1, defval: '' }); // to get the header of sheet
      // setFileUploaded( dataParse );
      // console.log(dataParse)
      const headers = dataParse[0];
      console.log('data paser', typeof dataParse, dataParse);
      var arr = [];
      var arr1 = {};

      for (let i = 1; i < dataParse.length; i++) {
        newArr.push({ ...dataParse[i], checked: false });
      }
      console.log(headers);
      console.log(newArr);
      newArr.map((rec, i) => {
        headers.map((rec1, index) => {
          arr1[rec1] = rec[index];
        });
        arr.push(arr1);
        arr1 = {};
      });
      var data = [];
      var aa2 = [];
      var aa3 = [];
      var data1 = {};

      // aa3.push(rec.id);
      // console.log(rec.id)
      arr.map((rec1, i) => {
        // aa3.push(rec.id);
        var userT = 'no';
        // console.log('Push');

        tableData.map(rec => {
          console.log('recordd', rec['user-type']);
          console.log('recordd', rec.id);
          console.log('rec1', rec1['Supplier ID*']);
          if (rec.id == rec1['Supplier ID*']) {
            userT = rec['user-type'];
            // console.log('preet', rec.id);
          }

          // }
        });

        aa2 = {
          id: rec1['Supplier ID*'].toString(),
          status:
            rec1['Status'] == '' ? 'active' : rec1['Status'].toLowerCase(),
          username: rec1['Username*'],
          short_name: rec1['Short Name'],
          full_company_name: rec1['Full Company Name*'],
          abn_no: rec1['ABN/ACN'] != '' ? rec1['ABN/ACN'].toString() : '',
          mob_number:
            rec1['Mob Number'] != '' ? rec1['Mob Number'].toString() : '',
          mini_ordr_value:
            rec1['Minimum Order Value'] != ''
              ? rec1['Minimum Order Value'].toString()
              : '',
          contract_type: rec1['Contract Type'],
          business_email: rec1['Email*'],
          address: rec1['Address'],
          state: rec1['State*'] == '' ? '' : rec1['State*'].toUpperCase(),
          country:
            rec1['Country*'] == ''
              ? ''
              : rec1['Country*'].charAt(0).toUpperCase() +
                rec1['Country*'].slice(1).toLowerCase(),
          notes: rec1['Notes'],
          'user-type': userT,
        };
        data.push(aa2);
      });
      console.log(data);
      // return;
      // arr.map((rec1, i) => {
      //   var aa2 = {
      //     id: rec1['Supplier ID*'].toString(),
      //     status:
      //       rec1['Status'] == '' ? 'active' : rec1['Status'].toLowerCase(),
      //     username: rec1['Username*'],
      //     short_name: rec1['Short Name'],
      //     full_company_name: rec1['Full Company Name*'],
      //     abn_no: rec1['ABN/ACN'] != '' ? rec1['ABN/ACN'].toString() : '',
      //     mob_number:
      //       rec1['Mob Number'] != '' ? rec1['Mob Number'].toString() : '',
      //     mini_ordr_value:
      //       rec1['Minimum Order Value'] != ''
      //         ? rec1['Minimum Order Value'].toString()
      //         : '',
      //     contract_type: rec1['Contract Type'],
      //     business_email: rec1['Email*'],
      //     address: rec1['Address'],
      //     state: rec1['State*'] == '' ? '' : rec1['State*'].toUpperCase(),
      //     country:
      //       rec1['Country*'] == ''
      //         ? ''
      //         : rec1['Country*'].charAt(0).toUpperCase() +
      //           rec1['Country*'].slice(1).toLowerCase(),
      //     notes: rec1['Notes'],
      //   };

      //   data.push(aa2);
      // });
      var error = '';
      data.map((rec, i) => {
        if (rec.id == '') {
          console.log('error in' + i + 'record');
          setErrorMsgBulk('Error in ' + (i + 1) + ' record. Please enter ID');
          error = 'Error in ' + (i + 1) + ' record. Please enter ID';
          setOpenUpd(true);
          setTimeout(() => {
            setOpenUpd(false);
          }, 3000);
          return;
        }
        if (rec.full_company_name == '') {
          console.log('error in' + i + 'record');
          setErrorMsgBulk(
            'Error in ' + (i + 1) + ' record. Please enter company name',
          );
          setOpenUpd(true);
          error = 'Error in ' + (i + 1) + ' record. Please enter ID';
          setTimeout(() => {
            setOpenUpd(false);
          }, 3000);
          return;
        }
        if (rec.username == '') {
          console.log('error in' + i + 'record');
          setErrorMsgBulk(
            'Error in ' + (i + 1) + ' record. Please enter username',
          );
          setOpenUpd(true);
          error = 'Error in ' + (i + 1) + ' record. Please enter ID';
          setTimeout(() => {
            setOpenUpd(false);
          }, 3000);
          return;
        }
        if (rec.business_email == '') {
          console.log('error in' + i + 'record');
          setErrorMsgBulk(
            'Error in ' + (i + 1) + ' record. Please enter email',
          );
          setOpenUpd(true);
          error = 'Error in ' + (i + 1) + ' record. Please enter ID';
          setTimeout(() => {
            setOpenUpd(false);
          }, 3000);
          return;
        }
        if (rec.country == '') {
          console.log('error in' + i + 'record');
          error = 'Error in ' + (i + 1) + ' record. Please enter ID';
          setErrorMsgBulk(
            'Error in ' + (i + 1) + ' record. Please enter country',
          );
          setOpenUpd(true);
          setTimeout(() => {
            setOpenUpd(false);
          }, 3000);
          return;
        }
        if (rec.state == '') {
          console.log('error in' + i + 'record');
          error = 'Error in ' + (i + 1) + ' record. Please enter ID';
          setErrorMsgBulk(
            'Error in ' + (i + 1) + ' record. Please enter state',
          );
          setOpenUpd(true);
          setTimeout(() => {
            setOpenUpd(false);
          }, 3000);
          return;
        }
        if (rec.status == '') {
          console.log('error in' + i + 'record');
          error = 'Error in ' + (i + 1) + ' record. Please enter ID';
          setErrorMsgBulk(
            'Error in ' + (i + 1) + ' record. Please enter Status',
          );
          setOpenUpd(true);
          setTimeout(() => {
            setOpenUpd(false);
          }, 3000);
          return;
        }
        // else {
        //   setErrorMsgBulk('')
        // }
      });
      if (error == '') {
        console.log(data);
        const payloadSupplier = {
          role: 'supplier',
          details: data,
        };

        axios({
          method: 'PUT',
          headers: {
            Authorization: sessionStorage.getItem('idtoken'),
          },
          url: process.env.REACT_APP_API_BASE + 'admin-portal',
          data: payloadSupplier,
        })
          .then(response => {
            console.log(response);
            if (!response.data.errorMessage) {
              setOpenUpd(true);
              setTimeout(() => {
                setOpenUpd(false);
              }, 3000);
              listAdmin();
            }
          })
          .catch(error => {
            console.log(error.response);
            console.log(error.response.status);
            if (
              error.response.status == 401 &&
              error.response.data.message == 'The incoming token has expired'
            ) {
              alert('Session timed out, please login again.');
              localStorage.clear();
              sessionStorage.clear();
              localStorage.clear();
              // localStorage.removeItem( "accessLevel" );
              // reset page location
              console.log('logout succesfully');
              document.location.replace('/');
            }
          });
      }
    };
    reader.readAsBinaryString(f);
    event.target.value = '';
    setErrorMsgBulk('');
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => {
            console.log('cahngeddd', e.target.value);
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    // TODO: if in future, no need, remove this
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {}, 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const filter = data => {
    data.map(rec => {
      console.log('rec====>', rec);
      setFilteredData(rec);
    });
  };

  const bulkDelete = e => {
    e.preventDefault();
    console.log('selectedList==>', props.selectedList);
    var arrData = [];
    props.selectedList.forEach(item => {
      const obj = {};
      if (item) {
        obj['id'] = item.id;
        obj['username'] = item.username;
        arrData.push(obj);
        console.log('arrData==>', arrData);
        confirmAlert({
          title: 'Confirm to Delete',
          message: 'Are you sure to do this.',
          buttons: [
            {
              label: 'yes',
              onClick: () => {
                axios({
                  method: 'PUT',
                  headers: {
                    Authorization: sessionStorage.getItem('idtoken'),
                  },
                  url:
                    process.env.REACT_APP_API_BASE + 'admin-portal/bulk-delete',
                  data: arrData,
                })
                  .then(response => {
                    console.log(response);
                    console.log('bulk supplier deleted !', response);
                    listAdmin();
                    props.setSelectedList([]);
                    arrData = [];
                  })
                  .catch(error => {
                    console.log(error.response);
                    console.log(error.response.status);
                    if (
                      error.response.status == 401 &&
                      error.response.data.message ==
                        'The incoming token has expired'
                    ) {
                      alert('Session timed out, please login again.');
                      localStorage.clear();
                      sessionStorage.clear();
                      localStorage.clear();
                      // localStorage.removeItem( "accessLevel" );
                      // reset page location
                      console.log('logout succesfully');
                      document.location.replace('/');
                    }
                  });
              },
            },
            {
              label: 'No',
              onClick: () => {
                return;
              },
            },
          ],
        });
      }
    });
  };
  // const filter = (data) => {

  //     var dd = []
  //     data.map((rec1, i) => {
  //       var aa2 = {
  //         id: (rec1['Supplier ID']).toString(),
  //         status: rec1['Status'],
  //         username: rec1['Username'],
  //         short_name: rec1['Short Name'],
  //         full_company_name: rec1['Full Company Name'],
  //         abn_no: rec1['ABN/ACN'] != "" ? (rec1['ABN/ACN']).toString() : "",
  //         mob_number: rec1['Mob Number'] != "" ? (rec1['Mob Number']).toString() : "",
  //         mini_ordr_value: rec1['Minimum Order Value'] != "" ? (rec1['Minimum Order Value']).toString() : "",
  //         contract_type: rec1['Contract Type'],
  //         business_email: rec1['Email'],
  //         address: rec1['Address'],
  //         state: rec1['State'],
  //         country: rec1['Country'],
  //         notes: rec1['Notes'],
  //       }

  //       dd.push(aa2)

  //     })

  //   // })
  //   setFilteredData(dd)
  // }

  const columns = [
    {
      title: 'Supplier ID',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      ...getColumnSearchProps('id'),
    },
    {
      title: 'Supplier',
      dataIndex: 'full_company_name',
      key: 'full_company_name',
      align: 'center',
      ...getColumnSearchProps('full_company_name'),
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      align: 'center',
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      align: 'center',
      filters: [
        { text: 'VIC', value: 'VIC' },
        { text: 'ACT', value: 'ACT' },
        { text: 'NSW', value: 'NSW' },
        { text: 'NT', value: 'NT' },
        { text: 'QLD', value: 'QLD' },
        { text: 'SA', value: 'SA' },
        { text: 'TAS', value: 'TAS' },
        { text: 'WA', value: 'WA' },
        { text: 'NI', value: 'NI' },
        { text: 'SI', value: 'SI' },
      ],
      onFilter: (value, record) => {
        console.log('vvvvv', value == record.state, value, record.state);
        return record['state'] == value;
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      align: 'center',
      key: 'x',
      render: (text, record) => {
        return (
          <>
            <button
              style={{
                marginRight: '0px',
                marginLeft: '5px',
                backgroundColor: 'transparent',
              }}
              onClick={e => handleView(record, e)}
            >
              <svg
                height="18"
                width="18"
                fill="#000000"
                xmlns="http://www.w3.org/2000/svg"
                data-name="Layer 3"
                viewBox="0 0 24 24"
                x="0px"
                y="0px"
              >
                <path d="M20.5,9.873A11.123,11.123,0,0,0,12,6,11.123,11.123,0,0,0,3.5,9.873L2.239,11.351a1,1,0,0,0,0,1.3L3.5,14.127A11.123,11.123,0,0,0,12,18a11.125,11.125,0,0,0,8.5-3.872l1.261-1.479a1,1,0,0,0,0-1.3Zm-1.521,2.958A9.134,9.134,0,0,1,12,16a9.131,9.131,0,0,1-6.979-3.17L4.314,12l.707-.83h0A9.134,9.134,0,0,1,12,8a9.131,9.131,0,0,1,6.979,3.17l.707.83Z"></path>
                <path d="M12,9a3,3,0,1,0,3,3A3,3,0,0,0,12,9Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,12,13Z"></path>
              </svg>{' '}
            </button>
            <button
              style={{ marginRight: '0', backgroundColor: 'transparent' }}
              onClick={e => deleteSupplier(record, e)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#000"
                className="bi bi-trash"
                viewBox="0 0 16 16"
              >
                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                <path
                  fillRule="evenodd"
                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                />
              </svg>
            </button>
          </>
        );
      },
    },
  ];

  const columns1 = [
    {
      title: 'ID',
      dataIndex: 'id',
      align: 'center',
    },
    {
      title: 'User Name',
      dataIndex: 'UserName',
      align: 'center',
      render: (text, record) => (
        <span>
          {record.first_name} {record.last_name}
        </span>
      ),
    },
    {
      title: 'Mob No.',
      dataIndex: 'mob_number',
      align: 'center',
    },
    {
      title: 'Email',
      dataIndex: 'business_email',
      align: 'center',
      render: (text, record) => {
        return (
          <p style={{ textTransform: 'lowercase' }}>{record.business_email}</p>
        );
      },
    },
  ];

  return (
    <>
      {loading ? (
        <div className="overlay-box">
          <ClipLoader
            color={color}
            loading={loading}
            css={override}
            size={150}
          />
        </div>
      ) : null}
      <Dialog open={openUpd}>
        <DialogContent>
          <DialogContentText>
            <Row>
              <Col md={12}>
                {errorMsgBulk == '' ? (
                  <>
                    <b style={{ color: 'red' }}>Thank you</b>
                    <br />
                    <br />
                    <label style={{ color: 'red' }}>
                      Supplier details successfully updated
                    </label>
                  </>
                ) : (
                  <>
                    {' '}
                    <br />
                    <br />
                    <label style={{ color: 'red' }}>{errorMsgBulk}</label>
                  </>
                )}
              </Col>
            </Row>
            <br />
          </DialogContentText>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Col
        md={9}
        style={{
          float: 'right',
          textAlign: 'right',
          paddingRight: '20px',
          marginTop: '10px',
          marginBottom: '-5px',
        }}
        className="uploadExcel"
      >
        <button
          title="Select records to delete!"
          type="button"
          variant="outlined"
          class="btn btn-primary"
          onClick={e => {
            bulkDelete(e);
          }}
          disabled={props.selectedList.length == 0}
        >
          {' '}
          Delete
        </button>
        <input
          type="file"
          id="importFile"
          accept=" .csv, .xlsx"
          style={{ display: 'none' }}
          onChange={handleUpload}
        />
        <label
          htmlFor="importFile"
          class="btn btn-primary"
          style={{ verticalAlign: 'sub' }}
        >
          Import
        </label>
        {/* <button type="button" variant="outlined" class="btn btn-primary" onClick={(e) => exportToCSV(filteredTableData, 'fileName')}>Import</button> */}
        <button
          type="button"
          variant="outlined"
          class="btn btn-primary"
          onClick={e => exportToCSV(filteredData, 'supplier_listing')}
        >
          Export
        </button>
        <button
          type="button"
          variant="outlined"
          class="btn btn-primary"
          onClick={e => exportToCSV(templateExcelData, 'supplier_template')}
        >
          Export Template
        </button>
      </Col>
      <div style={{ clear: 'both' }}></div>
      <div className="antdTable">
        {/* {tableData ? ( */}
        <Table
          columns={columns}
          onChange={(pagination, filters, sorter, currentPageData) =>
            filterD(currentPageData.currentDataSource)
          }
          // scroll={{ x: 1500 }}
          expandedRowRender={record => {
            console.log('row exp');
            const newUserData = userData.filter(
              item => item.supplier_name == record.username,
            );
            console.log(newUserData);
            return (
              <p style={{ margin: 0 }}>
                {record.username ? (
                  <Table
                    columns={columns1}
                    dataSource={newUserData}
                    bordered
                    pagination={false}
                  />
                ) : null}
              </p>
            );
          }}
          dataSource={tableData}
          rowSelection={{ ...props.rowSelection }}
          rowKey={record => record.id + record.username}
          // onChange={(pagination, filters, sorter, currentPageData) => filter(currentPageData.currentDataSource)}
          onChange={(pagination, filters, sorter, currentPageData) =>
            filterD(currentPageData.currentDataSource)
          }
          pagination={{
            total: filteredData.length,
            showSizeChanger: true,
            showTotal: total => `Total ${total} items`,
          }}
        />
        {/* ) : null} */}
      </div>
    </>
  );
};

export default SupplierTable;
