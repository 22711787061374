import Page from '../../components/Page';
import React, { useState } from 'react';
import { useEffect } from 'react';
import greyBanner from '../../assets/img/logo/Banner Grey.png';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
  Table,
} from 'reactstrap';
import { makeStyles } from '@material-ui/core/styles';

import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import DatePicker from 'react-datepicker';
import { useForm } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import axios from 'axios';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import validator from 'validator';
import { useMediaQuery } from 'react-responsive';
import TimePicker from 'react-time-picker';

import moment, { Moment } from 'moment';
import { isInvalidID, isInvalidUsername, isValidID } from './AddAdmin.js';

const TaskManagement = props => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    repFields,
    setRepFields,
    repHandler,
    vendorDetails,
    setVendorDetails,
    available,
    setAvailable,
    mobNums,
    setmobNums,
    handleMobChange,
    isViewModes,
    setIsViewModes,
    fieldDisabled,
    setFieldDisabled,
    setRequiredError,
    requiredError,
    retailerList,
  } = props;

  // const [toggleText, setToggleText] = React.useState('Inactive');
  const [openUpd, setOpenUpd] = React.useState(false);
  // const [statusValue, setStatusValue] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 1000px)' });
  const [calculateHours, setCalculateHours] = React.useState();
  const [openError, setOpenError] = React.useState(false);

  // const showContent = () => {
  //   var checkBox = document.getElementById('myCheck');
  //   setStatusValue(checkBox.checked);
  //   if (checkBox.checked == true) {
  //     setToggleText('Active');
  //   } else {
  //     setToggleText('Inactive');
  //   }
  // };

  // const [available, setAvailable] = React.useState([
  //   {
  //     availablity: 'Monday',
  //     avail_val: '',
  //     start_time: '',
  //     end_time: '',
  //     hours: '',
  //   },
  //   {
  //     availablity: 'Tuesday',
  //     avail_val: '',
  //     start_time: '',
  //     end_time: '',
  //     hours: '',
  //   },
  //   {
  //     availablity: 'Wednesday',
  //     avail_val: '',
  //     start_time: '',
  //     end_time: '',
  //     hours: '',
  //   },
  //   {
  //     availablity: 'Thursday',
  //     start_time: '',
  //     end_time: '',
  //     hours: '',
  //   },
  //   {
  //     availablity: 'Friday',
  //     avail_val: '',
  //     start_time: '',
  //     end_time: '',
  //     hours: '',
  //   },
  //   {
  //     availablity: 'Saturday',
  //     avail_val: '',
  //     start_time: '',
  //     end_time: '',
  //     hours: '',
  //   },
  //   {
  //     availablity: 'Sunday',
  //     avail_val: '',
  //     start_time: '',
  //     end_time: '',
  //     hours: '',
  //   },
  // ]);
  // const [repFields, setRepFields] = React.useState({
  //   role: 'rep',
  //   employee_id: '',
  //   first_name: '',
  //   last_name: '',
  //   username: '',
  //   employement_type: '',
  //   employment_status: '',
  //   line_manager: '',
  //   address: '',
  //   rep_state: '',
  //   rep_region: '',
  //   rep_territory: '',
  //   email: '',
  //   personal_email: '',
  //   mobile: '',
  //   availabeInfo: {
  //     availablity: '',
  //     start_date: '',
  //     end_date: '',
  //     hours: '',
  //   },
  //   total_hours: '',
  //   notes: '',
  //   venderDetails: {
  //     retailer_name: '',
  //     vendor_id: '',
  //   },
  // });
  // const [vendorDetails, setVendorDetails] = React.useState([
  //   {
  //     retailer_name: '',
  //     vendor_id: '',
  //   },
  // ]);
  const addVendorDet = () => {
    setVendorDetails([
      ...vendorDetails,
      {
        retailer_name: '',
        vendor_id: '',
      },
    ]);
  };
  // const [requiredError, setRequiredError] = React.useState({
  //   employee_id: '',
  //   emaila: '',
  //   personalEmail: '',
  // });
  // const [mobValue, setmobValue] = useState({
  //   mob_number: '',
  // });

  // const repHandler = e => {
  //   setRepFields({ ...repFields, [e.target.name]: e.target.value });
  //   if (e.target.name == 'employee_id') {
  //     setRequiredError({ employee_id: '' });
  //   }
  //   if (e.target.name == 'email') {
  //     setRequiredError({ email: '' });
  //   }
  //   if (e.target.name == 'personal_email') {
  //     setRequiredError({ personalEmail: '' });
  //   }
  // };

  const mobileValue = e => {
    // setmobValue({ ...mobValue, mob_number: e });
  };
  const addRep = () => {
    console.log('gyyghjbj', repFields);
    // return;
    if (repFields.id == '' || repFields.id == null) {
      setRequiredError({ repID: 'Please Enter Rep ID' });
      return;
    }
    if (
      repFields.id != '' &&
      repFields.id !== null &&
      isInvalidID(repFields.id)
    ) {
      setRequiredError({
        repID: 'Invalid ID, please remove special characters or shorten the ID',
      });
      return;
    }
    if (props.idError != '') {
      return;
    }
    if (repFields.username == '' || repFields.username == null) {
      setRequiredError({ repUsername: 'Please Enter Username' });
      return;
    }
    if (
      repFields.username != '' &&
      repFields.username != null &&
      isInvalidUsername(repFields.username)
    ) {
      setRequiredError({
        repUsername:
          'Invalid username, please remove special characters, space or shorten the username',
      });
      return;
    }
    if (repFields.first_name == '' || repFields.first_name == null) {
      setRequiredError({ repFirst: 'Please Enter First Name' });
      return;
    }
    if (repFields.last_name == '' || repFields.last_name == null) {
      setRequiredError({ repLast: 'Please Enter Last Name' });
      return;
    }
    if (repFields.rep_type == '' || repFields.rep_type == null) {
      setRequiredError({ rep_type: 'Please Enter Rep Type' });
      return;
    }
    if (repFields.state == '' || repFields.state == null) {
      setRequiredError({ state: 'Please Enter State' });
      return;
    }
    if (!validator.isEmail(repFields.email)) {
      setRequiredError({ repEmail: 'Please Enter Correct Email' });
      return;
    }
    // if (!validator.isEmail(repFields.personal_email)) {
    //   setRequiredError({ repPersonalEmail: 'Please Enter Email' });
    //   return;
    // }

    console.log({
      ...repFields,
      availabeInfo: [],
      status: props.statusValue,
      mob_number: mobNums.mob_number,
    });
    console.log('rep fields', repFields);

    const data = {
      ...repFields,
      id: repFields.id.toUpperCase(),
      // current_rep_type: repFields.rep_type,
      // rep_type: repFields.rep_type,
      status: props.statusValue ? 'active' : 'inactive',
      mobile: mobNums.rep_mob_number,
      delegated_task_count: 0,
      // availableInfo: availaData,

      // change the total hours to string in the payload
      total_hours: repFields.total_hours.toString(),
    };
    console.log('rep payload', data);
    const payloadRep = {
      role: 'rep',
      details: [data],
    };
    console.log(payloadRep);
    // return;
    axios({
      method: 'POST',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: process.env.REACT_APP_API_BASE + 'admin-portal',
      data: payloadRep,
    })
      .then(response => {
        console.log(response);
        if (response.data.ResponseMetadata) {
          setOpen(true);
          setTimeout(() => {
            setOpen(false);
          }, 3000);
          console.log('rep added');
        }
        // reset input field
        props.setStatusValue(true);
        props.setToggleText('Active');
        props.getRepList();
        setRepFields({
          role: 'rep',
          status: 'inactive',
          id: '',
          first_name: '',
          last_name: '',
          username: '',
          password: '',
          employement_type: '',
          employment_status: '',
          line_manager: '',
          address: '',
          state: '',
          rep_region: '',
          rep_territory: '',
          email: '',
          personal_email: '',
          mob_number: '',
          total_hours: 0,
          rep_type: '',
          notes: '',
        });
        setmobNums({
          ...mobNums,
          rep_mob_number: '',
        });
        setVendorDetails([
          {
            retailer_name: '',
            vendor_id: '',
          },
        ]);
        setAvailable([
          {
            availablity: 'Monday',
            avail_val: false,
            start_time: '00:00',
            end_time: '00:00',
            hours: '24:00',
          },
          {
            availablity: 'Tuesday',
            avail_val: false,
            start_time: '00:00',
            end_time: '00:00',
            hours: '24:00',
          },
          {
            availablity: 'Wednesday',
            avail_val: false,
            start_time: '00:00',
            end_time: '00:00',
            hours: '24:00',
          },
          {
            availablity: 'Thursday',
            avail_val: false,
            start_time: '00:00',
            end_time: '00:00',
            hours: '24:00',
          },
          {
            availablity: 'Friday',
            avail_val: false,
            start_time: '00:00',
            end_time: '00:00',
            hours: '24:00',
          },
          {
            availablity: 'Saturday',
            avail_val: false,
            start_time: '00:00',
            end_time: '00:00',
            hours: '24:00',
          },
          {
            availablity: 'Sunday',
            avail_val: false,
            start_time: '00:00',
            end_time: '00:00',
            hours: '24:00',
          },
        ]);
        setmobNums({
          ...mobNums,
          rep_mob_number: '',
        });
      })
      .catch(error => {
        console.log('add rep fail...', error.response.data);
        if ((error = 'Email already in use')) {
          setOpenError(true);
          setRepFields({
            ...repFields,
            email: '',
          });
        }

        if (
          error.response.status == 401 &&
          error.response.data.message == 'The incoming token has expired'
        ) {
          alert('Session timed out, please login again.');
          localStorage.clear();
          sessionStorage.clear();
          localStorage.clear();
          // localStorage.removeItem( "accessLevel" );
          // reset page location
          console.log('logout succesfully');
          document.location.replace('/');
        }
      });
  };
  const resetFields = () => {
    props.setStatusValue(true);
    props.setToggleText('Active');
    setRepFields({
      role: 'rep',
      status: 'inactive',
      id: '',
      first_name: '',
      last_name: '',
      rep_type: '',
      username: '',
      password: '',
      employement_type: '',
      employment_status: '',
      line_manager: '',
      address: '',
      state: '',
      rep_region: '',
      rep_territory: '',
      email: '',
      personal_email: '',
      mob_number: '',
      total_hours: 0,
      notes: '',
    });
    setVendorDetails([
      {
        retailer_name: '',
        vendor_id: '',
      },
    ]);
    setAvailable([
      {
        availablity: 'Monday',
        avail_val: false,
        start_time: '00:00',
        end_time: '00:00',
        hours: '24:00',
      },
      {
        availablity: 'Tuesday',
        avail_val: false,
        start_time: '00:00',
        end_time: '00:00',
        hours: '24:00',
      },
      {
        availablity: 'Wednesday',
        avail_val: false,
        start_time: '00:00',
        end_time: '00:00',
        hours: '24:00',
      },
      {
        availablity: 'Thursday',
        avail_val: false,
        start_time: '00:00',
        end_time: '00:00',
        hours: '24:00',
      },
      {
        availablity: 'Friday',
        avail_val: false,
        start_time: '00:00',
        end_time: '00:00',
        hours: '24:00',
      },
      {
        availablity: 'Saturday',
        avail_val: false,
        start_time: '00:00',
        end_time: '00:00',
        hours: '24:00',
      },
      {
        availablity: 'Sunday',
        avail_val: false,
        start_time: '00:00',
        end_time: '00:00',
        hours: '24:00',
      },
    ]);
    setmobNums({
      rep_mob_number: '',
    });
    setIsViewModes({
      ...isViewModes,
      isViewMode_rep: false,
    });
    setFieldDisabled({
      ...fieldDisabled,
      fieldDisabled_rep: false,
    });
    setRequiredError(false);
  };
  const updateRep = () => {
    // props.setIsDisabled(false);
    setOpenUpd(true);
    const data = {
      ...repFields,
      // current_rep_type: props.currentRepType,
      rep_type: repFields.rep_type,
      mobile: mobNums.rep_mob_number,
      // mob_number: mobNums.rep_mob_number,
      id: repFields.id.toUpperCase(),
      status: props.statusValue ? 'active' : 'inactive',

      // change the total hours to string in the payload
      total_hours: repFields.total_hours.toString(),
    };
    const payloadRep = {
      role: 'rep',

      details: [data],
    };
    console.log(payloadRep);
    console.log('update rep payload', data);
    // return
    axios({
      method: 'PUT',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: process.env.REACT_APP_API_BASE + 'admin-portal',
      data: payloadRep,
    })
      .then(response => {
        console.log(response);
        console.log(response.data);
        if (response.data) {
          setOpenUpd(true);
          setTimeout(() => {
            setOpenUpd(false);
          }, 3000);
          setIsViewModes({
            ...isViewModes,
            isViewMode_rep: false,
          });
          setmobNums({
            rep_mob_number: '',
          });
          props.setStatusValue(true);
          props.setToggleText('Active');
          setRepFields({
            role: 'rep',
            status: 'inactive',
            id: '',
            first_name: '',
            last_name: '',
            username: '',
            password: '',
            employement_type: '',
            employment_status: '',
            line_manager: '',
            address: '',
            state: '',
            rep_region: '',
            rep_territory: '',
            email: '',
            personal_email: '',
            mob_number: '',
            total_hours: 0,
            notes: '',
          });
          setVendorDetails([
            {
              retailer_name: '',
              vendor_id: '',
            },
          ]);
          setAvailable([
            {
              availablity: 'Monday',
              avail_val: false,
              start_time: '00:00',
              end_time: '00:00',
              hours: '24:00',
            },
            {
              availablity: 'Tuesday',
              avail_val: false,
              start_time: '00:00',
              end_time: '00:00',
              hours: '24:00',
            },
            {
              availablity: 'Wednesday',
              avail_val: false,
              start_time: '00:00',
              end_time: '00:00',
              hours: '24:00',
            },
            {
              availablity: 'Thursday',
              avail_val: false,
              start_time: '00:00',
              end_time: '00:00',
              hours: '24:00',
            },
            {
              availablity: 'Friday',
              avail_val: false,
              start_time: '00:00',
              end_time: '00:00',
              hours: '24:00',
            },
            {
              availablity: 'Saturday',
              avail_val: false,
              start_time: '00:00',
              end_time: '00:00',
              hours: '24:00',
            },
            {
              availablity: 'Sunday',
              avail_val: false,
              start_time: '00:00',
              end_time: '00:00',
              hours: '24:00',
            },
          ]);
        }
      })
      .catch(error => {
        console.log(error.response);
        console.log(error.response.status);
        if (
          error.response.status == 401 &&
          error.response.data.message == 'The incoming token has expired'
        ) {
          alert('Session timed out, please login again.');
          localStorage.clear();
          sessionStorage.clear();
          localStorage.clear();
          // localStorage.removeItem( "accessLevel" );
          // reset page location
          console.log('logout succesfully');
          document.location.replace('/');
        }
      });
  };

  const handleCloses = () => {
    setOpenError(false);
  };

  const handleClose = () => {
    props.setStatusValue(true);
    props.setToggleText('Active');
    setOpenError(false);
    setOpen(false);
    setOpenUpd(false);
    setRepFields({
      role: 'rep',
      status: 'inactive',
      id: '',
      first_name: '',
      last_name: '',
      username: '',
      password: '',
      employement_type: '',
      employment_status: '',
      line_manager: '',
      address: '',
      state: '',
      rep_region: '',
      rep_territory: '',
      email: '',
      personal_email: '',
      mob_number: '',
      total_hours: 0,
      notes: '',
    });
    setVendorDetails([
      {
        retailer_name: '',
        vendor_id: '',
      },
    ]);
    setAvailable([
      {
        availablity: 'Monday',
        avail_val: false,
        start_time: '00:00',
        end_time: '00:00',
        hours: '24:00',
      },
      {
        availablity: 'Tuesday',
        avail_val: false,
        start_time: '00:00',
        end_time: '00:00',
        hours: '24:00',
      },
      {
        availablity: 'Wednesday',
        avail_val: false,
        start_time: '00:00',
        end_time: '00:00',
        hours: '24:00',
      },
      {
        availablity: 'Thursday',
        avail_val: false,
        start_time: '00:00',
        end_time: '00:00',
        hours: '24:00',
      },
      {
        availablity: 'Friday',
        avail_val: false,
        start_time: '00:00',
        end_time: '00:00',
        hours: '24:00',
      },
      {
        availablity: 'Saturday',
        avail_val: false,
        start_time: '00:00',
        end_time: '00:00',
        hours: '24:00',
      },
      {
        availablity: 'Sunday',
        avail_val: false,
        start_time: '00:00',
        end_time: '00:00',
        hours: '24:00',
      },
    ]);
    setmobNums({
      rep_mob_number: '',
    });
    setIsViewModes({
      ...isViewModes,
      isViewMode_rep: false,
    });
  };
  // to disabled every field when under view mode
  useEffect(() => {
    const disabledVal = props.fieldDisabled.fieldDisabled_rep;

    const rep_form_inputs = document.querySelectorAll('#rep_form input');
    const rep_form_selects = document.querySelectorAll('#rep_form select');
    // console.log(store_form_inputs);
    for (let i = 0; i < rep_form_inputs.length; i++) {
      // store_id should not change
      if (
        rep_form_inputs[i].name !== 'id' &&
        rep_form_inputs[i].name !== 'username' &&
        rep_form_inputs[i].name !== 'email'
      ) {
        rep_form_inputs[i].disabled = disabledVal;
      }
    }
    for (let i = 0; i < rep_form_selects.length; i++) {
      rep_form_selects[i].disabled = disabledVal;
    }
  }, [props.fieldDisabled]);

  const renderRetailerOptions = () => {
    return retailerList.map(item => (
      <option value={item.full_company_name}>{item.full_company_name}</option>
    ));
  };
  
  const componentStyle = {
    selectFieldStyle: {
        fontSize: "0.7rem",
        width: "100%",
        height:"50%",
        padding: "0.3rem 0.5rem",
        border: "1px solid #ced4da",
    }
  }

  return (
    <>
      <Dialog open={open}>
        <DialogContent>
          <DialogContentText>
            <Row>
              <Col md={12}>
                <b style={{ color: 'red' }}>Thank you</b>
                <br />
                <br />
                <label style={{ color: 'red' }}>
                  Rep details successfully added
                </label>
              </Col>
            </Row>
            <br />
          </DialogContentText>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog open={openUpd}>
        <DialogContent>
          <DialogContentText>
            <Row>
              <Col md={12}>
                <b style={{ color: 'red' }}>Thank you</b>
                <br />
                <br />
                <label style={{ color: 'red' }}>
                  Rep details successfully updated
                </label>
              </Col>
            </Row>
            <br />
          </DialogContentText>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog open={openError}>
        <DialogContent>
          <DialogContentText>
            <Row>
              <Col md={12}>
                <b style={{ color: 'red' }}>Sorry</b>
                <br />
                <br />
                <label style={{ color: 'red' }}>Email already in use</label>
              </Col>
            </Row>
            <br />
          </DialogContentText>
          <Button onClick={handleCloses} variant="outlined" color="primary">
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Page>
        {/* <Row> */}
        {/* <Col xl={12} lg={12} md={12} style={{ paddingLeft: '0' }}> */}
        {/* <Col> */}
        {/* <Card id="userManagement">
              <CardBody
                style={{ background: '#fff', padding: '18px 18px 18px 0px' }}
              > */}
        {/* <div id="tabCol" style={{ paddingTop: '2%' }}> */}
        {/* <Row>
                    <Col md="6">
                      <b style={{ fontFamily: 'Arial Bold' }}>REP - SETUP </b>
                    </Col>
                  </Row> */}
        <Form id="rep_form">
          <FormGroup>
            {/* <Row>
                        <Col md="3">
                          <label className="switch">
                            <Input
                              type="checkbox"
                              name="status"
                              id="myCheck"
                              // onClick={showContent}
                              onChange={repHandler}
                              checked={
                                repFields.status == 'inactive' ? false : true
                              }
                            />
                            <span className="slider round"></span>
                          </label>
                          <p style={{ fontSize: '14px' }}>
                            {repFields &&
                              repFields.status == 'inactive'
                              ? 'Inactive'
                              : 'Active'}
                          </p>
                        </Col>
                      </Row> */}

            <Row className="adminrep-body" md="12">
              <Col>
                <br />
                <div className="rep-container-1" style={{ width: '100%' }}>
                  <div className="rep-container-1-inner">
                    <Row className="admin-row">
                      <Col md="6" style={{ margin: '0px' }}>
                        <label style={{ fontSize: '1vw' }}>
                          Rep ID<span style={{ color: 'red' }}> *</span>{' '}
                        </label>
                        <Input
                          type="text"
                          name="id"
                          style={{ textTransform: 'uppercase' }}
                          value={repFields.id}
                          onChange={repHandler}
                          disabled={isViewModes.isViewMode_rep}
                          placeholder="Rep ID"
                          className="admin-input"
                        />
                        <p style={{ color: 'red', fontSize: '12px' }}>
                          {requiredError.repID}
                          {props.idError}
                        </p>
                      </Col>

                      <Col md="6" style={{ margin: '0px' }}>
                        <label style={{ fontSize: '1vw' }}>
                          Username<span style={{ color: 'red' }}> *</span>{' '}
                        </label>
                        <Input
                          type="text"
                          name="username"
                          value={repFields.username}
                          onChange={repHandler}
                          disabled={isViewModes.isViewMode_rep}
                          placeholder="Username"
                          className="admin-input"
                        />
                        <p style={{ color: 'red', fontSize: '12px' }}>
                          {requiredError.repUsername}
                        </p>
                      </Col>
                    </Row>
                    <Row className="admin-row">
                      <Col md="6">
                        <label style={{ fontSize: '1vw' }}>
                          First Name <span style={{ color: 'red' }}> *</span>{' '}
                        </label>
                        <Input
                          type="text"
                          name="first_name"
                          value={repFields.first_name}
                          onChange={repHandler}
                          placeholder="First Name"
                          className="admin-input"
                        />
                        <p style={{ color: 'red', fontSize: '12px' }}>
                          {requiredError.repFirst}
                        </p>
                      </Col>
                      <Col md="6">
                        <label style={{ fontSize: '1vw' }}>
                          {' '}
                          Last Name <span style={{ color: 'red' }}>
                            {' '}
                            *
                          </span>{' '}
                        </label>
                        <Input
                          type="text"
                          name="last_name"
                          value={repFields.last_name}
                          onChange={repHandler}
                          placeholder="Last Name"
                          className="admin-input"
                        />
                        <p style={{ color: 'red', fontSize: '12px' }}>
                          {requiredError.repLast}
                        </p>
                      </Col>
                    </Row>
                    <Row className="admin-row">
                      {/* <Col>
                  <label style={{ fontSize: '1vw' }}>
                    {' '}
                  Rep Type <span style={{ color: 'red' }}> *</span>{' '}
                  </label>
                  <Input
                    type="select"
                    name="rep_type"
                    value={repFields.rep_type}
                    onChange={repHandler}
                    className="admin-input"
                  >
                    <option value="">Select Type</option>
                    <option value="">Merchandizer</option>
                    <option value="">Sales</option>
                    </Input>
                  <p style={{ color: 'red', fontSize: '12px' }}>
                    {requiredError.repLast}
                  </p>
                </Col> */}
                      <Col style={{ margin: '0px' }} className="emp">
                        <label style={{ fontSize: '1vw' }}>
                          Employment Type{' '}
                        </label>
                        <Input
                          type="select"
                          name="employement_type"
                          required
                          value={repFields.employement_type}
                          onChange={repHandler}
                          placeholder="Employment Type"
                          className="admin-input"
                          style={componentStyle.selectFieldStyle}
                        >
                          <option value="">Select Type</option>
                          <option value="partTime">Part Time </option>
                          <option value="fullTime">Full Time</option>
                          <option value="casual">Casual</option>
                        </Input>
                      </Col>
                      <Col md="6">
                        <label style={{ fontSize: '1vw' }}>
                          Employment Status{' '}
                        </label>
                        <Input
                          type="text"
                          name="employment_status"
                          value={repFields.employment_status}
                          onChange={repHandler}
                          placeholder="Employment Status"
                          className="admin-input"
                        />
                      </Col>
                    </Row>
                    <Row className="admin-row">
                      <Col md="6" style={{ margin: '0px' }}>
                        <label style={{ fontSize: '1vw' }}>
                          Rep Type<span style={{ color: 'red' }}> *</span>{' '}
                        </label>
                        <Input
                          type="select"
                          name="rep_type"
                          value={repFields.rep_type}
                          onChange={repHandler}
                          // disabled={isViewModes.isViewMode_rep}
                          placeholder="Rep ID"
                          className="admin-input"
                          required
                          style={{...componentStyle.selectFieldStyle, height: "60%"}}
                        >
                          <option value="">Select Type</option>
                          <option value="merchandiser">Merchandiser </option>
                          <option value="sales">Sales</option>
                        </Input>
                        <p style={{ color: 'red', fontSize: '12px' }}>
                          {requiredError.rep_type}
                          {/* {props.idError} */}
                        </p>
                      </Col>
                      <Col md="6" style={{ margin: '0px' }}>
                        <label style={{ fontSize: '1vw' }}>
                          Email <span style={{ color: 'red' }}> *</span>{' '}
                        </label>
                        <Input
                          type="text"
                          name="email"
                          value={repFields.email}
                          disabled={isViewModes.isViewMode_rep}
                          onChange={repHandler}
                          placeholder="Business Email"
                          className="admin-input"
                        />
                        <p style={{ color: 'red', fontSize: '12px' }}>
                          {requiredError.repEmail}
                        </p>
                      </Col>
                    </Row>
                    <Row className="admin-row">
                      <Col md="6" style={{ margin: '0px' }}>
                        <label style={{ fontSize: '1vw' }}>
                          Personal Email
                          {/* <span style={{ color: 'red' }}> *</span>{' '} */}
                        </label>
                        <Input
                          type="text"
                          name="personal_email"
                          value={repFields.personal_email}
                          onChange={repHandler}
                          placeholder="Personal Email"
                          className="admin-input"
                        />
                        {/* <p style={{ color: 'red', fontSize: '12px' }}>
                    {requiredError.repPersonalEmail}
                  </p> */}
                      </Col>
                      {/* <Col md='6'>
                  <label style={{ fontSize: '1vw' }}>Rep State </label>
                  <Input
                    type="text"
                    name="state"
                    value={repFields.state}
                    onChange={repHandler}
                    placeholder="Rep State"
                    className="admin-input"
                  />
                </Col> */}
                      <Col>
                        <label>
                          Rep State <span style={{ color: 'red' }}> *</span>{' '}
                        </label>
                        <Input
                          type="select"
                          name="state"
                          required
                          className="admin-input"
                          onChange={repHandler}
                          value={repFields.state}
                          style={{...componentStyle.selectFieldStyle, height: "70%"}}
                        >
                          <option selected value="">
                            Select State
                          </option>
                          <option value="ACT">
                            Australian Capital Territory
                          </option>
                          <option value="NSW">New South Wales</option>
                          <option value="NT">Northern Territory</option>
                          <option value="SA">South Australia</option>
                          <option value="QLD">Queensland</option>
                          <option value="TAS">Tasmania</option>
                          <option value="VIC">Victoria</option>
                          <option value="WA">Western Australia</option>
                          <option value="NI">North Island</option>
                          <option value="SI">South Island</option>
                        </Input>
                        <p style={{ color: 'red', fontSize: '12px' }}>
                          {requiredError.state}
                        </p>
                      </Col>
                    </Row>
                    <Row className="admin-row">
                      <Col md="6">
                        <label style={{ fontSize: '1vw' }}>
                          Rep Territory{' '}
                        </label>
                        <Input
                          type="text"
                          name="rep_territory"
                          value={repFields.rep_territory}
                          onChange={repHandler}
                          placeholder="Rep Territory"
                          className="admin-input"
                        />
                      </Col>
                      <Col md="6">
                        <label style={{ fontSize: '1vw' }}>Rep Region </label>
                        <Input
                          type="text"
                          name="rep_region"
                          value={repFields.rep_region}
                          onChange={repHandler}
                          placeholder="Rep Region"
                          className="admin-input"
                        />
                      </Col>
                    </Row>
                    <Row className="admin-row">
                      <Col md="6">
                        <label style={{ fontSize: '1vw' }}>Line Manager </label>
                        <Input
                          type="text"
                          name="line_manager"
                          value={repFields.line_manager}
                          onChange={repHandler}
                          placeholder="Line Manager"
                          className="admin-input"
                        />
                      </Col>
                      <Col md="6">
                        <label style={{ fontSize: '1vw' }}>Address </label>
                        <Input
                          type="text"
                          name="address"
                          value={repFields.address}
                          onChange={repHandler}
                          placeholder="Address"
                          className="admin-input"
                        />
                      </Col>
                    </Row>
                    <Row className="admin-row">
                      <Col md="6">
                        <label style={{ fontSize: '1vw' }}>Mobile </label>
                        <PhoneInput
                          //className="phnclass"
                          className="admin-input"
                          name="mob_number"
                          // onChange={mobileValue}
                          onChange={val =>
                            handleMobChange(val, 'rep_mob_number')
                          }
                          value={mobNums.rep_mob_number}
                          placeholder="Mobile Number"
                        />
                        {/* <Input type='text' name="mobile" value={repFields.mobile} onChange={repHandler}
                            /> */}
                      </Col>
                      <Col md="6">
                        <label style={{ fontSize: '1vw' }}>Notes</label>
                        <Input
                          type="textarea"
                          name="notes"
                          value={repFields.notes}
                          onChange={repHandler}
                          placeholder="Notes"
                          className="admin-input"
                        />
                      </Col>
                    </Row>
                    {/* <Row>
                              <Col md="3">
                                <h6>Vendor IDs </h6>
                              </Col>
                            </Row> */}

                    {vendorDetails &&
                      vendorDetails.map((rec, i) => (
                        // <Row key={i}>
                        <Row className="admin-row">
                          <Col>
                            <Row>
                              <Col md="6">
                                <label style={{ fontSize: '1vw' }}>
                                  Retailer
                                </label>
                                <Input
                                  type="select"
                                  name="retailer_name"
                                  required
                                  value={rec.retailer_name}
                                  onChange={e => {
                                    vendorDetails[i].retailer_name =
                                      e.target.value;
                                    setRepFields({
                                      ...repFields,
                                      venderDetails: [...vendorDetails],
                                    });
                                    setVendorDetails([...vendorDetails]);
                                  }}
                                  placeholder="Retailer Name"
                                  className="admin-input"
                                  style={{...componentStyle.selectFieldStyle, height: "60%"}}
                                >
                                  <option
                                    selected
                                    style={{ fontStyle: 'Arial' }}
                                    value=""
                                  >
                                    Select Retailer
                                  </option>
                                  {renderRetailerOptions()}
                                </Input>
                              </Col>
                              <Col md="6">
                                <label style={{ fontSize: '1vw' }}>
                                  Vendor ID Number
                                </label>
                                <Input
                                  type="text"
                                  name="vendor_id"
                                  value={rec.vendor_id}
                                  onChange={e => {
                                    vendorDetails[i].vendor_id = e.target.value;
                                    setRepFields({
                                      ...repFields,
                                      venderDetails: [...vendorDetails],
                                    });
                                    setVendorDetails([...vendorDetails]);
                                  }}
                                  placeholder="Retailer ID"
                                  className="admin-input"
                                />
                              </Col>
                            </Row>
                            <Row
                              style={{
                                float: 'right',
                              }}
                            >
                              {i == vendorDetails.length - 1 ? (
                                <Col>
                                  {/* <label style={{ color: 'transparent' }}>
                                          Add
                                        </label>
                                        <br /> */}
                                  <div
                                    onClick={e => {
                                      addVendorDet(e);
                                    }}
                                  >
                                    <b
                                      style={{
                                        fontSize: '34px',
                                        cursor: 'pointer',
                                        color: '#D71920',
                                      }}
                                    >
                                      +
                                    </b>
                                  </div>
                                </Col>
                              ) : null}
                              {i > 0 && i == vendorDetails.length - 1 ? (
                                <Col>
                                  {/* <label style={{ color: 'transparent' }}>
                                          Remove
                                        </label>
                                        <br /> */}
                                  <div
                                    onClick={e => {
                                      vendorDetails.splice(i, 1);
                                      setVendorDetails([...vendorDetails]);
                                    }}
                                  >
                                    {' '}
                                    <b
                                      style={{
                                        fontSize: '34px',
                                        cursor: 'pointer',
                                        color: '#D71920',
                                      }}
                                    >
                                      -
                                    </b>{' '}
                                  </div>
                                </Col>
                              ) : null}
                            </Row>
                          </Col>
                        </Row>
                      ))}
                  </div>
                </div>
              </Col>
              <Col>
                <Row style={{ marginLeft: '2rem' }}>
                  <h6>Availability Information </h6>
                </Row>
                <div
                  className="rep-right rep-container-23 rep-2-additional"
                  style={{ width: '100%' }}
                >
                  <div className="rep-container-1-inner">
                    {available &&
                      available.map((rec, i) => (
                        <Row className="admin-row smScreenWidth" key={i}>
                          <Col md="3" className="dayName">
                            {isMobile ? (
                              <> {/* <label>Availability </label> */}</>
                            ) : null}
                            <div style={{ paddingLeft: '21px' }}>
                              <Input
                                type="checkbox"
                                name="avail_val"
                                id={rec.availablity}
                                value={rec.avail_val}
                                checked={rec.avail_val}
                                onChange={e => {
                                  available[i].avail_val = e.target.checked;
                                  console.log(
                                    'checked 333 ',
                                    e.target.checked,
                                    available,
                                    repFields,
                                  );
                                  const total_hours = [...available].reduce(
                                    (previousValue, currentItem) => {
                                      if (currentItem.avail_val) {
                                        return (
                                          parseFloat(previousValue) +
                                          parseFloat(currentItem.hours)
                                        );
                                      } else {
                                        return previousValue;
                                      }
                                    },
                                    0,
                                  );
                                  console.log('calc total hours', total_hours);
                                  // check the row, add total hours
                                  if (e.target.checked) {
                                    setRepFields({
                                      ...repFields,
                                      availabeInfo: [...available],
                                      total_hours,
                                    });
                                    setAvailable([...available]);
                                  }
                                  // uncheck the row, restore the time
                                  else {
                                    available[i].start_time = '00:00';
                                    available[i].end_time = '00:00';
                                    available[i].hours = '24:00';

                                    setRepFields({
                                      ...repFields,
                                      availabeInfo: [...available],
                                      total_hours,
                                    });
                                    setAvailable([...available]);
                                  }
                                }}
                              />
                              <label
                                htmlFor={rec.availablity}
                                style={{ cursor: 'pointer', fontSize: '1vw' }}
                              >
                                {rec.availablity}
                              </label>
                            </div>
                          </Col>
                          <Col md="3">
                            {isMobile ? (
                              <>{/* <label>Start Time </label> */}</>
                            ) : null}
                            <TimePicker
                              format="hh:mm a"
                              className="admin-input form-control"
                              value={available[i].start_time}
                              disableClock="true"
                              onChange={e => {
                                available[i].start_time = e;
                                console.log(available[i].start_time);

                                var startTime = moment(
                                  available[i].start_time,
                                  'HH:mm a',
                                );
                                var endTime = moment(
                                  available[i].end_time,
                                  'HH:mm a',
                                );
                                var duration = moment.duration(
                                  endTime.diff(startTime),
                                );
                                var hours = parseInt(duration.asHours());
                                var minutes =
                                  parseInt(duration.asMinutes()) - hours * 60;
                                // alert(hours + ' hour and ' + minutes + ' minutes.')
                                if (
                                  Math.sign(minutes) === -1 ||
                                  Math.sign(hours) === -1
                                ) {
                                  console.log(hours);
                                  console.log(minutes);
                                  available[i].hours = 0 + '.' + 0;
                                } else if (available[i].end_time == '00:00') {
                                  available[i].hours = 24 + '.' + 0;
                                } else {
                                  available[i].hours = hours + '.' + minutes;
                                }
                                var result = available.reduce(function (
                                  tot,
                                  arr,
                                ) {
                                  // return the sum with previous value
                                  return tot + Number(arr.hours);

                                  // set initial value as 0
                                },
                                  0);
                                console.log('start result', result);
                                repFields.total_hours = result.toString();
                                console.log(e);
                                console.log(
                                  ' rep fields end time',
                                  repFields,
                                  available,
                                );
                                const total_hours = [...available].reduce(
                                  (previousValue, currentItem) => {
                                    if (currentItem.avail_val) {
                                      return (
                                        parseFloat(previousValue) +
                                        parseFloat(currentItem.hours)
                                      );
                                    } else {
                                      return previousValue;
                                    }
                                  },
                                  0,
                                );
                                setRepFields({
                                  ...repFields,
                                  availabeInfo: [...available],
                                  total_hours,
                                });
                                setAvailable([...available]);
                                // setRepFields({
                                //   ...repFields,
                                //   availabeInfo: [...available],
                                // });
                                // setAvailable([...available]);
                              }}
                            />
                            {/* <TimePicker
                              format="h:m a"
                              className="admin-input form-control"
                              value={available[i].start_time}
                              disableClock="true"
                              onChange={e => {
                                available[i].start_time = e;
                                console.log(available[i].start_time);

                                console.log(e);
                                
                                setRepFields({
                                  ...repFields,
                                  availabeInfo: [...available],
                                });
                                setAvailable([...available]);
                              }}
                            /> */}
                            {/* <Input type="select"

                        name="start_time"
                        value={available[i].start_time}
                        onChange={(e) => {

                          available[i].start_time = e.target.value;

                          console.log(available[i].start_time)
                          setRepFields({
                            ...repFields,
                            availabeInfo: [...available],
                          });
                          setAvailable([...available]);
                        }}
                        className="admin-input"
                        placeholder="Start Time"
                      >
                        <option value="">Start Time</option>
                        <option value="00:00:00">12:00 am</option>
                        <option value="00:30:00">12:30 am</option>
                        <option value="01:00:00">1:00 am</option>
                        <option value="01:30:00">1:30 am</option>
                        <option value="02:00:00">2:00 am</option>
                        <option value="02:30:00">2:30 am</option>
                        <option value="03:00:00">3:00 am</option>
                        <option value="03:30:00">3:30 am</option>
                        <option value="04:00:00">4:00 am</option>
                        <option value="04:30:00">4:30 am</option>
                        <option value="05:00:00">5:00 am</option>
                        <option value="05:30:00">5:30 am</option>
                        <option value="06:00:00">6:00 am</option>
                        <option value="06:30:00">6:30 am</option>
                        <option value="07:00:00">7:00 am</option>
                        <option value="07:30:00">7:30 am</option>
                        <option value="08:00:00">8:00 am</option>
                        <option value="08:30:00">8:30 am</option>
                        <option value="09:00:00">9:00 am</option>
                        <option value="09:30:00">9:30 am</option>
                        <option value="10:00:00">10:00 am</option>
                        <option value="10:30:00">10:30 am</option>
                        <option value="11:00:00">11:00 am</option>
                        <option value="11:30:00">11:30 am</option>
                        <option value="12:00:00">12:00 pm</option>
                        <option value="12:30:00">12:30 pm</option>
                        <option value="13:00:00">1:00 pm</option>
                        <option value="13:30:00">1:30 pm</option>
                        <option value="14:00:00">2:00 pm</option>
                        <option value="14:30:00">2:30 pm</option>
                        <option value="15:00:00">3:00 pm</option>
                        <option value="15:30:00">3:30 pm</option>
                        <option value="16:00:00">4:00 pm</option>
                        <option value="16:30:00">4:30 pm</option>
                        <option value="17:00:00">5:00 pm</option>
                        <option value="17:30:00">5:30 pm</option>
                        <option value="18:00:00">6:00 pm</option>
                        <option value="18:30:00">6:30 pm</option>
                        <option value="19:00:00">7:00 pm</option>
                        <option value="19:30:00">7:30 pm</option>
                        <option value="20:00:00">8:00 pm</option>
                        <option value="20:30:00">8:30 pm</option>
                        <option value="21:00:00">9:00 pm</option>
                        <option value="21:30:00">9:30 pm</option>
                        <option value="22:00:00">10:00 pm</option>
                        <option value="22:30:00">10:30 pm</option>
                        <option value="23:00:00">11:00 pm</option>
                        <option value="23:30:00">11:30 pm</option>
                      </Input> */}
                          </Col>
                          <Col md="3">
                            {isMobile ? (
                              <>{/* <label>End Time </label> */}</>
                            ) : null}
                            <TimePicker
                              format="hh:mm a"
                              className="admin-input form-control"
                              value={available[i].end_time}
                              disableClock="true"
                              onChange={e => {
                                available[i].end_time = e;
                                console.log(available[i].end_time);
                                var startTime = moment(
                                  available[i].start_time,
                                  'HH:mm a',
                                );
                                var endTime = moment(
                                  available[i].end_time,
                                  'HH:mm a',
                                );
                                var duration = moment.duration(
                                  endTime.diff(startTime),
                                );
                                var hours = parseInt(duration.asHours());
                                var minutes =
                                  parseInt(duration.asMinutes()) - hours * 60;
                                // alert(hours + ' hour and ' + minutes + ' minutes.')
                                if (
                                  Math.sign(minutes) === -1 ||
                                  Math.sign(hours) === -1
                                ) {
                                  console.log(hours);
                                  console.log(minutes);
                                  available[i].hours = 0 + '.' + 0;
                                } else if (available[i].end_time == '00:00') {
                                  available[i].hours = 24 + '.' + 0;
                                } else {
                                  available[i].hours = hours + '.' + minutes;
                                }
                                var result = available.reduce(function (
                                  tot,
                                  arr,
                                ) {
                                  // return the sum with previous value
                                  return tot + Number(arr.hours);

                                  // set initial value as 0
                                },
                                  0);
                                console.log(result);
                                repFields.total_hours = result.toString();
                                console.log(e);
                                console.log(
                                  ' rep fields end time',
                                  repFields,
                                  available,
                                );
                                const total_hours = [...available].reduce(
                                  (previousValue, currentItem) => {
                                    if (currentItem.avail_val) {
                                      return (
                                        parseFloat(previousValue) +
                                        parseFloat(currentItem.hours)
                                      );
                                    } else {
                                      return previousValue;
                                    }
                                  },
                                  0,
                                );
                                setRepFields({
                                  ...repFields,
                                  availabeInfo: [...available],
                                  total_hours,
                                });
                                setAvailable([...available]);
                              }}
                            />
                            {/* <Input type="select"

                        name="end_time"

                        value={available[i].end_time}

                        onChange={(e) => {
                          console.log(e.target.value)

                          available[i].end_time = e.target.value;

                          var timeStart = new Date("01/01/2020 " + available[i].start_time).getHours();
                          var timeStart1 = new Date("01/01/2020 " + available[i].start_time).getMinutes();
                          var timeEnd = new Date("01/01/2020 " + available[i].end_time).getHours();
                          var timeEnd1 = new Date("01/01/2020 " + available[i].end_time).getMinutes();


                          var hourDiff;
                          var minDiff = Number(timeEnd1) - Number(timeStart1);

                          if (timeStart == timeEnd && timeStart1 > timeEnd1) {
                            minDiff = 0 - minDiff;
                            hourDiff = 23;
                          }
                          else {
                            hourDiff = Number(timeEnd) - Number(timeStart);
                            if (hourDiff < 0) {
                              hourDiff = 24 + hourDiff;
                            }
                          }
                          if (minDiff < 0) {
                            minDiff = 0 - minDiff;
                          }
                          console.log(hourDiff + '.' + minDiff)
                          available[i].hours = hourDiff + '.' + minDiff;

                          setRepFields({
                            ...repFields,
                            availabeInfo: [...available],
                          });
                          setAvailable([...available]);
                          // {
                          //   available.map(rec => {
                          //     if (
                          //       rec.end_time == timeString ||
                          //       rec.start_time == timeString
                          //     ) {
                          //       var start = moment(rec.start_time, 'HH:mm');
                          //       var end = moment(rec.end_time, 'HH:mm');
                          //       console.log(start)
                          //       // calculate total duration
                          //       var duration = moment.duration(end.diff(start));
                          //       // duration in hours
                          //       var hoursss = parseInt(duration.asHours());
                          //       // setCalculateHours(hours);
                          //       console.log('hours', hoursss);
                          //       console.log('duration', duration);
                          //       console.log(start, end);
                          //       console.log(rec);
                          //       available[i].hours = hoursss;
                          //       var result = available.reduce(function (
                          //         tot,
                          //         arr,
                          //       ) {
                          //         // return the sum with previous value
                          //         return tot + Number(arr.hours);

                          //         // set initial value as 0
                          //       },
                          //         0);
                          //       console.log(result);
                          //       repFields.total_hours = result;
                          //       //  console.log( Number(hoursss))
                          //       setRepFields({
                          //         ...repFields,
                          //         availabeInfo: [...available],
                          //       });
                          //       setAvailable([...available]);
                          //     }
                          //   });
                          // }
                        }}
                        className="admin-input"
                        placeholder="End Time"
                      >
                     
                      </Input> */}
                          </Col>
                          <Col md="3" className="hourTime">
                            {isMobile ? (
                              <>{/* <label>Hours </label> */}</>
                            ) : null}
                            <Input
                              // type="number"
                              name="hours"
                              disabled
                              value={available[i].hours}
                              className="admin-input"
                              placeholder="Hours"
                            />
                          </Col>
                        </Row>
                      ))}
                    <Row className="admin-row">
                      <Col md="6">
                        <p>
                          <b style={{ fontSize: '1rem' }}>Total Hours </b>
                        </p>
                      </Col>
                      <Col md="6">
                        {/* <Input
                          // type="number"
                          disabled={true}
                          name="total_hours"

                          // min={0}
                          value={repFields.total_hours}
                          // onChange={repHandler}
                          className="admin-input"
                          placeholder="Total Hours"
                        /> */}
                        <p className="total-hour">{repFields.total_hours}</p>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
            <Row style={{ padding: '20px 0px 0px 25px' }}>
              {isViewModes.isViewMode_rep == false ? (
                <Col md={12} className="martop">
                  <Button onClick={addRep} variant="outlined" color="primary">
                    <span>
                      {' '}
                      <b> Add Rep</b>{' '}
                    </span>
                  </Button>
                  <Button
                    onClick={resetFields}
                    variant="outlined"
                    color="primary"
                  >
                    <span>
                      {' '}
                      <b>Cancel</b>{' '}
                    </span>
                  </Button>
                </Col>
              ) : (
                <Col md={12} className="martop">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={updateRep}
                  >
                    <span>
                      {' '}
                      <b> Update</b>{' '}
                    </span>
                  </Button>
                  <Button
                    onClick={resetFields}
                    variant="outlined"
                    color="primary"
                  >
                    <span>
                      {' '}
                      <b>Cancel</b>{' '}
                    </span>
                  </Button>
                </Col>
              )}
            </Row>

            {/* <Row style={{ display: 'flex' }}>
                        {/* <Col md="3">
                        <label> Login Password </label>
                        <Input type='password' name="password" value={repFields.password} onChange={repHandler}
                          />
                      </Col> */}
            {/* </Row> */}

            {/* {!isMobile ? (
                        <Row>
                          <Col md="2">
                            <p>
                              <b>Availability </b>
                            </p>
                          </Col>
                          <Col md="2">
                            <p>
                              {' '}
                              <b>Start Time </b>
                            </p>
                          </Col>
                          <Col md="2">
                            <p>
                              <b>End Time </b>
                            </p>
                          </Col>
                          <Col md="2">
                            <p>
                              <b>Hours </b>
                            </p>
                          </Col>
                        </Row>
                      ) : null}

                      {available.map((rec, i) => (
                        <Row key={i}>
                          <Col md="2">
                            {isMobile ? (
                              <>
                                {' '}
                                <label>Availability </label>
                                <br />
                              </>
                            ) : null}
                            <div style={{ paddingLeft: '21px' }}>
                              <Input
                                type="checkbox"
                                name="avail_val"
                                id={rec.availablity}
                                value={rec.avail_val}
                                checked={rec.avail_val}
                                onChange={e => {
                                  available[i].avail_val = e.target.checked;
                                  setRepFields({
                                    ...repFields,
                                    availabeInfo: [...available],
                                  });
                                  setAvailable([...available]);
                                }}
                              />
                              <label
                                htmlFor={rec.availablity}
                                style={{ cursor: 'pointer' }}
                              >
                                {rec.availablity}
                              </label>
                            </div>
                          </Col>
                          <Col md="2">
                            {isMobile ? (
                              <>
                                <label>Start Time </label>
                              </>
                            ) : null}
                            <Input
                              type="number"
                              name="start_time"
                              min={0}
                              value={rec.start_time}
                              onChange={e => {
                                available[i].start_time = e.target.value;
                                setRepFields({
                                  ...repFields,
                                  availabeInfo: [...available],
                                });
                                setAvailable([...available]);
                              }}
                            />
                          </Col>
                          <Col md="2">
                            {isMobile ? (
                              <>
                                <label>End Time </label>
                              </>
                            ) : null}
                            <Input
                              type="number"
                              name="end_time"
                              min={0}
                              value={rec.end_time}
                              onChange={e => {
                                available[i].end_time = e.target.value;
                                setRepFields({
                                  ...repFields,
                                  availabeInfo: [...available],
                                });
                                setAvailable([...available]);
                              }}
                            />
                          </Col>
                          <Col md="2">
                            {isMobile ? (
                              <>
                                <label>Hours </label>
                              </>
                            ) : null}
                            <Input
                              type="number"
                              name="hours"
                              min={0}
                              value={rec.hours}
                              onChange={e => {
                                available[i].hours = e.target.value;
                                setRepFields({
                                  ...repFields,
                                  availabeInfo: [...available],
                                });
                                setAvailable([...available]);
                              }}
                            />
                          </Col>
                        </Row>
                      ))}
                      <Row>
                        <Col md="2">
                          <p>
                            <b>Total Hours </b>
                          </p>
                        </Col>
                        <Col md="2"></Col>
                        <Col md="2"></Col>
                        <Col md="2">
                          <Input
                            type="number"
                            name="total_hours"
                            min={0}
                            value={repFields.total_hours}
                            onChange={repHandler}
                          />
                        </Col>
                      </Row> */}
            <Row></Row>

            {/* </FormGroup>
        </Form> */}
            {/* </div> */}
            {/* </CardBody>
            </Card> */}
            {/* </Col> */}
            {/* </Row> */}
          </FormGroup>
        </Form>
      </Page>
    </>
  );
};

export default TaskManagement;
