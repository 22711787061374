import Page from '../../components/Page';
import React from 'react';
import 'antd/dist/antd.min.css';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
} from 'reactstrap';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import 'react-datepicker/dist/react-datepicker.css';
import RequestDocument from '../Tables/RequestDocument';
import greyBanner from '../../assets/img/logo/Banner Grey.png';
import AdhocRequest from '../Tables/AdhocRequest.js';

const TaskManagement = () => {
  const [value, setValue] = React.useState('1');
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  }

  return (
    <Page>
      <Row>
        <Col xl={12} lg={12} md={12} style={{ paddingLeft: '0' }}>
          <Card id="userManagement">
            <CardHeader className="card-header-main"> ADHOC Request</CardHeader>
            <CardBody style={{ background: '#fff', padding: '1.25rem' }}>
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList
                      onChange={handleTabChange}
                      aria-label="lab API tabs example"
                      TabIndicatorProps={{ style: { background: '#D71920' } }}
                      className="tabb"
                    >
                      <Tab
                        label="Adhoc Request"
                        value="1"
                        name="adhocRequest"
                      />
                      <Tab
                        label="Catalogue/Range List Request"
                        value="2"
                        name="rangeList"
                      />
                    </TabList>
                  </Box>
                  <TabPanel value="1" style={{ padding: '24px 0px 0px 0px' }}>
                    <AdhocRequest />
                  </TabPanel>
                  <TabPanel value="2" style={{ padding: '24px 0px 0px 0px' }}>
                    <RequestDocument />
                  </TabPanel>
                </TabContext>
              </Box>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <img src={greyBanner} className="greyBanner"></img>
        </Col>
      </Row>
    </Page>
  );
};

export default TaskManagement;
