import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { Table, Tag, Space, Popconfirm } from 'antd';
import 'antd/dist/antd.min.css';
import axios from 'axios';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import {
  extractInfo,
  generateFilterList,
} from '../AdminPages/AdminProductCatalogue';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { confirmAlert } from 'react-confirm-alert';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/react';

const override = css`
  display: block;
  margin: 20% auto;
  border-color: #fff;
`;

const ViewPlanogram = props => {
  // const excelValueList = props.excelValues;
  // console.log(excelValueList);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [color, setColor] = React.useState('#ffffff');
  const [tableData, setTableData] = React.useState([]);
  const [filteredTableData, setFilteredTableData] = React.useState([]);
  const [selectedSupplier, setSelectedSupplier] = React.useState('');
  const [selectedPlano, setSelectedPlano] = React.useState('');
  const [supplierList, setSupplierList] = React.useState([]);
  const [filteredData, setFilteredData] = useState([]);

  // for saving table's data source
  const [selectedPartList, setSelectedPartList] = React.useState([]);
  const [unselectedPartList, setUnselectedPartList] = React.useState([]);

  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);

  // for saving checked items
  const [selectedPartCheckedList, setSelectedPartCheckedList] = React.useState(
    [],
  );
  const [unselectedPartCheckedList, setUnselectedPartCheckedList] =
    React.useState([]);
  const [actionHint, setActionHint] = useState({
    open: false,
    text: '',
    title: 'Thank you',
  });

  const [filterInfo, setFilterInfo] = useState({});
  const [sorterInfo, setSorterInfo] = useState({});
  const [filterLists, setFilterLists] = useState({
    Department: [],
    'Sub Department': [],
  });

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const getSupplierList = () => {
    axios({
      method: 'GET',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: process.env.REACT_APP_API_BASE + 'admin-portal',
    })
      .then(response => {
        // console.log('supppp', response);
        const admindata = response.data.body;
        const roles = admindata.filter(item => {
          if (item.role && item.role == 'supplier') {
            return item;
          }
        });
        console.log('sup Roles', roles);
        setSupplierList(roles);
      })
      .catch(err => {
        console.log('get plano err', err);
      });
  };
  const getTableData = () => {
    setLoading(true);
    axios({
      method: 'GET',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: process.env.REACT_APP_API_BASE + 'planogram',
    })
      .then(response => {
        console.log('plano res ', response);
        if (response.data.statusCode == 200) {
          const result = response.data.body.Items;
          if (Array.isArray(result)) {
            const list = result.map(item => {
              return {
                ...item,
                supplier_name:
                  Array.isArray(item.range_list) && item.range_list.length > 0
                    ? item.range_list[0].supplier_name
                    : '',
              };
            });
            console.log('plano table', result);
            setTableData(list);
            setFilteredTableData(list);
            filter(list);
            setFilteredData(list);
          }
        } else {
          console.log('not 200 status', response);
        }
        setLoading(false);
      })
      .catch(err => {
        console.log('get catas fail...', err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getSupplierList();
    getTableData();
  }, []);

  const getDifferenceSet = (arr1, arr2, typeName) => {
    return Object.values(
      arr1.concat(arr2).reduce((acc, cur) => {
        // use == instead of === to make the integer equal to string
        if (
          acc[cur[typeName]] &&
          acc[cur[typeName]][typeName] == cur[typeName]
        ) {
          delete acc[cur[typeName]];
        } else {
          acc[cur[typeName]] = cur;
        }
        return acc;
      }, {}),
    );
  };
  const viewModal = (e, record) => {
    e.preventDefault();
    console.log(record);
    setSelectedPlano(record);
    setShowModal(true);
  };
  const openEditModal = (e, record) => {
    e.preventDefault();
    console.log('edit rec', record);
    const selectedItems = record.range_list;
    const supplier_id = record.supplier_id;
    setLoading(true)
    axios({
      method: 'GET',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url:
        process.env.REACT_APP_API_BASE + 'rangelist?supplier_id=' + supplier_id,
    })
      .then(response => {
        setLoading(false)
        const result = response.data.body.Items;
        console.log('get one supp all range prodc', response);
        const approvedOneRangelist = result.filter(
          item => item.status.toLowerCase() == 'approved',
        );
        console.log(
          'get one supp all range prodc',
          approvedOneRangelist.length,
        );
        if (approvedOneRangelist.length == 1) {
          console.log('has one approved', approvedOneRangelist);
          const oneSupResult = approvedOneRangelist[0];
          const wholeRangelist = oneSupResult.items;
          // console.log('itemssss ', oneSupResult, wholeRangelist);

          const allDepartments = extractInfo(oneSupResult.items, 'Department');
          const allSubDepartments = extractInfo(
            oneSupResult.items,
            'Sub Department',
          );

          const unselectedItems = getDifferenceSet(
            wholeRangelist,
            record.range_list,
            'Item',
          );

          // add supplier id and name for unselected ones
          unselectedItems.forEach(item => {
            item.supplier_id = oneSupResult.supplier_id;
            item.supplier_name = oneSupResult.supplier_name;
          });
          // console.log('unselected', unselectedItems);
          setSelectedPlano(record);

          // set list for selected part and unselected part

          // need to update the selected items based on the latest data
          const updatedSelectedItems = selectedItems.map(item => {
            const filterResult = wholeRangelist.filter((itm1) => itm1.Item == item.Item)

            console.log('match range iem', filterResult)
            if (filterResult && filterResult[0]) {
              return {
                ...item,
                ...filterResult[0],
                Item: item.Item, // to keep the old Item value as the row select key as a string value
              }
            } else {
              return item
            }

          })

          console.log('updatedSelectedItems', updatedSelectedItems);
          console.log('selectedItems', selectedItems);

          // setSelectedPartList(selectedItems);

          setSelectedPartList(updatedSelectedItems);
          setUnselectedPartList(unselectedItems);

          // set preselected keys and set default selected checklist
          setSelectedRowKeys(record.range_list.map(item => item.Item));
          setSelectedPartCheckedList(updatedSelectedItems);
          // setSelectedPartCheckedList(selectedItems);

          setFilterLists({
            Department: generateFilterList(allDepartments),
            'Sub Department': generateFilterList(allSubDepartments),
          });
          setShowEditModal(true);
        }
        // const allDepartments = extractInfo(result, 'Department');
        // const allSubDepartments = extractInfo(result, 'Sub Department');
        // const unselectedItems = getDifferenceSet(
        //   result,
        //   record.range_list,
        //   'item',
        // );
        // // console.log('unselected', unselectedItems);
        // setSelectedPlano(record);
        // setSelectedPartList(selectedItems);
        // setUnselectedPartList(unselectedItems);
        // setSelectedRowKeys(record.range_list.map(item => item.item));

        // setFilterLists({
        //   Department: generateFilterList(allDepartments),
        //   'Sub Department': generateFilterList(allSubDepartments),
        // });
        // setShowEditModal(true);
      })
      .catch(err => {
        console.log('get plano err', err);
        setLoading(false)
      });
  };
  const deletePlano = (e, record) => {
    e.preventDefault();
    console.log(record);
    confirmAlert({
      style: { position: 'relative', zIndex: '9999' },
      message: `Are you sure to delete: ${record.planogram_name} ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            axios({
              method: 'DELETE',
              url:
                process.env.REACT_APP_API_BASE +
                'planogram?supplier_id=' +
                record.supplier_id +
                '&planogram_name=' +
                record.planogram_name,
              headers: {
                Authorization: sessionStorage.getItem('idtoken'),
              },
            })
              .then(response => {
                console.log('delete res ', response);
                if (response.data.statusCode == 200) {
                  setActionHint({
                    open: true,
                    text: 'Planogram successfully deleted',
                    title: 'Thank you',
                  });
                  setTimeout(() => {
                    setActionHint({
                      open: false,
                      text: 'Planogram successfully deleted',
                      title: 'Thank you',
                    });
                  }, 3000);
                  getTableData();
                } else {
                  console.log('not 200 status', response);
                }
              })
              .catch(error => {
                console.log(error.response);
                console.log(error.response.status);
                if (
                  error.response.status == 401 &&
                  error.response.data.message ==
                  'The incoming token has expired'
                ) {
                  alert('Session timed out, please login again.');
                  localStorage.clear();
                  sessionStorage.clear();
                  localStorage.clear();
                  // localStorage.removeItem( "accessLevel" );
                  // reset page location
                  console.log('logout succesfully');
                  document.location.replace('/');
                }
              });
          },
        },
        {
          label: 'No',
          onClick: () => {
            return;
          },
        },
      ],
    });
  };

  const updatePlano = () => {
    // console.log(
    //   'save plano',
    //   selectedPartCheckedList,
    //   unselectedPartCheckedList,
    // );
    const newList = selectedPartCheckedList.concat(unselectedPartCheckedList);
    // console.log('save plano222', newList);
    const data = {
      ...selectedPlano,
      range_list: newList,
      number_of_items: newList.length,
    };
    console.log('save plano payload', data);
    axios({
      method: 'PUT',
      url: process.env.REACT_APP_API_BASE + 'planogram',
      data: data,
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
    })
      .then(response => {
        console.log(response);
        if (response.status == 200) {
          console.log('plano updated');

          setActionHint({
            open: true,
            text: 'Planogram successfully updated',
            title: 'Thank you',
          });
          setTimeout(() => {
            setActionHint({
              open: false,
              text: 'Planogram successfully updated',
              title: 'Thank you',
            });
            handleClose();
          }, 3000);
          // setSelectedPlano('');
          // setSelectedPartList([]);
          // setUnselectedPartList([]);
          // setSelectedRowKeys([]);
          getTableData();
        }
      })
      .catch(error => {
        console.log('update plano fail...', error);
      });
  };
  const handleClose = () => {
    setShowModal(false);
    setShowEditModal(false);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    // this.setState({
    //   searchText: selectedKeys[0],
    //   searchedColumn: dataIndex,
    // });
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          // ref={node => {
          //   this.searchInput = node;
          // }}
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => {
            console.log('cahngeddd', e.target.value);
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : '',
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const modifyFilteredTableData = filteredTableData
    .sort((a, b) => a.planogram_name.localeCompare(b.planogram_name))
    .map(({ ...item }) => ({
      ...item,
    }));

  const filter = data => {
    data.map(rec => {
      // console.log('rec====>', rec)
      setFilteredData(rec);
    });
  };

  const planoColumns = [
    {
      title: 'Item No.',
      dataIndex: 'Item',
      align: 'center',
      ...getColumnSearchProps('Item'),
      // key: 'item',
    },
    {
      title: 'Supplier ID',
      dataIndex: 'supplier_id',
      align: 'center',
      key: 'supplier_id',
    },
    {
      title: 'Supplier',
      dataIndex: 'supplier_name',
      align: 'center',
      key: 'supplier_name',
    },
    {
      title: 'Department',
      dataIndex: 'Department',
      align: 'center',
      key: 'Department',
      filters: filterLists.Department,
      onFilter: (value, record) => {
        return record.Department == value;
      },
      filterSearch: true,
    },
    {
      title: 'Sub Department',
      dataIndex: 'Sub Department',
      align: 'center',
      key: 'Sub Department',
      filters: filterLists['Sub Department'],
      onFilter: (value, record) => {
        return record['Sub Department'] == value;
      },
      filterSearch: true,
    },
    {
      title: 'Item Name',
      dataIndex: 'ItemName',
      align: 'center',
      key: 'Description',
      // render: (text, record) => <span>{record['']}</span>,
    },
  ];

  const bulkDelete = e => {
    e.preventDefault();
    console.log('selectedList==>', props.selectedList);
    const arrData = [];
    props.selectedList.forEach(item => {
      const obj = {};
      obj['planogram_name'] = item.planogram_name;
      obj['supplier_id'] = item.supplier_id;
      arrData.push(obj);
    });
    console.log('arrData==>', arrData);
    confirmAlert({
      title: 'Confirm to Delete',
      message: 'Are you sure to do this?',
      buttons: [
        {
          label: 'yes',
          onClick: () => {
            axios({
              method: 'POST',
              headers: {
                Authorization: sessionStorage.getItem('idtoken'),
              },
              url: process.env.REACT_APP_API_BASE + 'planogram/bulk-delete',
              data: arrData,
            })
              .then(response => {
                console.log(response);
                console.log('bulk plano deleted !', response);
                getTableData();
                props.setSelectedList([]);
              })
              .catch(error => {
                console.log(error.response);
                console.log(error.response.status);
                if (
                  error.response.status == 401 &&
                  error.response.data.message ==
                  'The incoming token has expired'
                ) {
                  alert('Session timed out, please login again.');
                  localStorage.clear();
                  sessionStorage.clear();
                  localStorage.clear();
                  // localStorage.removeItem( "accessLevel" );
                  // reset page location
                  console.log('logout succesfully');
                  document.location.replace('/');
                }
              });
          },
        },
        {
          label: 'No',
          onClick: () => {
            return;
          },
        },
      ],
    });
  };

  const columns = [
    {
      title: 'Seq',
      dataIndex: 'seq',
      align: 'center',
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: 'Planogram Name',
      dataIndex: 'planogram_name',
      align: 'center',
      ...getColumnSearchProps('planogram_name'),
    },
    {
      title: 'No. of Items',
      dataIndex: 'number_of_items',
      align: 'center',
    },
    {
      title: 'Supplier ID',
      dataIndex: 'supplier_id',
      align: 'center',
      ...getColumnSearchProps('supplier_id'),
    },
    {
      title: 'Supplier',
      dataIndex: 'supplier_name',
      align: 'center',
      ...getColumnSearchProps('supplier_name'),
    },
    {
      title: 'Comment',
      dataIndex: 'planogram_comment',
      align: 'center',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      align: 'center',
      render: (text, record) => {
        return (
          <>
            <button
              onClick={e => viewModal(e, record)}
              style={{ marginRight: '0', backgroundColor: 'transparent' }}
            >
              <svg
                height="18"
                width="18"
                fill="#000000"
                xmlns="http://www.w3.org/2000/svg"
                data-name="Layer 3"
                viewBox="0 0 24 24"
                x="0px"
                y="0px"
              >
                <path d="M20.5,9.873A11.123,11.123,0,0,0,12,6,11.123,11.123,0,0,0,3.5,9.873L2.239,11.351a1,1,0,0,0,0,1.3L3.5,14.127A11.123,11.123,0,0,0,12,18a11.125,11.125,0,0,0,8.5-3.872l1.261-1.479a1,1,0,0,0,0-1.3Zm-1.521,2.958A9.134,9.134,0,0,1,12,16a9.131,9.131,0,0,1-6.979-3.17L4.314,12l.707-.83h0A9.134,9.134,0,0,1,12,8a9.131,9.131,0,0,1,6.979,3.17l.707.83Z"></path>
                <path d="M12,9a3,3,0,1,0,3,3A3,3,0,0,0,12,9Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,12,13Z"></path>
              </svg>
            </button>
            <button
              style={{
                marginRight: '0px',
                marginLeft: '5px',
                backgroundColor: 'transparent',
              }}
              onClick={e => openEditModal(e, record)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#000"
                className="bi bi-pencil"
                viewBox="0 0 16 16"
              >
                <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
              </svg>{' '}
            </button>
            <button
              style={{ marginRight: '0', backgroundColor: 'transparent' }}
              onClick={e => deletePlano(e, record)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#000"
                className="bi bi-trash"
                viewBox="0 0 16 16"
              >
                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                <path
                  fillRule="evenodd"
                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                />
              </svg>
            </button>
          </>
        );
      },
    },
  ];

  const supplierChangeHandler = e => {
    console.log('supplierChangeHandler', e.target.value, tableData);
    const selectedVal = e.target.value;
    setSelectedSupplier(selectedVal);
    if (selectedVal == '') {
      setFilteredTableData(tableData);
    } else {
      const selectedValArr = e.target.value.split(',');
      const newTableData = tableData.filter(
        item => item.supplier_id == selectedValArr[0],
      );
      console.log('newtttt', newTableData);
      setFilteredTableData(newTableData);
    }
  };

  const handleEditPlanoChange = e => {
    e.preventDefault();
    setSelectedPlano({
      ...selectedPlano,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectedPartTableChange = (pagination, filters, sorter) => {
    console.log(
      'handleSelectedPartTableChange parameters',
      pagination,
      filters,
      sorter,
    );
    setFilterInfo({
      filteredInfo: filters,
    });
  };
  const handleChanges = (...sorter) => {
    console.log('sorter', sorter);
    const { order, field } = sorter[2];
    // setSortedInfo({ columnKey: field, order });
  };

  const unselectedItemRowSelection = {
    preserveSelectedRowKeys: false,
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        'selectedRows: ',
        selectedRows,
      );
    },
    onSelect: (record, selected, selectedRows) => {
      console.log(record, selected, selectedRows);
      setUnselectedPartCheckedList(selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      console.log(selected, selectedRows, changeRows);
      setUnselectedPartCheckedList(selectedRows);
    },
  };
  const selectedItemRowSelection = {
    preserveSelectedRowKeys: false,
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        'selectedRows: ',
        selectedRows,
      );
      setSelectedRowKeys(selectedRowKeys);
      setSelectedPartCheckedList(selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      console.log(record, selected, selectedRows);
      setSelectedPartCheckedList(selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      console.log(selected, selectedRows, changeRows);
      setSelectedPartCheckedList(selectedRows);
    },
  };

  const renderSupplierOptions = () => {
    return supplierList.map(item => (
      <option value={item.id}>
        {item.id} - {item.full_company_name}
      </option>
    ));
  };

  return (
    <>
      {loading ? (
        <div className="overlay-box">
          <ClipLoader
            color={color}
            loading={loading}
            css={override}
            size={150}
          />
        </div>
      ) : null}
      <Dialog open={actionHint.open}>
        <DialogContent>
          <DialogContentText>
            <Row>
              <Col md={12}>
                <b style={{ color: 'red' }}>{actionHint.title}</b>
                <br />
                <br />
                <label style={{ color: 'red' }}>{actionHint.text}</label>
              </Col>
            </Row>
            <br />
          </DialogContentText>
          <Button
            onClick={e => {
              e.preventDefault();
              setActionHint({
                ...actionHint,
                open: false,
              });
            }}
            variant="outlined"
            color="primary"
          >
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Modal isOpen={showEditModal} className="plan-modal-dialog">
        <ModalHeader style={{ justifyContent: 'center' }}>
          Edit Planogram
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <label>Supplier ID</label>
              <Input
                disabled
                type="text"
                name="supplier_id"
                // value={selectedPlano.range_list[0].supplier_name}
                value={
                  Array.isArray(selectedPlano.range_list) &&
                    selectedPlano.range_list.length > 0
                    ? selectedPlano.range_list[0].supplier_id
                    : ''
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label>Supplier Name</label>
              <Input
                disabled
                type="text"
                name="supplier_name"
                // value={selectedPlano.range_list[0].supplier_name}
                value={
                  Array.isArray(selectedPlano.range_list) &&
                    selectedPlano.range_list.length > 0
                    ? selectedPlano.range_list[0].supplier_name
                    : ''
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label>Planogram Name</label>
              <Input
                disabled
                type="text"
                name="planogram_name"
                value={selectedPlano.planogram_name}
              // onChange={handleEditPlanoChange}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label>Comment</label>
              <Input
                // disabled
                type="text"
                name="planogram_comment"
                value={selectedPlano.planogram_comment}
                onChange={handleEditPlanoChange}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label style={{ marginTop: '15px' }}>Selected Items</label>
              <Col>
                <div className="antdTable">
                  <Table
                    columns={planoColumns}
                    // scroll={{ x: 1500 }}
                    dataSource={
                      selectedPartList
                        ? selectedPartList
                        : selectedPlano.range_list
                    }
                    // bordered
                    pagination={false}
                    rowSelection={{ ...selectedItemRowSelection }}
                    rowKey="Item"
                    onChange={handleSelectedPartTableChange}
                  />
                </div>
              </Col>
            </Col>
          </Row>
          <Row>
            <Col>
              <label style={{ marginTop: '15px' }}>Unselected Items</label>
              <Col>
                <div className="antdTable">
                  <Table
                    columns={planoColumns}
                    // scroll={{ x: 1500 }}
                    dataSource={
                      unselectedPartList
                        ? unselectedPartList
                        : selectedPlano.range_list
                    }
                    // bordered
                    pagination={false}
                    rowSelection={{ ...unselectedItemRowSelection }}
                    rowKey="Item"
                    onChange={handleChanges}
                  />
                </div>
              </Col>
            </Col>
          </Row>
          <Row className="modalRow">
            <Col style={{ justifyContent: 'end' }}>
              <Button onClick={updatePlano} variant="outlined" color="primary">
                Save
              </Button>
              <Button onClick={handleClose} variant="outlined" color="primary">
                Cancel
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <Modal isOpen={showModal} className="plan-modal-dialog">
        <ModalHeader style={{ justifyContent: 'center' }}>
          Planogram Details
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <label>Supplier Name</label>
              <Input
                disabled
                type="text"
                name="supplier_name"
                value={
                  Array.isArray(selectedPlano.range_list) &&
                    selectedPlano.range_list.length > 0
                    ? selectedPlano.range_list[0].supplier_name
                    : ''
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label>Planogram Name</label>
              <Input
                disabled
                type="text"
                name="planogram_name"
                value={selectedPlano.planogram_name}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label>Comment</label>
              <Input
                disabled
                type="text"
                name="planogram_comment"
                value={selectedPlano.planogram_comment}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label style={{ marginTop: '15px' }}>Range List</label>
              <Col>
                <div className="antdTable">
                  <Table
                    columns={planoColumns}
                    // scroll={{ x: 1500 }}
                    dataSource={selectedPlano.range_list}
                    // bordered
                    pagination={false}
                  />
                </div>
              </Col>
            </Col>
          </Row>
          <Row className="modalRow">
            <Col style={{ justifyContent: 'end' }}>
              <Button onClick={handleClose} variant="outlined" color="primary">
                Close
              </Button>
              {/* <Button onClick={handleClose} variant="outlined" color="primary">
                Cancel
              </Button> */}
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      {/* <Row>
        <Col md="3">
          <label>Supplier</label>
          <Input
            type="select"
            name="retailer_name"
            onChange={supplierChangeHandler}
            value={selectedSupplier}
            className="admin-input"
          >
            <option selected value="">
              Select Supplier
            </option>
            {renderSupplierOptions()}
          </Input>
        </Col>
      </Row> */}
      <Col
        md={9}
        style={{ float: 'right', textAlign: 'right', marginBottom: '4px' }}
        className="uploadExcel"
      >
        <button
          title="Select records to delete!"
          type="button"
          variant="outlined"
          class="btn btn-primary"
          onClick={e => {
            bulkDelete(e);
          }}
          disabled={props.selectedList.length == 0}
        >
          {' '}
          Delete
        </button>
      </Col>
      <div style={{ clear: 'both' }}></div>
      <div className="antdTable">
        {/* {modifyFilteredTableData ? */}
        <Table
          columns={columns}
          // scroll={{ x: 1500 }}
          dataSource={modifyFilteredTableData}
          rowSelection={{ ...props.rowSelection }}
          rowKey={record => record.supplier_id + record.planogram_name}
          onChange={(pagination, filters, sorter, currentPageData) =>
            filter(currentPageData.currentDataSource)
          }
          pagination={{
            total: filteredData.length,
            showTotal: total => `Total ${total} items`,
            pageSizeOptions: ['10', '50', '100', '200', '500'],
          }}
        // bordered
        />
        {/* : null
        } */}
      </div>
    </>
  );
};
export default ViewPlanogram;
