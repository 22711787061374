import React, { useEffect, useState, useRef } from 'react';
import { BiDownload } from 'react-icons/bi';
import { Table, Space } from 'antd';
import 'antd/dist/antd.min.css';
import {
  Input,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import axios from 'axios';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/react';

const override = css`
  display: block;
  margin: 20% auto;
  border-color: #fff;
`;

const RequestDocument = () => {
  const [tableData, setTableData] = React.useState([]);

  const [rejectedReason, setRejectedReason] = React.useState('');
  const [supplierList, setSupplierList] = React.useState([]);
  const [showModal, setShowModal] = React.useState(false);
  const [showReject, setShowRejected] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(false);
  const searchInput = useRef(null);
  const [searchedColumn, setSearchedColumn] = useState('');
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const [color, setColor] = React.useState('#ffffff');
  const [actionHint, setActionHint] = useState({
    open: false,
    text: '',
    title: '',
  });
  const getSupplierList = () => {
    axios({
      method: 'GET',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: process.env.REACT_APP_API_BASE + 'admin-portal',
    })
      .then(response => {
        const admindata = response.data.body;
        const roles = admindata.filter(item => {
          if (item.role && item.role == 'supplier') {
            return item;
          }
        });
        console.log('sup Roles', roles);
        setSupplierList(roles);
      })
      .catch(error => {
        console.log(error.response);
        console.log(error.response.status);
        if (
          error.response.status == 401 &&
          error.response.data.message == 'The incoming token has expired'
        ) {
          alert('Session timed out, please login again.');
          localStorage.clear();
          sessionStorage.clear();
          localStorage.clear();
          // reset page location
          console.log('logout succesfully');
          document.location.replace('/');
        }
      });
  };

  const getCataData = () => {
    setLoading(true);
    let catalist = [];
    axios({
      method: 'GET',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: process.env.REACT_APP_API_BASE + 'product-catalogue',
    })
      .then(response => {
        console.log('cata res ', response);
        if (response.data.statusCode == 200) {
          const result = response.data.body.Items;
          if (Array.isArray(result)) {
            result.forEach(item => {
              // only show the request from supplier
              if (item.upload_by && item.upload_by == 'supplier') {
                Array.isArray(item.catalogues) &&
                  item.catalogues.forEach(cata => {
                    catalist.push({
                      supplier_id: item.supplier_id,
                      supplier_name: item.supplier_name,
                      datetime: item.datetime,
                      document: cata.document,
                      comments: cata.comments,
                      status: item.status,
                      notes: item.notes,
                      doc_type: item.doc_type,
                      upload_by: item.upload_by,
                    });
                  });
              }
            });
            console.log('generate cata table', catalist);

            getRangelistData(catalist);
          }
        } else {
          console.log('not 200 status', response);
        }
      })
      .catch(err => {
        console.log('get catas fail...', err);
        setLoading(false);
      });
  };
  const getRangelistData = catalist => {
    axios({
      method: 'GET',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: process.env.REACT_APP_API_BASE + 'rangelist',
    })
      .then(response => {
        console.log('rangess res ', response);
        const rangeResultList = response.data.body.Items;
        if (Array.isArray(rangeResultList) && rangeResultList.length > 0) {
          // find all the rangelist request from supplier
          const allRangeListFromSup = rangeResultList.filter(
            item => item.upload_by == 'supplier',
          );

          const finalList = catalist.concat(
            allRangeListFromSup.map(item => ({
              ...item,
              document: item.rangelist_name,
            })),
          );
          console.log('final list', finalList);

          setTableData(finalList);
          filter(finalList);
          setFilteredData(finalList);
        }
      })
      .catch(err => {
        console.log('get range fail...', err);
        // if get rangelist fail, still set the catalogue data
        setTableData(catalist);
        filter(catalist);
        setFilteredData(catalist);
      }).finally(() => {
        setLoading(false)
      })
  };
  const getTableData = () => {
    // get catalogue first, if success then get range list data
    getCataData();
  };

  useEffect(() => {
    getSupplierList();
    getTableData();
  }, []);

  const handleReject = (e, rejectDocType) => {
    if (rejectedReason.length == 0) {
      setActionHint({
        open: true,
        title: 'Sorry',
        text: 'Reason are required',
      });
      setTimeout(() => {
        setActionHint({
          ...actionHint,
          open: false,
        });
      }, 3000);
      return;
    } else {
      const data = {
        ...selectedRow,
        status: 'Rejected',
        notes: rejectedReason,
      };
      const url =
        process.env.REACT_APP_API_BASE +
        `${selectedRow.doc_type == 'Catalogue'
          ? 'product-catalogue'
          : 'rangelist'
        }`;

      console.log('reject doc payload', data, url);
      axios({
        method: 'PUT',
        url,
        headers: {
          Authorization: sessionStorage.getItem('idtoken'),
        },
        data: data,
      })
        .then(response => {
          console.log(response);
          if (response.status == 200) {
            console.log('reject in do successfully');

            setActionHint({
              ...actionHint,
              open: true,
              text: `${rejectDocType} successfully rejected`,
              title: 'Thank you',
            });
            setTimeout(() => {
              setActionHint({
                ...actionHint,
                open: false,
              });
            }, 3000);
            setShowModal(false);
            setShowRejected(false);
            getTableData();
          }
        })
        .catch(error => {
          console.log(error.response);
          console.log(error.response.status);
          if (
            error.response.status == 401 &&
            error.response.data.message == 'The incoming token has expired'
          ) {
            alert('Session timed out, please login again.');
            localStorage.clear();
            sessionStorage.clear();
            localStorage.clear();
            // reset page location
            console.log('logout succesfully');
            document.location.replace('/');
          }
        });
    }
  };

  const updateProductRangelistInDB = () => {
    console.log('replace range list', selectedRow);
    const { supplier_id, rangelist_name, datetime } = selectedRow;


    // 1. get latest rangelist from supplier docs in S3
    const formatedUploadDate = moment(datetime).format('DDMMYYYY-HHmmss');
    const curKey = `supplier-documents/range-lists/${supplier_id}/${formatedUploadDate}/${rangelist_name}`;
    console.log('curKkkk', curKey);

    const updateRangeUrl = process.env.REACT_APP_API_BASE + 'rangelist';
    const newApiData = {
      ...selectedRow,
      status: 'Approved',
      notes: '',
    };
    axios({
      method: 'PUT',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: updateRangeUrl,
      data: newApiData,
    })
      .then(res => {
        console.log(
          'current waiting changed to approved in DB for one supplier',
          res,
        );
        if (res) {
          setActionHint({
            ...actionHint,
            open: true,
            text: 'Rangelist successfully approved',
            title: 'Thank you',
          });
          setTimeout(() => {
            setActionHint({
              ...actionHint,
              open: false,
            });
          }, 3000);
          setShowModal(false);
          getTableData();
        } else {
          console.log(
            'rangelist make waiting to approve part wrong',
            res,
          );
        }
      })
      .catch(err => {
        console.log('make waiting to approve fail', err);
      });

  };
  const handleApprove = (e, docType) => {
    if (docType == 'Rangelist') {
      confirmAlert({
        style: { position: 'relative', zIndex: '9999' },
        message: 'Are you sure replace current rangelist?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              updateProductRangelistInDB();
            },
          },
          {
            label: 'No',
            onClick: () => {
              return;
            },
          },
        ],
      });
    } else {
      console.log('approve cata');
      const data = {
        ...selectedRow,
        status: 'Approved',
      };
      const url = process.env.REACT_APP_API_BASE + 'product-catalogue';
      console.log('Approved uploadDoc payload', url, data);
      // TODO: error should be resolved after doc_type added
      axios({
        method: 'PUT',
        headers: {
          Authorization: sessionStorage.getItem('idtoken'),
        },
        url,
        data: data,
      })
        .then(response => {
          console.log(response);
          if (response.status == 200) {
            console.log('update in do successfully');

            setActionHint({
              ...actionHint,
              open: true,
              text: 'Catalogue successfully approved',
              title: 'Thank you',
            });
            setTimeout(() => {
              setActionHint({
                ...actionHint,
                open: false,
              });
            }, 3000);
            setShowModal(false);
            getTableData();
          }
        })
        .catch(error => {
          console.log(error.response);
          console.log(error.response.status);
          if (
            error.response.status == 401 &&
            error.response.data.message == 'The incoming token has expired'
          ) {
            alert('Session timed out, please login again.');
            localStorage.clear();
            sessionStorage.clear();
            localStorage.clear();
            // reset page location
            console.log('logout succesfully');
            document.location.replace('/');
          }
        });
    }
  };

  console.log(
    'dld',
    moment(new Date('2022-05-25T00:19:16.500Z')).format('DD/MM/YYY HH:mm:ss'),
  );

  const openViewModal = (e, record) => {
    console.log('selected doc row', record);
    setSelectedRow(record);
    setShowModal(true);
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => {
            console.log('cahngeddd', e.target.value);
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : '',
    // TODO: if in future, no need, remove this
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => { }, 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  console.log(tableData);
  const modifyData = tableData
    .sort((a, b) => a.supplier_name.localeCompare(b.supplier_name))
    .map(({ ...item }) => ({
      ...item,
    }));

  const filter = data => {
    data.map(rec => {
      console.log('rec====>', rec);
      setFilteredData(rec);
    });
  };

  const columns = [
    {
      title: 'Seq',
      dataIndex: 'seq',
      align: 'center',
      editable: true,
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: 'Submitted Date',
      dataIndex: 'datetime',
      align: 'center',
      render: (text, record, index) => (
        <>{moment(record.datetime).format('DD/MM/YYYY HH:mm:ss')}</>
      ),
    },
    {
      title: 'Supplier ID',
      dataIndex: 'supplier_id',
      align: 'center',
      ...getColumnSearchProps('supplier_id'),
    },
    {
      title: 'Supplier Name',
      dataIndex: 'supplier_name',
      align: 'center',
      editable: true,
      filters: supplierList.map(item => ({
        text: item.full_company_name,
        value: item.full_company_name,
      })),
      onFilter: (value, record) => {
        return record.supplier_name == value;
      },
      filterSearch: true,
    },
    {
      title: 'Document Name',
      dataIndex: 'document',
      align: 'center',
      editable: true,
    },
    {
      title: 'Document Type',
      dataIndex: 'doc_type',
      align: 'center',
      editable: true,
      filters: ['Rangelist', 'Catalogue'].map(item => ({
        text: item,
        value: item,
      })),
      onFilter: (value, record) => {
        return record.doc_type == value;
      },
    },
    {
      title: 'Comment',
      dataIndex: 'comments',
      align: 'center',
      editable: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'center',
      editable: true,
      filters: [
        'Waiting for approval',
        'Approved',
        'Rejected',
        'Redundant',
      ].map(item => ({
        text: item,
        value: item,
      })),
      onFilter: (value, record) => {
        return record.status == value;
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      align: 'center',
      width: '100px',
      render: (text, record) => {
        return (
          <>
            <button
              style={{
                padding: '0',
                marginLeft: '6px',
                backgroundColor: 'transparent',
              }}
              onClick={e => openViewModal(e, record)}
            >
              <svg
                height="18"
                width="18"
                fill="#000000"
                xmlns="http://www.w3.org/2000/svg"
                data-name="Layer 3"
                viewBox="0 0 24 24"
                x="0px"
                y="0px"
              >
                <path d="M20.5,9.873A11.123,11.123,0,0,0,12,6,11.123,11.123,0,0,0,3.5,9.873L2.239,11.351a1,1,0,0,0,0,1.3L3.5,14.127A11.123,11.123,0,0,0,12,18a11.125,11.125,0,0,0,8.5-3.872l1.261-1.479a1,1,0,0,0,0-1.3Zm-1.521,2.958A9.134,9.134,0,0,1,12,16a9.131,9.131,0,0,1-6.979-3.17L4.314,12l.707-.83h0A9.134,9.134,0,0,1,12,8a9.131,9.131,0,0,1,6.979,3.17l.707.83Z"></path>
                <path d="M12,9a3,3,0,1,0,3,3A3,3,0,0,0,12,9Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,12,13Z"></path>
              </svg>
            </button>
          </>
        );
      },
    },
  ];

  const hanleRejectReasonChange = e => {
    setRejectedReason(e.target.value);
  };
  const handleClose = () => {
    setActionHint({
      ...actionHint,
      open: false,
    });

    setRejectedReason('');
    setShowRejected(false);
  };

  return (
    <>
      {loading ? (
        <div className="overlay-box">
          <ClipLoader
            color={color}
            loading={loading}
            css={override}
            size={150}
          />
        </div>
      ) : null}
      <Dialog open={actionHint.open}>
        <DialogContent>
          <DialogContentText>
            <Row>
              <Col md={12}>
                <b style={{ color: 'red' }}>{actionHint.title}</b>
                <br />
                <br />
                <label style={{ color: 'red' }}>{actionHint.text}</label>
              </Col>
            </Row>
            <br />
          </DialogContentText>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogContent>
      </Dialog>

      <Dialog open={showReject}>
        <DialogContent>
          <DialogContentText>
            <Row>
              <Col md={12}>
                <label>Input Reject Reason</label>
                <Input
                  type="textarea"
                  name="reject_reason"
                  style={{ minHeight: '60%' }}
                  onChange={hanleRejectReasonChange}
                />
              </Col>
            </Row>
            <br />
          </DialogContentText>
          <Button
            onClick={e => handleReject(e, selectedRow.doc_type)}
            variant="outlined"
            color="primary"
          >
            Send
          </Button>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Cancel
          </Button>
        </DialogContent>
      </Dialog>

      <Modal isOpen={showModal} className="doc-modal-dialog">
        <ModalHeader style={{ justifyContent: 'center' }}>
          Document Details
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <label>Submitted Date</label>
              <Input
                disabled
                type="text"
                name="supplier_name"
                value={moment(selectedRow.datetime).format(
                  'DD/MM/YYYY HH:mm:ss',
                )}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label>Supplier Name</label>
              <Input
                disabled
                type="text"
                name="supplier_name"
                value={selectedRow.supplier_name}
              />
            </Col>
          </Row>
          <Row>
            <Col md="10">
              <label>Document Name</label>
              <Input
                disabled
                type="text"
                name="document"
                value={
                  selectedRow.doc_type == 'Rangelist'
                    ? selectedRow.rangelist_name
                    : selectedRow.document
                }
              />
            </Col>
            <Col md="1">
              <label style={{ visibility: 'hidden' }}>Download</label>

              <button
                style={{ padding: '10px', marginLeft: '6px', display: 'block' }}
              >
                {/* encode the document name in case the document name has special characters that AWS S3 requires handling for */}
                <a
                  href={
                    selectedRow.doc_type == 'Rangelist'
                      ? `${process.env.REACT_APP_CDN
                      }supplier-documents/range-lists/${selectedRow.supplier_id
                      }/${moment(selectedRow.datetime).format(
                        'DDMMYYYY-HHmmss',
                      )}/${encodeURIComponent(selectedRow.document)}`
                      : `${process.env.REACT_APP_CDN}admin-documents/supplier/${selectedRow.supplier_id
                      }/product-catalogues/${encodeURIComponent(
                        selectedRow.document,
                      )}`
                  }
                >
                  <BiDownload
                    style={{ fontSize: '24px', color: 'rgb(35, 34, 36)' }}
                  />
                </a>
              </button>
            </Col>
          </Row>
          <Row>
            <Col>
              <label>Document Type</label>
              <Input
                disabled
                type="text"
                name="doc_type"
                value={selectedRow.doc_type}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label>Status </label>
              <Input
                disabled
                type="text"
                name="status"
                value={selectedRow.status}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label>Comment </label>
              <Input
                disabled
                type="textarea"
                name="comments"
                value={selectedRow.comments}
              />
            </Col>
          </Row>
          {selectedRow.status == 'Rejected' ? (
            <Row>
              <Col>
                <label>Rejected Reason </label>
                <Input
                  disabled
                  type="text"
                  name="notes"
                  value={selectedRow.notes}
                />
              </Col>
            </Row>
          ) : null}

          <Row className="modalRow">
            <Col style={{ justifyContent: 'end' }}>
              {selectedRow.status == 'Waiting for approval' ? (
                <>
                  <Button
                    onClick={e => {
                      handleApprove(e, selectedRow.doc_type);
                    }}
                    variant="outlined"
                    color="primary"
                  >
                    Approve
                  </Button>
                  <Button
                    onClick={() => setShowRejected(true)}
                    variant="outlined"
                    color="primary"
                  >
                    Reject
                  </Button>
                </>
              ) : null}

              <Button
                onClick={() => setShowModal(false)}
                variant="outlined"
                color="primary"
              >
                {selectedRow.status == 'Waiting for approval'
                  ? 'Cancel'
                  : 'Close'}
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      <div className="antdTable">
        {modifyData ? (
          <Table
            columns={columns}
            dataSource={modifyData}
            onChange={(pagination, filters, sorter, currentPageData) =>
              filter(currentPageData.currentDataSource)
            }
            pagination={{
              total: filteredData.length,
              showTotal: total => `Total ${total} items`,
              pageSizeOptions: ['10', '50', '100', '200', '500'],
            }}
          />
        ) : null}
      </div>
    </>
  );
};

export default RequestDocument;
