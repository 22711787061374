import axios from 'axios';
import Page from '../../components/Page';
import React, { useState, useRef } from 'react';
import { useEffect } from 'react';
import { BiDownload } from 'react-icons/bi';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { extractInfo, generateFilterList } from '../AdminPages/AdminProductCatalogue.js';
import AWS from 'aws-sdk';
import moment from 'moment';
import { Table, Checkbox, Upload as UploadAnt, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import greyBanner from '../../assets/img/logo/Banner Grey.png';
import { css } from '@emotion/react';
import ClipLoader from 'react-spinners/ClipLoader';

const override = css`
  display: block;
  margin: 20% auto;
  border-color: #fff;
`;

const SubmitRangelist = (props) => {
  const {
    filteredData,
    supplierInfo,
    cancelDoc,
    actionHint,
    setActionHint,
    submitDoc,
    filter,
    getColumnSearchProps,
    rangelistField,
    setRangelistField,
    rangelistDocTableData,
    getRangelistTableData,
    rangelistTableData,
    filterLists,
    loading,
  } = props;

  // const [rangelistField, setRangelistField] = React.useState({
  //     // supplier_id: '',
  //     // supplier_name: '',
  //     catalogues: [],
  // });

  // const [rangelistTableData, setRangelistTableData] = React.useState([]);
  // const [rangelistDocTableData, setRangelistDocTableData] = React.useState([]);
  // const [latestRangeListInfo, setLatestRangeListInfo] = React.useState();
  // const [filterLists, setFilterLists] = useState({
  //     Department: [],
  //     'Sub Department': [],
  //     'Item Retail Grade': [],
  //     State: ['Live', 'Archived', 'CSO', 'Restricted', 'Promotion'],
  // });

  // const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    // get supplier info then get the table data

    if (supplierInfo && supplierInfo.id) {
      getRangelistTableData(supplierInfo);
    }
  }, [supplierInfo]);

  const rangelistDocColumns = [
    {
      title: 'Rangelist Name',
      dataIndex: 'rangelist_name',
      align: 'center',
      ...getColumnSearchProps('rangelist_name'),
    },
    {
      title: 'Submit Date',
      dataIndex: 'datetime',
      align: 'center',
      render: (text, record) => <>{moment(record.datetime).format('DD/MM/YYYY HH:mm:ss')}</>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'center',
      filters: ['Waiting for approve', 'Approved', 'Rejected', 'Redundant'].map((item) => ({
        text: item,
        value: item,
      })),
      onFilter: (value, record) => {
        return record.status == value;
      },
      render: (text, record) => (
        <div style={{ display: 'inline-block', marginLeft: '40%' }}>
          <span>{record.status}</span>
          <span
            style={{
              marginRight: '0px',
              marginLeft: '5px',
              backgroundColor: 'transparent',
              visibility: record.status.toLowerCase() == 'rejected' ? 'visible' : 'hidden',
            }}
            onClick={(e) => {
              console.log('typeof action hint', typeof setActionHint);

              if (record.status.toLowerCase() == 'rejected') {
                setActionHint({
                  ...actionHint,
                  open: true,
                  title: 'Rejected Reason',
                  text: record.notes,
                });
              } else {
                return;
              }
            }}
          >
            <svg
              height="18"
              width="18"
              fill="#000000"
              xmlns="http://www.w3.org/2000/svg"
              data-name="Layer 3"
              viewBox="0 0 24 24"
              x="0px"
              y="0px"
            >
              <path d="M20.5,9.873A11.123,11.123,0,0,0,12,6,11.123,11.123,0,0,0,3.5,9.873L2.239,11.351a1,1,0,0,0,0,1.3L3.5,14.127A11.123,11.123,0,0,0,12,18a11.125,11.125,0,0,0,8.5-3.872l1.261-1.479a1,1,0,0,0,0-1.3Zm-1.521,2.958A9.134,9.134,0,0,1,12,16a9.131,9.131,0,0,1-6.979-3.17L4.314,12l.707-.83h0A9.134,9.134,0,0,1,12,8a9.131,9.131,0,0,1,6.979,3.17l.707.83Z"></path>
              <path d="M12,9a3,3,0,1,0,3,3A3,3,0,0,0,12,9Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,12,13Z"></path>
            </svg>{' '}
          </span>
          {/* {record.status.toLowerCase() == 'rejected' ? (
                <span
                  style={{
                    marginRight: '0px',
                    marginLeft: '5px',
                    backgroundColor: 'transparent',
                  }}
                  onClick={e => {
                    setActionHint({
                      ...actionHint,
                      open: true,
                      title: 'Rejected Reason',
                      text: record.notes,
                    });
                  }}
                >
                  <svg
                    height="18"
                    width="18"
                    fill="#000000"
                    xmlns="http://www.w3.org/2000/svg"
                    data-name="Layer 3"
                    viewBox="0 0 24 24"
                    x="0px"
                    y="0px"
                  >
                    <path d="M20.5,9.873A11.123,11.123,0,0,0,12,6,11.123,11.123,0,0,0,3.5,9.873L2.239,11.351a1,1,0,0,0,0,1.3L3.5,14.127A11.123,11.123,0,0,0,12,18a11.125,11.125,0,0,0,8.5-3.872l1.261-1.479a1,1,0,0,0,0-1.3Zm-1.521,2.958A9.134,9.134,0,0,1,12,16a9.131,9.131,0,0,1-6.979-3.17L4.314,12l.707-.83h0A9.134,9.134,0,0,1,12,8a9.131,9.131,0,0,1,6.979,3.17l.707.83Z"></path>
                    <path d="M12,9a3,3,0,1,0,3,3A3,3,0,0,0,12,9Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,12,13Z"></path>
                  </svg>{' '}
                </span>
              ) : null} */}
        </div>
      ),
    },
  ];

  const rangelistColumns = [
    {
      title: 'Item No.',
      dataIndex: 'Item',
      align: 'left',
      key: 'Item',
      width: 110,
      ...getColumnSearchProps('Item'),
      // sorter: (a, b) => a.Item.length - b.Item.length,
      // sortOrder: sortedInfo.columnKey === 'Item' && sortedInfo.order,
      // ...getColumnSearchProps('Item'),
    },
    {
      title: 'Department',
      dataIndex: 'Department',
      align: 'left',
      key: 'Department',
      width: 120,
      filters: filterLists.Department,
      filterSearch: true,
      onFilter: (value, record) => {
        return record.Department == value;
      },
      // sorter: (a, b) => a.Department.length - b.Department.length,
      // sortOrder: sortedInfo.columnKey === 'Item' && sortedInfo.order,
      // ...getColumnSearchProps('Department'),
    },
    {
      title: 'Sub Department',
      dataIndex: 'Sub Department',
      align: 'left',
      key: 'Sub Department',
      width: 150,
      filters: Array.isArray(filterLists['Sub Department']) && filterLists['Sub Department'],
      onFilter: (value, record) => {
        return record['Sub Department'] == value;
      },
    },
    {
      title: 'Item Name',
      dataIndex: 'ItemName',
      align: 'left',
      key: 'Description',
      width: 200,
      // render: (text, record) => <span>{record['']}</span>,
    },
    {
      title: 'Item Retail Grade',
      dataIndex: 'Item Retail Grade',
      align: 'left',
      key: 'Item Retail Grade',
      width: 100,
      filters: Array.isArray(filterLists['Item Retail Grade']) && filterLists['Item Retail Grade'],
      onFilter: (value, record) => {
        return record['Item Retail Grade'] == value;
      },
    },
    {
      title: 'ACT',
      dataIndex: 'ACT',
      align: 'left',
      key: 'ACT',
      filters: Array.isArray(filterLists['State']) && filterLists['State'],
      onFilter: (value, record) => {
        return record['ACT'] == value;
      },
    },
    {
      title: 'NQ',
      dataIndex: 'NQ',
      align: 'left',
      key: 'NQ',
      filters: Array.isArray(filterLists['State']) && filterLists['State'],
      onFilter: (value, record) => {
        return record['NQ'] == value;
      },
    },
    {
      title: 'NSW',
      dataIndex: 'NSW',
      align: 'left',
      key: 'NSW',
      filters: Array.isArray(filterLists['State']) && filterLists['State'],
      onFilter: (value, record) => {
        return record['NSW'] == value;
      },
    },
    {
      title: 'NT',
      dataIndex: 'NT',
      align: 'left',
      key: 'NT',
      filters: Array.isArray(filterLists['State']) && filterLists['State'],
      onFilter: (value, record) => {
        return record['NT'] == value;
      },
    },
    {
      title: 'QLD',
      dataIndex: 'QLD',
      align: 'left',
      key: 'QLD',
      filters: Array.isArray(filterLists['State']) && filterLists['State'],
      onFilter: (value, record) => {
        return record['QLD'] == value;
      },
    },
    {
      title: 'SA',
      dataIndex: 'SA',
      align: 'left',
      key: 'SA',
      filters: Array.isArray(filterLists['State']) && filterLists['State'],
      onFilter: (value, record) => {
        return record['SA'] == value;
      },
    },
    {
      title: 'TAS',
      dataIndex: 'TAS',
      align: 'left',
      key: 'TAS',
      filters: Array.isArray(filterLists['State']) && filterLists['State'],
      onFilter: (value, record) => {
        return record['TAS'] == value;
      },
    },
    {
      title: 'VIC',
      dataIndex: 'VIC',
      align: 'left',
      key: 'VIC',
      filters: Array.isArray(filterLists['State']) && filterLists['State'],
      onFilter: (value, record) => {
        return record['VIC'] == value;
      },
    },
    {
      title: 'WA',
      dataIndex: 'WA',
      align: 'left',
      key: 'WA',
      filters: Array.isArray(filterLists['State']) && filterLists['State'],
      onFilter: (value, record) => {
        return record['WA'] == value;
      },
    },
    {
      title: 'GP (%)',
      dataIndex: 'GP %',
      align: 'left',
      key: 'GP',
      render: (text, record, index) => <span>{(record['GP %'] * 100).toFixed(2)} % </span>,
    },
    {
      title: 'Uploaded Images',
      dataIndex: 'uploadImgs',
      align: 'center',
      key: 'uploadImgs',
      width: 140,
      render: (text, record) => (
        <ul style={{ listStyle: 'none', paddingInlineStart: '0px' }}>
          {record.uploadImgs.length > 0 ? (
            record.uploadImgs.map((item) => (
              <li style={{ display: 'flex', alignItems: 'center' }}>
                <a href={`${process.env.REACT_APP_CDN}${item}`}>{item.split('/')[4]} </a>
              </li>
            ))
          ) : (
            <li style={{ display: 'flex', alignItems: 'center' }}>No Images</li>
          )}
        </ul>
      ),
    },
  ];

  const beforeRangelistUploadHandler = (e, file) => {
    // check if has that file already
    // const curFileName = file.name;
    rangelistField.catalogues.push({
      document: file,
      comments: '',
    });
    setRangelistField({
      ...rangelistField,
      catalogues: [...rangelistField.catalogues],
    });

    // in order to select the same file twice
    e.target.value = '';
  };

  return loading ? (
    <div className="overlay-box">
      <ClipLoader color={'#fffff'} loading={loading} css={override} size={150} />
    </div>
  ) : (
    <Form>
      <FormGroup>
        <Row
          style={{
            justifyContent: 'space-between',
            marginRight: '45px',
          }}
        >
          <Col style={{ maxWidth: '40rem' }}>
            <label
              className="upload-label"
              style={{
                height: '13vh',
                padding: '4px 0px',
                border: '2px solid #E6E6E6',
                borderRadius: '32px',
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              {/* Upload{' '} */}
              <Input
                // style={{ padding: '30%' }}
                accept=".xlsx"
                type="file"
                disabled={rangelistField.catalogues.length == 1}
                onChange={(e) => beforeRangelistUploadHandler(e, e.target.files[0])}
                style={{ display: 'none' }}
              />
              <div>
                <span
                  style={{
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    justifyContent: 'center',
                    display: 'flex',
                    margin: '0px 0px 5% 0px',
                  }}
                >
                  Upload Rangelist{' '}
                </span>

                <span
                  style={{
                    fontSize: '0.9rem',
                    justifyContent: 'center',
                    display: 'flex',
                    margin: '0px 0px 5% 0px',
                  }}
                >
                  {' '}
                  Click here to browse your files
                </span>
              </div>
            </label>
          </Col>
          <Col md="7" className="exposmall" style={{ textAlign: 'left', marginTop: '29px' }}>
            {/* <label style={{ color: 'transparent' }}>okkay</label> */}
            <br />
            <a
              style={{
                color: '#fff',
                background: '#D71920',
                padding: '10px',
                borderRadius: '32px',
              }}
              href="https://staging-cdn.gforceportal.com.au/rangelist-callcycle/RangeListTemplate.xlsx"
            >
              Export Template
            </a>
          </Col>
          <Col md="2"></Col>
        </Row>
        {rangelistField.catalogues.length > 0 ? (
          <>
            <Row>
              <Col md="5">
                <label style={{ padding: '0px 0px 0px 8px' }}>Rangelist Name</label>
              </Col>
              <Col md="5">
                <label style={{ padding: '0px 0px 0px 8px' }}> Comments </label>
              </Col>
            </Row>
            {rangelistField.catalogues.map((item, index) => (
              <Row>
                <Col md="5">
                  <Input
                    type="text"
                    className="admin-input"
                    placeHolder="Product Sheet"
                    name="document_name"
                    disabled
                    value={rangelistField.catalogues[index].document.name}
                  />
                </Col>
                <Col md="5">
                  <Input
                    type="text"
                    // style={{ marginTop: '7px' }}
                    className="admin-input"
                    name="comments"
                    onChange={(e) => {
                      rangelistField.catalogues[index].comments = e.target.value;
                      setRangelistField({
                        ...rangelistField,
                        catalogues: [...rangelistField.catalogues],
                      });
                    }}
                    value={rangelistField.catalogues[index].comments}
                  />
                </Col>
              </Row>
            ))}
          </>
        ) : null}
        <Row style={{ marginTop: '20px' }}>
          <Col lg="8" md="8">
            <Button
              variant="outlined"
              onClick={(e) => {
                submitDoc(e, 'Rangelist');
              }}
              color="primary"
              disabled={rangelistField.catalogues[0] ? false : true}
            >
              {' '}
              Submit
            </Button>
            <Button
              variant="outlined"
              onClick={(e) => {
                cancelDoc(e, 'Rangelist');
              }}
              color="primary"
              disabled={rangelistField.catalogues[0] ? false : true}
            >
              Cancel
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <div
              className="antdTable"
              style={{
                width: '98%',
              }}
            >
              {rangelistDocTableData ? (
                <Table
                  className="TableSet rangelistTable"
                  columns={rangelistDocColumns}
                  // size="small"
                  // scroll={{ x: 1000 }}
                  dataSource={rangelistDocTableData}
                  onChange={(pagination, filters, sorter, currentPageData) =>
                    filter(currentPageData.currentDataSource)
                  }
                  pagination={{
                    total: filteredData.length,
                    showTotal: (total) => `Total ${total} items`,
                    pageSizeOptions: ['10', '50', '100', '200', '500'],
                  }}
                  // bordered

                  // rowSelection={{ ...rowSelection }}
                  // rowKey="Item"
                  // onChange={handleChanges}
                />
              ) : null}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div
              className="antdTable"
              style={{
                width: '98%',
                overflowX: 'scroll',
                // overflowY: 'scroll',
              }}
            >
              {rangelistTableData ? (
                <Table
                  className="TableSet"
                  columns={rangelistColumns}
                  // size="small"
                  // scroll={{ x: 1000 }}
                  dataSource={rangelistTableData}
                  // bordered
                  // rowSelection={{ ...rowSelection }}
                  rowKey="item"
                  // onChange={handleChanges}
                  // rowKey="Item"
                  // onChange={handleChanges}
                  scroll={{
                    y: 500,
                    x: 1500,
                  }}
                  onChange={(pagination, filters, sorter, currentPageData) =>
                    filter(currentPageData.currentDataSource)
                  }
                  pagination={{
                    total: filteredData.length,
                    showTotal: (total) => `Total ${total} items`,
                    pageSizeOptions: ['10', '50', '100', '200', '500'],
                  }}
                />
              ) : null}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <img src={greyBanner} className="greyBanner" style={{ marginRight: '35px' }}></img>
          </Col>
        </Row>
      </FormGroup>
    </Form>
  );
};

export default SubmitRangelist;
