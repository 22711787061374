import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Table, Tag, Space, Popconfirm } from 'antd';
import { confirmAlert } from 'react-confirm-alert';
import 'antd/dist/antd.min.css';
import axios from 'axios';
import { Row, Col, Button, Input } from 'reactstrap';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/react';

const override = css`
  display: block;
  margin: 20% auto;
  border-color: #fff;
`;

const RetailerTable = props => {
  console.log(props);
  const [retailerData, setRetailerData] = useState();
  const [loading, setLoading] = React.useState(false);
  const [color, setColor] = React.useState('#ffffff');
  const [listingExcelData, setListingExcelData] = React.useState([]);
  const searchInput = useRef(null);
  const [searchedColumn, setSearchedColumn] = useState('');
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const listRetailer = () => {
    setLoading(true);
    axios({
      method: 'GET',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: process.env.REACT_APP_API_BASE + 'admin-portal/retailer',
    })
      .then(response => {
        const retailerData = response.data.body.Items;
        filterD(retailerData);
        if (Array.isArray(retailerData) && retailerData.length > 0) {
          console.log(retailerData);
          // get store number for each retailer

          retailerData.map(async item => {
            const res = await axios({
              method: 'GET',
              headers: {
                Authorization: sessionStorage.getItem('idtoken'),
              },
              url: `${process.env.REACT_APP_API_BASE}admin-portal/store/count-stores?retailer_name=${item.full_company_name}`,
            }).then(res => {
              item['no_of_store'] = res.data;
              setRetailerData([...retailerData]);
            });
            // item['no_of_store'] = res.data;
            // setRetailerData([...retailerData]);
          });
        } else {
          console.log('no retailer found');
        }
        setLoading(false);
      })
      .catch(error => {
        console.log('error...', error);
        setLoading(false);
      });
  };
  const filterD = data => {
    var storeList = [];
    console.log();
    data.map(rec => {
      const aa = {
        'Retailer ID*': rec.id,
        'Full Company Name*': rec.full_company_name,
        'First Name': rec.first_name,
        'Last Name': rec.last_name,
        Position: rec.position,
        'Mob Number': rec.ph_number,
        Email: rec.email,
        Address: rec.address,
        'State*': rec.state,
        'Country*': rec.country,
        Comments: rec.comment,
        Status: rec.status,
      };

      storeList.push(aa);
    });
    console.log(storeList);
    setListingExcelData(storeList);
    setFilteredData(storeList);
  };
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    saveAs(data, fileName + fileExtension);
  };
  useEffect(() => {
    listRetailer();
    const head = {
      'Retailer ID*': '',
      'Full Company Name*': '',
      'First Name': '',
      'Last Name': '',
      Position: '',
      'Mob Number': '',
      Email: '',
      Address: '',
      'State*': '',
      'Country*': '',
      Comments: '',
      Status: '',
    };
    setTemplateExcelData([head]);
  }, []);
  console.log('retailerDataWithCount', retailerData);
  const deleteRetailer = (record, e) => {
    e.preventDefault();
    console.log(record);
    const retailerID = record.id;
    console.log(retailerID);
    // setLoading(true);
    confirmAlert({
      title: 'Confirm to Delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            axios({
              method: 'DELETE',
              headers: {
                Authorization: sessionStorage.getItem('idtoken'),
              },
              url:
                process.env.REACT_APP_API_BASE +
                'admin-portal/retailer?id=' +
                retailerID,
            })
              .then(response => {
                console.log('retailer deleted !', response);
                listRetailer();
              })
              .catch(error => {
                console.log(error.response);
                console.log(error.response.status);
                if (
                  error.response.status == 401 &&
                  error.response.data.message ==
                    'The incoming token has expired'
                ) {
                  alert('Session timed out, please login again.');
                  localStorage.clear();
                  sessionStorage.clear();
                  localStorage.clear();
                  // localStorage.removeItem( "accessLevel" );
                  // reset page location
                  console.log('logout succesfully');
                  document.location.replace('/');
                }
              });
          },
        },
        {
          label: 'No',
          onClick: () => {
            return;
          },
        },
      ],
    });
  };
  const [templateExcelData, setTemplateExcelData] = React.useState([]);
  const [openUpd, setOpenUpd] = React.useState(false);
  console.log('rep table', props);
  const handleClose = () => {
    setOpenUpd(false);
  };
  const handleView = (record, e) => {
    console.log('recccc', record);
    props.setValue('1');
    props.setRetailerInputField(record);
    props.setmobNums({
      retailer_ph_number: record.ph_number,
      contact_mob_number: record.mob_number,
    });
    props.selectedRole({ value: 'Retailer', label: 'Retailer' });
    props.setIsViewModes({
      ...props.isViewModes,
      isViewMode_retailer: true,
    });
    props.setFieldDisabled({
      ...props.fieldDisabled,
      fieldDisabled_retailer: true,
    });
    if (record.status == 'active') {
      console.log('true active');
      props.setStatusValue(true);
      props.setToggleText('Active');
    } else if (record.status == 'inactive') {
      props.setStatusValue(false);
      props.setToggleText('Inactive');
    }
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => {
            console.log('cahngeddd', e.target.value);
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    // TODO: if in future, no need, remove this
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {}, 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  // console.log(retailerData);
  const rec =
    retailerData &&
    retailerData
      .sort((a, b) => a.full_company_name.localeCompare(b.full_company_name))
      .map(({ ...item }) => ({
        ...item,
      }));
  console.log(rec);

  const bulkDelete = e => {
    console.log('selectedList==>', props.selectedList);
    var arrData = [];
    props.selectedList.forEach((item, index) => {
      const obj = {};
      if (item) {
        obj['id'] = item.id;
        arrData.push(obj);
        console.log('arrData==>', arrData);
        confirmAlert({
          title: 'Confirm to Delete',
          message: 'Are you sure to do this.',
          buttons: [
            {
              label: 'yes',
              onClick: () => {
                axios({
                  method: 'PUT',
                  headers: {
                    Authorization: sessionStorage.getItem('idtoken'),
                  },
                  url:
                    process.env.REACT_APP_API_BASE +
                    'admin-portal/retailer/bulk-delete',
                  data: arrData,
                })
                  .then(response => {
                    console.log(response);
                    console.log('bulk retailer deleted !', response);
                    listRetailer();
                    props.setSelectedList([]);
                    arrData = [];
                  })
                  .catch(error => {
                    console.log(error.response);
                    console.log(error.response.status);
                    if (
                      error.response.status == 401 &&
                      error.response.data.message ==
                        'The incoming token has expired'
                    ) {
                      alert('Session timed out, please login again.');
                      localStorage.clear();
                      sessionStorage.clear();
                      localStorage.clear();
                      // localStorage.removeItem( "accessLevel" );
                      // reset page location
                      console.log('logout succesfully');
                      document.location.replace('/');
                    }
                  });
              },
            },
            {
              label: 'No',
              onClick: () => {
                return;
              },
            },
          ],
        });
      }
    });
  };

  const columns = [
    {
      title: 'Retailer ID',
      dataIndex: 'id',
      align: 'center',
      ...getColumnSearchProps('id'),
    },
    {
      title: 'Company Name',
      dataIndex: 'full_company_name',
      align: 'center',
      ...getColumnSearchProps('full_company_name'),
    },
    {
      title: 'Country',
      dataIndex: 'country',
      align: 'center',
    },
    {
      title: 'No. of Stores',
      dataIndex: 'no_of_store',
      align: 'center',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      width: '100px',
      align: 'center',
      render: (text, record) => {
        return (
          <>
            <button
              style={{
                marginRight: '0px',
                marginLeft: '5px',
                backgroundColor: 'transparent',
              }}
              onClick={e => handleView(record, e)}
            >
              <svg
                height="18"
                width="18"
                fill="#000000"
                xmlns="http://www.w3.org/2000/svg"
                data-name="Layer 3"
                viewBox="0 0 24 24"
                x="0px"
                y="0px"
              >
                <path d="M20.5,9.873A11.123,11.123,0,0,0,12,6,11.123,11.123,0,0,0,3.5,9.873L2.239,11.351a1,1,0,0,0,0,1.3L3.5,14.127A11.123,11.123,0,0,0,12,18a11.125,11.125,0,0,0,8.5-3.872l1.261-1.479a1,1,0,0,0,0-1.3Zm-1.521,2.958A9.134,9.134,0,0,1,12,16a9.131,9.131,0,0,1-6.979-3.17L4.314,12l.707-.83h0A9.134,9.134,0,0,1,12,8a9.131,9.131,0,0,1,6.979,3.17l.707.83Z"></path>
                <path d="M12,9a3,3,0,1,0,3,3A3,3,0,0,0,12,9Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,12,13Z"></path>
              </svg>{' '}
            </button>
            <button
              style={{ marginRight: '0', backgroundColor: 'transparent' }}
              onClick={e => deleteRetailer(record, e)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#000"
                className="bi bi-trash"
                viewBox="0 0 16 16"
              >
                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                <path
                  fillRule="evenodd"
                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                />
              </svg>
            </button>
          </>
        );
      },
    },
  ];
  const [errorMsgBulk, setErrorMsgBulk] = React.useState('');

  const handleUpload = event => {
    event.preventDefault();
    var file = event.target.files,
      f = file[0]; //file info, get file
    console.log(f.name);
    const newArr = [];

    var reader = new FileReader();
    console.log('data');
    reader.onload = function (e) {
      console.log('data');
      // console.log(e.target.result)
      var data = e.target.result;

      let readedData = XLSX.read(data, { type: 'binary' }); // convert to binary, make readed data
      const wsname = readedData.SheetNames[0]; // to find sheet name
      const ws = readedData.Sheets[wsname]; // To get first sheet

      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(ws, {
        header: 1,
        defval: '',
        blankrows: false,
      }); // to get the header of sheet
      // setFileUploaded( dataParse );
      // console.log(dataParse)
      const headers = dataParse[0];
      console.log('data paser', typeof dataParse, dataParse);
      var arr = [];
      var arr1 = {};

      for (let i = 1; i < dataParse.length; i++) {
        newArr.push({ ...dataParse[i], checked: false });
      }
      console.log(headers);
      console.log(newArr);
      newArr.map((rec, i) => {
        headers.map((rec1, index) => {
          arr1[rec1] = rec[index];
        });
        arr.push(arr1);
        arr1 = {};
      });
      console.log(arr);
      var data = [];
      arr.map((rec1, i) => {
        var aa2 = {
          id: rec1['Retailer ID*'].toString().toUpperCase(),
          full_company_name: rec1['Full Company Name*'],
          first_name: rec1['First Name'],
          last_name: rec1['Last Name'],
          position: rec1['Position'],
          ph_number: rec1['Mob Number'],
          email: rec1['Email'],
          address: rec1['Address'],
          state: rec1['State*'] == '' ? '' : rec1['State*'].toUpperCase(),
          country:
            rec1['Country*'] == ''
              ? ''
              : rec1['Country*'].charAt(0).toUpperCase() +
                rec1['Country*'].slice(1).toLowerCase(),
          comment: rec1['Comments'],
          status:
            rec1['Status'] == '' ? 'active' : rec1['Status'].toLowerCase(),
        };

        data.push(aa2);
      });
      console.log(data);
      var error = '';
      data.map((rec, i) => {
        if (rec.id == '') {
          console.log('error in' + i + 'record');
          setErrorMsgBulk('Error in ' + (i + 1) + ' record. Please enter ID');
          error = 'Error in ' + (i + 1) + ' record. Please enter ID';
          setOpenUpd(true);
          setTimeout(() => {
            setOpenUpd(false);
          }, 3000);
          return;
        }
        if (rec.full_company_name == '') {
          console.log('error in' + i + 'record');
          setErrorMsgBulk(
            'Error in ' + (i + 1) + ' record. Please enter full company name',
          );
          setOpenUpd(true);
          error = 'Error in ' + (i + 1) + ' record. Please enter ID';
          setTimeout(() => {
            setOpenUpd(false);
          }, 3000);
          return;
        }
        if (rec.country == '') {
          console.log('error in' + i + 'record');
          error = 'Error in ' + (i + 1) + ' record. Please enter ID';
          setErrorMsgBulk(
            'Error in ' + (i + 1) + ' record. Please enter country',
          );
          setOpenUpd(true);
          setTimeout(() => {
            setOpenUpd(false);
          }, 3000);
          return;
        }
        if (rec.state == '') {
          console.log('error in' + i + 'record');
          error = 'Error in ' + (i + 1) + ' record. Please enter ID';
          setErrorMsgBulk(
            'Error in ' + (i + 1) + ' record. Please enter state',
          );
          setOpenUpd(true);
          setTimeout(() => {
            setOpenUpd(false);
          }, 3000);
          return;
        }
        // else {
        //   setErrorMsgBulk('')
        // }
      });
      if (error == '') {
        console.log(data);
        axios({
          method: 'PUT',
          headers: {
            Authorization: sessionStorage.getItem('idtoken'),
          },
          url: process.env.REACT_APP_API_BASE + 'admin-portal/retailer',
          data: data,
        })
          .then(response => {
            console.log(response);
            setOpenUpd(true);
            setTimeout(() => {
              setOpenUpd(false);
            }, 3000);
            listRetailer();
          })
          .catch(error => {
            console.log(error.response);
            console.log(error.response.status);
            if (
              error.response.status == 401 &&
              error.response.data.message == 'The incoming token has expired'
            ) {
              alert('Session timed out, please login again.');
              localStorage.clear();
              sessionStorage.clear();
              localStorage.clear();
              // localStorage.removeItem( "accessLevel" );
              // reset page location
              console.log('logout succesfully');
              document.location.replace('/');
            }
          });
      }
    };
    reader.readAsBinaryString(f);
    event.target.value = '';
    setErrorMsgBulk('');
  };
  return (
    <>
      {loading ? (
        <div className="overlay-box">
          <ClipLoader
            color={color}
            loading={loading}
            css={override}
            size={150}
          />
        </div>
      ) : null}
      <Dialog open={openUpd}>
        <DialogContent>
          <DialogContentText>
            <Row>
              <Col md={12}>
                {errorMsgBulk == '' ? (
                  <>
                    <b style={{ color: 'red' }}>Thank you</b>
                    <br />
                    <br />
                    <label style={{ color: 'red' }}>
                      Retailer details successfully updated
                    </label>
                  </>
                ) : (
                  <>
                    {' '}
                    <br />
                    <br />
                    <label style={{ color: 'red' }}>{errorMsgBulk}</label>
                  </>
                )}
              </Col>
            </Row>
            <br />
          </DialogContentText>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Row style={{ margin: '10px 0 -5px 0' }}>
        <Col md={3} className="autoComp" style={{ float: 'left' }}></Col>
        <Col
          md={9}
          style={{ float: 'right', textAlign: 'right', paddingRight: '20px' }}
          className="uploadExcel"
        >
          <button
            title="Select records to delete!"
            type="button"
            variant="outlined"
            class="btn btn-primary"
            onClick={e => {
              bulkDelete(e);
            }}
            disabled={props.selectedList.length == 0}
          >
            {' '}
            Delete
          </button>
          <input
            type="file"
            id="importFile"
            accept=" .csv, .xlsx"
            style={{ display: 'none' }}
            onChange={handleUpload}
          />
          <label
            htmlFor="importFile"
            class="btn btn-primary"
            style={{ verticalAlign: 'sub' }}
          >
            Import
          </label>
          {/* <button type="button" variant="outlined" class="btn btn-primary" onClick={(e) => exportToCSV(filteredTableData, 'fileName')}>Import</button> */}
          <button
            type="button"
            variant="outlined"
            class="btn btn-primary"
            onClick={e => exportToCSV(filteredData, 'retailer_listing')}
          >
            Export
          </button>
          <button
            type="button"
            variant="outlined"
            class="btn btn-primary"
            onClick={e => exportToCSV(templateExcelData, 'retailer_template')}
          >
            Export Template
          </button>
        </Col>
      </Row>

      {/* <br /> */}
      <div className="antdTable">
        {/* {rec ? ( */}
        <Table
          columns={columns}
          dataSource={rec}
          rowSelection={{ ...props.rowSelection }}
          rowKey={record => record.id}
          onChange={(pagination, filters, sorter, currentPageData) =>
            filterD(currentPageData.currentDataSource)
          }
          pagination={{
            total: filteredData.length,
            showSizeChanger: true,
            showTotal: total => `Total ${total} items`,
            pageSizeOptions: ['10', '50', '100', '200', '500'],
          }}
        />
        {/* ) : null} */}
      </div>
    </>
  );
};

export default RetailerTable;
