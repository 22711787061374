import React, { useState } from 'react';
import Page from '../../components/Page';
import { useEffect } from 'react';
import { BiDownload } from 'react-icons/bi';
import { Table } from 'antd';
import 'antd/dist/antd.min.css';
import greyBanner from '../../assets/img/logo/Banner Grey.png';
import ReactToPrint from 'react-to-print';
import { FcPrint } from 'react-icons/fc';
import PurchaseOrder from '../../components/print/PurchaseOrder';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
} from 'reactstrap';
import axios from 'axios';
import moment from 'moment';
import logo200Image from '../../assets/img/logo/Banner Grey.png';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/react';

const override = css`
  display: block;
  margin: 20% auto;
  border-color: #fff;
`;

const InvoiceTable = props => {
  const componentRef = React.useRef();
  const [invoiceData, setInvoiceData] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const [color, setColor] = React.useState('#ffffff');
  const listInvoice = () => {
    setLoading(true);
    const supplier_username = sessionStorage.getItem('username');
    axios({
      method: 'GET',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url:
        // process.env.REACT_APP_API_BASE + 'myob?supplier=ACOL',
        process.env.REACT_APP_API_BASE + 'myob?supplier=' + supplier_username,
      // + supplier_username,
    })
      .then(response => {
        var suppData = response.data.body;
        if (suppData == 'Call Complete, No Invoices Found for Supplier') {
          setLoading(false);
        } else {
          suppData.map(rec => {
            rec.gatherData = false;
          });
          console.log(suppData);
          setInvoiceData(suppData);
          setLoading(false);
        }
      })
      .catch(error => {
        console.log('Error ', error);
        setLoading(false);
      });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    listInvoice();
  }, []);

  const [isShown, setIsShown] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [isTimeOut, setIsTimeOut] = useState(false);
  var isShownData = [];
  const handleClick = (event, data, index) => {
    setIsShown(data);
    data.gatherData = true;
    isShownData.push(data);
  };
  // const invoiceData = [{
  //   'id': '1',
  //   'start_date': '13/03/2022',
  //   'month': 'March',
  //   'invoice_no': 'INV001',
  //   'status': 'Pending',
  //   'due_date': '22/10/2022',
  //   'comment': 'SA',
  //   'action': 'View | Download',
  // },
  // {
  //   'id': '2',
  //   'start_date': '13/04/2022',
  //   'month': 'Feb',
  //   'invoice_no': 'INV002',
  //   'status': 'Paid',
  //   'due_date': '23/12/2022',
  //   'comment': 'WA',
  //   'action': 'View | Download',
  // }]
  // const delay = data => {
  //   setTimeout(() => {
  //     return (
  //       <ReactToPrint
  //         trigger={() => <FcPrint size={36} style={{ cursor: 'pointer' }} />}
  //         content={() => componentRef.current}
  //         pageStyle={{ background: '' }}
  //       />
  //     );
  //   }, 3000);
  // };

  console.log(invoiceData);
  const columns = [
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   align: "left",
    //   editable: true,
    // },
    {
      title: 'Date',
      dataIndex: 'Date',
      align: 'left',
      editable: true,
      render: (text, record) => {
        const date = record.Date;
        return (
          <>
            <p> {moment(date).format('DD/MM/YYYY')}</p>
          </>
        );
      },
    },
    {
      title: 'Customer ID',
      dataIndex: 'month',
      align: 'left',
      editable: true,
      render: (text, record) => {
        return (
          <>
            <p>{record.Customer.DisplayID}</p>
          </>
        );
      },
    },
    {
      title: 'Invoice No.',
      dataIndex: 'Number',
      align: 'left',
      editable: true,
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      align: 'left',
      editable: true,
    },
    {
      title: 'Due Date',
      dataIndex: 'due_date',
      align: 'left',
      editable: true,
      render: (text, record) => {
        const date = record.due_date;
        return (
          <>
            <p> {moment(date).format('DD/MM/YYYY')}</p>
          </>
        );
      },
    },
    {
      title: 'Comments',
      dataIndex: 'Comment',
      align: 'left',
      editable: true,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      align: 'center',
      render: (text, record, index) => {
        // dataRec = record;
        console.log(index, record);

        return (
          <>
            {/* <button onClick={(e) => { detailsView(e, record) }} style={{ padding: '0', marginRight: '6px', backgroundColor: 'transparent' }}><svg height='18' width='18' fill="#000000" xmlns="http://www.w3.org/2000/svg" data-name="Layer 3" viewBox="0 0 24 24" x="0px" y="0px"><path d="M20.5,9.873A11.123,11.123,0,0,0,12,6,11.123,11.123,0,0,0,3.5,9.873L2.239,11.351a1,1,0,0,0,0,1.3L3.5,14.127A11.123,11.123,0,0,0,12,18a11.125,11.125,0,0,0,8.5-3.872l1.261-1.479a1,1,0,0,0,0-1.3Zm-1.521,2.958A9.134,9.134,0,0,1,12,16a9.131,9.131,0,0,1-6.979-3.17L4.314,12l.707-.83h0A9.134,9.134,0,0,1,12,8a9.131,9.131,0,0,1,6.979,3.17l.707.83Z"></path>
              <path d="M12,9a3,3,0,1,0,3,3A3,3,0,0,0,12,9Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,12,13Z"></path></svg>
            </button> */}
            <Button
              onClick={e => {
                handleClick(e, record, index);
              }}
            >
              View
            </Button>

            {record.gatherData == true ? (
              <ReactToPrint
                trigger={() => (
                  <FcPrint size={36} style={{ cursor: 'pointer' }} />
                )}
                content={() => componentRef.current}
                pageStyle={{ background: '' }}
              />
            ) : null}

            {/* {delay(record)} */}
            {/* </button> */}

            {/* <span>Print</span> */}
            {/* <span style={{ float: 'right', color: '#fff', fontSize: '10px', paddingRight: '30px' }}>  <button style={{ padding: '0', marginLeft: '6px', backgroundColor: 'transparent' }}><BiDownload style={{ fontSize: '20px' }} />
            </button></span> */}
          </>
        );
      },
    },
  ];
  // const PrintTime = () => {
  //   return (
  //     setTimeout(() => (

  //       <div ref={componentRef}   >
  //         <PurchaseOrder data={isShown} />
  //       </div>

  //     ), 3000)
  //   );
  // };
  // const printTime = () => {

  //   return (
  //     <>
  //       <div ref={componentRef}   >
  //         <PurchaseOrder data={isShown} />
  //       </div>
  //     </>
  //   );
  // };
  return (
    <>
      {loading ? (
        <div className="overlay-box">
          <ClipLoader
            color={color}
            loading={loading}
            css={override}
            size={150}
          />
        </div>
      ) : null}
      {/* {isTimeOut ? ( */}
      <div ref={componentRef}>
        <PurchaseOrder data={isShown} />
      </div>
      {/* ) : null} */}

      <Page>
        <Row>
          <Col xl={12} lg={12} md={12} style={{ paddingLeft: '0' }}>
            <Card id="userManagement">
              <CardHeader className="card-header-main">
                Invoice View{' '}
              </CardHeader>
              <CardBody style={{ background: '#fff', padding: '1.25rem' }}>
                <div id="tabCol" style={{ paddingTop: '2%' }}>
                  <br />
                  <div className="antdTable invoiceTable">
                    <Table
                      columns={columns}
                      dataSource={invoiceData}
                      pagination={{
                        total: invoiceData.length,
                        showTotal: total => `Total ${total} items`,
                        pageSizeOptions: ['10', '50', '100', '200', '500'],
                      }}
                      // bordered
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <img src={greyBanner} className="greyBanner"></img>
          </Col>
        </Row>
      </Page>
    </>
  );
};

export default InvoiceTable;
