import Page from '../../components/Page';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Input, Row, Button, Form, FormGroup } from 'reactstrap';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import AWS from 'aws-sdk';
import _ from 'lodash';
import axios from 'axios';
import AdminTable from '../Tables/AdminTable';
import 'react-phone-number-input/style.css';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Manager from '../AdminPages/Manager';
import Retailer from '../AdminPages/Retailer';
import ManagerTable from '../Tables/ManagerTable';
import StoreTable from '../Tables/StoreTable';
import RetailerTable from '../Tables/RetailerTable';
import SupplierTable from '../Tables/SupplierTable';
import Rep from '../AdminPages/Rep';
import Store from '../AdminPages/Store';
import Supplier from '../AdminPages/Supplier';
import CallCycle from '../AdminPages/CallCycle';
import AddAdmin from '../AdminPages/AddAdmin';
import Select from 'react-select';
import RepTable from '../Tables/RepTable';
import CallCycleTable from '../Tables/CallCycleTable';
import UkgTable from '../Tables/UkgTable';
import greyBanner from '../../assets/img/logo/Banner Grey.png';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/react';
import { is } from 'date-fns/locale';

// import QuicksightEmbed from './QuicksightEmbed'
const override = css`
  display: block;
  margin: 20% auto;
  border-color: #fff;
`;
const AdminSetup = (props) => {
  console.log(props);
  const [color, setColor] = React.useState('#ffffff');

  ////////////// toggle checkbox to show content
  const showContent = () => {
    var checkBox = document.getElementById('myCheck');
    // var myCheckText = document.getElementById('myCheckText');
    console.log('checkbox ', checkBox.checked);

    setStatusValue(checkBox.checked);
    if (checkBox.checked == true) {
      setStatusValue(true);
      setToggleText('Active');
      // myCheckText.innerHTML = 'Active'
    } else {
      setStatusValue(false);
      setToggleText('Inactive');
      // myCheckText.innerHTML = 'Inactive'
    }
    console.log(statusValue);
    console.log(checkBox.checked);
    // props.setStatusValue(checkBox.checked);
  };

  var page = props.history?.location.page;
  // console.log(page);
  // console.log(props);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getRepList = () => {
    axios({
      method: 'GET',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: process.env.REACT_APP_API_BASE + 'admin-portal',
    })
      .then((response) => {
        const admindata = response.data.body;
        const roles = admindata.filter((item) => {
          if (item.role && item.role == 'rep' && item.status == 'active') {
            return item;
          }
        });
        console.log('rep   Roles', roles);
        setRepListt(roles.sort((a, b) => a.first_name.localeCompare(b.first_name)));
      })
      .catch((error) => {
        console.log(error.response);
        // console.log(error.response.status);
        if (
          error.response.status == 401 &&
          error.response.data.message == 'The incoming token has expired'
        ) {
          alert('Session timed out, please login again.');
          localStorage.clear();
          sessionStorage.clear();
          localStorage.clear();
          // localStorage.removeItem( "accessLevel" );
          // reset page location
          console.log('logout succesfully');
          document.location.replace('/');
        }
      });
  };

  const getRetailerList = () => {
    axios({
      method: 'GET',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: process.env.REACT_APP_API_BASE + 'admin-portal/retailer',
    }).then((response) => {
      console.log('retailer', response);
      const admindata = response.data.body.Items;

      const activeRetailer = admindata.filter((rec) => {
        if (rec.status == 'active') {
          return rec;
        }
      });
      setRetailerList(
        activeRetailer.sort((a, b) => a.full_company_name.localeCompare(b.full_company_name))
      );
    });
  };

  const getSupplierList = () => {
    axios({
      method: 'GET',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: process.env.REACT_APP_API_BASE + 'admin-portal',
    }).then((response) => {
      const admindata = response.data.body;
      const roles = admindata.filter((item) => {
        if (item.role && item.role == 'supplier' && item.status == 'active') {
          return item;
        }
      });
      console.log('sup Roles', roles);
      setSupplierList(roles.sort((a, b) => a.full_company_name.localeCompare(b.full_company_name)));
    });
  };

  const getStoreList = () => {
    axios({
      method: 'GET',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: process.env.REACT_APP_API_BASE + 'admin-portal/store',
    }).then((response) => {
      console.log('store', response);
      const storedata = response.data.body.Items;

      setStoreList(storedata);
    });
  };

  // get rep and retailer list and pass to store
  // cannot get those list in store component because of exceeding the maximum state loop
  useEffect(() => {
    getRepList();
    getRetailerList();
    getSupplierList();
    getStoreList();
  }, []);

  const [selectedList, setSelectedList] = React.useState([]);
  const rowSelection = {
    preserveSelectedRowKeys: false,
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      console.log(record, selected, selectedRows);
      setSelectedList(selectedRows);
      console.log(record);
      console.log(selectedList);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      console.log(selected, selectedRows, changeRows);
      setSelectedList(selectedRows);
      console.log(selectedList);
    },
  };

  const [repList, setRepList] = React.useState([]);
  const [repListt, setRepListt] = React.useState([]);
  const [storeList, setStoreList] = React.useState([]);
  const [supplierList, setSupplierList] = React.useState([]);
  const [retailerList, setRetailerList] = React.useState([]);
  const [adminInputField, setAdminInputField] = useState({
    role: 'admin',
    status: 'active',
    id: '',
    first_name: '',
    last_name: '',
    address: '',
    state: '',
    personal_email: '',
    business_email: '',
    username: '',
  });
  const [repFields, setRepFields] = React.useState({
    role: 'rep',
    status: 'active',
    id: '',
    first_name: '',
    'user-type': 'rep',
    last_name: '',
    username: '',
    employement_type: '',
    employment_status: '',
    line_manager: '',
    address: '',
    state: '',
    rep_region: '',
    rep_territory: '',
    delegated_task_count: '0',
    email: '',
    personal_email: '',
    // mob_number: '',
    mobile: '',
    availabeInfo: [
      {
        availablity: '',
        start_date: '00:00',
        avail_val: false,
        end_date: '00:00',
        hours: '',
      },
    ],
    total_hours: 0,
    notes: '',
    venderDetails: [
      {
        retailer_name: '',
        vendor_id: '',
      },
    ],
  });
  const [vendorDetails, setVendorDetails] = React.useState([
    {
      retailer_name: '',
      vendor_id: '',
    },
  ]);
  const [available, setAvailable] = React.useState([
    {
      availablity: 'Monday',
      avail_val: false,
      start_time: '00:00',
      end_time: '00:00',
      hours: '24:00',
    },
    {
      availablity: 'Tuesday',
      avail_val: false,
      start_time: '00:00',
      end_time: '00:00',
      hours: '24:00',
    },
    {
      availablity: 'Wednesday',
      avail_val: false,
      start_time: '00:00',
      end_time: '00:00',
      hours: '24:00',
    },
    {
      availablity: 'Thursday',
      avail_val: false,
      start_time: '00:00',
      end_time: '00:00',
      hours: '24:00',
    },
    {
      availablity: 'Friday',
      avail_val: false,
      start_time: '00:00',
      end_time: '00:00',
      hours: '24:00',
    },
    {
      availablity: 'Saturday',
      avail_val: false,
      start_time: '00:00',
      end_time: '00:00',
      hours: '24:00',
    },
    {
      availablity: 'Sunday',
      avail_val: false,
      start_time: '00:00',
      end_time: '00:00',
      hours: '24:00',
    },
  ]);
  const [managerInputField, setManagerInputField] = useState({
    role: 'manager',
    status: 'active',
    id: '',
    job_title: '',
    first_name: '',
    last_name: '',
    state: '',
    email: '',
    username: '',
    comment: '',
  });
  const [retailerInputField, setRetailerInputField] = React.useState({
    role: 'retailer',
    status: 'active',
    id: '',
    full_company_name: '',
    address: '',
    state: '',
    country: '',

    first_name: '',
    last_name: '',
    position: '',
    email: '',
    comment: '',
  });
  const [storeInputField, setStoreInputField] = useState({
    role: 'store',
    store_status: 'active',
    retailer_name: '',
    store_id: '',
    store_size: '',
    store_name: '',
    store_grade: '',
    address: '',
    state: '',
    country: '',
    contact: [],
    region: '',
    store_manager: '',
    mob_number: '',
    store_manager_email: '',
    territory: '',
    trade_drive_trough: '',
    whare_sfs: '',
    visit_freq: '',
    visit_time: '',
    visit_start_week_no: '',
    visit_days: [
      { checked: false, name: 'Monday' },
      { checked: false, name: 'Tuesday' },
      { checked: false, name: 'Wednesday' },
      { checked: false, name: 'Thursday' },
      { checked: false, name: 'Friday' },
      { checked: false, name: 'Saturday' },
      { checked: false, name: 'Sunday' },
    ],
    sales_rep_username: '',
    sales_status: '',
    sales_rep_notes: [{ notes: '', datetime: '' }],
    senior_rep_username: '',
    senior_status: 'active',
    support_rep_username: '',
    support_status: 'inactive',
    rep_leave: '',
    additionalRep: [
      {
        rep_cover: 'Primary Rep',
        rep_cover_username: '',
        first_name: '',
        last_name: '',
        from_date: '',
        to_date: '',
      },
    ],
  });
  const [supplierInputField, setSupplierInputField] = useState({
    logo_img: '',
    status: 'active',
    id: '',
    short_name: '',
    full_company_name: '',
    'user-type': '',
    abn_no: '',
    mob_number: '',
    mini_ordr_value: '',
    business_email: '',
    contract_type: '',
    address: '',
    state: '',
    country: '',
    record_time: 'no',
    username: '',
    notes: '',
    dashId: [{ dashIDs: '' }],
    contactInfo: [
      {
        first_name: '',
        last_name: '',
        email: '',
        ph_number: '',
      },
    ],
    supplierDoc: [
      {
        document_type: '',
        document: '',
      },
    ],
    call_cycle: [
      {
        callcycle_id: '',
      },
      {
        callcycle_id: '',
      },
    ],
  });
  const [unassigenedData, setunassignedData] = useState([]);
  const [movedData, setMovedData] = React.useState([]);
  const [storeCheckbox, setStoreCheckbox] = React.useState(false);
  const [storesValue, setStoresValue] = React.useState([]);
  const [finalData, setFinalData] = useState([]);
  const [finalLeftData, setFinalLeftData] = useState([]);
  const [finalRightData, setFinalRightData] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedAusState, setSelectedAusState] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [selectedStore, setSelectedStore] = useState([]);

  const countryOptions = [
    { label: 'Australia', value: 'Australia' },
    { label: 'New Zealand', value: 'New Zealand' },
  ];
  const [callCycleInput, setCallCycleInput] = useState({
    call_status: 'active',
    call_id: '',
    retailer: '',
    supplier_name: '',
    supplier_username: '',
    state: '',
    country: '',
    stores: [
      {
        store_name: '',
      },
    ],
    start_date: '',
    end_date: '',
    recurring_task_type: '',
    call_cycle_name: '',
    call_cycle_freq: '',
    call_cycle_type: '',
    call_time: '',
    total_visits: '',
    unassigned_status: 'inactive',
    assignedStore: [
      {
        store_name: '',
        store_code: '',
        state: '',
        supplier: '',
        frequency: '',
        task_type: '',
      },
    ],
    unassignedStore: [
      {
        store_name: '',
        store_code: '',
        state: '',
        supplier: '',
        frequency: '',
        task_type: '',
      },
    ],
  });
  const [mobNums, setmobNums] = useState({
    manager_mob_number: '',
    rep_mob_number: '',
    store_mob_number: '',
    retailer_ph_number: '',
    contact_mob_number: '',
    supplier_mob_number: '',
    supplier_ph_number: [],
  });
  const [isViewModes, setIsViewModes] = useState({
    isViewMode_manager: false,
    isViewMode_rep: false,
    isViewMode_store: false,
    isViewMode_retailer: false,
    isViewMode_supplier: false,
    isViewMode_call: false,
  });
  const [fieldDisabled, setFieldDisabled] = useState({
    fieldDisabled_manager: false,
    fieldDisabled_rep: false,
    fieldDisabled_store: false,
    fieldDisabled_retailer: false,
    fieldDisabled_admin: false,
    fieldDisabled_supplier: false,
  });
  const [mobValue, setmobValue] = useState({
    mob_number: '',
  });

  const [loading, setLoading] = React.useState(false);
  const [statusValue, setStatusValue] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [toggleText, setToggleText] = React.useState('Active');
  const [btnUpdate, setBtnUpdate] = React.useState(false);
  const [retailerSelected, setRetailerSelected] = React.useState(false);
  const [managerSelected, setManagerSelected] = React.useState(false);
  const [repSelected, setrepSelected] = React.useState(false);
  const [adminSelected, setAdminSelected] = React.useState(false);
  const [supplierSelected, setSupplierSelected] = React.useState(false);
  const [storeSelected, setStoreSelected] = React.useState(false);
  const [callCycleSelected, setCallCycleSelected] = React.useState(false);
  const [callAssignedStores, setCallAssignedStores] = React.useState([]);
  const [selectedOpt, setselectedOpt] = useState({
    value: 'Select Role',
    label: 'Select Role',
  });

  const options = [
    { value: 'Select Role', label: 'Select Role' },
    { value: 'Admin', label: 'Admin' },
    { value: 'Rep', label: 'Rep' },
    { value: 'Manager', label: 'Manager' },
    { value: 'Supplier', label: 'Supplier' },
    { value: 'Store', label: 'Store' },
    { value: 'Retailer', label: 'Retailer' },
    { value: 'Call Cycle', label: 'Call Cycle' },
  ];
  const customStyles = {
    control: (options, state) => ({
      ...options,
      padding: '0rem 0.5rem',
      height: 'calc(2rem + 0.75rem + 2px)',
      fontSize: '14px',
      borderRadius: '32px',
      backgroundColor: '#E6E6E6',
      boxShadow: 'none',
      indicatorSeparator: 'none',
      border: '1px solid black',
      color: 'black',
      '&:hover': {
        border: '1px solid #ced4da',
      },
    }),
    singleValue: (provided, state) => {
      const curSelectedValue = state.getValue()[0].value;
      console.log('ssss', state, curSelectedValue);

      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return {
        ...provided,
        opacity,
        transition,
        color: curSelectedValue == 'Select Role' ? 'gray' : 'black',
      };
    },
  };
  const [idError, setIdError] = React.useState('');
  // const taskIdVal = (id, entity) => {

  //   setRequiredError({ task_id: '' });
  //   setTaskFields({
  //     ...taskFields,
  //     taskInfo: [...taskInfo],
  //   });
  //   setTaskInfo([...taskInfo]);
  // }
  const selectedRole = (selectedOption) => {
    const value = selectedOption.value;
    setselectedOpt({ value: value, label: value });
    if (value == 'Select Role') {
      setManagerSelected(false);
      setrepSelected(false);
      setAdminSelected(false);
      setSupplierSelected(false);
      setStoreSelected(false);
      setRetailerSelected(false);
      setCallCycleSelected(false);
      return;
    }
    if (value == 'Manager') {
      setManagerSelected(true);
      setrepSelected(false);
      setAdminSelected(false);
      setSupplierSelected(false);
      setStoreSelected(false);
      setRetailerSelected(false);
      setCallCycleSelected(false);
      return;
    }
    if (value == 'Retailer') {
      setRetailerSelected(true);
      setManagerSelected(false);
      setrepSelected(false);
      setAdminSelected(false);
      setSupplierSelected(false);
      setStoreSelected(false);
      setCallCycleSelected(false);
      return;
    }
    if (value == 'Rep') {
      setrepSelected(true);
      setRetailerSelected(false);
      setManagerSelected(false);
      setAdminSelected(false);
      setSupplierSelected(false);
      setStoreSelected(false);
      setCallCycleSelected(false);
      return;
    }
    if (value == 'Store') {
      setStoreSelected(true);
      setrepSelected(false);
      setRetailerSelected(false);
      setManagerSelected(false);
      setAdminSelected(false);
      setSupplierSelected(false);
      setCallCycleSelected(false);
      return;
    }
    if (value == 'Supplier') {
      setSupplierSelected(true);
      setrepSelected(false);
      setRetailerSelected(false);
      setManagerSelected(false);
      setAdminSelected(false);
      setStoreSelected(false);
      setCallCycleSelected(false);
      return;
    }
    if (value == 'Admin') {
      setAdminSelected(true);
      setrepSelected(false);
      setRetailerSelected(false);
      setManagerSelected(false);
      setSupplierSelected(false);
      setStoreSelected(false);
      setCallCycleSelected(false);
      return;
    }
    if (value == 'Call Cycle') {
      setAdminSelected(false);
      setrepSelected(false);
      setRetailerSelected(false);
      setManagerSelected(false);
      setSupplierSelected(false);
      setStoreSelected(false);
      setCallCycleSelected(true);
      return;
    }
  };
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log(event);
    console.log(newValue);
    setSelectedList([]);
    onTabChange();
  };
  const handleMobChange = (value, name) => {
    // if (name == 'supplier_ph_number'){
    //   mobNums.supplier_ph_number.push(value)
    //   console.log(name);
    //   setmobNums({
    //     ...mobNums,
    //     [name]: [...mobNums.supplier_ph_number],
    //   });
    // }else {
    setmobNums({
      ...mobNums,
      [name]: value,
    });
  };

  const [requiredError, setRequiredError] = React.useState({
    repID: '',
    repUsername: '',
    repEmail: '',
    repPersonalEmail: '',
    repFirst: '',
    repLast: '',
    retailerID: '',
    rep_type: '',
    // reportId: '',
    retailerEmail: '',
    retailerFullCompany: '',
    managerID: '',
    managerUsername: '',
    managerState: '',
    // managerEmail: '',
    storeId: '',
    storeRetailer: '',
    storeState: '',
    storeCountry: '',
    storeVisit: '',
    storeVisitDays: '',
    storeWeek: '',
    storeSenior: '',
    storeSupport: '',
    callId: '',
    callName: '',
    callSupplier: '',
    callRetailer: '',
    callFrequency: '',
    strtDate: '',
    endDate: '',
    supplierID: '',
    supplierUser: '',
    supplierEmail: '',
    supplierType: '',
    supplierFullComapany: '',
    storeName: '',
    from_date: '',
    to_date: '',
  });

  const onTabChange = () => {
    setRequiredError({
      repID: '',
      repUsername: '',
      repEmail: '',
      repPersonalEmail: '',
      repFirst: '',
      repLast: '',
      retailerID: '',
      rep_type: '',
      // reportId: '',
      retailerEmail: '',
      retailerFullCompany: '',
      managerID: '',
      managerUsername: '',
      managerState: '',
      // managerEmail: '',
      storeId: '',
      storeRetailer: '',
      storeState: '',
      storeCountry: '',
      storeVisit: '',
      storeVisitDays: '',
      storeWeek: '',
      storeSenior: '',
      storeSupport: '',
      callId: '',
      callName: '',
      callSupplier: '',
      callRetailer: '',
      callFrequency: '',
      strtDate: '',
      endDate: '',
      supplierID: '',
      supplierUser: '',
      supplierEmail: '',
      supplierType: '',
      supplierFullComapany: '',
      storeName: '',
      from_date: '',
      to_date: '',
    });
  };

  const managerInputHandler = (e) => {
    // console.log('eeeeee', e);
    const name = e.target.name;
    if (e.target.name == 'id') {
      setRequiredError({ id: '' });
      idVal(e.target.value, 'user');
    }
    if (e.target.name == 'email') {
      setRequiredError({ email: '' });
    }
    if (e.target.name == 'username') {
      setRequiredError({ username: '' });
    }
    if (name === 'status') {
      const checked = e.target.checked;
      setManagerInputField({
        ...managerInputField,
        status: checked ? 'active' : 'inactive',
      });
    } else {
      const val = e.target.value;
      setManagerInputField({
        ...managerInputField,
        [name]: val,
      });
    }

    // if (e && typeof e == 'string') {
    //   // mobile number input
    //   setManagerInputField({
    //     ...managerInputField,
    //     mob_number: e,
    //   });
    // } else {
    //   const name = e.target.name;

    //   if (name === 'status') {
    //     const checked = e.target.checked;
    //     setManagerInputField({
    //       ...managerInputField,
    //       status: checked ? 'active' : 'inactive',
    //     });
    //   } else {
    //     const val = e.target.value;
    //     setManagerInputField({
    //       ...managerInputField,
    //       [name]: val,
    //     });
    //   }
    // }
  };
  const retailerInputHandler = (e) => {
    console.log('eeeeee', e);
    const name = e.target.name;
    if (e.target.name == 'id') {
      setRequiredError({ id: '' });
      idVal(e.target.value, 'retailer');
    }
    if (e.target.name == 'country') {
      setRequiredError({ country: '' });
    }
    if (e.target.name == 'state') {
      setRequiredError({ state: '' });
    }
    if (e.target.name == 'full_company_name') {
      setRequiredError({ full_company_name: '' });
    }
    // if (e.target.name == 'email') {
    //   setRequiredError({ email: '' });
    // }
    if (name === 'status') {
      const checked = e.target.checked;
      setRetailerInputField({
        ...retailerInputField,
        status: checked ? 'active' : 'inactive',
      });
    } else {
      const val = e.target.value;
      setRetailerInputField({ ...retailerInputField, [name]: val });
    }
  };
  const repHandler = (e) => {
    console.log('eeeeee', e);
    const name = e.target.name;
    if (e.target.name == 'id') {
      setRequiredError({ id: '' });
      idVal(e.target.value, 'user');
    }
    if (e.target.name == 'rep_type') {
      setRequiredError({ rep_type: '' });
    }
    if (e.target.name == 'username') {
      setRequiredError({ username: '' });
    }
    // if (e.target.name == 'personal_email') {
    //   setRequiredError({ personal_email: '' });
    // }
    if (e.target.name == 'email') {
      setRequiredError({ email: '' });
    }
    if (e.target.name == 'first_name') {
      setRequiredError({ first_name: '' });
    }
    if (e.target.name == 'last_name') {
      setRequiredError({ last_name: '' });
    }
    if (e.target.name == 'state') {
      setRequiredError({ state: '' });
    }
    if (name === 'status') {
      const checked = e.target.checked;
      setRepFields({ ...repFields, status: checked ? 'active' : 'inactive' });
    } else {
      const val = e.target.value;
      setRepFields({ ...repFields, [name]: val });
    }
  };
  const supplierHandler = (e) => {
    console.log('eeeeee', e);
    const name = e.target.name;
    const { value } = e.target;
    if (e.target.name == 'id') {
      setRequiredError({ id: '' });
      idVal(e.target.value, 'user');
    }

    if (e.target.name == 'username') {
      setRequiredError({ username: '' });
    }
    if (e.target.name == 'full_company_name') {
      setRequiredError({ full_company_name: '' });
    }
    if (e.target.name == 'country') {
      setRequiredError({ country: '' });
    }
    if (e.target.name == 'state') {
      setRequiredError({ state: '' });
    }
    if (e.target.name == 'user-type') {
      setRequiredError({ 'user-type': '' });
    }
    // if (e.target.name == 'dashIDs') {
    //   setRequiredError({ reportId: '' });
    // }
    if (e.target.name == 'business_email') {
      setRequiredError({ business_email: '' });
    }
    if (name == 'user-type' && value == 'no') {
      console.log(supplierInputField.id);

      // setSupplierInputField({
      //   ...supplierInputField,
      //   dashId: [{ 'dashIDs': supplierInputField.id }]
      // });
      supplierInputField.dashId[0].dashIDs = supplierInputField.id;
    }
    if (name === 'record_time') {
      const checked = e.target.checked;
      setSupplierInputField({
        ...supplierInputField,
        record_time: checked ? 'yes' : 'no',
      });
    } else if (name === 'status') {
      const checked = e.target.checked;
      setSupplierInputField({
        ...supplierInputField,
        status: checked ? 'active' : 'inactive',
      });
    } else {
      const val = e.target.value;
      setSupplierInputField({ ...supplierInputField, [name]: val });
    }
  };
  const listStoreCall = () => {
    axios({
      method: 'GET',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: process.env.REACT_APP_API_BASE + 'call-cycle/list-stores',
    })
      .then((response) => {
        console.log('storesResponse', response);
        const strData = response.data;
        console.log('strData', strData);
        setCallAssignedStores(strData);
      })
      .catch((error) => {
        console.log('call-cycle/list-stores fail...', error);
      });
  };

  useEffect(() => {
    listStoreCall();
  }, []);
  const daysFilter = (selectedDay, repList, content) => {
    console.log(storeInputField.sales_rep_username);
    var selectedArr = [];
    console.log('selectedDay', selectedDay);
    console.log('selectedDay', repList);
    var aa = [];
    const updatedCheckList = storeInputField.visit_days.map((item) => {
      if (item.name == selectedDay) {
        return {
          ...item,
          checked: !item.checked,
        };
      } else {
        return item;
      }
    });
    console.log(updatedCheckList);
    // if (item.name == selectedDay) {
    updatedCheckList.map((item) => {
      if (item.checked == true) {
        console.log(item.checked);
        console.log(item.name);
        selectedArr.push(item.name);
      }
    });
    var aa11 = [];
    console.log(selectedArr);
    var repD;
    if (content == 'visitdays') {
      repD = repListt;
    } else {
      repD = repList;
    }
    repD.map((rec) => {
      console.log(rec.availabeInfo);
      rec.availabeInfo &&
        rec.availabeInfo.map((col) => {
          aa11.push({
            availablity: col.availablity,
            avail_val: col.avail_val,
            username: rec.username,
          });
        });
    });
    var tt = [];
    aa11.map((rec) => {
      if (rec.avail_val == true) {
        tt.push(rec);
      }
    });
    console.log(tt);
    const testOrderF = _.groupBy(tt, 'username');
    console.log(testOrderF);
    var dataa = [];
    var days = [];
    Object.entries(testOrderF).map(([key, val]) => {
      val.map((rec) => {
        days.push(rec.availablity);
      });
      dataa.push({ [key]: days });
      days = [];
    });
    console.log(dataa);
    var repNames = [];
    dataa.map((rec) => {
      console.log(rec);
      Object.entries(rec).map(([key, value]) => {
        var result = selectedArr.every(function (val) {
          return value.indexOf(val) >= 0;
        });
        console.log(result);
        if (result) {
          repNames.push(key);
        }
        // else {
        //   console.log('preettest');
        // }
      });
    });
    console.log(repNames);
    // repNames.map((rec, i) => {
    //   if (storeInputField.sales_rep_username !== rec) {
    //     setStoreInputField({ sales_rep_username: '' });
    //     console.log(storeInputField.sales_rep_username);
    //   }
    // });
    var repData = [];
    if (content == 'visitdays') {
      repD = repListt;
    } else {
      repD = repList;
    }
    repD.map((rec) => {
      repNames.map((rec1) => {
        if (rec.username == rec1) {
          repData.push(rec);
        }
        //  else {
        //   storeInputField.visit_days = updatedCheckList;
        //   // setStoreInputField({ sales_rep_username: '' });
        //   // setStoreInputField({ senior_rep_username: '' });
        // }
      });
    });
    console.log(repData);
    setRepList(repData);
    console.log('oklist1', storeInputField.senior_rep_username);

    // repData.filter(rec => {
    //   if (rec.username != storeInputField.senior_rep_username) {
    //     console.log(storeInputField.senior_rep_username);
    //     console.log('ifdata', rec, storeInputField.senior_rep_username);

    //     // storeInputField.senior_rep_username = '';
    //   }
    //   if (rec.username != storeInputField.sales_rep_username) {
    //     console.log(storeInputField.sales_rep_username);
    //     console.log('ifdata', rec, storeInputField.sales_rep_username);

    //     // storeInputField.sales_rep_username = '';
    //   }
    // });
    // console.log('oklist', storeInputField.senior_rep_username);
    setStoreInputField({
      ...storeInputField,
      visit_days: updatedCheckList,
    });
  };
  const changeStore = (val) => {
    const newListLeft = [];
    const newListRight = [];
    console.log(finalData);
    console.log(val);
    finalData.forEach((item) => {
      if (val == '7' && item['Frequency*'] == '7') {
        selectedAusState.map((rec) => {
          if (rec.value.toLowerCase() == item['State*'].toLowerCase()) {
            console.log(' state match', item);
            const curStoreId = item['Store ID*'];
            const curCallId = callCycleInput.call_id;
            let isIncluded = false;
            let anotherDis = false;
            callAssignedStores.forEach((itm) => {
              console.log('ifcallCycleInput.call_id', callCycleInput.call_id);
              if (itm.call_id == curCallId && itm.store_id == curStoreId) {
                console.log(
                  'ifadcallCycleInput.call_id',
                  callCycleInput.call_id,
                  itm.call_id,
                  curCallId,
                  itm.store_id,
                  curStoreId
                );
                isIncluded = false;

                return;
              } else {
                console.log(
                  'elseadcallCycleInput.call_id',
                  callCycleInput.call_id,
                  itm.call_id,
                  curCallId,
                  itm.store_id,
                  curStoreId
                );
                if (
                  itm.store_id == curStoreId &&
                  callCycleInput.supplier_name == itm.supplier_name
                ) {
                  isIncluded = true;
                  return;
                }
              }
            });
            console.log(' state match22', isIncluded);
            newListLeft.push({
              ...item,
              checked: true,
              disabled: isIncluded,
              frequency: '7',
            });
          }
          // else {
          //   const curStoreId = item['Store ID*'];
          //   const curCallId = callCycleInput.call_id;
          //   let isIncluded = false;
          //   callAssignedStores.forEach( itm => {
          //     if ( itm.store_id == curStoreId ) {
          //       isIncluded = true;
          //       return;
          //     }
          //   } );
          //   newListRight.push( {
          //     ...item,
          //     checked: true,
          //     disabled: isIncluded,
          //   } );
          // }
        });
      } else if (
        val == '28' &&
        (item['Frequency*'] == '28' ||
          item['Frequency*'] == '14O' ||
          item['Frequency*'] == '14o' ||
          item['Frequency*'] == '14e' ||
          item['Frequency*'] == '14E' ||
          item['Frequency*'] == '7')
      ) {
        selectedAusState.map((rec) => {
          if (rec.value.toLowerCase() == item['State*'].toLowerCase()) {
            const curStoreId = item['Store ID*'];
            const curCallId = callCycleInput.call_id;
            let isIncluded = false;
            callAssignedStores.forEach((itm) => {
              if (itm.call_id == curCallId && itm.store_id == curStoreId) {
                console.log(
                  'ifcccallCycleInput.call_id',
                  callCycleInput.call_id,
                  itm.call_id,
                  curCallId,
                  itm.store_id,
                  curStoreId
                );
                isIncluded = false;
                return;
              } else {
                if (
                  itm.store_id == curStoreId &&
                  callCycleInput.supplier_name == itm.supplier_name
                ) {
                  isIncluded = true;
                  return;
                }
              }
            });
            newListLeft.push({
              ...item,
              checked: true,
              disabled: isIncluded,
              frequency: '28',
            });
            // newListLeft.push({ ...item, checked: true, frequency: '28' });
          }
          // else {
          //   const curStoreId = item['Store ID*'];
          //   const curCallId = callCycleInput.call_id;
          //   let isIncluded = false;
          //   callAssignedStores.forEach( itm => {
          //     if ( itm.store_id == curStoreId ) {
          //       isIncluded = true;
          //       return;
          //     }
          //   } );
          //   newListRight.push( {
          //     ...item,
          //     checked: true,
          //     disabled: isIncluded,
          //   } );
          // }
        });
      } else if (
        val == '14odd' &&
        (item['Frequency*'] == '14O' || item['Frequency*'] == '14o' || item['Frequency*'] == '7')
      ) {
        selectedAusState.map((rec) => {
          if (rec.value.toLowerCase() == item['State*'].toLowerCase()) {
            const curStoreId = item['Store ID*'];
            const curCallId = callCycleInput.call_id;
            let isIncluded = false;
            let anotherDis = false;

            callAssignedStores.forEach((itm) => {
              if (itm.call_id == curCallId && itm.store_id == curStoreId) {
                console.log(
                  'ifcccallCycleInput.call_id',
                  callCycleInput.call_id,
                  itm.call_id,
                  curCallId,
                  itm.store_id,
                  curStoreId
                );
                isIncluded = false;
                anotherDis = false;
                return;
              } else {
                if (
                  itm.store_id == curStoreId &&
                  callCycleInput.supplier_name == itm.supplier_name
                ) {
                  isIncluded = true;
                  anotherDis = true;
                  return;
                }
              }
            });
            newListLeft.push({
              ...item,
              checked: true,
              disabled: isIncluded,
              frequency: '14odd',
            });
            // newListLeft.push({ ...item, checked: true, frequency: '14odd' });
          }
          // else {
          //   const curStoreId = item['Store ID*'];
          //   const curCallId = callCycleInput.call_id;
          //   let isIncluded = false;
          //   callAssignedStores.forEach( itm => {
          //     if ( itm.store_id == curStoreId ) {
          //       isIncluded = true;
          //       return;
          //     }
          //   } );
          //   newListRight.push( {
          //     ...item,
          //     checked: true,
          //     disabled: isIncluded,
          //   } );
          // }
        });
      } else if (
        val == '14even' &&
        (item['Frequency*'] == '14E' || item['Frequency*'] == '14e' || item['Frequency*'] == '7')
      ) {
        selectedAusState.map((rec) => {
          if (rec.value.toLowerCase() == item['State*'].toLowerCase()) {
            const curStoreId = item['Store ID*'];
            const curCallId = callCycleInput.call_id;
            let isIncluded = false;
            let anotherDis = false;
            callAssignedStores.forEach((itm) => {
              if (itm.call_id == curCallId && itm.store_id == curStoreId) {
                console.log(
                  'ifcccallCycleInput.call_id',
                  callCycleInput.call_id,
                  itm.call_id,
                  curCallId,
                  itm.store_id,
                  curStoreId
                );
                isIncluded = false;
                anotherDis = false;

                return;
              } else {
                if (
                  itm.store_id == curStoreId &&
                  callCycleInput.supplier_name == itm.supplier_name
                ) {
                  isIncluded = true;
                  anotherDis = true;

                  return;
                }
              }
            });
            newListLeft.push({
              ...item,
              checked: true,
              disabled: isIncluded,
              frequency: '14even',
            });
            // newListLeft.push({ ...item, checked: true, frequency: '14even' });
          }
          // else {
          //   const curStoreId = item['Store ID*'];
          //   const curCallId = callCycleInput.call_id;
          //   let isIncluded = false;
          //   callAssignedStores.forEach( itm => {
          //     if ( itm.store_id == curStoreId ) {
          //       isIncluded = true;
          //       return;
          //     }
          //   } );
          //   newListRight.push( {
          //     ...item,
          //     checked: true,
          //     disabled: isIncluded,
          //   } );
          // }
        });
      } else {
        const curStoreId = item['Store ID*'];
        const curCallId = callCycleInput.call_id;
        let isIncluded = false;
        let anotherDis = false;
        callAssignedStores.forEach((itm) => {
          if (itm.store_id == curStoreId) {
            isIncluded = true;
            anotherDis = true;
            return;
          }
        });
        newListRight.push({
          ...item,
          checked: true,
          disabled: isIncluded,
          anotherDis: anotherDis,
        });
      }
    });
    console.log('newListLeftlast', newListLeft);
    console.log('newListRightlast', newListRight);
    setFinalLeftData(newListLeft);
    setStoresValue(newListLeft);
    setFinalRightData(newListRight);
    console.log('newListLeftlast', newListLeft);
    console.log('newListRightlast', newListRight);
  };
  const callCycleHandler = (e) => {
    var val;
    if (e.target.name == 'call_id') {
      setRequiredError({ call_id: '' });
      idVal(e.target.value, 'call_cycle');
    }
    if (e.target.name == 'call_cycle_name') {
      setRequiredError({ call_cycle_name: '' });
    }
    if (e.target.name == 'supplier_name') {
      setRequiredError({ supplier_name: '' });
      // setTimeout(() => {
      // changeStore(callCycleInput.call_cycle_freq);
      // }, 2000);
    }
    if (e.target.name == 'retailer') {
      setRequiredError({ retailer: '' });
    }

    // if (e.target.name == 'start_date') {
    //   setRequiredError({ start_date: '' });
    // }
    // if (e.target.name == 'end_date') {
    //   setRequiredError({ end_date: '' });
    // }
    console.log('eeeeee', e, e.target.value);
    const name = e.target.name;

    // if (name === 'call_status'
    //   // || name === 'unassigned_status'
    // ) {
    //   const checked = e.target.checked;
    //   setCallCycleInput({
    //     ...callCycleInput,
    //     [name]: checked ? 'active' : 'inactive',
    //   });
    // } else
    if (name == 'call_cycle_freq') {
      setRequiredError({ call_cycle_freq: '' });

      val = e.target.value;
      console.log(val);
      changeStore(val);
      setCallCycleInput({ ...callCycleInput, [name]: val });
      console.log('first', finalLeftData);
      console.log('girst', finalRightData);
    } else {
      const val = e.target.value;
      setCallCycleInput({ ...callCycleInput, [name]: val });
    }
  };
  const adminInputHandler = (e) => {
    setAdminInputField({
      ...adminInputField,
      [e.target.name]: e.target.value,
    });
    if (e.target.name == 'id') {
      setRequiredError({ id: '' });
      idVal(e.target.value, 'user');
    }
    if (e.target.name == 'username') {
      setRequiredError({ username: '' });
    }
    // if (e.target.name == 'personal_email') {
    //   setRequiredError({ personal_email: '' });
    // }
    if (e.target.name == 'business_email') {
      setRequiredError({ business_email: '' });
    }
  };
  const checkStoreVisit =
    storeInputField &&
    storeInputField.visit_days &&
    storeInputField.visit_days.every((itm) => {
      console.log('itm', itm);
      return itm.checked == false;
    });
  const storeInputHandler = (e) => {
    var selectedArr = [];
    var list = [];
    // console.log('storeInputHandler', e);
    const name = e.target.name;
    console.log('storeInputHandler name', name);
    if (e.target.name == 'store_id') {
      setRequiredError({ storeId: '' });
      idVal(e.target.value, 'store');
    }
    if (e.target.name == 'store_name') {
      setRequiredError({ storeName: '' });
    }
    if (e.target.name == 'retailer_name') {
      setRequiredError({ storeRetailer: '' });
    }
    // if (e.target.name == 'store_manager_email') {
    //   setRequiredError({ store_manager_email: '' });
    // }
    if (e.target.name == 'sales_rep_username') {
      setRequiredError({ storeSales: '' });
    }
    if (e.target.name == 'state') {
      setRequiredError({ state: '' });
      console.log('state', e.target.name);

      repListt.map((rec) => {
        if (rec.state == e.target.value && rec.state != '') {
          console.log(rec);
          list.push(rec);
        }
      });
      console.log('okkk', storeInputField.visit_days);
      // if()
      setRepList(list);
      const daysDaata = [];
      storeInputField.visit_days &&
        storeInputField.visit_days.map((rec) => {
          if (rec.checked == true) {
            daysDaata.push(rec.name);
          }
        });
      console.log(daysDaata);
      console.log(list);
      console.log(repListt);
      const selectedDay = name.split('&')[1];
      daysFilter(storeInputField.visit_days, list, 'state');
    }
    if (e.target.name == 'country') {
      setRequiredError({ country: '' });
    }
    if (e.target.name == 'visit_freq') {
      setRequiredError({ visit_freq: '' });
    }
    if (checkStoreVisit) {
      setRequiredError({ visit_days: '' });
    }
    if (e.target.name == 'visit_start_week_no') {
      setRequiredError({ storeWeek: '' });
    }
    if (e.target.name == 'senior_rep_username') {
      setRequiredError({ senior_rep_username: '' });
    }
    // if (e.target.name == 'support_rep_username') {
    //   setRequiredError({ support_rep_username: '' });
    // }

    if (
      name == 'store_status' ||
      name == 'senior_status' ||
      name == 'support_status' ||
      name == 'sales_status'
    ) {
      const checked = e.target.checked;
      setStoreInputField({
        ...storeInputField,
        [name]: checked ? 'active' : 'inactive',
      });
    } else if (name.includes('visit_days')) {
      console.log(name);
      const selectedDay = name.split('&')[1];
      console.log('selectedDay', daysFilter);
      // const checkVal = e.target.checked;
      daysFilter(selectedDay, repList, 'visitdays');
      // console.log('repList', repList);
      // repList.map(rec => {
      //   console.log('inside loop', rec.username);
      //   console.log('inside loop', storeInputField.senior_rep_username);

      //   if (storeInputField.senior_rep_username == rec.username) {
      //     console.log('inside loop', rec.username);
      //     setStoreInputField({ senior_rep_username: rec.username });
      //   }
      // });
    } else {
      const val = e.target.value;
      setStoreInputField({
        ...storeInputField,
        [name]: val,
      });
    }
    // if (e.target.name == 'rep_leave') {
    //   if (e.target.value != '') {
    //     console.log('leave', e.target.value);
    //     setStoreInputField({
    //       ...storeInputField,
    //       [name]: e.target.value,
    //       senior_status: 'inactive',
    //     });
    //     console.log(storeInputField);
    //   } else {
    //     console.log('empty');
    //     setStoreInputField({
    //       ...storeInputField,
    //       [name]: e.target.value,
    //       senior_status: 'active',
    //     });
    //     console.log(storeInputField);
    //   }
    // }
  };
  const [currentRepType, setCurrentRepType] = React.useState('');

  const idVal = (id, entity) => {
    axios({
      method: 'GET',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url:
        process.env.REACT_APP_API_BASE +
        'id-validation?id=' +
        id.toUpperCase() +
        '&entity=' +
        entity,
    })
      .then((response) => {
        console.log(response.data.body);
        const idVald = response.data.body;
        if (idVald == 'true') {
          setIdError('ID already exist');
        } else {
          setIdError('');
        }
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error.response.status);
        if (
          error.response.status == 401 &&
          error.response.data.message == 'The incoming token has expired'
        ) {
          alert('Session timed out, please login again.');
          localStorage.clear();
          sessionStorage.clear();
          localStorage.clear();
          // localStorage.removeItem( "accessLevel" );
          // reset page location
          console.log('logout succesfully');
          document.location.replace('/');
        }
      });
  };
  const [openDraft, setOpenDraft] = React.useState(false);
  const [openDraftS, setOpenDraftS] = React.useState(false);
  const [openDraftEnd, setOpenDraftEnd] = React.useState(false);
  const handleClose = () => {
    // setOpen(false);
    setOpenDraft(false);
  };
  const handleCloseEnd = () => {
    // setOpen(false);
    setOpenDraftEnd(false);
  };
  const handleCloseS = () => {
    // setOpen(false);
    setOpenDraftS(false);
  };
  useEffect(() => {
    console.log(props);
    console.log(props.history?.location?.state);

    if (props.history?.location?.state) {
      console.log(props.history?.location.state);
      setValue(props.history?.location.state.tabValue);
    }
  }, []);

  const toggleEditableStatus = (e) => {
    console.log('selected role', selectedOpt);
    const key = `fieldDisabled_${selectedOpt.value.toLowerCase()}`;
    setFieldDisabled({
      ...fieldDisabled,
      [key]: !fieldDisabled[key],
    });
  };
  AWS.config.update({
    accessKeyId: sessionStorage.getItem('AccessKeyId'),
    secretAccessKey: sessionStorage.getItem('AccessKeySec'),
    sessionToken: sessionStorage.getItem('sessionToken'),
  });
  const myBucket = new AWS.S3({
    params: { Bucket: process.env.REACT_APP_BUCKET_NAME },
    region: 'ap-southeast-2',
  });
  const [fileData, setFileData] = useState([]);
  const [fileDataE, setFileDataE] = useState([]);
  const addDoc = (e) => {
    console.log(e.target.files.length);
    const files = e.target.files;
    setFileData(files);

    // }
  };
  const addDocS = async () => {
    console.log(fileData);
    const fileFile = document.getElementById('fileUpload');
    if (fileFile.value != '') {
      setLoading(true);
      var paramss = {
        Bucket: process.env.REACT_APP_BUCKET_NAME,
        Prefix: 'admin-documents/supplier-reporting/',
        Delimiter: '/',
        // MaxKeys: ''
      };
      let dataFile = [];
      // var arrList = [];
      // var dataDublicate = {};

      for (let i = 0; i < fileData.length; i++) {
        dataFile.push('admin-documents/supplier-reporting/' + fileData[i].name);
        const params = {
          ACL: 'bucket-owner-full-control',
          Body: fileData[i],
          Bucket: process.env.REACT_APP_BUCKET_NAME,
          Key: 'admin-documents/supplier-reporting/' + fileData[i].name,
        };
        myBucket
          .putObject(params)
          .on('httpUploadProgress', (evt, response) => {
            // console.log(Math.round((evt.loaded / evt.total) * 100));
          })
          .send((err, data) => {
            if (err) {
              console.log(err);
            } else {
              console.log(dataFile);
            }
          });

        // return;
      }
      console.log('dataFile', dataFile);
      setOpenDraftEnd(true);
      setTimeout(() => {
        setOpenDraftEnd(false);
      }, 6000);
      setLoading(false);
      fileFile.value = '';
      setFileData('');
      await axios({
        method: 'POST',
        headers: {
          Authorization: sessionStorage.getItem('idtoken'),
        },
        url: process.env.REACT_APP_API_BASE + 'reporting-csv-upload',
        data: dataFile,
      })
        .then((response) => {
          console.log(response);
          // setLoading(false);
          // setOpenDraftS(true);
          // setTimeout(() => {
          //   setOpenDraftS(false);
          // }, 3000);
          // setLoading(false);

          const fileFile = document.getElementById('fileUpload');
        })
        .catch((error) => {
          console.log(error.response);
          console.log(error.response.status);
          if (
            error.response.status == 401 &&
            error.response.data.message == 'The incoming token has expired'
          ) {
            alert('Session timed out, please login again.');
            localStorage.clear();
            sessionStorage.clear();
            localStorage.clear();
            // localStorage.removeItem( "accessLevel" );
            // reset page location
            console.log('logout succesfully');
            document.location.replace('/');
          }
        });
    }

    // }
    // return;
    // myBucket.listObjectsV2(paramss, function (err, data) {
    //   if (err) console.log(err, err.stack); // an error occurred
    //   else {
    //     console.log(data.Contents);

    //     data.Contents.map(rec => {
    //       console.log(fileData);
    //       const dataa = rec.Key.split('/');
    //       console.log(dataa[2]);
    //       for (let i = 0; i < fileData.length; i++) {
    //         console.log(fileData[i].name);
    //         if (fileData[i].name != dataa[2]) {
    //           console.log('equal File Name', dataa[2]);
    //           // dataDublicate.push(data[2]);
    //           dataFile.push(
    //             'admin-documents/supplier-reporting/' + fileData[i].name,
    //           );
    //           return;
    //         } else {
    //           dataFile = [];
    //           setOpenDraft(true);
    //           setTimeout(() => {
    //             setOpenDraft(false);
    //           }, 3000);
    //         }
    //         console.log(dataFile);
    //       }
    //       console.log('dataFile', dataFile);
    //       dataDublicate = dataFile;
    //       // console.log(dataDublicate);
    //       dataFile = [];
    //       // dataFile.splice(0, dataFile.length);
    //     });
    //     console.log('dataFile', dataFile);
    //     console.log('dataFile', dataDublicate.length);
    //     if (dataDublicate.length > 0) {
    //       console.log('dataDublicate', dataDublicate);

    //       dataDublicate.map((rec, i) => {
    //         const params = {
    //           ACL: 'bucket-owner-full-control',
    //           Body: fileData[i],
    //           Bucket: process.env.REACT_APP_BUCKET_NAME,
    //           Key: 'admin-documents/supplier-reporting/' + fileData[i].name,
    //         };
    //         myBucket
    //           .putObject(params)
    //           .on('httpUploadProgress', (evt, response) => {
    //             console.log(Math.round((evt.loaded / evt.total) * 100));
    //           })
    //           .send((err, data) => {
    //             if (err) {
    //               console.log(err);
    //             } else {
    //               // dataFile.push(files[i].name);
    //               console.log(data);
    //               // const fileFile = document.getElementById('file-input');
    //               // fileFile.value = '';
    //             }
    //           });
    //         console.log(dataFile);
    //         // return;
    //         axios({
    //           method: 'POST',
    //           headers: {
    //             'x-api-key': sessionStorage.getItem('apikey'),
    //           },
    //           url: process.env.REACT_APP_API_BASE + 'reporting-csv-upload',
    //           data: dataFile,
    //         })
    //           .then(response => {
    //             console.log(response);
    //             setLoading(false);
    //           })
    //           .catch(error => {
    //             console.error(
    //               'Error during service worker registration:',
    //               error,
    //             );
    //             setLoading(false);
    //           });
    //       });
    //     } else {
    //       setLoading(false);
    //     }
    //   }
    // });
    // console.log('dataFile', dataFile);
  };
  const renderEditButton = () => {
    const key = `isViewMode_${selectedOpt.value.toLowerCase()}`;
    const editableKey = `fieldDisabled_${selectedOpt.value.toLowerCase()}`;
    console.log('key', editableKey, fieldDisabled[editableKey]);
    console.log(`fieldDisabled_${selectedOpt.value.toLowerCase()}`);
    console.log('key', key, isViewModes[key]);
    if (isViewModes[key] || isDisabled) {
      return (
        <Col style={{ paddingLeft: '0px' }}>
          {/* <label style={{ fontSize: '14px' }}>Edit</label> */}
          <button
            style={{
              margin: '10px 16px 0px 5px',
              float: 'right',
              border: '1px solid hsl(0, 0%, 80%)',
              borderRadius: '0.7rem',
            }}
            onClick={toggleEditableStatus}
          >
            {!fieldDisabled[editableKey] ? (
              <svg
                height="18"
                width="18"
                fill="#000000"
                xmlns="http://www.w3.org/2000/svg"
                data-name="Layer 3"
                viewBox="0 0 24 24"
                x="0px"
                y="0px"
              >
                <path d="M20.5,9.873A11.123,11.123,0,0,0,12,6,11.123,11.123,0,0,0,3.5,9.873L2.239,11.351a1,1,0,0,0,0,1.3L3.5,14.127A11.123,11.123,0,0,0,12,18a11.125,11.125,0,0,0,8.5-3.872l1.261-1.479a1,1,0,0,0,0-1.3Zm-1.521,2.958A9.134,9.134,0,0,1,12,16a9.131,9.131,0,0,1-6.979-3.17L4.314,12l.707-.83h0A9.134,9.134,0,0,1,12,8a9.131,9.131,0,0,1,6.979,3.17l.707.83Z"></path>
                <path d="M12,9a3,3,0,1,0,3,3A3,3,0,0,0,12,9Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,12,13Z"></path>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#000"
                className="bi bi-pencil"
                viewBox="0 0 16 16"
              >
                <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
              </svg>
            )}{' '}
          </button>
        </Col>
      );
    } else {
      return null;
    }
  };
  console.log(statusValue);
  return (
    <Page className={loading ? 'parentDisable' : ''}>
      {loading ? (
        <div className="overlay-box">
          <ClipLoader color={color} loading={loading} css={override} size={150} />
        </div>
      ) : null}
      <Dialog open={openDraftEnd}>
        <DialogContent>
          <DialogContentText>
            <Row>
              <Col md={12}>
                <b style={{ color: 'red' }}></b>
                <br />
                <br />
                <label style={{ color: 'red' }}>
                  Data is processing in background, you will be notified by email once complete.
                </label>
              </Col>
            </Row>
            <br />
          </DialogContentText>
          <Button onClick={handleCloseEnd} variant="outlined" color="primary">
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog open={openDraft}>
        <DialogContent>
          <DialogContentText>
            <Row>
              <Col md={12}>
                <b style={{ color: 'red' }}></b>
                <br />
                <br />
                <label style={{ color: 'red' }}>
                  {fileDataE?.toString()} file already uploaded, can't upload again.
                </label>
              </Col>
            </Row>
            <br />
          </DialogContentText>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog open={openDraftS}>
        <DialogContent>
          <DialogContentText>
            <Row>
              <Col md={12}>
                <b style={{ color: 'red' }}>Thank you</b>
                <br />
                <br />
                <label style={{ color: 'red' }}>File uploaded successfully.</label>
              </Col>
            </Row>
            <br />
          </DialogContentText>
          <Button onClick={handleCloseS} variant="outlined" color="primary">
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Row>
        <Col xl={12} lg={12} md={12} style={{ paddingLeft: '0' }}>
          <Card id="userManagement">
            <CardHeader className="card-header-main">Admin</CardHeader>
            <CardBody style={{ background: '#fff', padding: '1.25rem' }}>
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                      TabIndicatorProps={{ style: { background: '#D71920' } }}
                      className="tabb"
                    >
                      <Tab
                        label="Add"
                        value="1"
                        name="add"
                        style={{
                          padding: '12px 16px',
                          color: 'rgba(0, 0, 0, 0.6)',
                        }}
                      />
                      <Tab
                        label="View Admin"
                        value="2"
                        name="viewAdmin"
                        style={{
                          padding: '12px 16px',
                          color: 'rgba(0, 0, 0, 0.6)',
                        }}
                      />
                      <Tab
                        label="View Rep"
                        name="viewRep"
                        value="3"
                        style={{
                          padding: '12px 16px',
                          color: 'rgba(0, 0, 0, 0.6)',
                        }}
                      />
                      <Tab
                        label="View Manager"
                        value="4"
                        name="viewManager"
                        style={{
                          padding: '12px 16px',
                          color: 'rgba(0, 0, 0, 0.6)',
                        }}
                      />
                      <Tab
                        label="View Supplier"
                        value="5"
                        name="viewsupplier"
                        style={{
                          padding: '12px 16px',
                          color: 'rgba(0, 0, 0, 0.6)',
                        }}
                      />
                      <Tab
                        label="View Store"
                        value="6"
                        name="viewstore"
                        style={{
                          padding: '12px 16px',
                          color: 'rgba(0, 0, 0, 0.6)',
                        }}
                      />
                      <Tab
                        label="View Retailer"
                        value="7"
                        name="viewRetailer"
                        style={{
                          padding: '12px 16px',
                          color: 'rgba(0, 0, 0, 0.6)',
                        }}
                      />
                      <Tab
                        label="View Call Cycle"
                        value="8"
                        name="viewCallCycle"
                        style={{
                          padding: '12px 16px',
                          color: 'rgba(0, 0, 0, 0.6)',
                        }}
                      />
                      <Tab
                        label="View UKG"
                        name="viewUKG"
                        value="9"
                        style={{
                          padding: '12px 16px',
                          color: 'rgba(0, 0, 0, 0.6)',
                        }}
                      />

                      <Tab
                        label="Add supplier Data"
                        name="addSupplierData"
                        value="10"
                        style={{
                          padding: '12px 16px',
                          color: 'rgba(0, 0, 0, 0.6)',
                        }}
                      />
                    </TabList>
                  </Box>
                  {/* <br /> */}
                  <TabPanel value="1" style={{ padding: '0px', paddingTop: '20px' }}>
                    <Row
                      style={{
                        maxHeight: '60px',
                      }}
                    >
                      <Col style={{ maxWidth: '20rem', minWidth: '10rem' }}>
                        {/* <label style={{ fontSize: '14px' }}>Select Role</label> */}
                        <Select
                          styles={customStyles}
                          onChange={selectedRole}
                          value={selectedOpt}
                          options={options}
                          isDisabled={
                            fieldDisabled[`fieldDisabled_${selectedOpt.value.toLowerCase()}`]
                          }
                          // className='admin-input hide-scrollbar'
                        />
                      </Col>
                      <Col
                        md="0.5"
                        style={{
                          marginTop: '7px',
                        }}
                      >
                        <label className="switch">
                          <Input
                            type="checkbox"
                            name="status"
                            id="myCheck"
                            // onClick={showContent}
                            onChange={(e) => showContent(e)}
                            // value={statusValue}
                            checked={statusValue}
                          />
                          <span className="slider round"></span>
                        </label>
                      </Col>
                      <Col>
                        <p
                          style={{
                            padding: '12px 0px 0px 0px',
                            fontSize: '14px',
                          }}
                        >
                          {toggleText}{' '}
                        </p>
                      </Col>
                      {renderEditButton()}
                      {/* <Col md="8" style={{ paddingLeft: '0px' }}></Col> */}
                    </Row>

                    {managerSelected ? (
                      <Manager
                        value={value}
                        setValue={setValue}
                        managerInputField={managerInputField}
                        setManagerInputField={setManagerInputField}
                        managerInputHandler={managerInputHandler}
                        setIdError={setIdError}
                        idError={idError}
                        // isDisabled={isDisabled}
                        // setIsDisabled={setIsDisabled}
                        selectedRole={selectedRole}
                        mobNums={mobNums}
                        setmobNums={setmobNums}
                        // btnUpdate={btnUpdate}
                        // setBtnUpdate={setBtnUpdate}
                        handleMobChange={handleMobChange}
                        isViewModes={isViewModes}
                        setIsViewModes={setIsViewModes}
                        fieldDisabled={fieldDisabled}
                        setFieldDisabled={setFieldDisabled}
                        requiredError={requiredError}
                        setRequiredError={setRequiredError}
                        statusValue={statusValue}
                        setStatusValue={setStatusValue}
                        toggleText={toggleText}
                        setToggleText={setToggleText}
                      />
                    ) : null}
                    {retailerSelected ? (
                      <Retailer
                        value={value}
                        setValue={setValue}
                        retailerInputField={retailerInputField}
                        setIdError={setIdError}
                        idError={idError}
                        setRetailerInputField={setRetailerInputField}
                        retailerInputHandler={retailerInputHandler}
                        selectedRole={selectedRole}
                        mobNums={mobNums}
                        setmobNums={setmobNums}
                        handleMobChange={handleMobChange}
                        isViewModes={isViewModes}
                        setIsViewModes={setIsViewModes}
                        fieldDisabled={fieldDisabled}
                        setFieldDisabled={setFieldDisabled}
                        requiredError={requiredError}
                        setRequiredError={setRequiredError}
                        statusValue={statusValue}
                        setStatusValue={setStatusValue}
                        toggleText={toggleText}
                        setToggleText={setToggleText}
                      />
                    ) : null}
                    {repSelected ? (
                      <Rep
                        value={value}
                        setValue={setValue}
                        setCurrentRepType={setCurrentRepType}
                        currentRepType={currentRepType}
                        setIdError={setIdError}
                        idError={idError}
                        repFields={repFields}
                        setRepFields={setRepFields}
                        repHandler={repHandler}
                        selectedRole={selectedRole}
                        vendorDetails={vendorDetails}
                        setVendorDetails={setVendorDetails}
                        available={available}
                        setAvailable={setAvailable}
                        statusValue={statusValue}
                        setStatusValue={setStatusValue}
                        toggleText={toggleText}
                        setToggleText={setToggleText}
                        mobNums={mobNums}
                        setmobNums={setmobNums}
                        handleMobChange={handleMobChange}
                        isViewModes={isViewModes}
                        setIsViewModes={setIsViewModes}
                        fieldDisabled={fieldDisabled}
                        setFieldDisabled={setFieldDisabled}
                        requiredError={requiredError}
                        setRequiredError={setRequiredError}
                        retailerList={retailerList}
                        getRepList={getRepList}
                      />
                    ) : null}
                    {callCycleSelected ? (
                      <CallCycle
                        value={value}
                        setValue={setValue}
                        supplierList={supplierList}
                        retailerList={retailerList}
                        callCycleInput={callCycleInput}
                        setCallCycleInput={setCallCycleInput}
                        callCycleHandler={callCycleHandler}
                        selectedRole={selectedRole}
                        isViewModes={isViewModes}
                        setIsViewModes={setIsViewModes}
                        fieldDisabled={fieldDisabled}
                        finalData={finalData}
                        setFinalData={setFinalData}
                        finalLeftData={finalLeftData}
                        setFinalLeftData={setFinalLeftData}
                        finalRightData={finalRightData}
                        setFinalRightData={setFinalRightData}
                        storesValue={storesValue}
                        setStoresValue={setStoresValue}
                        storeCheckbox={storeCheckbox}
                        setStoreCheckbox={setStoreCheckbox}
                        unassigenedData={unassigenedData}
                        setunassignedData={setunassignedData}
                        statusValue={statusValue}
                        setStatusValue={setStatusValue}
                        toggleText={toggleText}
                        setToggleText={setToggleText}
                        requiredError={requiredError}
                        setRequiredError={setRequiredError}
                        setIdError={setIdError}
                        idError={idError}
                        selectedCountry={selectedCountry}
                        setSelectedCountry={setSelectedCountry}
                        selectedAusState={selectedAusState}
                        setSelectedAusState={setSelectedAusState}
                        countryOptions={countryOptions}
                        stateList={stateList}
                        setStateList={setStateList}
                        selectedStore={selectedStore}
                        setSelectedStore={setSelectedStore}
                        setCallAssignedStores={setCallAssignedStores}
                        callAssignedStores={callAssignedStores}
                        listStoreCall={listStoreCall}
                      />
                    ) : null}

                    {storeSelected ? (
                      <Store
                        value={value}
                        setValue={setValue}
                        storeInputField={storeInputField}
                        setIdError={setIdError}
                        idError={idError}
                        setStoreInputField={setStoreInputField}
                        storeInputHandler={storeInputHandler}
                        selectedRole={selectedRole}
                        mobNums={mobNums}
                        setmobNums={setmobNums}
                        handleMobChange={handleMobChange}
                        isViewModes={isViewModes}
                        setIsViewModes={setIsViewModes}
                        repList={repList}
                        setRepList={setRepList}
                        repListt={repListt}
                        setRepListt={setRepListt}
                        retailerList={retailerList}
                        fieldDisabled={fieldDisabled}
                        setFieldDisabled={setFieldDisabled}
                        statusValue={statusValue}
                        setStatusValue={setStatusValue}
                        toggleText={toggleText}
                        setToggleText={setToggleText}
                        setRequiredError={setRequiredError}
                        requiredError={requiredError}
                        checkStoreVisit={checkStoreVisit}
                      />
                    ) : null}
                    {supplierSelected ? (
                      <Supplier
                        value={value}
                        setValue={setValue}
                        setIdError={setIdError}
                        idError={idError}
                        supplierInputField={supplierInputField}
                        setSupplierInputField={setSupplierInputField}
                        supplierHandler={supplierHandler}
                        callCycleInput={callCycleInput}
                        setCallCycleInput={setCallCycleInput}
                        callCycleHandler={callCycleHandler}
                        selectedRole={selectedRole}
                        mobNums={mobNums}
                        setmobNums={setmobNums}
                        handleMobChange={handleMobChange}
                        isViewModes={isViewModes}
                        setIsViewModes={setIsViewModes}
                        retailerList={retailerList}
                        fieldDisabled={fieldDisabled}
                        setFieldDisabled={setFieldDisabled}
                        supplierList={supplierList}
                        requiredError={requiredError}
                        setRequiredError={setRequiredError}
                        statusValue={statusValue}
                        setStatusValue={setStatusValue}
                        toggleText={toggleText}
                        setToggleText={setToggleText}
                      />
                    ) : null}
                    {adminSelected ? (
                      <>
                        <AddAdmin
                          adminInputField={adminInputField}
                          setAdminInputField={setAdminInputField}
                          mobValue={mobValue}
                          adminInputHandler={adminInputHandler}
                          setIdError={setIdError}
                          requiredError={requiredError}
                          setRequiredError={setRequiredError}
                          idError={idError}
                          setmobValue={setmobValue}
                          statusValue={statusValue}
                          setStatusValue={setStatusValue}
                          toggleText={toggleText}
                          setToggleText={setToggleText}
                          btnUpdate={btnUpdate}
                          setBtnUpdate={setBtnUpdate}
                          isDisabled={isDisabled}
                          setIsDisabled={setIsDisabled}
                          fieldDisabled={fieldDisabled}
                          setFieldDisabled={setFieldDisabled}
                        />
                      </>
                    ) : null}
                  </TabPanel>
                  <TabPanel value="2" style={{ padding: '0px' }}>
                    <AdminTable
                      value={value}
                      setValue={setValue}
                      adminInputField={adminInputField}
                      setAdminInputField={setAdminInputField}
                      mobValue={mobValue}
                      setmobValue={setmobValue}
                      statusValue={statusValue}
                      setStatusValue={setStatusValue}
                      toggleText={toggleText}
                      setToggleText={setToggleText}
                      btnUpdate={btnUpdate}
                      setBtnUpdate={setBtnUpdate}
                      isDisabled={isDisabled}
                      setIsDisabled={setIsDisabled}
                      selectedRole={selectedRole}
                      fieldDisabled={fieldDisabled}
                      setFieldDisabled={setFieldDisabled}
                      rowSelection={rowSelection}
                      setSelectedList={setSelectedList}
                      selectedList={selectedList}
                    />
                  </TabPanel>
                  <TabPanel value="3" style={{ padding: '0px' }}>
                    <RepTable
                      value={value}
                      setValue={setValue}
                      repFields={repFields}
                      setRepFields={setRepFields}
                      // repHandler={repHandler}
                      setCurrentRepType={setCurrentRepType}
                      currentRepType={currentRepType}
                      vendorDetails={vendorDetails}
                      setVendorDetails={setVendorDetails}
                      available={available}
                      setAvailable={setAvailable}
                      mobNums={mobNums}
                      setmobNums={setmobNums}
                      statusValue={statusValue}
                      setStatusValue={setStatusValue}
                      toggleText={toggleText}
                      setToggleText={setToggleText}
                      btnUpdate={btnUpdate}
                      setBtnUpdate={setBtnUpdate}
                      isDisabled={isDisabled}
                      setIsDisabled={setIsDisabled}
                      selectedRole={selectedRole}
                      isViewModes={isViewModes}
                      setIsViewModes={setIsViewModes}
                      fieldDisabled={fieldDisabled}
                      setFieldDisabled={setFieldDisabled}
                      requiredError={requiredError}
                      setRequiredError={setRequiredError}
                      retailerList={retailerList}
                    />
                  </TabPanel>
                  <TabPanel value="4" style={{ padding: '0px' }}>
                    <ManagerTable
                      value={value}
                      setValue={setValue}
                      managerInputField={managerInputField}
                      setManagerInputField={setManagerInputField}
                      mobNums={mobNums}
                      setmobNums={setmobNums}
                      selectedRole={selectedRole}
                      isViewModes={isViewModes}
                      statusValue={statusValue}
                      setStatusValue={setStatusValue}
                      toggleText={toggleText}
                      setToggleText={setToggleText}
                      setIsViewModes={setIsViewModes}
                      fieldDisabled={fieldDisabled}
                      setFieldDisabled={setFieldDisabled}
                      rowSelection={rowSelection}
                      setSelectedList={setSelectedList}
                      selectedList={selectedList}
                    />
                  </TabPanel>
                  <TabPanel value="5" style={{ padding: '0px' }}>
                    <SupplierTable
                      value={value}
                      setValue={setValue}
                      supplierInputField={supplierInputField}
                      setSupplierInputField={setSupplierInputField}
                      supplierHandler={supplierHandler}
                      callCycleInput={callCycleInput}
                      setCallCycleInput={setCallCycleInput}
                      callCycleHandler={callCycleHandler}
                      selectedRole={selectedRole}
                      mobNums={mobNums}
                      setmobNums={setmobNums}
                      statusValue={statusValue}
                      setStatusValue={setStatusValue}
                      toggleText={toggleText}
                      setToggleText={setToggleText}
                      handleMobChange={handleMobChange}
                      isViewModes={isViewModes}
                      setIsViewModes={setIsViewModes}
                      retailerList={retailerList}
                      fieldDisabled={fieldDisabled}
                      setFieldDisabled={setFieldDisabled}
                      supplierList={supplierList}
                      rowSelection={rowSelection}
                      setSelectedList={setSelectedList}
                      selectedList={selectedList}
                    />
                  </TabPanel>
                  <TabPanel value="6" style={{ padding: '0px' }}>
                    <StoreTable
                      value={value}
                      setValue={setValue}
                      storeInputField={storeInputField}
                      setStoreInputField={setStoreInputField}
                      mobNums={mobNums}
                      setmobNums={setmobNums}
                      selectedRole={selectedRole}
                      isViewModes={isViewModes}
                      setIsViewModes={setIsViewModes}
                      retailerList={retailerList}
                      fieldDisabled={fieldDisabled}
                      setFieldDisabled={setFieldDisabled}
                      statusValue={statusValue}
                      setStatusValue={setStatusValue}
                      toggleText={toggleText}
                      setToggleText={setToggleText}
                      repList={repList}
                      rowSelection={rowSelection}
                      setSelectedList={setSelectedList}
                      selectedList={selectedList}
                      repListt={repListt}
                      setRepListt={setRepListt}
                      checkStoreVisit={checkStoreVisit}
                    />
                  </TabPanel>
                  <TabPanel value="7" style={{ padding: '0px' }}>
                    <RetailerTable
                      value={value}
                      setValue={setValue}
                      retailerInputField={retailerInputField}
                      setRetailerInputField={setRetailerInputField}
                      retailerInputHandler={retailerInputHandler}
                      selectedRole={selectedRole}
                      mobNums={mobNums}
                      setmobNums={setmobNums}
                      handleMobChange={handleMobChange}
                      isViewModes={isViewModes}
                      setIsViewModes={setIsViewModes}
                      fieldDisabled={fieldDisabled}
                      setFieldDisabled={setFieldDisabled}
                      requiredError={requiredError}
                      setRequiredError={setRequiredError}
                      statusValue={statusValue}
                      setStatusValue={setStatusValue}
                      toggleText={toggleText}
                      setToggleText={setToggleText}
                      rowSelection={rowSelection}
                      setSelectedList={setSelectedList}
                      selectedList={selectedList}
                    />
                  </TabPanel>
                  <TabPanel value="8" style={{ padding: '0px' }}>
                    <CallCycleTable
                      value={value}
                      setValue={setValue}
                      callCycleInput={callCycleInput}
                      setCallCycleInput={setCallCycleInput}
                      callCycleHandler={callCycleHandler}
                      selectedRole={selectedRole}
                      isViewModes={isViewModes}
                      setIsViewModes={setIsViewModes}
                      fieldDisabled={fieldDisabled}
                      finalData={finalData}
                      setFinalData={setFinalData}
                      finalLeftData={finalLeftData}
                      setFinalLeftData={setFinalLeftData}
                      finalRightData={finalRightData}
                      setFinalRightData={setFinalRightData}
                      storesValue={storesValue}
                      setStoresValue={setStoresValue}
                      storeCheckbox={storeCheckbox}
                      setStoreCheckbox={setStoreCheckbox}
                      unassigenedData={unassigenedData}
                      setunassignedData={setunassignedData}
                      statusValue={statusValue}
                      setStatusValue={setStatusValue}
                      toggleText={toggleText}
                      setToggleText={setToggleText}
                      requiredError={requiredError}
                      setRequiredError={setRequiredError}
                      selectedCountry={selectedCountry}
                      setSelectedCountry={setSelectedCountry}
                      selectedAusState={selectedAusState}
                      setSelectedAusState={setSelectedAusState}
                      countryOptions={countryOptions}
                      stateList={stateList}
                      setStateList={setStateList}
                      selectedStore={selectedStore}
                      setSelectedStore={setSelectedStore}
                      rowSelection={rowSelection}
                      setSelectedList={setSelectedList}
                      selectedList={selectedList}
                      setCallAssignedStores={setCallAssignedStores}
                      callAssignedStores={callAssignedStores}
                      listStoreCall={listStoreCall}
                    />
                  </TabPanel>
                  <TabPanel value="9" style={{ padding: '0px' }}>
                    <UkgTable
                      value={value}
                      setValue={setValue}
                      callCycleInput={callCycleInput}
                      setCallCycleInput={setCallCycleInput}
                      callCycleHandler={callCycleHandler}
                      selectedRole={selectedRole}
                      isViewModes={isViewModes}
                      setIsViewModes={setIsViewModes}
                      fieldDisabled={fieldDisabled}
                      finalData={finalData}
                      setFinalData={setFinalData}
                      finalLeftData={finalLeftData}
                      setFinalLeftData={setFinalLeftData}
                      finalRightData={finalRightData}
                      setFinalRightData={setFinalRightData}
                      storesValue={storesValue}
                      setStoresValue={setStoresValue}
                      storeCheckbox={storeCheckbox}
                      setStoreCheckbox={setStoreCheckbox}
                      unassigenedData={unassigenedData}
                      setunassignedData={setunassignedData}
                      statusValue={statusValue}
                      setStatusValue={setStatusValue}
                      toggleText={toggleText}
                      setToggleText={setToggleText}
                      requiredError={requiredError}
                      setRequiredError={setRequiredError}
                    />
                  </TabPanel>
                  <TabPanel value="10" style={{ padding: '0px' }}>
                    <Page>
                      <Form id="rep_form">
                        <FormGroup>
                          {/* <Row>
                        <Col md="3">
                          <label className="switch">
                            <Input
                              type="checkbox"
                              name="status"
                              id="myCheck"
                              // onClick={showContent}
                              onChange={repHandler}
                              checked={
                                repFields.status == 'inactive' ? false : true
                              }
                            />
                            <span className="slider round"></span>
                          </label>
                          <p style={{ fontSize: '14px' }}>
                            {repFields &&
                              repFields.status == 'inactive'
                              ? 'Inactive'
                              : 'Active'}
                          </p>
                        </Col>
                      </Row> */}

                          <Row className="adminrep-body" md="12">
                            <Col>
                              <Row className="admin-row" style={{ marginTop: '4rem' }}>
                                <Col md="6" style={{ margin: '0px' }}>
                                  <label>Add documents</label>
                                  <br />
                                  <Input
                                    onChange={(e) => addDoc(e)}
                                    id="fileUpload"
                                    type="file"
                                    multiple
                                    accept=".csv"
                                    style={{ border: 'none', outline: 'none' }}
                                  />
                                </Col>
                                <Col md={12}>
                                  <Button
                                    style={{ marginTop: '30px' }}
                                    onClick={addDocS}
                                    variant="outlined"
                                    color="primary"
                                    // disabled
                                  >
                                    <span>
                                      {' '}
                                      <b> Upload</b>{' '}
                                    </span>
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Form>
                    </Page>
                  </TabPanel>
                </TabContext>
              </Box>
              <br />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <img src={greyBanner} className="greyBanner"></img>
        </Col>
      </Row>
    </Page>
  );
};

export default AdminSetup;
