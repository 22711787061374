import Page from '../../components/Page';
import React, { useState } from 'react';
import { useEffect } from 'react';
import greyBanner from '../../assets/img/logo/Banner Grey.png';
import workImage from '../../assets/img/work.png';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
  Table,
} from 'reactstrap';
import axios from 'axios';
import ManagerTable from '../Tables/ManagerTable';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import validator from 'validator';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import Box from '@mui/material/Box';
import { isInvalidID, isInvalidUsername, isValidID } from './AddAdmin.js';

const RetailerSetup = props => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    managerInputField,
    managerInputHandler,
    setManagerInputField,
    mobNums,
    setmobNums,
    handleMobChange,
    isViewModes,
    setIsViewModes,
    fieldDisabled,
    setFieldDisabled,
    setRequiredError,
    requiredError,
    statusValue,
    setStatusValue,
  } = props;

  ////// toggle checkbox function show content
  const [toggleText, setToggleText] = React.useState('Inactive');
  const [validate, setValidate] = React.useState(false);
  const [emailError, setEmailError] = useState('');
  // const [statusValue, setStatusValue] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openUpd, setOpenUpd] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState('Email already in use');

  const [mobValue, setmobValue] = useState({
    mob_number: '',
  });

  ////////////// toggle checkbox to show content
  // const showContent = () => {
  //   var checkBox = document.getElementById('myCheck');
  //   setStatusValue(checkBox.checked);
  //   if (checkBox.checked == true) {
  //     setToggleText('Active');
  //   } else {
  //     setToggleText('Inactive');
  //   }
  // };

  //// Add Manager Data
  const addManager = () => {
    console.log(statusValue);
    if (managerInputField.id == '' || managerInputField.id == null) {
      setRequiredError({ managerId: 'Please Enter Manager ID' });
      return;
    }
    if (
      managerInputField.id != '' &&
      managerInputField.id !== null &&
      isInvalidID(managerInputField.id)
    ) {
      setRequiredError({
        managerId:
          'Invalid ID, please remove special characters or shorten the ID',
      });
      return;
    }
    if (props.idError != '') {
      return;
    }
    if (
      managerInputField.username == '' ||
      managerInputField.username == null
    ) {
      setRequiredError({ managerUsername: 'Please Enter Username' });
      return;
    }
    if (
      managerInputField.username != '' &&
      managerInputField.username != null &&
      isInvalidUsername(managerInputField.username)
    ) {
      setRequiredError({
        managerUsername:
          'Invalid username, please remove special characters, space or shorten the username',
      });
      return;
    }
    if (!validator.isEmail(managerInputField.email)) {
      setRequiredError({ managerEmail: 'Enter Valid Email' });
      return;
    }

    if (managerInputField.state == '' || managerInputField.state == null) {
      setRequiredError({ managerState: 'Please Select State' });
      return;
    }

    const data = {
      ...managerInputField,
      id: managerInputField.id.toUpperCase(),
      status: statusValue ? 'active' : 'inactive',
      mob_number: mobNums.manager_mob_number,
    };
    console.log('manager payload', data);
    axios({
      method: 'POST',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: process.env.REACT_APP_API_BASE + 'admin-portal',
      data: data,
    })
      .then(response => {
        console.log(response);
        if (response) {
          console.log('manager added');
          setOpen(true);
          setTimeout(() => {
            setOpen(false);
          }, 3000);
          // reset input field
          props.setStatusValue(true);
          props.setToggleText('Active');
          setManagerInputField({
            role: 'manager',
            status: 'inactive',
            id: '',
            job_title: '',
            first_name: '',
            last_name: '',
            state: '',
            email: '',
            username: '',
            comment: '',
            // mob_number: '',
          });
          setmobNums({
            ...mobNums,
            manager_mob_number: '',
          });
        }
      })
      .catch(error => {
        console.log('add manger fail...', error);
        console.log('add manger fail...', error.response);
        if (error) {
          setOpenError(true);
          if (error.response && error.response.data && error.response.data.message) {
            console.log('add manger fail message from backend ...', error.response.data.message);
            if (error.response.data.message == 'User account already exists.') {
              setErrorMsg('Username already exists.')
            } else {
              setErrorMsg(error.response.data.message)
            }

          }

          setManagerInputField({
            ...managerInputField,
            email: '',
          });
        }

        if (
          error.response.status == 401 &&
          error.response.data.message == 'The incoming token has expired'
        ) {
          alert('Session timed out, please login again.');
          localStorage.clear();
          sessionStorage.clear();
          localStorage.clear();
          // localStorage.removeItem( "accessLevel" );
          // reset page location
          console.log('logout succesfully');
          document.location.replace('/');
        }
      });
  };

  ////////// cancel input Field handler
  const cancelInputField = () => {
    props.setStatusValue(true);
    props.setToggleText('Active');
    setManagerInputField({
      role: 'manager',
      status: 'inactive',
      id: '',
      job_title: '',
      first_name: '',
      last_name: '',
      state: '',
      email: '',
      username: '',
      comment: '',
      mob_number: '',
    });

    setmobNums({
      manager_mob_number: '',
    });
    setIsViewModes({
      ...isViewModes,
      isViewMode_manager: false,
    });
    setFieldDisabled({
      ...fieldDisabled,
      fieldDisabled_manager: false,
    });
    setRequiredError('');
  };

  ///// Update Manager
  const updateManager = () => {
    // props.setIsDisabled(false);
    setOpenUpd(true);
    const data = {
      ...managerInputField,
      id: managerInputField.id.toUpperCase(),
      status: statusValue ? 'active' : 'inactive',

      mob_number: mobNums.manager_mob_number,
    };
    console.log('update manager payload', data);
    axios({
      method: 'PUT',

      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: process.env.REACT_APP_API_BASE + 'admin-portal',
      data: data,
    })
      .then(response => {
        console.log(response);
        console.log(response.data);
        if (response.data) {
          setOpenUpd(true);
          setTimeout(() => {
            setOpenUpd(false);
          }, 3000);
          setIsViewModes({
            ...isViewModes,
            isViewMode_manager: false,
          });
          setmobNums({
            manager_mob_number: '',
          });
          props.setStatusValue(true);
          props.setToggleText('Active');
          setManagerInputField({
            role: 'manager',
            status: 'inactive',
            id: '',
            job_title: '',
            first_name: '',
            last_name: '',
            state: '',
            email: '',
            username: '',
            comment: '',
          });

          // if the login manager change her own state, update the localstorage state value
          const curLocalState = localStorage.getItem('userState');
          const curLocalRole = localStorage.getItem('userRole');
          if (curLocalState && curLocalRole == 'manager') {
            const curLocalUsername = localStorage.getItem('username');
            if (curLocalUsername == data.username) {
              const updatedState = data.state;
              if (curLocalState != updatedState) {
                localStorage.setItem('userState', updatedState);
              }
            }
          }
        }
      })
      .catch(error => {
        console.log(error.response);
        console.log(error.response.status);
        if (
          error.response.status == 401 &&
          error.response.data.message == 'The incoming token has expired'
        ) {
          alert('Session timed out, please login again.');
          localStorage.clear();
          sessionStorage.clear();
          localStorage.clear();
          // localStorage.removeItem( "accessLevel" );
          // reset page location
          console.log('logout succesfully');
          document.location.replace('/');
        }
      });
  };

  const handleCloses = () => {
    setOpenError(false);
  };

  const handleClose = () => {
    setOpenError(false);
    setOpen(false);
    setOpenUpd(false);
    // setBtnUpdate(false);
    setIsViewModes({
      ...isViewModes,
      isViewMode_manager: false,
    });
    setmobNums({
      manager_mob_number: '',
    });
    props.setStatusValue(true);
    props.setToggleText('Active');
    setManagerInputField({
      role: 'manager',
      status: 'inactive',
      id: '',
      job_title: '',
      first_name: '',
      last_name: '',
      state: '',
      email: '',
      username: '',
      comment: '',
    });
  };

  useEffect(() => {
    const disabledVal = props.fieldDisabled.fieldDisabled_manager;

    const manager_form_inputs = document.querySelectorAll(
      '#manager_form input',
    );

    const manager_form_textarea = document.querySelectorAll(
      '#manager_form textarea',
    );
    // console.log(manager_form_inputs);
    for (let i = 0; i < manager_form_inputs.length; i++) {
      // store_id should not change
      if (
        manager_form_inputs[i].name !== 'id' &&
        manager_form_inputs[i].name !== 'username' &&
        manager_form_inputs[i].name !== 'email'
      ) {
        manager_form_inputs[i].disabled = disabledVal;
      }
    }

    for (let i = 0; i < manager_form_textarea.length; i++) {
      manager_form_textarea[i].disabled = disabledVal;
    }
  }, [props.fieldDisabled]);

  return (
    <>
      <Dialog open={open}>
        <DialogContent>
          <DialogContentText>
            <Row>
              <Col md={12}>
                <b style={{ color: 'red' }}>Thank you</b>
                <br />
                <br />
                <label style={{ color: 'red' }}>
                  Manager details successfully added
                </label>
              </Col>
            </Row>
            <br />
          </DialogContentText>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog open={openUpd}>
        <DialogContent>
          <DialogContentText>
            <Row>
              <Col md={12}>
                <b style={{ color: 'red' }}>Thank you</b>
                <br />
                <br />
                <label style={{ color: 'red' }}>
                  Manager details successfully updated
                </label>
              </Col>
            </Row>
            <br />
          </DialogContentText>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog open={openError}>
        <DialogContent>
          <DialogContentText>
            <Row>
              <Col md={12}>
                <b style={{ color: 'red' }}>Sorry</b>
                <br />
                <br />
                <label style={{ color: 'red' }}>{errorMsg}</label>
              </Col>
            </Row>
            <br />
          </DialogContentText>
          <Button onClick={handleCloses} variant="outlined" color="primary">
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Page>
        <Row style={{ height: '100%' }}>
          <div
            className="rep-container-1"
            style={{ width: '40rem', maxWidth: '40rem' }}
          >
            <div className="rep-container-1-inner">
              <Col xl={12} lg={12} md={12} style={{ paddingLeft: '0' }}>
                <Card id="userManagement">
                  <CardBody
                    style={{ background: '#fff', padding: '0px 18px 18px 0px' }}
                  >
                    <div id="tabCol" style={{ paddingTop: '0%' }}>
                      {/* <Row>
                      <Col md="6">
                        <b style={{ fontFamily: 'Arial Bold' }}>
                          MANAGER - SETUP{' '}
                        </b>
                      </Col>
                    </Row> */}

                      <Form id="manager_form">
                        <FormGroup>
                          {/* <Row>
                          <Col md="3">
                            <label className="switch">
                              <Input
                                type="checkbox"
                                name="status"
                                id="myCheck"
                                // onClick={showContent}
                                onChange={managerInputHandler}
                                checked={
                                  managerInputField.status == 'inactive'
                                    ? false
                                    : true
                                }
                              />
                              <span className="slider round"></span>
                            </label>
                            <p style={{ fontSize: '14px' }}>
                              {managerInputField &&
                                managerInputField.status == 'inactive'
                                ? 'Inactive'
                                : 'Active'}
                            </p>
                          </Col>
                        </Row> */}
                          <Row>
                            <Col md="6">
                              <label style={{ fontSize: '1vw' }}>
                                Manager ID
                                <span style={{ color: 'red' }}> *</span>{' '}
                              </label>
                              <Input
                                type="text"
                                name="id"
                                style={{ textTransform: 'uppercase' }}
                                onChange={managerInputHandler}
                                value={managerInputField.id}
                                disabled={isViewModes.isViewMode_manager}
                                className="admin-input"
                                placeholder="Manager ID"
                              />
                              <p style={{ color: 'red', fontSize: '12px' }}>
                                {requiredError.managerId}
                                {props.idError}
                              </p>
                            </Col>
                            <Col md="6">
                              <label style={{ fontSize: '1vw' }}>
                                Username<span style={{ color: 'red' }}> *</span>{' '}
                              </label>
                              <Input
                                type="text"
                                name="username"
                                onChange={managerInputHandler}
                                value={managerInputField.username}
                                disabled={isViewModes.isViewMode_manager}
                                className="admin-input"
                                placeholder="Username"
                              />
                              <p style={{ color: 'red', fontSize: '12px' }}>
                                {requiredError.managerUsername}
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6">
                              <label style={{ fontSize: '1vw' }}>
                                First Name<span style={{ color: 'red' }}></span>{' '}
                              </label>
                              <Input
                                type="text"
                                name="first_name"
                                onChange={managerInputHandler}
                                value={managerInputField.first_name}
                                className="admin-input"
                                placeholder="First Name"
                              />
                            </Col>
                            <Col md="6">
                              <label style={{ fontSize: '1vw' }}>
                                Last Name<span style={{ color: 'red' }}></span>{' '}
                              </label>
                              <Input
                                type="text"
                                name="last_name"
                                onChange={managerInputHandler}
                                value={managerInputField.last_name}
                                className="admin-input"
                                placeholder="Last Name"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6">
                              <label style={{ fontSize: '1vw' }}>
                                Job Title<span style={{ color: 'red' }}></span>{' '}
                              </label>
                              <Input
                                type="text"
                                name="job_title"
                                onChange={managerInputHandler}
                                value={managerInputField.job_title}
                                className="admin-input"
                                placeholder="Job Title"
                              />
                            </Col>
                            <Col md="6">
                              <label style={{ fontSize: '1vw' }}>
                                Email<span style={{ color: 'red' }}> *</span>{' '}
                              </label>
                              <Input
                                type="text"
                                name="email"
                                onChange={managerInputHandler}
                                value={managerInputField.email}
                                disabled={isViewModes.isViewMode_manager}
                                className="admin-input"
                                placeholder="Email"
                              />{' '}
                              <p style={{ color: 'red', fontSize: '12px' }}>
                                {requiredError.managerEmail}
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="12">
                              <label style={{ fontSize: '1vw' }}>
                                Comments<span style={{ color: 'red' }}></span>{' '}
                              </label>
                              <Input
                                type="textarea"
                                name="comment"
                                onChange={managerInputHandler}
                                value={managerInputField.comment}
                                className="admin-input"
                                placeholder="Comment/Notes"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6">
                              <label style={{ fontSize: '1vw' }}>
                                State<span style={{ color: 'red' }}>*</span>{' '}
                              </label>
                              <Input
                                type="select"
                                name="state"
                                className="admin-input"
                                placeholder="State"
                                onChange={managerInputHandler}
                                value={managerInputField.state}
                                required
                                style={{
                                  fontSize: "0.7rem",
                                  width: "100%",
                                  height:"60%",
                                  padding: "0.3rem 0.5rem",
                                  border: "1px solid #ced4da",
                                }}
                              >
                                <option selected value="">
                                  Select State
                                </option>
                                <option value="ACT">
                                  Australian Capital Territory
                                </option>
                                <option value="NSW">New South Wales</option>
                                <option value="NT">Northern Territory</option>
                                <option value="SA">South Australia</option>
                                <option value="QLD">Queensland</option>
                                <option value="TAS">Tasmania</option>
                                <option value="VIC">Victoria</option>
                                <option value="WA">Western Australia</option>
                                <option value="NI">North Island</option>
                                <option value="SI">South Island</option>
                              </Input>
                              <p style={{ color: 'red', fontSize: '12px' }}>
                                {requiredError.managerState}
                              </p>
                              {/* <Input
                                type="text"
                                name="state"
                                onChange={managerInputHandler}
                                value={managerInputField.state}
                                className="admin-input"
                                placeholder="State"
                              /> */}
                            </Col>

                            <Col md="6">
                              <label style={{ fontSize: '1vw' }}>
                                Mobile Number
                                <span style={{ color: 'red' }}></span>{' '}
                              </label>
                              <PhoneInput
                                className="phnclass admin-input PhoneInput"
                                name="mob_number"
                                onChange={val =>
                                  handleMobChange(val, 'manager_mob_number')
                                }
                                value={mobNums.manager_mob_number}
                                placeholder="Mobile Phone"
                              // onChange={mobileValue}
                              // value={mobValue.mob_number}
                              // onChange={managerInputHandler}
                              // value={managerInputField.mob_number}
                              />
                            </Col>
                          </Row>
                          <br />
                          <Row>
                            {isViewModes.isViewMode_manager == false ? (
                              <Col md={12} className="martop">
                                <Button
                                  onClick={addManager}
                                  variant="outlined"
                                  color="primary"
                                >
                                  <span>
                                    {' '}
                                    <b> Add Manager</b>{' '}
                                  </span>
                                </Button>
                                <Button
                                  onClick={cancelInputField}
                                  variant="outlined"
                                  color="primary"
                                >
                                  <span>
                                    {' '}
                                    <b>Cancel</b>{' '}
                                  </span>
                                </Button>
                              </Col>
                            ) : (
                              <Col md={12} className="martop">
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={updateManager}
                                >
                                  <span>
                                    {' '}
                                    <b> Update</b>{' '}
                                  </span>
                                </Button>
                                <Button
                                  onClick={cancelInputField}
                                  variant="outlined"
                                  color="primary"
                                >
                                  <span>
                                    {' '}
                                    <b>Cancel</b>{' '}
                                  </span>
                                </Button>
                              </Col>
                            )}
                          </Row>
                        </FormGroup>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </div>
          </div>
          <Col
            className="admin-svg"
            style={{ marginBottom: '0', maxWidth: '50rem' }}
          >
            <div
              className="rep-container-2 hide-scrollbar"
              style={{ position: 'relative', height: '100%' }}
            >
              <img
                src={workImage}
                style={{
                  width: '15rem',
                  position: 'absolute',
                  bottom: '5rem',
                  right: '10%',
                }}
              ></img>

              {/* <svg style={{ position: 'absolute', bottom: '3rem', left: '0', width: '100%' }} xmlns="http://www.w3.org/2000/svg" width="782.796" height="134.888" viewBox="0 0 782.796 134.888">
                <path id="Path_77" data-name="Path 77" d="M7030.418,1674.8c-96.372,205-710.066-16.283-710.066-16.283l-72.083-30.542" transform="translate(-6248.074 -1627.517)" fill="none" stroke="#ff1313" stroke-width="1"/>
              </svg> */}
              <svg
                style={{
                  position: 'absolute',
                  bottom: '0',
                  left: '3px',
                  width: '100%',
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="1255.939"
                height="258.104"
                viewBox="0 0 1255.939 258.104"
              >
                <path
                  id="Path_238"
                  data-name="Path 238"
                  d="M7503.517,1717.894c-154.665,393.656-1139.565-31.267-1139.565-31.267l-115.683-58.649"
                  transform="translate(-6248.043 -1627.531)"
                  fill="none"
                  stroke="#ff1313"
                  stroke-width="1"
                />
              </svg>
            </div>
          </Col>
        </Row>
      </Page>
    </>
  );
};

export default RetailerSetup;
