import Page from '../../components/Page';
import React, { useState } from 'react';
import { useEffect } from 'react';
import greyBanner from '../../assets/img/logo/Banner Grey.png';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
  Table,
} from 'reactstrap';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import axios from 'axios';
import RetailerTable from '../Tables/RetailerTable';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import validator from 'validator';
import { isInvalidID, isInvalidUsername, isValidID } from './AddAdmin.js';

const RetailerSetup = props => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const {
    retailerInputField,
    setRetailerInputField,
    retailerInputHandler,
    mobNums,
    setmobNums,
    handleMobChange,
    isViewModes,
    setIsViewModes,
    fieldDisabled,
    setFieldDisabled,
    requiredError,
    setRequiredError,
  } = props;

  ////// toggle checkbox function show content
  const [toggleText, setToggleText] = React.useState('Inactive');
  const [validate, setValidate] = React.useState(false);
  const [btnUpdate, setBtnUpdate] = React.useState(false);
  const [emailError, setEmailError] = React.useState('');
  // const [statusValue, setStatusValue] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openUpd, setOpenUpd] = React.useState(false);

  ////////////// toggle checkbox to show content
  // const showContent = () => {
  //   var checkBox = document.getElementById( 'myCheck' );
  //   setStatusValue( checkBox.checked );
  //   if ( checkBox.checked == true ) {
  //     setToggleText( 'Active' );
  //   } else {
  //     setToggleText( 'Inactive' );
  //   }
  // };

  // const [requiredError, setRequiredError] = React.useState({
  //   retailerId: '',
  //   retailerEmail: '',
  // });

  //// Add Reatiler Data
  const addRetailer = () => {
    if (retailerInputField.id == '' || retailerInputField.id == null) {
      setRequiredError({ retailerID: 'Please Enter Retailer ID' });
      return;
    }
    if (
      retailerInputField.id != '' &&
      retailerInputField.id !== null &&
      isInvalidID(retailerInputField.id)
    ) {
      setRequiredError({
        retailerID:
          'Invalid ID, please remove special characters or shorten the ID',
      });
      return;
    }
    if (
      retailerInputField.country == '' ||
      retailerInputField.country == null
    ) {
      setRequiredError({ country: 'Please Select Country' });
      return;
    }
    if (retailerInputField.state == '' || retailerInputField.state == null) {
      setRequiredError({ state: 'Please Select State' });
      return;
    }
    if (props.idError != '') {
      return;
    }
    if (
      retailerInputField.full_company_name == '' ||
      retailerInputField.full_company_name == null
    ) {
      setRequiredError({
        retailerFullCompany: 'Please Enter Full Company Name',
      });
      return;
    }
    // if (!validator.isEmail(retailerInputField.email)) {
    //   setRequiredError({ retailerEmail: 'Enter Valid Email' });
    //   return;
    // }
    const data = {
      ...retailerInputField,
      mob_number: mobNums.contact_mob_number,
      id: retailerInputField.id.toUpperCase(),
      status: props.statusValue ? 'active' : 'inactive',
      ph_number: mobNums.retailer_ph_number,
    };
    console.log('add retailer payload', data);

    axios({
      method: 'POST',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: process.env.REACT_APP_API_BASE + 'admin-portal/retailer',
      data: [data],
    })
      .then(response => {
        console.log(response);
        if (response.status == 200) {
          console.log('retailer added');
          setOpen(true);
          setTimeout(() => {
            setOpen(false);
          }, 3000);
          // reset input field
          props.setStatusValue(true);
          props.setToggleText('Active');
          setRetailerInputField({
            role: 'retailer',
            status: 'inactive',
            id: '',
            full_company_name: '',
            address: '',
            state: '',
            country: '',
            first_name: '',
            last_name: '',
            position: '',
            email: '',
            comment: '',
          });
          setmobNums({
            ...mobNums,
            contact_mob_number: '',
            retailer_ph_number: '',
          });
        }
      })
      .catch(error => {
        console.log('add retailer fail...', error);
      });
    setOpen(true);
    console.log('add retailer payload', data);
  };
  ///// Update Retailer
  const updateRetailer = () => {
    const data = {
      ...retailerInputField,
      mob_number: mobNums.contact_mob_number,
      ph_number: mobNums.retailer_ph_number,
      id: retailerInputField.id.toUpperCase(),
      status: props.statusValue ? 'active' : 'inactive',
    };
    console.log('update retailer payload', data);
    axios({
      method: 'PUT',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: process.env.REACT_APP_API_BASE + 'admin-portal/retailer',
      data: [data],
    })
      .then(response => {
        console.log(response);
        console.log(response.data);
        if (response.data) {
          setOpenUpd(true);
          setTimeout(() => {
            setOpenUpd(false);
          }, 3000);
          setIsViewModes({
            ...isViewModes,
            isViewMode_retailer: false,
          });
          setmobNums({
            retailer_ph_number: '',
            contact_mob_number: '',
          });
          props.setStatusValue(true);
          props.setToggleText('Active');
          setRetailerInputField({
            role: 'retailer',
            status: 'inactive',
            id: '',
            full_company_name: '',
            address: '',
            state: '',
            country: '',
            first_name: '',
            last_name: '',
            position: '',
            email: '',
            comment: '',
          });
        }
      })
      .catch(error => {
        console.log(error.response);
        console.log(error.response.status);
        if (
          error.response.status == 401 &&
          error.response.data.message == 'The incoming token has expired'
        ) {
          alert('Session timed out, please login again.');
          localStorage.clear();
          sessionStorage.clear();
          localStorage.clear();
          // localStorage.removeItem( "accessLevel" );
          // reset page location
          console.log('logout succesfully');
          document.location.replace('/');
        }
      });
    setBtnUpdate(false);
  };
  const [value, setValue] = React.useState('1');

  const handleClose = () => {
    props.setStatusValue(true);
    props.setToggleText('Active');
    setOpen(false);
    setOpenUpd(false);
    setRetailerInputField({
      role: 'retailer',
      status: 'inactive',
      id: '',
      full_company_name: '',
      address: '',
      state: '',
      country: '',
      first_name: '',
      last_name: '',
      position: '',
      email: '',
      comment: '',
    });
    setmobNums({
      ...mobNums,
      contact_mob_number: '',
      retailer_ph_number: '',
    });
    setIsViewModes({
      ...isViewModes,
      isViewMode_retailer: false,
    });
  };

  ////////// cancel input Field handler
  const cancelInputField = () => {
    props.setStatusValue(true);
    props.setToggleText('Active');
    setRetailerInputField({
      role: 'retailer',
      status: 'inactive',
      id: '',
      full_company_name: '',
      address: '',
      state: '',
      country: '',
      first_name: '',
      last_name: '',
      position: '',
      email: '',
      comment: '',
    });
    setmobNums({
      ...mobNums,
      contact_mob_number: '',
      retailer_ph_number: '',
    });
    setIsViewModes({
      ...isViewModes,
      isViewMode_retailer: false,
    });
    setFieldDisabled({
      ...fieldDisabled,
      fieldDisabled_retailer: false,
    });
    setRequiredError('');
  };

  // to disabled every field when under view mode
  useEffect(() => {
    const disabledVal = props.fieldDisabled.fieldDisabled_retailer;

    const retailer_form_inputs = document.querySelectorAll(
      '#retailer_form input',
    );
    const retailer_form_selects = document.querySelectorAll(
      '#retailer_form select',
    );
    // console.log(store_form_inputs);
    for (let i = 0; i < retailer_form_inputs.length; i++) {
      // retailer should not change
      if (retailer_form_inputs[i].name !== 'id') {
        retailer_form_inputs[i].disabled = disabledVal;
      }
    }
    for (let i = 0; i < retailer_form_selects.length; i++) {
      retailer_form_selects[i].disabled = disabledVal;
    }
  }, [props.fieldDisabled]);

  const renderStateOptions = () => {
    console.log(
      'renderStateOptions',
      retailerInputField,
      retailerInputField.country.length == 0,
    );
    return (
      <Input
        type="select"
        name="state"
        className="admin-input"
        placeholder="State"
        onChange={retailerInputHandler}
        value={retailerInputField.state}
        required
        style={{...componentStyle.selectFieldStyle, height: "70%"}}
      >
        {retailerInputField.country.length == 0 ? (
          <option selected value="">
            Please Select Country First
          </option>
        ) : retailerInputField.country.toLowerCase() == 'australia' ? (
          <>
            <option selected value="">
              Select State
            </option>
            <option value="ACT">Australian Capital Territory</option>
            <option value="NSW">New South Wales</option>
            <option value="NT">Northern Territory</option>
            <option value="SA">South Australia</option>
            <option value="QLD">Queensland</option>
            <option value="TAS">Tasmania</option>
            <option value="VIC">Victoria</option>
            <option value="WA">Western Australia</option>
          </>
        ) : (
          <>
            <option selected value="">
              Select State
            </option>
            <option value="NI">North Island</option>
            <option value="SI">South Island</option>
          </>
        )}
      </Input>
    );
  };

  const componentStyle = {
    selectFieldStyle: {
        fontSize: "0.7rem",
        width: "100%",
        height:"50%",
        padding: "0.3rem 0.5rem",
        border: "1px solid #ced4da",
    }
  }

  return (
    <>
      <Dialog open={open}>
        <DialogContent>
          <DialogContentText>
            <Row>
              <Col md={12}>
                <b style={{ color: 'red' }}>Thank you</b>
                <br />
                <br />
                <label style={{ color: 'red' }}>
                  Retailer details successfully added
                </label>
              </Col>
            </Row>
            <br />
          </DialogContentText>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog open={openUpd}>
        <DialogContent>
          <DialogContentText>
            <Row>
              <Col md={12}>
                <b style={{ color: 'red' }}>Thank you</b>
                <br />
                <br />
                <label style={{ color: 'red' }}>
                  Retailer details successfully updated
                </label>
              </Col>
            </Row>
            <br />
          </DialogContentText>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Page>
        <Row>
          <Col xl={12} lg={12} md={12} style={{ paddingLeft: '0' }}>
            <Card id="userManagement">
              <CardBody
                style={{ background: '#fff', padding: '0px 18px 18px 0px' }}
              >
                <div id="tabCol" style={{ paddingTop: '1%' }}>
                  {/* <Row>
                    <Col md="6">
                      <b style={{ fontFamily: 'Arial Bold' }}>
                        RETAILER - SETUP{' '}
                      </b>
                    </Col>
                  </Row> */}
                  <Form id="retailer_form">
                    <FormGroup>
                      {/* <Row>
                        <Col md="3">
                          <label className="switch">
                            <Input
                              type="checkbox"
                              name="status"
                              id="myCheck"
                              onClick={showContent}
                              onChange={retailerInputHandler}
                              checked={
                                retailerInputField.status == 'inactive'
                                  ? false
                                  : true
                              }
                            />
                            <span className="slider round"></span>
                          </label>
                          <p style={{ fontSize: '14px', display: "inline-block", verticalAlign: "text-bottom", marginLeft: "20px" }}>
                            {retailerInputField &&
                              retailerInputField.status == 'inactive'
                              ? 'Inactive'
                              : 'Active'}
                          </p>
                        </Col>
                      </Row> */}

                      <Row md="12">
                        <Col>
                          <Row style={{ marginLeft: '2rem' }}>
                            <h6>Business Info </h6>
                          </Row>
                          <div
                            className="rep-container-1 hide-scrollbar"
                            style={{ width: '100%' }}
                          >
                            <Row>
                              <Col md="6">
                                <label>
                                  Retailer ID
                                  <span style={{ color: 'red' }}> *</span>{' '}
                                </label>
                                <Input
                                  type="text"
                                  className="admin-input"
                                  name="id"
                                  required
                                  onChange={retailerInputHandler}
                                  value={retailerInputField.id}
                                  disabled={isViewModes.isViewMode_retailer}
                                  style={{ textTransform: 'uppercase' }}
                                  placeholder="Retailer ID"
                                />
                                <p style={{ color: 'red', fontSize: '12px' }}>
                                  {requiredError.retailerID}
                                  {props.idError}
                                </p>
                              </Col>
                              <Col md="6">
                                <label>
                                  Full Company Name
                                  <span style={{ color: 'red' }}> *</span>{' '}
                                </label>
                                <Input
                                  type="text"
                                  className="admin-input"
                                  name="full_company_name"
                                  onChange={retailerInputHandler}
                                  value={retailerInputField.full_company_name}
                                  placeholder="Full Company Name"
                                />
                                <p style={{ color: 'red', fontSize: '12px' }}>
                                  {requiredError.retailerFullCompany}
                                </p>
                              </Col>
                              <Col md="12">
                                <label>
                                  Address<span style={{ color: 'red' }}></span>{' '}
                                </label>
                                <Input
                                  type="text"
                                  name="address"
                                  className="admin-input"
                                  onChange={retailerInputHandler}
                                  value={retailerInputField.address}
                                  placeholder="Address"
                                />
                              </Col>
                              <Col md="6">
                                <label>
                                  Country
                                  <span style={{ color: 'red' }}> *</span>
                                </label>
                                <Input
                                  type="select"
                                  name="country"
                                  className="admin-input"
                                  placeholder="country"
                                  onChange={retailerInputHandler}
                                  value={retailerInputField.country}
                                  required
                                  style={{...componentStyle.selectFieldStyle, height: "70%"}}
                                >
                                  <option selected value="">
                                    Select country
                                  </option>
                                  <option value="Australia">Australia</option>
                                  <option value="New Zealand">
                                    New Zealand
                                  </option>
                                </Input>
                                <p style={{ color: 'red', fontSize: '12px' }}>
                                  {requiredError.country}
                                </p>
                              </Col>
                              <Col md="6">
                                <label>
                                  State<span style={{ color: 'red' }}> *</span>
                                </label>
                                {renderStateOptions()}
                                {/* <Input
                                  type="select"
                                  name="state"
                                  className="admin-input"
                                  placeholder="State"
                                  onChange={retailerInputHandler}
                                  value={retailerInputField.state}
                                  required
                                >
                                  <option selected value="">Select State</option>
                                  <option value="ACT">
                                    Australian Capital Territory
                                  </option>
                                  <option value="NSW">New South Wales</option>
                                  <option value="NT">
                                    Northern Territory
                                  </option>
                                  <option value="SA">South Australia</option>
                                  <option value="QLD">Queensland</option>
                                  <option value="TAS">Tasmania</option>
                                  <option value="VIC">Victoria</option>
                                  <option value="WA">
                                    Western Australia
                                  </option>
                                  <option value="NI">North Island</option>
                                  <option value="SI">South Island</option>
                                </Input> */}
                                <p style={{ color: 'red', fontSize: '12px' }}>
                                  {requiredError.state}
                                </p>
                              </Col>
                              <Col md="6">
                                <label>
                                  Phone Number
                                  <span style={{ color: 'red' }}></span>{' '}
                                </label>
                                <PhoneInput
                                  className="phnclass admin-input"
                                  style={{
                                    background:
                                      '#E6E6E6 0% 0% no-repeat padding-box',
                                    borderRadius: '32px',
                                  }}
                                  name="retailer_ph_number"
                                  // onChange={phoneValueNum}
                                  // value={phValue.ph_number}
                                  onChange={val =>
                                    handleMobChange(val, 'retailer_ph_number')
                                  }
                                  value={mobNums.retailer_ph_number}
                                  placeholder="Phone Number"
                                />
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col>
                          <Row style={{ marginLeft: '2rem' }}>
                            <h6>Contact Info </h6>
                          </Row>
                          <div className="rep-container-21  hide-scrollbar">
                            <Row>
                              <Col md="6">
                                <label>
                                  First Name
                                  <span style={{ color: 'red' }}></span>{' '}
                                </label>
                                <Input
                                  type="text"
                                  name="first_name"
                                  onChange={retailerInputHandler}
                                  value={retailerInputField.first_name}
                                  className="admin-input"
                                  placeholder="First Name"
                                />
                                <p
                                  style={{
                                    color: 'red',
                                    fontSize: '12px',
                                    visibility: 'hidden',
                                  }}
                                >
                                  {requiredError.retailerID ||
                                    requiredError.full_company_name}
                                </p>
                              </Col>
                              <Col md="6">
                                <label>
                                  Last Name
                                  <span style={{ color: 'red' }}></span>{' '}
                                </label>
                                <Input
                                  type="text"
                                  name="last_name"
                                  onChange={retailerInputHandler}
                                  value={retailerInputField.last_name}
                                  className="admin-input"
                                  placeholder="Last Name"
                                />
                              </Col>
                              <Col md="6">
                                <label>
                                  Position
                                  <span style={{ color: 'red' }}></span>{' '}
                                </label>
                                <Input
                                  type="text"
                                  name="position"
                                  onChange={retailerInputHandler}
                                  value={retailerInputField.position}
                                  className="admin-input"
                                  placeholder="Position"
                                />
                              </Col>
                              <Col md="6">
                                <label>
                                  Contact Mobile
                                  <span style={{ color: 'red' }}></span>{' '}
                                </label>
                                <PhoneInput
                                  className="phnclass admin-input"
                                  name="contact_mob_number"
                                  placeholder="Mobile Number"
                                  style={{
                                    background:
                                      '#E6E6E6 0% 0% no-repeat padding-box',
                                    borderRadius: '32px',
                                  }}
                                  // onChange={phoneValue}
                                  // value={phValue.cont_mob}
                                  onChange={val =>
                                    handleMobChange(val, 'contact_mob_number')
                                  }
                                  value={mobNums.contact_mob_number}
                                />
                              </Col>
                              <Col md="12">
                                <label>
                                  Email
                                  {/* <span style={{ color: 'red' }}> *</span>{' '} */}
                                </label>
                                <Input
                                  type="email"
                                  name="email"
                                  onChange={retailerInputHandler}
                                  value={retailerInputField.email}
                                  className="admin-input"
                                  placeholder="Email"
                                />
                                {/* <p style={{ color: 'red', fontSize: '12px' }}>
                                  {requiredError.retailerEmail}
                                </p> */}
                                <p
                                  style={{
                                    color: 'red',
                                    fontSize: '12px',
                                    visibility: 'hidden',
                                  }}
                                >
                                  {requiredError.country || requiredError.state}
                                </p>
                              </Col>
                              <Col md="12">
                                <label>
                                  Comments<span style={{ color: 'red' }}></span>{' '}
                                </label>
                                <Input
                                  type="textarea"
                                  name="comment"
                                  className="admin-input"
                                  placehoder="Comment"
                                  onChange={retailerInputHandler}
                                  value={retailerInputField.comment}
                                  placeholder="Comments"
                                />
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <br />
                      <Row style={{ padding: '0px 0px 0px 40px' }}>
                        {isViewModes.isViewMode_retailer == false ? (
                          <Col md={12} className="martop">
                            <Button
                              onClick={addRetailer}
                              variant="outlined"
                              color="primary"
                            >
                              <span>
                                {' '}
                                <b> Add Retailer</b>{' '}
                              </span>
                            </Button>
                            <Button
                              onClick={cancelInputField}
                              variant="outlined"
                              color="primary"
                            >
                              <span>
                                {' '}
                                <b>Cancel</b>{' '}
                              </span>
                            </Button>
                          </Col>
                        ) : (
                          <Col md={12} className="martop">
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={updateRetailer}
                            >
                              <span>
                                {' '}
                                <b> Update</b>{' '}
                              </span>
                            </Button>
                            <Button
                              onClick={cancelInputField}
                              variant="outlined"
                              color="primary"
                            >
                              <span>
                                {' '}
                                <b>Cancel</b>{' '}
                              </span>
                            </Button>
                          </Col>
                        )}
                      </Row>
                    </FormGroup>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* <Row style={{ padding: '20px 0px 0px 55px' }}>
          {isViewModes.isViewMode_retailer == false ? (
            <Col md={12} className="martop">
              <Button
                onClick={addRetailer}
                variant="outlined"
                color="primary"
              >
                <span>
                  {' '}
                  <b> Add Retailer</b>{' '}
                </span>
              </Button>
              <Button
                onClick={cancelInputField}
                variant="outlined"
                color="primary"
              >
                <span>
                  {' '}
                  <b>Cancel</b>{' '}
                </span>
              </Button>
            </Col>
          ) : (
            <Col md={12} className="martop">
              <Button
                variant="outlined"
                color="primary"
                onClick={updateRetailer}
              >
                <span>
                  {' '}
                  <b> Update</b>{' '}
                </span>
              </Button>
              <Button
                onClick={cancelInputField}
                variant="outlined"
                color="primary"
              >
                <span>
                  {' '}
                  <b>Cancel</b>{' '}
                </span>
              </Button>
            </Col>
          )}
        </Row> */}
      </Page>
    </>
  );
};

export default RetailerSetup;
