import React, { useEffect, useState, useRef } from 'react';
import { Table, Space } from 'antd';
import 'antd/dist/antd.min.css';
import {
  Input,

  Button,

} from 'reactstrap';
import axios from 'axios';
import moment from 'moment';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/react';
import { Link, useNavigate } from 'react-router-dom';

const override = css`
  display: block;
  margin: 20% auto;
  border-color: #fff;
`;

const AdhocRequest = () => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState();
  const searchInput = useRef(null);
  const [searchedColumn, setSearchedColumn] = useState('');
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = React.useState(false);

  const getTableData = () => {
    setLoading(true);
    axios({
      method: 'GET',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url:
        process.env.REACT_APP_API_BASE + 'supplier-portal/supplier-adhoc-tasks',
    })
      .then(response => {
        console.log('all tasks', response);
        if (response) {
          if (response.data != null) {
            const result = response.data.body.Items;

            if (Array.isArray(result) && result.length > 0) {
              // only completed task will be displayed in admin portal
              const list = [];
              result.forEach(item => {
                if (
                  item.task_status == 'completed' ||
                  item.task_status == 'approved' ||
                  item.task_status == 'created'
                ) {
                  list
                    .sort((a, b) => a.username.localeCompare(b.username))
                    .push({
                      ...item,
                      display_date: moment(item.create_date).format(
                        'DD/MM/YYYY',
                      ),
                    });
                }
              });
              console.log('all completed tasks', list);
              setTableData(list);
              setFilteredData(list);
            } else {
              console.log('No result found');
            }
          }
        } else {
          console.log('not 200 status', response);
        }
        setLoading(false);
      })
      .catch(error => {
        console.log(error.response);
        console.log(error.response.status);
        if (
          error.response.status == 401 &&
          error.response.data.message == 'The incoming token has expired'
        ) {
          alert('Session timed out, please login again.');
          localStorage.clear();
          sessionStorage.clear();
          localStorage.clear();
          // reset page location
          console.log('logout succesfully');
          document.location.replace('/');
        }
      });
  };

  useEffect(() => {
    getTableData();
  }, []);



  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => {
            console.log('cahngeddd', e.target.value);
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : '',
    // TODO: if in future, no need, remove this
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => { }, 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const filter = data => {
    data.map(rec => {
      console.log('rec====>', rec);
      setFilteredData(rec);
    });
  };

  const handleClick = (record) => {
    if (record) {
      navigate('/requestAdhocTask', { state: { rec: record } });
    } else {
      console.warn('Record is not available');
    }
  }; 

  const columns = [
    {
      title: 'Date',
      dataIndex: 'display_date',
      align: 'center',
      ...getColumnSearchProps('display_date'),
    },
    {
      title: 'Request ID',
      dataIndex: 'task_id',
      align: 'center',
      ...getColumnSearchProps('task_id'),
    },
    {
      title: 'Supplier',
      dataIndex: 'username',
      align: 'center',
      ...getColumnSearchProps('username'),
    },
    {
      title: 'Request Name',
      dataIndex: 'taskName',
      align: 'center',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      align: 'center',
      render: (text, record) => {
        return (
          <>
            
              <svg
                height="18"
                width="18"
                fill="#000000"
                xmlns="http://www.w3.org/2000/svg"
                data-name="Layer 3"
                viewBox="0 0 24 24"
                x="0px"
                y="0px"
                onClick={() => handleClick(record)}
              >
                <path d="M20.5,9.873A11.123,11.123,0,0,0,12,6,11.123,11.123,0,0,0,3.5,9.873L2.239,11.351a1,1,0,0,0,0,1.3L3.5,14.127A11.123,11.123,0,0,0,12,18a11.125,11.125,0,0,0,8.5-3.872l1.261-1.479a1,1,0,0,0,0-1.3Zm-1.521,2.958A9.134,9.134,0,0,1,12,16a9.131,9.131,0,0,1-6.979-3.17L4.314,12l.707-.83h0A9.134,9.134,0,0,1,12,8a9.131,9.131,0,0,1,6.979,3.17l.707.83Z"></path>
                <path d="M12,9a3,3,0,1,0,3,3A3,3,0,0,0,12,9Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,12,13Z"></path>
              </svg>
           
          </>
        );
      },
    },
  ];

  return (
    <div className="antdTable centerTd">
      {loading ? (
        <div className="overlay-box">
          <ClipLoader
            color={'green'}
            loading={loading}
            css={override}
            size={150}
          />
        </div>
      ) : (

        <Table
          columns={columns}
          dataSource={tableData}
          onChange={(
            pagination,
            filters,
            sorter,
            currentPageData,
          ) => filter(currentPageData.currentDataSource)}
          pagination={{
            total: filteredData.length,
            showTotal: total => `Total ${total} items`,
            pageSizeOptions: ['10', '50', '100', '200', '500'],
          }}
        />
      )}
    </div>
  );
};

export default AdhocRequest;
