import Page from '../../components/Page';
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
  Table,
} from 'reactstrap';
import 'react-phone-number-input/style.css';
import 'react-datepicker/dist/react-datepicker.css';
import Box from '@mui/material/Box';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import moment from 'moment';
import { RiVisaLine } from 'react-icons/ri';
import axios from 'axios';
import parse from 'html-react-parser';

const RequestAdhocTask = props => {
  const location = useLocation();
  const requestTask = location?.state.rec;
  // const requestValue = props.location.state.rec;
  // console.log(requestTask);

  useEffect(() => {
    window.scrollTo(0, 0);
    // setRequestValue(requestTask);
    getRequestDetail();
  }, [requestTask]);

  const getRequestDetail = () => {
    const task_id = requestTask?.task_id;
    const username = requestTask?.username;
    console.log('get detail for ', task_id, username);
    axios({
      method: 'GET',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url:
        process.env.REACT_APP_API_BASE +
        'supplier-portal/supplier-adhoc-tasks?username=' +
        username +
        '&task_id=' +
        task_id,
    })
      .then(response => {
        console.log(response);
        if (response.status == 200) {
          console.log('ad hoc updated');
          const rec = response.data.body.Items[0];
          setRequestValue(rec);
        }
      })
      .catch(error => {
        console.log(error.response);
        console.log(error.response.status);
        if (
          error.response.status == 401 &&
          error.response.data.message == 'The incoming token has expired'
        ) {
          alert('Session timed out, please login again.');
          localStorage.clear();
          sessionStorage.clear();
          localStorage.clear();
          // localStorage.removeItem( "accessLevel" );
          // reset page location
          console.log('logout succesfully');
          document.location.replace('/');
        }
      });
  };

  const [open, setOpen] = React.useState({
    open: false,
    title: '',
    text: '',
  });
  const [openNote, setOpenNote] = React.useState(false);
  const [requestValue, setRequestValue] = useState(requestTask);
  const [isViewMode, setIsViewMode] = useState(true);
  const [moreInfo, setMoreInfo] = useState('');

  //  Request for More Info
  const askMoreInfo = e => {
    e.preventDefault();
    setOpenNote(true);
  };

  const handleClose = () => {
    setOpen({
      open: false,
      title: '',
      text: '',
    });
    setOpenNote(false);
  };

  const updateRequest = (e, action) => {
    let data;

    // construct payload for PUT call
    // approve or approve/create task
    if (action === 'approve' || action === 'approve_and_create') {
      data = {
        ...requestValue,
        appStatus: 'approved',
        task_status: 'approved',
      };
    }
    // ask more info
    else {
      if (moreInfo.length == 0) {
        setOpen({
          open: true,
          title: 'Sorry',
          text: 'Please enter information',
        });
        setTimeout(() => {
          handleClose();
        }, 3000);
        return;
      }
      data = {
        ...requestValue,
        admin_additional_info: [
          { additinal_info: moreInfo, additional_note: '' },
        ],
        appStatus: 'pending',
        task_status: 'pending',
      };
    }
    console.log('updateRequest payload', data);

    // task already approved before, go to create task page
    if (action === 'approve_and_create') {
      if (requestValue.task_status == 'approved') {
        console.log(
          ' adhoc task is already approved, no api call, go to create task page',
        );
        props.history.push({
          pathname: '/task',
          state: { requestValue, tabValue: '1' },
        });

        return;
      }
    }

    axios({
      method: 'PUT',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url:
        process.env.REACT_APP_API_BASE + 'supplier-portal/supplier-adhoc-tasks',
      data: data,
    })
      .then(response => {
        console.log(response);
        if (response.status == 200) {
          console.log('ad hoc updated');

          setOpen({
            open: true,
            title: 'Thank you',
            text: 'Request successfully sent',
          });
          setTimeout(() => {
            handleClose();
          }, 3000);
          getRequestDetail();
          // only approve and create button, need to go to task page
          if (action === 'approve_and_create') {
            // props.history.goBack();
            console.log(' req value', requestValue);
            props.history.push({
              pathname: '/task',
              state: { requestValue, tabValue: '1' },
            });
          }
        }
      })
      .catch(error => {
        console.log(error.response);
        console.log(error.response.status);
        if (
          error.response.status == 401 &&
          error.response.data.message == 'The incoming token has expired'
        ) {
          alert('Session timed out, please login again.');
          localStorage.clear();
          sessionStorage.clear();
          localStorage.clear();
          // localStorage.removeItem( "accessLevel" );
          // reset page location
          console.log('logout succesfully');
          document.location.replace('/');
        }
      });
  };

  // old version of update request
  // const updateRequest = (e, action) => {
  //   let data;
  //   if (action === 'approve' || action === 'approve_and_create') {
  //     data = {
  //       ...requestValue,
  //       appStatus: 'approved',
  //       task_status: 'approved',
  //     };
  //   } else {
  //     if (moreInfo.length == 0) {
  //       setOpen({
  //         open: true,
  //         title: 'Sorry',
  //         text: 'Please enter information',
  //       });
  //       setTimeout(() => {
  //         handleClose();
  //       }, 3000);
  //       return;
  //     }
  //     data = {
  //       ...requestValue,
  //       admin_additional_info: [
  //         { additinal_info: moreInfo, additional_note: '' },
  //       ],
  //       appStatus: 'pending',
  //       task_status: 'pending',
  //     };
  //   }
  //   console.log('updateRequest payload', data);
  //   axios({
  //     method: 'PUT',
  //     headers: {
  //       Authorization: sessionStorage.getItem('idtoken'),
  //     },
  //     url:
  //       process.env.REACT_APP_API_BASE + 'supplier-portal/supplier-adhoc-tasks',
  //     data: data,
  //   })
  //     .then(response => {
  //       console.log(response);
  //       if (response.status == 200) {
  //         console.log('ad hoc updated');

  //         setOpen({
  //           open: true,
  //           title: 'Thank you',
  //           text: 'Request successfully sent',
  //         });
  //         setTimeout(() => {
  //           handleClose();
  //         }, 3000);
  //         getRequestDetail();
  //         // only approve and create button, need to go to task page
  //         if (action === 'approve_and_create') {
  //           // props.history.goBack();
  //           console.log(' req value', requestValue);
  //           props.history.push({
  //             pathname: '/task',
  //             state: { requestValue, tabValue: '1' },
  //           });
  //         }
  //       }
  //     })
  //     .catch(error => {
  //       console.log(error.response);
  //       console.log(error.response.status);
  //       if (
  //         error.response.status == 401 &&
  //         error.response.data.message == 'The incoming token has expired'
  //       ) {
  //         alert('Session timed out, please login again.');
  //         localStorage.clear();
  //         sessionStorage.clear();
  //         localStorage.clear();
  //         // localStorage.removeItem( "accessLevel" );
  //         // reset page location
  //         console.log('logout succesfully');
  //         document.location.replace('/');
  //       }
  //     });
  // };

  // const toggleIsViewMode = e => {
  //   e.preventDefault();
  //   console.log('click toggle', !isViewMode);
  //   setIsViewMode(!isViewMode);
  // };

  const handleAskInfoChange = e => {
    e.preventDefault();
    const selectedVal = e.target.value;
    // Only allow one time asking for infor
    // Please notice the admin_additional_infor is in Array format, and value will need to be set to additional_info field
    // requestValue.admin_additional_info[0].additinal_info = selectedVal;
    // setRequestValue({
    //   ...requestValue,
    // });
    setMoreInfo(selectedVal);
  };

  return (
    <>
      <Dialog open={openNote}>
        <DialogContent style={{ padding: '20px' }}>
          <DialogContentText>
            <Row>
              {/* TODO: additonal notes is array or object? what fields need to be there */}
              <Col md={12}>
                <label>Ask for more information</label>
                <Input
                  type="textarea"
                  name="admin_additional_info"
                  // value={requestValue.additional_note}
                  style={{ padding: '10px', minHeight: '20vh' }}
                  onChange={handleAskInfoChange}
                />
              </Col>
            </Row>
            <br />
          </DialogContentText>
          <Row>
            <Col>
              <Button
                onClick={e => updateRequest(e, 'askMoreInfo')}
                variant="outlined"
                color="primary"
              >
                Send
              </Button>
            </Col>
            <Col>
              <Button onClick={handleClose} variant="outlined" color="primary">
                Cancel
              </Button>
            </Col>
          </Row>
        </DialogContent>
      </Dialog>
      <Dialog open={open.open}>
        <DialogContent>
          <DialogContentText>
            <Row>
              <Col md={12}>
                <b style={{ color: 'red' }}>{open.title}</b>
                <br />
                <br />
                <label style={{ color: 'red' }}>{open.text}</label>
              </Col>
            </Row>
            <br />
          </DialogContentText>
          <Button
            onClick={() => {
              handleClose();
              // props.history.goBack();
            }}
            variant="outlined"
            color="primary"
          >
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Page>
        <Row>
          <Col style={{ padding: '0px' }}>
            <Card id="userManagement">
              <CardHeader className="card-header-main">
                Requested Task
              </CardHeader>
              <CardBody
                style={{ background: '#fff', padding: '0px 0px 0px 20px' }}
              >
                <Box sx={{ width: '100%', typography: 'body1' }}>
                  <Form>
                    <FormGroup>
                      <Row style={{ padding: '0px 0px 0px 20px' }}>
                        <div className="individual-adhoc-task-container hide-scrollbar">
                          <Col>
                            <Row>
                              <Col>
                                <label>Request ID</label>
                                <Input
                                  type="text"
                                  name="request_id"
                                  disabled
                                  value={requestValue?.task_id}
                                  // value={requestTask.task_id}
                                />
                              </Col>
                              <Col>
                                <label>Supplier Name</label>
                                <Input
                                  type="text"
                                  name="username"
                                  disabled
                                  value={requestValue?.username}
                                  // value={requestTask.task_id}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <label>Date</label>
                                <Input
                                  type="text"
                                  name="create_date"
                                  disabled
                                  value={requestValue?.display_date}
                                  // value={requestTask.display_date}
                                />
                              </Col>
                              <Col>
                                <label>Task Status</label>
                                <Input
                                  type="text"
                                  name="task_status"
                                  disabled
                                  value={
                                    requestValue?.task_status == 'completed'
                                      ? 'submitted'
                                      : requestValue?.task_status
                                  }

                                  // value={requestTask.taskName}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <label>Task Name</label>
                                <Input
                                  type="text"
                                  name="task_name"
                                  disabled
                                  value={requestValue?.taskName}
                                  // value={requestTask.taskName}
                                />
                              </Col>
                              <Col>
                                <label>Frequency</label>
                                <Input
                                  type="select"
                                  name="frequency"
                                  value={requestValue?.frequency}
                                  // value={requestTask.frequency}
                                  disabled
                                  style={{
                                    fontSize: "0.7rem",
                                    width: "100%",
                                    height:"50%",
                                    padding: "0.3rem 0.5rem",
                                    border: "1px solid #ced4da",
                                    backgroundColor: "#e9ecef",
                                    borderRadius: "10px"
                                  }}
                                >
                                  <option value="default">
                                    Select Visit Frequency
                                  </option>
                                  <option value="one off">One Off</option>
                                  <option value="7">Days 7</option>
                                  <option value="14odd">Days 14 Odd</option>
                                  <option value="14even">Days 14 Even</option>
                                  <option value="28">Days 28</option>
                                  <option value="">Exception</option>
                                </Input>
                                {requestValue?.frequency?.length == 0 ? (
                                  <Input
                                    type="text"
                                    name="add_exception"
                                    disabled
                                    value={requestValue?.add_exception}
                                    style={{ marginTop: '5px' }}
                                    // value={requestTask.add_exception}
                                  />
                                ) : null}
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <label>Direction/Description</label>
                                {!requestValue?.description ? (
                                  <Input
                                    type="textarea"
                                    name="description"
                                    disabled
                                    value={requestValue?.description}
                                    // value={requestTask.description}
                                  />
                                ) : (
                                  <div
                                    className="form-control"
                                    style={{
                                      height: 'auto',
                                      backgroundColor: '#e9ecef',
                                    }}
                                  >
                                    {parse(requestValue?.description)}
                                  </div>
                                )}
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <label>Additional Notes</label>
                                <Input
                                  type="textarea"
                                  name="additional_note"
                                  disabled
                                  value={requestValue?.additional_note}
                                  // value={requestTask.additional_note}
                                  // onChange={requestAdhocTaskHandler}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </div>
                        <Col>
                          <Row>
                            <Col>
                              {Array.isArray(requestValue?.documentAdd) &&
                              requestValue?.documentAdd ? (
                                <>
                                  <Row>
                                    <Col
                                      md="12"
                                      style={{ padding: '0px 0px 0px 40px' }}
                                    >
                                      <h6>Reference Document </h6>
                                    </Col>
                                  </Row>
                                  <Table
                                    style={{
                                      textAlign: 'left',
                                      width: '95%',
                                      paddingLeft: '1%',
                                    }}
                                  >
                                    <thead>
                                      <tr>
                                        <th>Document</th>
                                        <th>Notes</th>
                                      </tr>
                                    </thead>
                                    {requestValue?.documentAdd &&
                                      requestValue?.documentAdd?.map(rec => {
                                        return (
                                          <tr>
                                            <td>
                                              <a
                                                title="Click to download"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={`${process.env.REACT_APP_CDN}supplier-documents/tasks/${requestValue.task_id}/${rec.document}`}
                                              >
                                                {rec.document}
                                              </a>
                                            </td>
                                            <td>{rec.notes}</td>
                                          </tr>
                                        );
                                      })}
                                  </Table>
                                </>
                              ) : null}
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              {Array.isArray(requestValue?.responseAdd) &&
                              requestValue?.responseAdd ? (
                                <>
                                  <Row>
                                    <Col
                                      style={{ padding: '0px 0px 0px 40px' }}
                                      md="12"
                                    >
                                      <h6>Response/Feedback </h6>
                                    </Col>
                                  </Row>
                                  <Table
                                    style={{
                                      textAlign: 'left',
                                      width: '95%',
                                      paddingLeft: '1%',
                                    }}
                                  >
                                    <thead>
                                      <tr>
                                        <th>Response</th>
                                        <th>Notes</th>
                                      </tr>
                                    </thead>
                                    {requestValue.responseAdd &&
                                      requestValue.responseAdd.map(rec => {
                                        return (
                                          <tr>
                                            <td>{rec.responseInput}</td>
                                            <td>{rec.responseNote}</td>
                                          </tr>
                                        );
                                      })}
                                  </Table>
                                </>
                              ) : null}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      {/* <Row>
                        <Col xl={11} lg={11} md={11}></Col>
                        <Col xl={1} lg={1} md={1}>
                          {isViewMode ? (
                            <button onClick={toggleIsViewMode}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="#000"
                                class="bi bi-pencil"
                                viewBox="0 0 16 16"
                              >
                                <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"></path>
                              </svg>{' '}
                            </button>
                          ) : (
                            <button onClick={toggleIsViewMode}>
                              <svg
                                height="18"
                                width="18"
                                fill="#000000"
                                xmlns="http://www.w3.org/2000/svg"
                                data-name="Layer 3"
                                viewBox="0 0 24 24"
                                x="0px"
                                y="0px"
                              >
                                <path d="M20.5,9.873A11.123,11.123,0,0,0,12,6,11.123,11.123,0,0,0,3.5,9.873L2.239,11.351a1,1,0,0,0,0,1.3L3.5,14.127A11.123,11.123,0,0,0,12,18a11.125,11.125,0,0,0,8.5-3.872l1.261-1.479a1,1,0,0,0,0-1.3Zm-1.521,2.958A9.134,9.134,0,0,1,12,16a9.131,9.131,0,0,1-6.979-3.17L4.314,12l.707-.83h0A9.134,9.134,0,0,1,12,8a9.131,9.131,0,0,1,6.979,3.17l.707.83Z"></path>
                                <path d="M12,9a3,3,0,1,0,3,3A3,3,0,0,0,12,9Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,12,13Z"></path>
                              </svg>
                            </button>
                          )}
                        </Col>
                      </Row> */}

                      {/* <Row>
                          <Col md="3">
                            {requestValue.frequency.length == 0 ? (
                              <Input
                                type="text"
                                name="add_exception"
                                onChange={handleChangeFreqException}
                                disabled={isViewMode}
                                value={requestValue.add_exception}
                                // value={requestTask.add_exception}
                              />
                            ) : null}
                          </Col>
                        </Row> */}

                      {/* {requestValue.frequency == '' &&
                        requestValue.add_exception.length > 0 ? (
                          <Col md="3">
                            <label>Frequency: Exception</label>
                            <Input
                              type="text"
                              name="add_exception"
                              disabled={isViewMode}
                              value={requestValue.add_exception}
                            />
                          </Col>
                        ) : (
                          <Col md="3">
                            <label>Frequency</label>
                            <Input
                              type="select"
                              name="frequency"
                              onChange={handleChangeFreq}
                              value={requestValue.frequency}
                              disabled={isViewMode}
                            >
                              <option value="default">
                                Select Visit Frequency
                              </option>
                              <option value="weekly">Weekly</option>
                              <option value="fortnightly">Fortnightly</option>
                              <option value="monthly">Monthly</option>
                              <option value="">Exception</option>
                            </Input>
                            {requestValue.add_exception.length > 0 ? (
                              <Input
                                type="text"
                                name="add_exception"
                                disabled={isViewMode}
                                value={requestValue.add_exception}
                              />
                            ) : null}
                          </Col>
                        )} */}
                      {typeof requestValue?.admin_additional_info[0]
                        .additinal_info == 'string' &&
                      requestValue?.admin_additional_info[0]?.additinal_info
                        .length > 0 ? (
                        <Row>
                          <Col md="12">
                            <h6> More Information </h6>
                          </Col>
                          <Col md="3">
                            <Input
                              type="textarea"
                              name="additional_note"
                              disabled
                              value={
                                requestValue?.admin_additional_info[0]
                                  .additinal_info
                              }
                              // value={requestTask.additional_note}
                              // onChange={requestAdhocTaskHandler}
                            />
                          </Col>
                        </Row>
                      ) : null}
                      <br />
                      <Row>
                        <Col
                          style={{ padding: '0px 0px 0px 55px' }}
                          md={12}
                          className="martop"
                        >
                          <Button
                            variant="outlined"
                            color="primary"
                            // button enabled only when task is completed
                            disabled={
                              // requestValue.task_status == 'approved' ||
                              // requestValue.task_status == 'pending' ||
                              // requestValue.task_status == 'created'
                              !(requestValue?.task_status == 'completed')
                            }
                            onClick={e => updateRequest(e, 'approve')}
                          >
                            <span>
                              {' '}
                              <b> Approve </b>{' '}
                            </span>
                          </Button>
                          {/* <Link
                            to={{
                              pathname: '/task',
                              state: { requestValue, tabValue: '1' },
                            }}
                          > */}
                          <Button
                            variant="outlined"
                            color="primary"
                            // button enabled only when task is completed or approved
                            disabled={
                              // requestValue.task_status == 'created' ||
                              // requestValue.task_status == 'pending'
                              !(
                                requestValue?.task_status == 'completed' ||
                                requestValue?.task_status == 'approved'
                              )
                            }
                            onClick={e =>
                              updateRequest(e, 'approve_and_create')
                            }
                          >
                            <span>
                              {' '}
                              <b>Approve/Create Task</b>{' '}
                            </span>
                          </Button>
                          {/* </Link> */}
                          <Button
                            variant="outlined"
                            onClick={e => {
                              askMoreInfo(e);
                            }}
                            color="primary"
                            disabled={
                              // requestValue.task_status == 'pending' ||
                              // requestValue.task_status == 'approved' ||
                              // requestValue.task_status == 'created'
                              !(requestValue?.task_status == 'completed')
                            }
                          >
                            <span>
                              {' '}
                              <b>Ask More Info</b>{' '}
                            </span>
                          </Button>
                          <Link to={{ pathname: '/runList' }}>
                            <Button variant="outlined" color="primary">
                              <span>
                                {' '}
                                <b> Cancel</b>{' '}
                              </span>
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Form>
                </Box>
                <br />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
    </>
  );
};

export default RequestAdhocTask;
