
import axios from 'axios';

export const fetchId = async (type = "task") => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE}id-gen?type=${type}`,
      {
        headers: {
          Authorization: sessionStorage.getItem("idtoken"),
        },
      }
    );
  
    if (response.data && response.data.body) {
      return response.data.body;
    } else {
      throw new Error("ID not found in response.");
    }
  } catch (error) {
    console.error("Error fetching ID:", error);
    throw error;
  }
};