import React from 'react';
// import componentQueries from 'react-component-queries';
import { STATE_LOGIN, STATE_SIGNUP } from './components/AuthForm';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import GAListener from './components/GAListener';
import { EmptyLayout, MainLayout } from './components/Layout';
import PageSpinner from './components/PageSpinner';
import AuthPage from './pages/AuthPage';
import DashboardPage from './pages/DashboardPage';
import TaskManagement from './pages/AdminPages/TaskManagement';
import Admin from './pages/AdminPages/Admin';
import AddAdmin from './pages/AdminPages/AddAdmin';
import Manager from './pages/AdminPages/Manager';
import Rep from './pages/AdminPages/Rep';
import Reports from './pages/AdminPages/Reports';
import Retailer from './pages/AdminPages/Retailer';
import RequestManagement from './pages/AdminPages/RequestManagement';
import Store from './pages/AdminPages/Store';
import Supplier from './pages/AdminPages/Supplier';
import AdhocTask from './pages/SupplierPages/AdhocTask';
import Invoice from './pages/SupplierPages/Invoice';
import ProductCatalogue from './pages/SupplierPages/ProductCatalogue';
import Administration from './pages/SupplierPages/Administration';
import ProgressTaskSupplierTable from './pages/Tables/ProgressTaskSupplierTable';
import TableViewAdmin from './pages/TableViewAdmin';
import RequestAdhocTask from './pages/AdminPages/RequestAdhocTask';
import TableTaskView from './pages/Tables/TableTaskView';
import AdminProductCatalogue from './pages/AdminPages/AdminProductCatalogue';
import withLayout from './withLayout';
import './styles/reduction.scss';

// const PrivateRoute = ({ component: Component, ...rest }) => {
//   const currentUser = sessionStorage.getItem('username');

//   return (
//     <Route
//       {...rest}
//       element={(props) =>
//         authenticate(currentUser) ? (
//           <Component {...props} />
//         ) : (
//           <Navigate to="/" replace />
//         )
//       }
//     />
//   );
// };
const PrivateRoute = ({ children }) => {
  const isAuthenticated = sessionStorage.getItem('username') !== null;
  return isAuthenticated ? children : <Navigate to="/" replace />;
};

// const authenticate = currentUser => {
//   console.log('currentUser', currentUser);
//   if (currentUser !== null && currentUser !== undefined) {
//     return true;
//   }
//   return false;
// };

class App extends React.Component {
  componentDidMount() {
    const expiryDate = localStorage.getItem('expiry');
    console.log('expiryDate', expiryDate);
    setTimeout(() => {
      if (localStorage.getItem('username')) {
        console.log('expiryDate', expiryDate - 60 * 60 * 4000);
        alert('Session time out, please sign in again');
        sessionStorage.clear();
        localStorage.clear();
        document.location.replace('/');
      }
    }, expiryDate - 60 * 60 * 4000);
  }

  render() {
    return (
      <BrowserRouter>
        {/* <GAListener> */}
        <Routes>
          <Route
            path="/"
            element={withLayout(() => <AuthPage authState={STATE_LOGIN} />, EmptyLayout)()}
          />
          <Route
            path="/dashboard"
            element={<PrivateRoute>{withLayout(DashboardPage, MainLayout)()}</PrivateRoute>}
          />
          <Route
            path="/task"
            element={<PrivateRoute>{withLayout(TaskManagement, MainLayout)()}</PrivateRoute>}
          />
          <Route
            path="/addadmin"
            element={<PrivateRoute>{withLayout(AddAdmin, MainLayout)()} </PrivateRoute>}
          />
          <Route
            path="/admin"
            element={<PrivateRoute> {withLayout(Admin, MainLayout)()}</PrivateRoute>}
          />
          <Route
            path="/manager"
            element={<PrivateRoute>{withLayout(Manager, MainLayout)()}</PrivateRoute>}
          />
          <Route
            path="/rep"
            element={<PrivateRoute>{withLayout(Rep, MainLayout)()}</PrivateRoute>}
          />
          <Route
            path="/reports"
            element={<PrivateRoute>{withLayout(Reports, MainLayout)()}</PrivateRoute>}
          />
          <Route
            path="/runList"
            element={<PrivateRoute>{withLayout(RequestManagement, MainLayout)()}</PrivateRoute>}
          />
          <Route
            path="/store"
            element={<PrivateRoute>{withLayout(Store, MainLayout)()}</PrivateRoute>}
          />
          <Route
            path="/retailer"
            element={<PrivateRoute>{withLayout(Retailer, MainLayout)()}</PrivateRoute>}
          />
          <Route
            path="/supplier"
            element={<PrivateRoute>{withLayout(Supplier, MainLayout)()}</PrivateRoute>}
          />
          <Route
            path="/adhoc"
            element={<PrivateRoute>{withLayout(AdhocTask, MainLayout)()}</PrivateRoute>}
          />
          <Route
            path="/invoice"
            element={<PrivateRoute>{withLayout(Invoice, MainLayout)()}</PrivateRoute>}
          />
          <Route
            path="/progressTask"
            element={
              <PrivateRoute>{withLayout(ProgressTaskSupplierTable, MainLayout)()}</PrivateRoute>
            }
          />
          <Route
            path="/product-catalogue"
            element={<PrivateRoute>{withLayout(ProductCatalogue, MainLayout)()}</PrivateRoute>}
          />
          <Route
            path="/tableView"
            element={<PrivateRoute>{withLayout(TableViewAdmin, MainLayout)()}</PrivateRoute>}
          />
          <Route
            path="/administration"
            element={<PrivateRoute>{withLayout(Administration, MainLayout)()}</PrivateRoute>}
          />
          <Route
            path="/requestadhoctask"
            element={<PrivateRoute>{withLayout(RequestAdhocTask, MainLayout)()}</PrivateRoute>}
          />
          <Route
            path="/tasktableview"
            element={<PrivateRoute>{withLayout(TableTaskView, MainLayout)()}</PrivateRoute>}
          />
          <Route
            path="/ProductCatalogue"
            element={<PrivateRoute>{withLayout(AdminProductCatalogue, MainLayout)()}</PrivateRoute>}
          />
        </Routes>
        {/* </GAListener> */}
      </BrowserRouter>
    );
  }
}

// const query = ({ width }) => {
//   if (width < 575) {
//     return { breakpoint: 'xs' };
//   }

//   if (576 < width && width < 767) {
//     return { breakpoint: 'sm' };
//   }

//   if (768 < width && width < 991) {
//     return { breakpoint: 'md' };
//   }

//   if (992 < width && width < 1199) {
//     return { breakpoint: 'lg' };
//   }

//   if (width > 1200) {
//     return { breakpoint: 'xl' };
//   }

//   return { breakpoint: 'xs' };
// };

export default App;
// export default componentQueries(query)(App);
