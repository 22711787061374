import React from 'react';
import { useNavigate } from 'react-router-dom';

const withRouter = (Component) => {
  const ComponentWithRouterProp = (props) => {
    let navigate = useNavigate();
    return <Component {...props} navigate={navigate} />;
  };

  return ComponentWithRouterProp;
};

export default withRouter;
