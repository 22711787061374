import Page from '../../components/Page';
import React, { useState } from 'react';
import { useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
  Table,
} from 'reactstrap';
import { makeStyles } from '@material-ui/core/styles';

import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import axios from 'axios';




const TaskManagement = () => {
 
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  

  return (
    <Page>
      <Row>
       <Col xl={12} lg={12} md={12} style={{paddingLeft:'0'}}>
          <Card id='userManagement'>
            <CardHeader className="card-header-main">Request Management
            </CardHeader>
            <CardBody style={{ background: '#fff', padding: '1.25rem' }}>
            <div  id="tabCol" style={{ paddingTop: '2%' }}>
            <br/>
            <Form>
                <FormGroup >

                  <Row style={{ display: "flex" }}>
                    <Col md="3">
                      <label>Product Name<span style={{ color: 'red' }}> *</span> </label>
                      <Input type='text' name="product_name" 
                        />
                    </Col>
                      <Col md={4} className="martop">
                         <label style={{color:'transparent'}}>Add Submit</label><br/>
                        <Button variant="outlined"
                          color="primary" ><span> <b> Add & Save</b> </span></Button>
                      </Col>
                  
                  </Row>
                  <br/>
                
                  <Table responsive className='table-bordered'>
                <thead>
                  <tr>
                    <th>Customer ID </th>
                    <th>Customer Name </th>
                    <th>Product Name</th>
                    <th>Price/Kg</th>
                
                   
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>

                        <tr > 
                         
                          <td>RnR</td>
                          <td>Rock N Roll Butcher</td>
                        <td>Small Prime Carcase (10-12kg)</td>
                         <td>$ 13.95</td>
                    
                       
                          <td  colSpan='38'>
                            <button   style={{marginRight: '0px'}}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000" className="bi bi-pencil" viewBox="0 0 16 16">
                              <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                            </svg> </button>
                            <button  style={{marginRight: '0'}}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000" className="bi bi-trash" viewBox="0 0 16 16">
                              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                              <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                            </svg></button>
                          </td>
                        </tr>
            
                 

                </tbody>
              </Table>
            

                </FormGroup>

              </Form>
           
              </div>
            </CardBody>

          </Card>
        </Col>

      </Row>


    </Page>
  );
};

export default TaskManagement;
