import Page from '../../components/Page';
import React, { useState, useRef } from 'react';
import { useEffect } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
} from 'reactstrap';
import axios from 'axios';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import validator from 'validator';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { confirmAlert } from 'react-confirm-alert';
import Dialog from '@material-ui/core/Dialog';
import { Table, Tag, Space, Popconfirm } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import greyBanner from '../../assets/img/logo/Banner Grey.png';
import 'antd/dist/antd.min.css';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/react';
import {
  isInvalidID,
  isInvalidUsername,
  isValidID,
} from '../AdminPages/AddAdmin.js';

const override = css`
  display: block;
  margin: 20% auto;
  border-color: #fff;
`;

const Administration = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [supplierAdmin, setSupplierAdmin] = React.useState(true);
  const [color, setColor] = React.useState('#ffffff');
  const [isView, setIsView] = React.useState(false);
  const [openUpd, setOpenUpd] = React.useState(false);
  const [tableData, setTableData] = useState();
  const [loading, setLoading] = React.useState(false);
  const [btnUpdate, setBtnUpdate] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const searchInput = useRef(null);
  const [searchedColumn, setSearchedColumn] = useState('');
  const [searchText, setSearchText] = useState('');
  const [supppList, setSupppList] = React.useState();
  const [filteredData, setFilteredData] = useState([]);
  const [selectedList, setSelectedList] = React.useState([]);
  const [adminInputField, setAdminInputField] = useState({
    role: 'user',
    id: '',
    supplier_name: '',
    admin_name: 'user',
    first_name: '',
    last_name: '',
    mob_number: '',
    business_email: '',
    username: '',
  });
  const [mobValue, setmobValue] = useState({
    mob_number: '',
  });

  /////////set phone value
  const mobileValue = e => {
    setmobValue({ ...mobValue, mob_number: e });
  };

  const [requiredError, setRequiredError] = React.useState({
    businessEmail: '',
  });

  //////// onchange input handler function
  const adminInputHandler = e => {
    setAdminInputField({ ...adminInputField, [e.target.name]: e.target.value });
    if (e.target.name == 'id') {
      setRequiredError({ id: '' });
      idVal(e.target.value, 'user');
    }
    if (e.target.name == 'business_email') {
      setRequiredError({ business_email: '' });
    }
    if (e.target.name == 'username') {
      setRequiredError({ username: '' });
    }
  };
  //////// Get supplier name from session storage
  const supplierName = sessionStorage.getItem('username');
  console.log(supplierName);

  ///// get supplier list
  const listSupp = () => {
    setLoading(true);
    axios({
      method: 'GET',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: process.env.REACT_APP_API_BASE + 'admin-portal',
    })
      .then(response => {
        const suppData = response.data.body;
        const roles = suppData.filter(item => {
          if (item.role && item.role == 'supplier') {
            return item;
          }
        });
        console.log('supplierRoles', roles);
        setSupppList(roles);
        setLoading(false);
      })
      .catch(error => {
        console.log('Error ', error);
        setLoading(false);
      });
  };

  //// Add Admin Data
  const addAdmin = () => {
    if (adminInputField.id == '' || adminInputField.id == null) {
      setRequiredError({ adminId: 'Please Enter ID' });
      return;
    }
    if (
      adminInputField.id != '' &&
      adminInputField.id !== null &&
      isInvalidID(adminInputField.id)
    ) {
      setRequiredError({
        adminId:
          'Invalid ID, please remove special characters or shorten the ID',
      });
      return;
    }
    if (idError != '') {
      return;
    }
    if (adminInputField.username == '' || adminInputField.username == null) {
      setRequiredError({ userName: 'Please Enter Username' });
      return;
    }
    if (
      adminInputField.username != '' &&
      adminInputField.username != null &&
      isInvalidUsername(adminInputField.username)
    ) {
      setRequiredError({
        userName:
          'Invalid username, please remove special characters, space or shorten the username',
      });
      return;
    }
    if (!validator.isEmail(adminInputField.business_email)) {
      setRequiredError({ businessEmail: 'Enter Valid Email' });
      return;
    }
    var dashId = [];
    var userType;
    supppList.map(rec => {
      console.log('rec====>', rec);
      if (rec.id) {
        dashId = rec.dashIDs;
        userType = rec['user-type'];
      }
    });
    const data = {
      ...adminInputField,
      id: adminInputField.id.toUpperCase(),
      mob_number: mobValue.mob_number,
      supplier_name: supplierName,
      dashId,
      userType,
    };
    console.log('sub_supplier payload', data);
    axios({
      method: 'POST',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: process.env.REACT_APP_API_BASE + 'admin-portal',
      data: data,
    })
      .then(response => {
        console.log(response);
        if (response.data.ResponseMetadata) {
          console.log('user added');
          listUser();
          setOpen(true);
          // setLoading(true);
          setTimeout(() => {
            setOpen(false);
          }, 3000);
          setmobValue({ mob_number: '' });
          setAdminInputField({
            role: 'user',
            id: '',
            supplier_name: '',
            admin_name: '',
            first_name: '',
            last_name: '',
            mob_number: '',
            business_email: '',
            username: '',
          });
        }
      })
      .catch(error => {
        console.log('add subSupplier fail...', error);
        if (error) {
          setOpenError(true);
          setAdminInputField({
            ...adminInputField,
            business_email: '',
          });
        }
      });
  };

  const listUser = () => {
    axios({
      method: 'GET',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: process.env.REACT_APP_API_BASE + 'admin-portal',
    }).then(response => {
      const admindata = response.data.body;
      console.log('inside repos', supplierName);
      const roles = admindata.filter(item => {
        if (
          item.role &&
          item.role == 'user' &&
          item.supplier_name == supplierName
        ) {
          return item;
        }
      });
      console.log('Userroles', roles);
      setTableData(roles);
      filter(roles);
      setFilteredData(roles);
    });
  };

  useEffect(() => {
    listUser();
    listSupp();
  }, []);

  ////////// cancel input Field handler
  const cancelInputField = () => {
    setAdminInputField({
      role: 'user',
      id: '',
      supplier_name: '',
      admin_name: '',
      first_name: '',
      last_name: '',
      mob_number: '',
      business_email: '',
      username: '',
    });
    setmobValue({
      mob_number: '',
    });
    setRequiredError('');
    setBtnUpdate(false);
    setIsView(false);
  };
  ///// Update Admin
  const updateAdmin = () => {
    const data = {
      ...adminInputField,
      mob_number: mobValue.mob_number,
      id: adminInputField.id.toUpperCase(),

      supplier_name: supplierName,
    };
    console.log('update user payload', data);
    axios({
      method: 'PUT',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url: process.env.REACT_APP_API_BASE + 'admin-portal',
      data: data,
    })
      .then(response => {
        console.log(response);
        console.log(response.data);
        if (response.data) {
          setOpenUpd(true);
          listUser();
          setTimeout(() => {
            setOpenUpd(false);
          }, 3000);
          setmobValue({ mob_number: '' });
          setAdminInputField({
            role: 'user',
            id: '',
            supplier_name: '',
            admin_name: '',
            first_name: '',
            last_name: '',
            mob_number: '',
            business_email: '',
            username: '',
          });
          setBtnUpdate(false);
          setIsView(false);
        }
      })
      .catch(err => {
        console.log('update user failed...', err);
      });
  };
  const [idError, setIdError] = React.useState('');
  const idVal = (id, entity) => {
    axios({
      method: 'GET',
      headers: {
        Authorization: sessionStorage.getItem('idtoken'),
      },
      url:
        process.env.REACT_APP_API_BASE +
        'id-validation?id=' +
        id.toUpperCase() +
        '&entity=' +
        entity,
    }).then(response => {
      console.log(response.data.body);
      const idVald = response.data.body;
      if (idVald == 'true') {
        setIdError('ID already exist');
      } else {
        setIdError('');
      }
    });
  };
  ////// delete Admin
  const deleteAdmin = admin_id => {
    const filleredRequest = adminInputField.filter((element, index) => {
      return element.admin_id !== admin_id;
    });
    setAdminInputField(filleredRequest);
  };

  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCloses = () => {
    setOpenError(false);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenError(false);
    setAdminInputField({
      role: 'user',
      id: '',
      supplier_name: '',
      admin_name: '',
      first_name: '',
      last_name: '',
      mob_number: '',
      business_email: '',
      username: '',
    });
    setmobValue({
      mob_number: '',
    });
    setRequiredError('');
    setBtnUpdate(false);
    setIsView(false);
  };

  ////// Update User
  const viewUser = (record, e) => {
    setIsView(true);
    setAdminInputField(record);
    setmobValue({ mob_number: record.mob_number });
    setBtnUpdate(true);
  };
  ///// To delete User
  const deleteUser = (record, e) => {
    e.preventDefault();
    console.log(record);
    const userID = record.id;
    const usrName = record.username;
    console.log(usrName);
    // setLoading(true);
    confirmAlert({
      title: 'Confirm to Delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            axios({
              method: 'DELETE',
              headers: {
                Authorization: sessionStorage.getItem('idtoken'),
              },
              url:
                process.env.REACT_APP_API_BASE +
                'admin-portal?id=' +
                userID +
                '&username=' +
                usrName,
            }).then(response => {
              console.log(response);
              listUser();
            });
          },
        },
        {
          label: 'No',
          onClick: () => {
            return;
          },
        },
      ],
    });
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => {
            console.log('cahngeddd', e.target.value);
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    // TODO: if in future, no need, remove this
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => {}, 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const modifyTable =
    tableData &&
    tableData
      .sort((a, b) => a.first_name.localeCompare(b.first_name))
      .map(({ ...item }) => ({
        ...item,
      }));
  console.log('modifyTable', modifyTable);

  const filter = data => {
    data.map(rec => {
      console.log('rec====>', rec);
      setFilteredData(rec);
    });
  };

  const rowSelection = {
    preserveSelectedRowKeys: false,
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        'selectedRows: ',
        selectedRows,
      );
    },
    onSelect: (record, selected, selectedRows) => {
      console.log(record, selected, selectedRows);
      setSelectedList(selectedRows);
      console.log(record);
      console.log(selectedList);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      console.log(selected, selectedRows, changeRows);
      setSelectedList(selectedRows);
      console.log(selectedList);
    },
  };

  const bulkDelete = e => {
    console.log('selectedList==>', selectedList);
    var arrData = [];
    selectedList.forEach((item, index) => {
      var obj = {};
      if (item) {
        obj['id'] = item.id;
        obj['username'] = item.username;
        arrData.push(obj);
        console.log('arrData==>', arrData);
        confirmAlert({
          title: 'Confirm to Delete',
          message: 'Are you sure to do this.',
          buttons: [
            {
              label: 'yes',
              onClick: () => {
                axios({
                  method: 'PUT',
                  headers: {
                    Authorization: sessionStorage.getItem('idtoken'),
                  },
                  url:
                    process.env.REACT_APP_API_BASE + 'admin-portal/bulk-delete',
                  data: arrData,
                }).then(response => {
                  console.log(response);
                  console.log('bulk sub-supplier deleted !', response);
                  listUser();
                  setSelectedList([]);
                  arrData = [];
                });
              },
            },
            {
              label: 'No',
              onClick: () => {
                return;
              },
            },
          ],
        });
      }
    });
  };
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      align: 'center',
      ...getColumnSearchProps('id'),
    },
    {
      title: 'Name',
      dataIndex: 'first_name',
      align: 'center',
      ...getColumnSearchProps('first_name'),
    },
    {
      title: 'Mob No.',
      dataIndex: 'mob_number',
      align: 'center',
    },
    {
      title: 'Email',
      dataIndex: 'business_email',
      align: 'center',
      render: (text, record) => {
        return (
          <p style={{ textTransform: 'lowercase' }}>{record.business_email}</p>
        );
      },
    },
    {
      title: 'Username',
      dataIndex: 'username',
      align: 'center',
      ...getColumnSearchProps('username'),
      render: (text, record) => {
        return <p style={{ textTransform: 'none' }}>{record.username}</p>;
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      align: 'center',
      render: (text, record) => {
        return (
          <>
            {/* <button style={{marginRight: '0'}}><svg height='18' width='18' fill="#000000" xmlns="http://www.w3.org/2000/svg" data-name="Layer 3" viewBox="0 0 24 24" x="0px" y="0px"><path d="M20.5,9.873A11.123,11.123,0,0,0,12,6,11.123,11.123,0,0,0,3.5,9.873L2.239,11.351a1,1,0,0,0,0,1.3L3.5,14.127A11.123,11.123,0,0,0,12,18a11.125,11.125,0,0,0,8.5-3.872l1.261-1.479a1,1,0,0,0,0-1.3Zm-1.521,2.958A9.134,9.134,0,0,1,12,16a9.131,9.131,0,0,1-6.979-3.17L4.314,12l.707-.83h0A9.134,9.134,0,0,1,12,8a9.131,9.131,0,0,1,6.979,3.17l.707.83Z"></path>
      <path d="M12,9a3,3,0,1,0,3,3A3,3,0,0,0,12,9Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,12,13Z"></path></svg></button> */}
            <button
              onClick={e => viewUser(record, e)}
              style={{
                marginRight: '0px',
                marginLeft: '5px',
                backgroundColor: 'transparent',
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#000"
                className="bi bi-pencil"
                viewBox="0 0 16 16"
              >
                <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
              </svg>{' '}
            </button>
            <button
              onClick={e => deleteUser(record, e)}
              style={{ marginRight: '0', backgroundColor: 'transparent' }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#000"
                className="bi bi-trash"
                viewBox="0 0 16 16"
              >
                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                <path
                  fillRule="evenodd"
                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                />
              </svg>
            </button>
          </>
        );
      },
    },
  ];

  return (
    <>
      {loading ? (
        <div className="overlay-box">
          <ClipLoader
            color={color}
            loading={loading}
            css={override}
            size={150}
          />
        </div>
      ) : null}
      <Dialog open={open}>
        <DialogContent>
          <DialogContentText>
            <Row>
              <Col md={12}>
                <b style={{ color: 'red' }}>Thank you</b>
                <br />
                <br />
                <label style={{ color: 'red' }}>
                  User details successfully added
                </label>
              </Col>
            </Row>
            <br />
          </DialogContentText>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog open={openUpd}>
        <DialogContent>
          <DialogContentText>
            <Row>
              <Col md={12}>
                <b style={{ color: 'red' }}>Thank you</b>
                <br />
                <br />
                <label style={{ color: 'red' }}>
                  User details successfully updated
                </label>
              </Col>
            </Row>
            <br />
          </DialogContentText>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog open={openError}>
        <DialogContent>
          <DialogContentText>
            <Row>
              <Col md={12}>
                <b style={{ color: 'red' }}>Sorry</b>
                <br />
                <br />
                <label style={{ color: 'red' }}>Email already in use</label>
              </Col>
            </Row>
            <br />
          </DialogContentText>
          <Button onClick={handleCloses} variant="outlined" color="primary">
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Page>
        <Row>
          <Col xl={12} lg={12} md={12} style={{ paddingLeft: '0' }}>
            <Card id="userManagement">
              <CardHeader className="card-header-main">
                Administration
              </CardHeader>
              <CardBody
                style={{ background: '#fff', padding: '0px 18px 18px 0px' }}
              >
                <div id="tabCol" style={{ padding: '0px 2% 2% 2%' }}>
                  <Form>
                    <FormGroup>
                      <Row style={{ marginLeft: '0px' }}>
                        <Col md="6">
                          <div
                            className="rep-container-1 hide-scrollbar"
                            style={{ width: '100%' }}
                          >
                            <Row>
                              <Col md="6">
                                <label style={{ paddingLeft: '8px' }}>
                                  Role
                                </label>
                                <Input
                                  type="text"
                                  disabled
                                  name="role"
                                  onChange={adminInputHandler}
                                  value={adminInputField.role}
                                  className="admin-input"
                                  placeholder="Role"
                                />
                              </Col>
                              <Col md="6">
                                <label style={{ paddingLeft: '8px' }}>
                                  User ID<span style={{ color: 'red' }}>*</span>{' '}
                                </label>
                                <Input
                                  type="text"
                                  name="id"
                                  onChange={adminInputHandler}
                                  value={adminInputField.id}
                                  disabled={isView}
                                  className="admin-input"
                                  placeholder="User ID"
                                  style={{ textTransform: 'uppercase' }}
                                />
                                <p style={{ color: 'red', fontSize: '12px' }}>
                                  {requiredError.adminId}
                                  {idError}
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="6">
                                <label style={{ paddingLeft: '8px' }}>
                                  Username
                                  <span style={{ color: 'red' }}> *</span>{' '}
                                </label>
                                <Input
                                  type="text"
                                  name="username"
                                  onChange={adminInputHandler}
                                  value={adminInputField.username}
                                  disabled={isView}
                                  className="admin-input"
                                  placeholder="Username"
                                />
                                {requiredError.userName ? (
                                  <p style={{ color: 'red', fontSize: '12px' }}>
                                    {requiredError.userName}
                                  </p>
                                ) : requiredError.businessEmail ? (
                                  <p
                                    style={{
                                      color: 'red',
                                      fontSize: '12px',
                                      visibility: 'hidden',
                                    }}
                                  >
                                    {requiredError.businessEmail}
                                  </p>
                                ) : null}
                              </Col>
                              <Col md="6">
                                <label style={{ paddingLeft: '8px' }}>
                                  Mobile Number
                                  <span style={{ color: 'red' }}></span>{' '}
                                </label>
                                <PhoneInput
                                  className="phnclass"
                                  name="mob_number"
                                  onChange={mobileValue}
                                  value={mobValue.mob_number}
                                  placeholder="Mobile Number"
                                />
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="rep-container-2 hide-scrollbar adminastration-container">
                            <Row>
                              <Col md="6">
                                <label style={{ paddingLeft: '8px' }}>
                                  First Name
                                  <span style={{ color: 'red' }}></span>{' '}
                                </label>
                                <Input
                                  type="text"
                                  name="first_name"
                                  onChange={adminInputHandler}
                                  value={adminInputField.first_name}
                                  className="admin-input"
                                  placeholder="First Name"
                                />
                                {requiredError.adminId ? (
                                  <p
                                    style={{
                                      color: 'red',
                                      fontSize: '12px',
                                      visibility: 'hidden',
                                    }}
                                  >
                                    {requiredError.adminId}
                                    {idError}
                                  </p>
                                ) : null}
                              </Col>
                              <Col md="6">
                                <label style={{ paddingLeft: '8px' }}>
                                  Last Name
                                  <span style={{ color: 'red' }}></span>{' '}
                                </label>
                                <Input
                                  type="text"
                                  name="last_name"
                                  onChange={adminInputHandler}
                                  value={adminInputField.last_name}
                                  className="admin-input"
                                  placeholder="Last Name"
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col md="12">
                                <label style={{ paddingLeft: '8px' }}>
                                  Email<span style={{ color: 'red' }}> *</span>{' '}
                                </label>
                                <Input
                                  type="text"
                                  name="business_email"
                                  onChange={adminInputHandler}
                                  value={adminInputField.business_email}
                                  className="admin-input"
                                  placeholder="Email"
                                />{' '}
                                {requiredError.userName ? (
                                  <p
                                    style={{
                                      color: 'red',
                                      fontSize: '12px',
                                      visibility: 'hidden',
                                    }}
                                  >
                                    {requiredError.userName}
                                  </p>
                                ) : requiredError.businessEmail ? (
                                  <p style={{ color: 'red', fontSize: '12px' }}>
                                    {requiredError.businessEmail}
                                  </p>
                                ) : null}
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <br />
                      <Row style={{ marginLeft: '25px' }}>
                        {btnUpdate == false ? (
                          <Col md={12} className="martop">
                            <Button
                              onClick={addAdmin}
                              variant="outlined"
                              color="primary"
                            >
                              <span>
                                {' '}
                                <b> Add </b>{' '}
                              </span>
                            </Button>
                            <Button
                              onClick={cancelInputField}
                              variant="outlined"
                              color="primary"
                            >
                              <span>
                                {' '}
                                <b>Cancel</b>{' '}
                              </span>
                            </Button>
                          </Col>
                        ) : (
                          <Col md={12} className="martop">
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={updateAdmin}
                            >
                              <span>
                                {' '}
                                <b> Update</b>{' '}
                              </span>
                            </Button>
                            <Button
                              onClick={cancelInputField}
                              variant="outlined"
                              color="primary"
                            >
                              <span>
                                {' '}
                                <b>Cancel</b>{' '}
                              </span>
                            </Button>
                          </Col>
                        )}
                      </Row>
                    </FormGroup>
                  </Form>
                  <br />
                  <Col
                    md={9}
                    style={{
                      float: 'right',
                      textAlign: 'right',
                      marginBottom: '4px',
                    }}
                    className="uploadExcel"
                  >
                    <button
                      title="Select records to delete!"
                      type="button"
                      variant="outlined"
                      class="btn btn-primary"
                      onClick={e => {
                        bulkDelete(e);
                      }}
                      disabled={selectedList.length == 0}
                    >
                      {' '}
                      Delete
                    </button>
                  </Col>
                  <div style={{ clear: 'both' }}></div>
                  <div className="antdTable">
                    {modifyTable ? (
                      <Table
                        columns={columns}
                        // scroll={{ x: 1500 }}
                        dataSource={modifyTable}
                        rowSelection={{ ...rowSelection }}
                        rowKey={record => record.id + record.username}
                        onChange={(
                          pagination,
                          filters,
                          sorter,
                          currentPageData,
                        ) => filter(currentPageData.currentDataSource)}
                        pagination={{
                          total: filteredData.length,
                          showTotal: total => `Total ${total} items`,
                          pageSizeOptions: ['10', '50', '100', '200', '500'],
                        }}
                        // bordered
                      />
                    ) : null}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <img src={greyBanner} className="greyBanner"></img>
          </Col>
        </Row>
      </Page>
    </>
  );
};

export default Administration;
