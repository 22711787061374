import React, { useEffect, useState, useRef } from 'react';
import { Table, Tag, Space, Popconfirm } from 'antd';
import 'antd/dist/antd.min.css';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { Button, Input, Col } from 'reactstrap';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/react';

const override = css`
  display: block;
  margin: 20% auto;
  border-color: #fff;
`;

const RequestTable = props => {
  const { tableType } = props;
  const [tableData, setTableData] = useState();
  const [loading, setLoading] = React.useState(false);
  const [color, setColor] = React.useState('#ffffff');
  const searchInput = useRef(null);
  const [searchedColumn, setSearchedColumn] = useState('');
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  // const [list, setList] = useState();

  const getTableData = () => {
    setLoading(true);
    const curUsername = sessionStorage.getItem('parentSupUsername')
      ? sessionStorage.getItem('parentSupUsername')
      : sessionStorage.getItem('username');
    // const username = sessionStorage.getItem('username');
    // TODO: api not correct, should be get with username
    if (tableType == 'all') {
      axios({
        method: 'GET',
        headers: {
          Authorization: sessionStorage.getItem('idtoken'),
        },
        url:
          process.env.REACT_APP_API_BASE +
          'supplier-portal/supplier-adhoc-tasks?username=' +
          curUsername,
      })
        .then(response => {
          if (response.data.statusCode == 200) {
            console.log('all tasks', response);
            const result = response.data.body.Items;
            if (Array.isArray(result) && result.length > 0) {
              const list = result.map(item => {
                return {
                  ...item,
                  display_date: moment(item.create_date).format('DD/MM/YYYY'),
                };
              });

              setTableData(list);
              filter(list);
              setFilteredData(list);
            } else {
              console.log('No result found');
            }
          } else {
            console.log('not 200 status', response);
          }
          setLoading(false);
        })
        .catch(error => {
          console.log(error.response);
          console.log(error.response.status);
          if (
            error.response.status == 401 &&
            error.response.data.message == 'The incoming token has expired'
          ) {
            alert('Session timed out, please login again.');
            localStorage.clear();
            sessionStorage.clear();
            localStorage.clear();
            // localStorage.removeItem( "accessLevel" );
            // reset page location
            console.log('logout succesfully');
            document.location.replace('/');
          }
        });
    } else {
      setLoading(true);
      axios({
        method: 'GET',
        headers: {
          Authorization: sessionStorage.getItem('idtoken'),
        },
        url:
          process.env.REACT_APP_API_BASE +
          'supplier-portal/supplier-adhoc-tasks?task_status=' +
          tableType +
          '&username=' +
          curUsername,
      })
        .then(response => {
          if (response.data.statusCode == 200) {
            console.log('status tasks', tableType, response);
            const result = response.data.body.Items;
            if (Array.isArray(result) && result.length > 0) {
              const list = result.map(item => {
                return {
                  ...item,
                  // display_date: moment(item.create_date).format('DD/MM/YYYY'),
                };
              });
              setTableData(list);
              filter(list);
              setFilteredData(list);
            } else {
              console.log('No result found');
            }
          } else {
            console.log('not 200 status', response);
          }
          setLoading(false);
        })
        .catch(err => {
          console.log('get fail...', err);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getTableData();
  }, []);

  const deleteTask = (record, e) => {
    const curUsername = sessionStorage.getItem('parentSupUsername')
      ? sessionStorage.getItem('parentSupUsername')
      : sessionStorage.getItem('username');
    e.preventDefault();
    console.log(record);
    const adminID = record.task_id;
    const appStatus = record.appStatus;
    // const username = sessionStorage.getItem('username');
    if (appStatus == 'waitApproval') {

      confirmAlert({
        title: 'Confirm to Delete',
        message: 'Are you sure to do this.',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              setLoading(true);
              // TODO: Delete api not working correctly, get status 200 but not actually deleted
              axios({
                method: 'DELETE',
                headers: {
                  Authorization: sessionStorage.getItem('idtoken'),
                },
                url:
                  process.env.REACT_APP_API_BASE +
                  'supplier-portal/supplier-adhoc-tasks?username=' +
                  curUsername +
                  '&task_id=' +
                  adminID,
              })
                .then(response => {
                  console.log('task deleted !', response);
                  getTableData();
                })
                .catch(error => {
                  console.log(error.response);
                  console.log(error.response.status);
                  if (
                    error.response.status == 401 &&
                    error.response.data.message ==
                    'The incoming token has expired'
                  ) {
                    alert('Session timed out, please login again.');
                    localStorage.clear();
                    sessionStorage.clear();
                    localStorage.clear();
                    // localStorage.removeItem( "accessLevel" );
                    // reset page location
                    console.log('logout succesfully');
                    document.location.replace('/');
                  }
                }).finally(() => setLoading(false))
            },
          },
          {
            label: 'No',
            onClick: () => {
              return;
            },
          },
        ],
      });
    } else {
      alert('Approved cannot be deleted');
      console.log('app status approved, cannot be deleted');
      return;
    }
  };

  const handleView = (record, e) => {
    console.log('recccc', record.display_date, record);
    const formatedDateRecord = {
      ...record,
      create_date: '' ? '' : new Date(record.create_date),
      display_date: '' ? '' : new Date(record.display_date),
    };
    console.log('recccc', formatedDateRecord);
    props.setValue('1');
    props.setIsEdit(true);
    props.setAdhocTaskField(formatedDateRecord);

    if (tableType == 'completed') {
      props.setIsViewModes({
        isView: true,
        isStatusSubmitted: true,
      });
    } else {
      props.setIsViewModes({
        isView: true,
        isStatusSubmitted: false,
      });
    }

    // set the exception check
    if (record.frequency == '') {
      props.setExceptionChecked(true);
    }
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => {
            console.log('cahngeddd', e.target.value);
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : '',
    // TODO: if in future, no need, remove this
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => { }, 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  console.log('tableData---->', tableData);
  const modifyTableData =
    tableData &&
    tableData
      .sort((a, b) => a.task_id.localeCompare(b.task_id))
      .map(({ ...item }) => ({
        ...item,
      }));
  console.log('modifytabledat', modifyTableData);

  const filter = data => {
    data.map(rec => {
      console.log('rec====>', rec);
      setFilteredData(rec);
    });
  };

  const bulkDelete = e => {
    console.log('selectedList==>', props.selectedList);
    const curUsername = sessionStorage.getItem('parentSupUsername')
      ? sessionStorage.getItem('parentSupUsername')
      : sessionStorage.getItem('username');
    const arrData = [];
    const multiTaskId = [];

    props.selectedList.forEach((item, index) => {
      if (item) {
        multiTaskId.push(item.task_id);
      }
    });
    const obj = {
      username: curUsername,
      multiTaskId,
    };
    console.log('arrTaskId==>', multiTaskId);
    console.log('arrData==>', arrData, obj);

    confirmAlert({
      title: 'Confirm to Delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'yes',
          onClick: () => {
            axios({
              method: 'PUT',
              headers: {
                Authorization: sessionStorage.getItem('idtoken'),
              },
              url:
                process.env.REACT_APP_API_BASE +
                'supplier-portal/supplier-adhoc-tasks',
              data: obj,
            })
              .then(response => {
                console.log(response);
                console.log('bulk admin deleted !', response);
                getTableData();
                props.setSelectedList([]);
              })
              .catch(error => {
                console.log(error.response);
                console.log(error.response.status);
                if (
                  error.response.status == 401 &&
                  error.response.data.message ==
                  'The incoming token has expired'
                ) {
                  alert('Session timed out, please login again.');
                  localStorage.clear();
                  sessionStorage.clear();
                  localStorage.clear();
                  // localStorage.removeItem( "accessLevel" );
                  // reset page location
                  console.log('logout succesfully');
                  document.location.replace('/');
                }
              });
          },
        },
        {
          label: 'No',
          onClick: () => {
            return;
          },
        },
      ],
    });
  };

  const columns = [
    {
      title: 'Date',
      dataIndex: 'display_date',
      align: 'center',
      render: (text, record) => (
        <>{moment(record.create_date).format('DD/MM/YYYY')}</>
      ),
    },
    {
      title: 'Request ID',
      dataIndex: 'task_id',
      align: 'center',
      ...getColumnSearchProps('task_id'),
    },
    {
      title: 'Task Name',
      dataIndex: 'taskName',
      align: 'center',
      ...getColumnSearchProps('taskName'),
    },
    {
      title: 'Status',
      dataIndex: 'task_status',
      align: 'center',
      render: (text, record) => (
        <>
          {record.task_status == 'completed'
            ? 'Submitted'
            : record.task_status == 'pending'
              ? 'Pending'
              : record.task_status == 'approved'
                ? 'Approved'
                : record.task_status == 'created'
                  ? 'Task Created'
                  : 'Draft'}
        </>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      align: 'center',
      width: '100px',
      render: (text, record) => {
        console.log('record===>', record);
        return (
          <>
            <button
              style={{
                marginRight: '0px',
                marginLeft: '5px',
                // padding: '0',
                // marginRight: '6px',
                backgroundColor: 'transparent',
                float: 'left',
              }}
              onClick={() => handleView(record)}
            >
              <svg
                height="18"
                width="18"
                fill="#000000"
                xmlns="http://www.w3.org/2000/svg"
                data-name="Layer 3"
                viewBox="0 0 24 24"
                x="0px"
                y="0px"
              >
                <path d="M20.5,9.873A11.123,11.123,0,0,0,12,6,11.123,11.123,0,0,0,3.5,9.873L2.239,11.351a1,1,0,0,0,0,1.3L3.5,14.127A11.123,11.123,0,0,0,12,18a11.125,11.125,0,0,0,8.5-3.872l1.261-1.479a1,1,0,0,0,0-1.3Zm-1.521,2.958A9.134,9.134,0,0,1,12,16a9.131,9.131,0,0,1-6.979-3.17L4.314,12l.707-.83h0A9.134,9.134,0,0,1,12,8a9.131,9.131,0,0,1,6.979,3.17l.707.83Z"></path>
                <path d="M12,9a3,3,0,1,0,3,3A3,3,0,0,0,12,9Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,12,13Z"></path>
              </svg>
            </button>
            {record.task_status == 'completed' ||
              record.task_status == 'draft' ||
              record.task_status == 'pending' ||
              record.task_status == 'all' ||
              record.task_status == 'pending' ||
              record.task_status == 'archived' ? (
              <button
                style={{
                  marginRight: '0',
                  marginLeft: '5px',
                  backgroundColor: 'transparent',
                }}
                onClick={e => deleteTask(record, e)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#000"
                  className="bi bi-trash"
                  viewBox="0 0 16 16"
                >
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                  <path
                    fillRule="evenodd"
                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                  />
                </svg>
              </button>
            ) : null}
          </>
        );
      },
    },
  ];
  return (
    <>
      {loading ? (
        <div className="overlay-box">
          <ClipLoader
            color={color}
            loading={loading}
            css={override}
            size={150}
          />
        </div>
      ) : null}
      {props.tableType == 'completed' ||
        props.tableType == 'draft' ||
        props.tableType == 'pending' ||
        props.tableType == 'all' ||
        props.tableType == 'pending' ||
        props.tableType == 'archived' ? (
        //  console.log('aaaa')
        <>
          <Col
            md={9}
            style={{ float: 'right', textAlign: 'right', marginBottom: '4px' }}
            className="uploadExcel"
          >
            <button
              title="Select records to delete!"
              type="button"
              variant="outlined"
              class="btn btn-primary"
              onClick={e => {
                bulkDelete(e);
              }}
              disabled={props.selectedList.length == 0}
            >
              {' '}
              Delete
            </button>
          </Col>
          <div style={{ clear: 'both' }}></div>
          <div className="antdTable">
            {/* {modifyTableData ? ( */}
            <Table
              columns={columns}
              dataSource={modifyTableData}
              rowSelection={{ ...props.rowSelection }}
              rowKey={record => record.task_id + record.appStatus}
              onChange={(pagination, filters, sorter, currentPageData) =>
                filter(currentPageData.currentDataSource)
              }
              pagination={{
                total: filteredData.length,
                showTotal: total => `Total ${total} items`,
                pageSizeOptions: ['10', '50', '100', '200', '500'],
              }}
            />
            {/* // ) : null} */}
          </div>
        </>
      ) : (
        <div className="antdTable centerTd">
          {/* {modifyTableData ? ( */}
          <Table
            columns={columns}
            dataSource={modifyTableData}
            //  rowSelection={{ ...props.rowSelection }}
            rowKey={record => record.task_id + record.appStatus}
            onChange={(pagination, filters, sorter, currentPageData) =>
              filter(currentPageData.currentDataSource)
            }
            pagination={{
              total: filteredData.length,
              showTotal: total => `Total ${total} items`,
              pageSizeOptions: ['10', '50', '100', '200', '500'],
            }}
          />
          {/* ) : null} */}
        </div>
      )}
      {/* <Col md={9} style={{ float: 'right', textAlign: 'right', marginBottom: '4px' }} className="uploadExcel">
        <button title="Select records to delete!" type="button" variant="outlined" class="btn btn-primary" 
        onClick={(e) => {bulkDelete(e)}}
        disabled={
          props.selectedList.length == 0
        }
        >{' '}Delete</button>
      </Col> */}
      {/* <div style={{ clear: 'both' }}></div>
      <div className="antdTable">
      {modifyTableData ?
        <Table columns={columns}
          dataSource={modifyTableData}
          rowSelection={{ ...props.rowSelection }}
          rowKey={record => record.task_id + record.appStatus}
          onChange={(pagination, filters, sorter, currentPageData) => filter(currentPageData.currentDataSource)}
          pagination={{ total: filteredData.length, showTotal: (total) => `Total ${total} items`, pageSizeOptions: ['10', '50', '100', '200', '500'] }}
        />
        : null}
    </div> */}
    </>
  );
};

export default RequestTable;
