import Page from '../../components/Page';
import React, { useState } from 'react';
import { useEffect } from 'react';
import _ from 'lodash';
import ClipLoader from 'react-spinners/ClipLoader';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
  Table,
} from 'reactstrap';
import ReactDOM from 'react-dom';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes, { object } from 'prop-types';
import { useForm } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import axios from 'axios';
import StoreTable from '../Tables/StoreTable';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import validator from 'validator';
import isEmail from 'validator/lib/isEmail';
import greyBanner from '../../assets/img/logo/Banner Grey.png';
import { MultiSelect } from 'react-multi-select-component';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DatePicker, { registerLocale } from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB';
import { css } from '@emotion/react';
import { isInvalidID, isInvalidUsername, isValidID } from './AddAdmin.js';
const override = css`
  display: block;
  margin: 20% auto;
  border-color: #fff;
`;

const componentStyle = {
  selectFieldStyle: {
      fontSize: "0.7rem",
      width: "100%",
      height:"50%",
      padding: "0.3rem 0.5rem",
      border: "1px solid #ced4da",
  }
}
const TaskManagement = (props) => {
  registerLocale("en-gb", enGB);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [color, setColor] = React.useState("#ffffff");
  const [loading, setLoading] = React.useState(false);
  const {
    storeInputField,
    setStoreInputField,
    storeInputHandler,
    handleMobChange,
    mobNums,
    setmobNums,
    isViewModes,
    setIsViewModes,
    repList,
    setRepList,
    setRepListt,
    repListt,
    retailerList,
    fieldDisabled,
    setFieldDisabled,
    setRequiredError,
    requiredError,
  } = props;

  // const [repList, setRepList] = React.useState([]);
  // const [retailerList, setRetailerList] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [stateData, setStateData] = useState([]);
  const [openUpd, setOpenUpd] = React.useState(false);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [selectedAusState, setSelectedAusState] = useState([]);

  const countryOptions = [
    { label: "Australia", value: "Australia" },
    { label: "New Zealand", value: "New Zealand" },
  ];
  const newStateOptions = [
    { label: "North Island", value: "NI" },
    { label: "South Island", value: "SI" },
  ];

  var ausStateOptions = [];
  const handleChange = (selectedCount) => {
    console.log("ok");
    console.log(selectedCount);
    setSelectedCountry(selectedCount);
    selectedCount.map((rec) => {
      console.log(rec);
      if (rec.value.toLowerCase() == "australia") {
        ausStateOptions.push(
          { label: "ACT", value: "ACT" },
          { label: "NSW", value: "NSW" },
          { label: "NT", value: "NT" },
          { label: "QLD", value: "QLD" },
          { label: "SA", value: "SA" },
          { label: "TAS", value: "TAS" },
          { label: "VIC", value: "VIC" },
          { label: "WA", value: "WA" }
        );
        setStateData(ausStateOptions);
      } else if (rec.value.toLowerCase() == "new zealand") {
        ausStateOptions.push(
          { label: "NI", value: "NI" },
          { label: "SI", value: "SI" }
        );
        setStateData(newStateOptions);
      } else {
        setSelectedCountry(rec);
      }
    });
    console.log(ausStateOptions);
    setStateList(ausStateOptions);
  };
  // const getRepList = () => {
  //   axios({
  //     method: 'GET',
  //     url: process.env.REACT_APP_API_BASE + 'admin-portal',
  //   }).then(response => {
  //     const admindata = response.data.body.Items;
  //     const roles = admindata.filter(item => {
  //       if (item.role && item.role == 'rep') {
  //         return item;
  //       }
  //     });
  //     console.log('rep   Roles', roles);
  //     // setRepList(roles);
  //   });
  // };

  // const getRetailerList = () => {
  //   axios({
  //     method: 'GET',
  //     url: process.env.REACT_APP_API_BASE + 'admin-portal',
  //   }).then(response => {
  //     const admindata = response.data.body.Items;
  //     const roles = admindata.filter(item => {
  //       if (item.role && item.role == 'retailer') {
  //         return item;
  //       }
  //     });
  //     console.log('retailer   Roles', roles);
  //     // setRetailerList(roles);
  //   });
  // };

  // useEffect(() => {
  //   getRepList();
  //   getRetailerList();
  // }, []);

  const addRepCover = (e) => {
    console.log("add repppp", storeInputField);
    setStoreInputField({
      ...storeInputField,
      additionalRep: [
        ...storeInputField.additionalRep,
        {
          rep_cover: "Primary Rep",
          rep_cover_username: "",
          from_date: "",
          to_date: "",
        },
      ],
    });
  };

  const addStore = () => {
    console.log("storeInputField===>", storeInputField);
    var addRep = [];
    var repcoverfirstName;
    var repcoverlastName;
    repListt.map((itm1) => {
      storeInputField.additionalRep.map((itm2) => {
        if (itm2.rep_cover_username == itm1.username) {
          repcoverfirstName = itm1.first_name;
          repcoverlastName = itm1.last_name;
          console.log("print additional rep", itm2);
          addRep.push({
            ...itm2,
            first_name: repcoverfirstName,
            last_name: repcoverlastName,
          });
        }
      });
    });

    const datas = {
      ...storeInputField,
      mob_number: mobNums.store_mob_number,
      store_id: storeInputField.store_id.toUpperCase(),
      additionalRep: storeInputField.rep_leave == "" ? [] : addRep,
      contact: [],
      // state: selectedAusState,
      // country: selectedCountry,
      store_status: props.statusValue ? "active" : "inactive",
    };
    console.log("datasaaaaaa", datas);
    console.log("repcoverfirstName==>", repcoverfirstName, repcoverlastName);

    // const checkStoreVisit = storeInputField.visit_days.every(itm => {
    //   console.log('itm', itm)
    //   return itm.checked == false
    // })
    console.log(
      "storeInputField.visit_days.length checkStoreVisit",
      storeInputField.visit_days.length,
      props.checkStoreVisit
    );
    if (
      storeInputField.retailer_name == "" ||
      storeInputField.retailer_name == null
    ) {
      setRequiredError({ storeRetailer: "Please Select Retailer" });
      return;
    }
    if (props.idError != "") {
      return;
    }
    if (storeInputField.store_id == "" || storeInputField.store_id == null) {
      setRequiredError({ storeId: "Please Enter Store ID" });
      return;
    }
    if (
      storeInputField.store_id != "" &&
      storeInputField.store_id &&
      isInvalidID(storeInputField.store_id)
    ) {
      setRequiredError({
        storeId:
          "Invalid ID, please remove special characters or shorten the ID",
      });
      return;
    }
    if (
      storeInputField.store_name == "" ||
      storeInputField.store_name == null
    ) {
      setRequiredError({ storeName: "Please Enter Store Name" });
      return;
    }
    if (
      storeInputField.store_name != "" &&
      storeInputField.store_name != null &&
      isInvalidUsername(storeInputField.store_name, true)
    ) {
      setRequiredError({
        storeName:
          "Invalid store name, please remove special characters or shorten the store name",
      });
      return;
    }
    if (storeInputField.country == "" || storeInputField.country == null) {
      setRequiredError({ storeCountry: "Please Select Country" });
      return;
    }
    if (storeInputField.state == "" || storeInputField.state == null) {
      setRequiredError({ storeState: "Please Select State" });
      return;
    }
    // if (!validator.isEmail(storeInputField.store_manager_email)) {
    //   setRequiredError({ managerEmail: 'Enter Valid Email' });
    //   return;
    // }
    if (
      storeInputField.senior_rep_username == "" ||
      storeInputField.senior_rep_username == null
    ) {
      setRequiredError({ storeSenior: "Please Select Senior Rep" });
      return;
    }
    // if (
    //   storeInputField.sales_rep_username == '' ||
    //   storeInputField.sales_rep_username == null
    // ) {
    //   setRequiredError({ storeSales: 'Please Select Sales Rep' });
    //   return;
    // }
    // if (storeInputField.support_rep_username == '' || storeInputField.support_rep_username == null) {
    //   setRequiredError({ storeSupport: 'Please Select Support Rep' });
    //   return;
    // }
    if (
      storeInputField.visit_freq == "" ||
      storeInputField.visit_freq == null
    ) {
      setRequiredError({ storeVisit: "Please Select Store Visit Frequency" });
      return;
    }
    if (props.checkStoreVisit) {
      setRequiredError({ storeVisitDays: "Please Select Store Visit days" });
      return;
    }
    if (
      storeInputField.visit_freq == "28" &&
      storeInputField.visit_start_week_no == ""
    ) {
      setRequiredError({ storeWeek: "Please Select Week" });
      return;
    }
    if (storeInputField.rep_leave != "") {
      if (storeInputField.additionalRep[0].rep_cover_username == "") {
        setRequiredError({
          rep_cover_username: "Please Select Merchandiser",
        });
        return;
      }
      if (
        storeInputField.additionalRep[0].from_date == "" ||
        storeInputField.additionalRep[0].from_date == null
      ) {
        setRequiredError({ from_date: "Please Select From Date" });
        return;
      }
      if (
        storeInputField.additionalRep[0].to_date == "" ||
        storeInputField.additionalRep[0].to_date == null
      ) {
        setRequiredError({ to_date: "Please Select To Date" });
        return;
      }
    }

    // const data = {
    //   ...storeInputField,
    //   mob_number: mobNums.store_mob_number,
    //   store_id: ( storeInputField.store_id ).toUpperCase(),
    //   // additionalRep:[...additionalRep, rep_cover_firstName:"",rep_cover_lastName:'']
    //   // state: selectedAusState,
    //   // country: selectedCountry,
    //   store_status: props.statusValue ? 'active' : 'inactive',
    // };

    setLoading(true);

    axios({
      method: "POST",
      headers: {
        Authorization: sessionStorage.getItem("idtoken"),
      },
      url: process.env.REACT_APP_API_BASE + "admin-portal/store",
      data: [datas],
    })
      .then((response) => {
        console.log(response);
        if (response.status == 200) {
          setLoading(false);
          console.log("store added");
          setOpen(true);
          setTimeout(() => {
            setOpen(false);
          }, 3000);
          // reset input field
          props.setStatusValue(true);
          props.setToggleText("Active");
          setStoreInputField({
            role: "store",
            store_status: "inactive",
            retailer_name: "",
            rep_leave: "",
            store_id: "",
            store_size: "",
            store_name: "",
            store_grade: "",
            address: "",
            state: "",
            country: "",
            region: "",
            store_manager: "",
            mob_number: "",
            store_manager_email: "",
            territory: "",
            trade_drive_trough: "",
            whare_sfs: "",
            visit_freq: "",
            visit_time: "",
            visit_start_week_no: "",
            visit_days: [
              { checked: false, name: "Monday" },
              { checked: false, name: "Tuesday" },
              { checked: false, name: "Wednesday" },
              { checked: false, name: "Thursday" },
              { checked: false, name: "Friday" },
              { checked: false, name: "Saturday" },
              { checked: false, name: "Sunday" },
            ],
            senior_rep_username: "",
            senior_status: "active",
            support_rep_username: "",
            support_status: "inactive",
            additionalRep: [
              {
                rep_cover: "Primary Rep",
                rep_cover_username: "",
                from_date: "",
                to_date: "",
              },
            ],
          });
          setmobNums({
            ...mobNums,
            store_mob_number: "",
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.response);
        console.log(error.response.status);
        if (
          error.response.status == 401 &&
          error.response.data.message == "The incoming token has expired"
        ) {
          alert("Session timed out, please login again.");
          localStorage.clear();
          sessionStorage.clear();
          localStorage.clear();
          // localStorage.removeItem( "accessLevel" );
          // reset page location
          console.log("logout succesfully");
          document.location.replace("/");
        }
        if (error.response.status == 504) {
          // setLoading(false);
          setOpen(true);
          setTimeout(() => {
            setOpen(false);
          }, 3000);
          setStoreInputField({
            role: "store",
            store_status: "inactive",
            retailer_name: "",
            rep_leave: "",
            store_id: "",
            store_size: "",
            store_name: "",
            store_grade: "",
            address: "",
            state: "",
            country: "",
            region: "",
            store_manager: "",
            mob_number: "",
            store_manager_email: "",
            territory: "",
            trade_drive_trough: "",
            whare_sfs: "",
            visit_freq: "",
            visit_time: "",
            visit_start_week_no: "",
            visit_days: [
              { checked: false, name: "Monday" },
              { checked: false, name: "Tuesday" },
              { checked: false, name: "Wednesday" },
              { checked: false, name: "Thursday" },
              { checked: false, name: "Friday" },
              { checked: false, name: "Saturday" },
              { checked: false, name: "Sunday" },
            ],
            senior_rep_username: "",
            senior_status: "active",
            support_rep_username: "",
            support_status: "inactive",
            additionalRep: [
              {
                rep_cover: "Primary Rep",
                rep_cover_username: "",
                from_date: "",
                to_date: "",
              },
            ],
          });
          setmobNums({
            ...mobNums,
            store_mob_number: "",
          });
        }
      });
  };

  const updateStore = () => {
    console.log("update store");
    var addRep = [];
    var repcoverfirstName;
    var repcoverlastName;
    repListt.map((itm1) => {
      storeInputField.additionalRep.map((itm2) => {
        if (itm2.rep_cover_username == itm1.username) {
          repcoverfirstName = itm1.first_name;
          repcoverlastName = itm1.last_name;
          addRep.push({
            ...itm2,
            first_name: repcoverfirstName,
            last_name: repcoverlastName,
          });
        }
      });
    });

    const datas = {
      ...storeInputField,
      mob_number: mobNums.store_mob_number,
      store_id: storeInputField.store_id.toUpperCase(),
      additionalRep: storeInputField.rep_leave == "" ? [] : addRep,
      // state: selectedAusState,
      // country: selectedCountry,
      store_status: props.statusValue ? "active" : "inactive",
    };
    console.log(datas);
    // setOpenUpd(true);
    // const data = {
    //   ...storeInputField,
    //   mob_number: mobNums.store_mob_number,
    //   store_id: ( storeInputField.store_id ).toUpperCase(),

    //   store_status: props.statusValue ? 'active' : 'inactive',
    // };
    console.log("update store payload222", datas);

    // return;
    // NEED TO RECHECK NECCESSARY FIELDS WHEN UPDATE A STORE
    if (
      storeInputField.retailer_name == "" ||
      storeInputField.retailer_name == null
    ) {
      setRequiredError({ storeRetailer: "Please Select Retailer" });
      return;
    }
    if (props.idError != "") {
      return;
    }
    if (storeInputField.store_id == "" || storeInputField.store_id == null) {
      setRequiredError({ storeId: "Please Enter Store ID" });
      return;
    }
    if (
      storeInputField.store_id != "" &&
      storeInputField.store_id &&
      isInvalidID(storeInputField.store_id)
    ) {
      setRequiredError({
        storeId:
          "Invalid ID, please remove special characters or shorten the ID",
      });
      return;
    }
    if (
      storeInputField.store_name == "" ||
      storeInputField.store_name == null
    ) {
      setRequiredError({ storeName: "Please Enter Store Name" });
      return;
    }
    if (
      storeInputField.store_name != "" &&
      storeInputField.store_name != null &&
      isInvalidUsername(storeInputField.store_name, true)
    ) {
      setRequiredError({
        storeName:
          "Invalid store name, please remove special characters or shorten the store name",
      });
      return;
    }
    if (storeInputField.country == "" || storeInputField.country == null) {
      setRequiredError({ storeCountry: "Please Select Country" });
      return;
    }
    if (storeInputField.state == "" || storeInputField.state == null) {
      setRequiredError({ storeState: "Please Select State" });
      return;
    }
    if (
      storeInputField.senior_rep_username == "" ||
      storeInputField.senior_rep_username == null
    ) {
      setRequiredError({ storeSenior: "Please Select Senior Rep" });
      return;
    }
    // if (
    //   storeInputField.sales_rep_username == '' ||
    //   storeInputField.sales_rep_username == null
    // ) {
    //   setRequiredError({ storeSales: 'Please Select Sales Rep' });
    //   return;
    // }
    if (
      storeInputField.visit_freq == "" ||
      storeInputField.visit_freq == null
    ) {
      setRequiredError({ storeVisit: "Please Select Store Visit Frequency" });
      return;
    }
    if (props.checkStoreVisit) {
      setRequiredError({ storeVisitDays: "Please Select Store Visit days" });
      return;
    }
    if (
      storeInputField.visit_freq == "Monthly" &&
      storeInputField.visit_start_week_no == ""
    ) {
      setRequiredError({ storeWeek: "Please Select Week" });
      return;
    }
    if (storeInputField.rep_leave != "") {
      if (storeInputField.additionalRep[0].rep_cover_username == "") {
        setRequiredError({
          rep_cover_username: "Please Select Merchandiser",
        });
        return;
      }
      if (
        storeInputField.additionalRep[0].from_date == "" ||
        storeInputField.additionalRep[0].from_date == null
      ) {
        setRequiredError({ from_date: "Please Select From Date" });
        return;
      }
      if (
        storeInputField.additionalRep[0].to_date == "" ||
        storeInputField.additionalRep[0].to_date == null
      ) {
        setRequiredError({ to_date: "Please Select To Date" });
        return;
      }
    }

    console.log("update store payload222", datas);
    setLoading(true);
    axios({
      method: "PUT",
      headers: {
        Authorization: sessionStorage.getItem("idtoken"),
      },
      url: process.env.REACT_APP_API_BASE + "admin-portal/store",
      data: [datas],
    })
      .then((response) => {
        console.log(response);
        console.log(response.data);
        if (response.data) {
          setLoading(false);

          setOpenUpd(true);
          setTimeout(() => {
            setOpenUpd(false);
          }, 3000);
          setIsViewModes({
            ...isViewModes,
            isViewMode_store: false,
          });
          props.setStatusValue(true);
          props.setToggleText("Active");
          setStoreInputField({
            role: "store",
            store_status: "inactive",
            rep_leave: "",
            retailer_name: "",
            store_id: "",
            store_size: "",
            store_name: "",
            store_grade: "",
            address: "",
            state: "",
            country: "",
            region: "",
            store_manager: "",
            mob_number: "",
            store_manager_email: "",
            territory: "",
            trade_drive_trough: "",
            whare_sfs: "",
            visit_freq: "",
            visit_time: "",
            visit_start_week_no: "",
            visit_days: [
              { checked: false, name: "Monday" },
              { checked: false, name: "Tuesday" },
              { checked: false, name: "Wednesday" },
              { checked: false, name: "Thursday" },
              { checked: false, name: "Friday" },
              { checked: false, name: "Saturday" },
              { checked: false, name: "Sunday" },
            ],
            senior_rep_username: "",
            senior_status: "active",
            support_rep_username: "",
            support_status: "inactive",
            additionalRep: [
              {
                rep_cover: "Primary Rep",
                rep_cover_username: "",
                from_date: "",
                to_date: "",
              },
            ],
          });
          setmobNums({
            ...mobNums,
            store_mob_number: "",
          });
        } else {
          console.log("not 200 status", response);
        }
      })
      .catch((error) => {
        setLoading(false);

        console.log(error.response);
        console.log(error.response.status);
        if (
          error.response.status == 401 &&
          error.response.data.message == "The incoming token has expired"
        ) {
          alert("Session timed out, please login again.");
          localStorage.clear();
          sessionStorage.clear();
          localStorage.clear();
          // localStorage.removeItem( "accessLevel" );
          // reset page location
          console.log("logout succesfully");
          document.location.replace("/");
        }
        if (error.response.status == 504) {
          // setLoading(false);
          setIsViewModes({
            ...isViewModes,
            isViewMode_store: false,
          });
          props.setStatusValue(true);
          props.setToggleText("Active");
          setOpenUpd(true);
          setTimeout(() => {
            setOpenUpd(false);
          }, 3000);
          setStoreInputField({
            role: "store",
            store_status: "inactive",
            rep_leave: "",
            retailer_name: "",
            store_id: "",
            store_size: "",
            store_name: "",
            store_grade: "",
            address: "",
            state: "",
            country: "",
            region: "",
            store_manager: "",
            mob_number: "",
            store_manager_email: "",
            territory: "",
            trade_drive_trough: "",
            whare_sfs: "",
            visit_freq: "",
            visit_time: "",
            visit_start_week_no: "",
            visit_days: [
              { checked: false, name: "Monday" },
              { checked: false, name: "Tuesday" },
              { checked: false, name: "Wednesday" },
              { checked: false, name: "Thursday" },
              { checked: false, name: "Friday" },
              { checked: false, name: "Saturday" },
              { checked: false, name: "Sunday" },
            ],
            senior_rep_username: "",
            senior_status: "active",
            support_rep_username: "",
            support_status: "inactive",
            additionalRep: [
              {
                rep_cover: "Primary Rep",
                rep_cover_username: "",
                from_date: "",
                to_date: "",
              },
            ],
          });
          setmobNums({
            ...mobNums,
            store_mob_number: "",
          });
        }
      });
  };

  //////reset Input field
  const resetInputField = () => {
    props.setStatusValue(true);
    props.setToggleText("Active");
    setStoreInputField({
      role: "store",
      store_status: "inactive",
      retailer_name: "",
      rep_leave: "",
      store_id: "",
      store_size: "",
      store_name: "",
      store_grade: "",
      address: "",
      state: "",
      country: "",
      region: "",
      store_manager: "",
      mob_number: "",
      store_manager_email: "",
      territory: "",
      trade_drive_trough: "",
      whare_sfs: "",
      visit_freq: "",
      visit_time: "",
      visit_start_week_no: "",
      visit_days: [
        { checked: false, name: "Monday" },
        { checked: false, name: "Tuesday" },
        { checked: false, name: "Wednesday" },
        { checked: false, name: "Thursday" },
        { checked: false, name: "Friday" },
        { checked: false, name: "Saturday" },
        { checked: false, name: "Sunday" },
      ],
      senior_rep_username: "",
      senior_status: "active",
      support_rep_username: "",
      support_status: "inactive",
      additionalRep: [
        {
          rep_cover: "Primary Rep",
          rep_cover_username: "",
          from_date: "",
          to_date: "",
        },
      ],
    });

    setmobNums({
      ...mobNums,
      store_mob_number: "",
    });

    setIsViewModes({
      ...isViewModes,
      isViewMode_store: false,
    });
    setFieldDisabled({
      ...fieldDisabled,
      fieldDisabled_store: false,
    });
    setRequiredError("");
  };

  const handleClose = () => {
    props.setStatusValue(true);
    props.setToggleText("Active");
    setOpen(false);
    setOpenUpd(false);
    // setBtnUpdate(false);
    setIsViewModes({
      ...isViewModes,
      isViewMode_manager: false,
    });
    setmobNums({
      manager_mob_number: "",
    });
    setStoreInputField({
      role: "store",
      store_status: "inactive",
      retailer_name: "",
      store_id: "",
      store_size: "",
      store_name: "",
      store_grade: "",
      address: "",
      state: "",
      country: "",
      region: "",
      store_manager: "",
      mob_number: "",
      store_manager_email: "",
      territory: "",
      trade_drive_trough: "",
      whare_sfs: "",
      visit_freq: "",
      visit_time: "",
      visit_start_week_no: "",
      visit_days: [
        { checked: false, name: "Monday" },
        { checked: false, name: "Tuesday" },
        { checked: false, name: "Wednesday" },
        { checked: false, name: "Thursday" },
        { checked: false, name: "Friday" },
        { checked: false, name: "Saturday" },
        { checked: false, name: "Sunday" },
      ],
      senior_rep_username: "",
      senior_status: "active",
      support_rep_username: "",
      support_status: "inactive",
      additionalRep: [
        {
          rep_cover: "Primary Rep",
          rep_cover_username: "",
          from_date: "",
          to_date: "",
        },
      ],
    });
  };
  // const [repData, setRepData] = React.useState([]);
  // useEffect(() => {}, []);

  const renderRepOptions = (isSupportRepOptions = false) => {
    console.log(repList);
    console.log(
      "current senior_rep_username , isSupportRepOptions",
      isSupportRepOptions,
      storeInputField.senior_rep_username
    );
    return repList.map((item) => (
      <>
        {isSupportRepOptions &&
        storeInputField.senior_rep_username &&
        storeInputField.senior_rep_username.length > 0 &&
        item.username == storeInputField.senior_rep_username ? null : (
          <>
            {item.rep_type == "sales" && item.state == storeInputField.state ? (
              <option
                value={`${item.username}`}
              >{`${item.first_name} ${item.last_name}`}</option>
            ) : null}
          </>
        )}
        {/* <option
          value={`${item.username}`}
          disabled={isSupportRepOptions && storeInputField.senior_rep_username && storeInputField.senior_rep_username.length > 0 && item.username == storeInputField.senior_rep_username ? true : false}
          // style={{ color: isSupportRepOptions && storeInputField.senior_rep_username && storeInputField.senior_rep_username.length > 0 && item.username == storeInputField.senior_rep_username ? 'gray !important' : '#000' }}
          className={isSupportRepOptions && storeInputField.senior_rep_username && storeInputField.senior_rep_username.length > 0 && item.username == storeInputField.senior_rep_username ? 'disabled-option' : ''}
        >{`${item.first_name} ${item.last_name}`}</option> */}
      </>
    ));
  };
  const storeLeaveHandler = (e) => {
    if (e.target.value !== "") {
      console.log("leave", e.target.value);
      setStoreInputField({ ...storeInputField, senior_status: "inactive" });
    } else {
      console.log("empty");
      setStoreInputField({ ...storeInputField, senior_status: "active" });
    }
  };
  // Need to remove senior rep in the support rep list and sales rep
  const renderMerchandiserOptions = (isSupportRepOptions = false) => {
    console.log("inside merchandiser listing", repList);
    console.log(storeInputField.state);
    return repList.map((item) => (
      <>
        {item.rep_type == "sales" ? null : item.rep_type == "merchandiser" &&
          isSupportRepOptions &&
          storeInputField.senior_rep_username &&
          storeInputField.senior_rep_username.length > 0 &&
          item.username ==
            storeInputField.senior_rep_username ? null : item.rep_type ==
            "merchandiser" && item.state == storeInputField.state ? (
          <option
            value={`${item.username}`}
          >{`${item.first_name} ${item.last_name}`}</option>
        ) : null}
        {/* {
          (item.rep_type == 'merchandiser' ?
            <option
              value={`${item.username}`}
            >{`${item.first_name} ${item.last_name}`}</option>
            : null
          )} */}
        {/* <option
          value={`${item.username}`}
          disabled={isSupportRepOptions && storeInputField.senior_rep_username && storeInputField.senior_rep_username.length > 0 && item.username == storeInputField.senior_rep_username ? true : false}
          // style={{ color: isSupportRepOptions && storeInputField.senior_rep_username && storeInputField.senior_rep_username.length > 0 && item.username == storeInputField.senior_rep_username ? 'gray !important' : '#000' }}
          className={isSupportRepOptions && storeInputField.senior_rep_username && storeInputField.senior_rep_username.length > 0 && item.username == storeInputField.senior_rep_username ? 'disabled-option' : ''}
        >{`${item.first_name} ${item.last_name}`}</option> */}
      </>
    ));
  };
  const renderRetailerOptions = () => {
    return retailerList.map((item) => (
      <option value={item.full_company_name}>{item.full_company_name}</option>
    ));
  };

  // to disabled every field when under view mode
  useEffect(() => {
    //  if(storeInputField.state ==
    // axios({
    //   method: 'GET',
    //   url: process.env.REACT_APP_API_BASE + 'admin-portal/rep-with-stores',
    //   headers: {
    //     Authorization: sessionStorage.getItem('idtoken'),
    //   },
    // }).then(respons => {
    //   console.log(respons);
    //   const data = respons.data.body;
    //   data.map(rec => {
    //     if (rec.role == 'rep') {
    //       console.log(rec);
    //       console.log(record);
    //       if (rec.username == record.support_rep_username) {
    //         if (rec.status == 'active') {
    //           console.log('ook');
    //         }
    //       }
    //     }
    //   });
    // });
    // if (isViewModes.isViewMode_store == true) {

    // }
    console.log("storeInputField", storeInputField);
    var selectedArr = [];
    storeInputField.visit_days &&
      storeInputField.visit_days.map((item) => {
        if (item.checked == true) {
          console.log(item.checked);
          console.log(item.name);
          selectedArr.push(item.name);
        }
      });

    var list = [];

    repListt.map((rec) => {
      if (storeInputField.state == rec.state && rec.state != "") {
        list.push(rec);
      }
    });
    // setRepList(list)
    console.log(repListt);
    console.log("listing based on state", list);
    var aa11 = [];
    list.map((rec) => {
      console.log(rec.availabeInfo);
      rec.availabeInfo &&
        rec.availabeInfo.map((col) => {
          aa11.push({
            availablity: col.availablity,
            avail_val: col.avail_val,
            username: rec.username,
          });
        });
    });
    var tt = [];
    aa11.map((rec) => {
      if (rec.avail_val == true) {
        tt.push(rec);
      }
    });
    console.log(tt);
    const testOrderF = _.groupBy(tt, "username");
    console.log(testOrderF);
    var dataa = [];
    var days = [];
    Object.entries(testOrderF).map(([key, val]) => {
      val.map((rec) => {
        days.push(rec.availablity);
      });
      dataa.push({ [key]: days });
      days = [];
    });
    console.log(dataa);
    var repNames = [];
    console.log(storeInputField.visit_days);
    dataa.map((rec) => {
      console.log(rec);
      Object.entries(rec).map(([key, value]) => {
        var result = selectedArr.every(function (val) {
          return value.indexOf(val) >= 0;
        });
        console.log(result);
        if (result) {
          repNames.push(key);
        }
      });
    });
    console.log(repNames);
    console.log(props.repList);
    var repData = [];
    repListt.map((rec) => {
      repNames.map((rec1) => {
        if (rec.username == rec1) {
          repData.push(rec);
        } else {
          // repData([]);
        }
      });
    });
    console.log(repData);
    props.setRepList(repData);
    const disabledVal = props.fieldDisabled.fieldDisabled_store;
    const store_form_inputs = document.querySelectorAll("#store_form input");
    const store_form_selects = document.querySelectorAll("#store_form select");
    const store_action_btns = document.querySelectorAll(".action_btn");
    // console.log(store_form_inputs);
    for (let i = 0; i < store_form_inputs.length; i++) {
      // store_id should not change
      if (store_form_inputs[i].name !== "store_id") {
        store_form_inputs[i].disabled = disabledVal;
      }
    }
    for (let i = 0; i < store_form_selects.length; i++) {
      store_form_selects[i].disabled = disabledVal;
    }
    for (let i = 0; i < store_action_btns.length; i++) {
      store_action_btns[i].style.pointerEvents = disabledVal ? "none" : "auto";
    }
  }, [props.fieldDisabled]);
  // const [text, setText] = React.useState()
  // const handleChange = (value) => {
  //   setText(value)
  // }

  const renderStateOptions = () => {
    console.log("renderStateOptions", storeInputField, storeInputField.country);
    return (
      <Input
        type="select"
        name="state"
        className="admin-input"
        placeholder="State"
        onChange={storeInputHandler}
        value={storeInputField.state}
        required
        style={{...componentStyle.selectFieldStyle, height:"60%"}}
      >
        {storeInputField &&
        storeInputField.country &&
        storeInputField.country.length == 0 ? (
          <option selected value="">
            Please Select Country First
          </option>
        ) : storeInputField.country &&
          storeInputField.country.toLowerCase() == "australia" ? (
          <>
            <option selected value="">
              Select State
            </option>
            <option value="ACT">Australian Capital Territory</option>
            <option value="NSW">New South Wales</option>
            <option value="NT">Northern Territory</option>
            <option value="SA">South Australia</option>
            <option value="QLD">Queensland</option>
            <option value="TAS">Tasmania</option>
            <option value="VIC">Victoria</option>
            <option value="WA">Western Australia</option>
          </>
        ) : storeInputField.country &&
          storeInputField.country.toLowerCase() == "new zealand" ? (
          <>
            <option selected value="">
              Select State
            </option>
            <option value="NI">North Island</option>
            <option value="SI">South Island</option>
          </>
        ) : (
          <option selected value="">
            Select State
          </option>
        )}
      </Input>
    );
  };
  return (
    <div className={loading ? "parentDisable" : ""}>
      {loading ? (
        <div className="overlay-box">
          <ClipLoader
            color={color}
            loading={loading}
            css={override}
            size={150}
          />
        </div>
      ) : null}
      <Dialog open={open}>
        <DialogContent>
          <DialogContentText>
            <Row>
              <Col md={12}>
                <b style={{ color: "red" }}>Thank you</b>
                <br />
                <br />
                <label style={{ color: "red" }}>
                  Store details successfully added
                </label>
              </Col>
            </Row>
            <br />
          </DialogContentText>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog open={openUpd}>
        <DialogContent>
          <DialogContentText>
            <Row>
              <Col md={12}>
                <b style={{ color: "red" }}>Thank you</b>
                <br />
                <br />
                <label style={{ color: "red" }}>
                  Store details successfully updated
                </label>
              </Col>
            </Row>
            <br />
          </DialogContentText>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Page>
        <Row>
          <Col xl={12} lg={12} md={12} style={{ paddingLeft: "0" }}>
            <Card id="userManagement">
              <CardBody
                style={{ background: "#fff", padding: "0px 18px 18px 0px" }}
              >
                <div id="tabCol" style={{ paddingTop: "0%" }}>
                  {/* <Row>
                    <Col md="6">
                      <b style={{ fontFamily: 'Arial Bold' }}>STORE - SETUP </b>
                    </Col>
                  </Row> */}
                  <Form id="store_form">
                    <FormGroup>
                      {/* <label class="switch">
                        <input
                          type="checkbox"
                          id="myCheck"
                          name="store_status"
                          onChange={storeInputHandler}
                          checked={
                            storeInputField.store_status == 'inactive'
                              ? false
                              : true
                          }
                        />
                        <span className="slider round"></span>
                      </label>
                      <p style={{ fontSize: '14px' }}>
                        {storeInputField &&
                          storeInputField.store_status == 'inactive'
                          ? 'Inactive'
                          : 'Active'}
                      </p> */}
                      <Row style={{ marginLeft: "35px" }}>
                        <Col></Col>

                        <Col
                          style={{
                            margin: "0px 0px 8px 0px",
                            paddingLeft: "35px",
                          }}
                        >
                          <h6
                            style={{
                              margin: "0px",
                            }}
                          >
                            Store Visit Days
                            <span style={{ color: "red" }}> *</span>{" "}
                          </h6>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <Row>
                            <Col
                              style={{
                                border: "2px solid #E6E6E6",
                                borderRadius: "32px",
                                padding: "30px",
                                margin: "0px 0px 0px 16px",
                                height: "100%",
                              }}
                            >
                              <Row>
                                <Col
                                  md="6"
                                  style={{
                                    margin: "0px",
                                  }}
                                >
                                  <label>
                                    Retailer
                                    <span style={{ color: "red" }}>
                                      {" "}
                                      *
                                    </span>{" "}
                                  </label>
                                  <Input
                                    type="select"
                                    name="retailer_name"
                                    className="admin-input"
                                    onChange={storeInputHandler}
                                    value={storeInputField.retailer_name}
                                    required
                                    style={{...componentStyle.selectFieldStyle}}
                                  >
                                    <option selected value="">
                                      Select Retailer
                                    </option>
                                    {renderRetailerOptions()}
                                  </Input>
                                  <p style={{ color: "red", fontSize: "12px" }}>
                                    {requiredError.storeRetailer}
                                  </p>
                                </Col>
                                <Col
                                  md="6"
                                  style={{
                                    margin: "0px",
                                  }}
                                >
                                  <label>
                                    Store ID{" "}
                                    <span style={{ color: "red" }}> *</span>{" "}
                                  </label>
                                  <Input
                                    type="text"
                                    name="store_id"
                                    className="admin-input"
                                    onChange={storeInputHandler}
                                    value={storeInputField.store_id}
                                    disabled={isViewModes.isViewMode_store}
                                    placeholder="Store Id"
                                    style={{ textTransform: "uppercase" }}
                                  />
                                  <p style={{ color: "red", fontSize: "12px" }}>
                                    {requiredError.storeId}
                                    {props.idError}
                                  </p>
                                </Col>
                                <Col md="6">
                                  <label>
                                    Store Size
                                    <span style={{ color: "red" }}></span>{" "}
                                  </label>
                                  <Input
                                    type="text"
                                    name="store_size"
                                    className="admin-input"
                                    placeholder="Store Size"
                                    onChange={storeInputHandler}
                                    value={storeInputField.store_size}
                                  />
                                </Col>
                                <Col md="6">
                                  <label>
                                    Store Name
                                    <span style={{ color: "red" }}>
                                      {" "}
                                      *
                                    </span>{" "}
                                  </label>
                                  <Input
                                    type="text"
                                    name="store_name"
                                    className="admin-input"
                                    placeholder="Store Name"
                                    onChange={storeInputHandler}
                                    value={storeInputField.store_name}
                                  />
                                  <p style={{ color: "red", fontSize: "12px" }}>
                                    {requiredError.storeName}
                                  </p>
                                </Col>
                                <Col md="6">
                                  <label>
                                    Store Grade
                                    <span style={{ color: "red" }}></span>{" "}
                                  </label>
                                  <Input
                                    type="text"
                                    name="store_grade"
                                    className="admin-input"
                                    placeholder="Store Grade"
                                    onChange={storeInputHandler}
                                    value={storeInputField.store_grade}
                                  />
                                </Col>
                                <Col md="6">
                                  <label>
                                    Address
                                    <span style={{ color: "red" }}></span>{" "}
                                  </label>
                                  <Input
                                    type="text"
                                    name="address"
                                    className="admin-input"
                                    placeholder="Address"
                                    onChange={storeInputHandler}
                                    value={storeInputField.address}
                                  />
                                </Col>
                                <Col md="6">
                                  <label>
                                    Country
                                    <span style={{ color: "red" }}></span>
                                    <span style={{ color: "red" }}>
                                      {" "}
                                      *
                                    </span>{" "}
                                  </label>
                                  <Input
                                    type="select"
                                    name="country"
                                    className="admin-input"
                                    placeholder="country"
                                    onChange={storeInputHandler}
                                    value={storeInputField.country}
                                    required
                                    style={{...componentStyle.selectFieldStyle, height: "60%"}}
                                  >
                                    <option selected value="">
                                      Select country
                                    </option>
                                    <option value="Australia">Australia</option>
                                    <option value="New Zealand">
                                      New Zealand
                                    </option>
                                  </Input>
                                  <p style={{ color: "red", fontSize: "12px" }}>
                                    {requiredError.storeCountry}
                                  </p>
                                </Col>
                                <Col md="6">
                                  <label>
                                    State<span style={{ color: "red" }}></span>
                                    <span style={{ color: "red" }}>
                                      {" "}
                                      *
                                    </span>{" "}
                                  </label>
                                  {renderStateOptions()}
                                  {/* <Input
                                    type="select"
                                    name="state"
                                    className="admin-input"
                                    placeholder="State"
                                    onChange={storeInputHandler}
                                    value={storeInputField.state}
                                    required
                                  >
                                    <option selected value="">
                                      Select State
                                    </option>
                                    <option value="ACT">
                                      Australian Capital Territory
                                    </option>
                                    <option value="NSW">New South Wales</option>
                                    <option value="NT">
                                      Northern Territory
                                    </option>
                                    <option value="SA">South Australia</option>
                                    <option value="QLD">Queensland</option>
                                    <option value="TAS">Tasmania</option>
                                    <option value="VIC">Victoria</option>
                                    <option value="WA">
                                      Western Australia
                                    </option>
                                    <option value="NI">North Island</option>
                                    <option value="SI">South Island</option>
                                  </Input> */}
                                  <p style={{ color: "red", fontSize: "12px" }}>
                                    {requiredError.storeState}
                                  </p>
                                </Col>
                                <Col md="6">
                                  <label>
                                    Region<span style={{ color: "red" }}></span>{" "}
                                  </label>
                                  <Input
                                    type="text"
                                    name="region"
                                    className="admin-input"
                                    placeholder="Region"
                                    onChange={storeInputHandler}
                                    value={storeInputField.region}
                                  />
                                </Col>
                                <Col md="6">
                                  <label>
                                    Store Manager
                                    <span style={{ color: "red" }}></span>{" "}
                                  </label>
                                  <Input
                                    type="text"
                                    name="store_manager"
                                    className="admin-input"
                                    placeholder="Store Manager"
                                    onChange={storeInputHandler}
                                    value={storeInputField.store_manager}
                                  />
                                </Col>
                                <Col md="6">
                                  <label>
                                    Store Manager No.
                                    <span style={{ color: "red" }}></span>{" "}
                                  </label>
                                  <PhoneInput
                                    className="phnclass admin-input"
                                    name="mob_number"
                                    style={{
                                      background:
                                        "#E6E6E6 0% 0% no-repeat padding-box",
                                      borderRadius: "32px",
                                    }}
                                    onChange={(val) =>
                                      handleMobChange(val, "store_mob_number")
                                    }
                                    value={mobNums.store_mob_number}
                                  />
                                </Col>
                                <Col md="6">
                                  <label>
                                    Store Manager Email
                                    <span style={{ color: "red" }}>
                                      {" "}
                                      {/* * */}
                                    </span>{" "}
                                  </label>
                                  <Input
                                    type="email"
                                    name="store_manager_email"
                                    className="admin-input"
                                    placeholder="Email"
                                    onChange={storeInputHandler}
                                    value={storeInputField.store_manager_email}
                                  />
                                  {/* <p style={{ color: 'red', fontSize: '12px' }}>
                                    {requiredError.managerEmail}
                                  </p> */}
                                </Col>
                                <Col md="6">
                                  <label>
                                    Territory
                                    <span style={{ color: "red" }}></span>{" "}
                                  </label>
                                  <Input
                                    type="text"
                                    name="territory"
                                    className="admin-input"
                                    placeholder="Territory"
                                    onChange={storeInputHandler}
                                    value={storeInputField.territory}
                                  />
                                </Col>
                                <Col md="6">
                                  <label>Trade Drive Through? </label>
                                  <Input
                                    type="select"
                                    name="trade_drive_trough"
                                    className="admin-input"
                                    onChange={storeInputHandler}
                                    value={storeInputField.trade_drive_trough}
                                    required
                                    style={{...componentStyle.selectFieldStyle, height: "60%"}}
                                  >
                                    <option selected value="">
                                      Select Trade Drive
                                    </option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </Input>
                                </Col>
                                <Col md="6">
                                  <label>Warehouse or SFS? </label>
                                  <Input
                                    type="select"
                                    name="whare_sfs"
                                    className="admin-input"
                                    onChange={storeInputHandler}
                                    value={storeInputField.whare_sfs}
                                    required
                                    style={{...componentStyle.selectFieldStyle, height: "60%"}}
                                  >
                                    <option selected value="">
                                      Select Option
                                    </option>
                                    <option value="Warehouse">Warehouse</option>
                                    <option value="SFS">SFS</option>
                                  </Input>
                                </Col>
                                <Col md="6">
                                  <label>
                                    Store Visit Frequency{" "}
                                    <span style={{ color: "red" }}> *</span>{" "}
                                  </label>
                                  <Input
                                    type="select"
                                    name="visit_freq"
                                    className="admin-input"
                                    onChange={storeInputHandler}
                                    value={storeInputField.visit_freq}
                                    required
                                    style={{...componentStyle.selectFieldStyle, height: "60%"}}
                                  >
                                    <option selected value="">
                                      Select Visit Frequency
                                    </option>
                                    <option value="7">Days 7</option>
                                    <option value="14odd">Days 14 Odd</option>
                                    <option value="14even">Days 14 Even</option>
                                    <option value="28">Days 28</option>
                                  </Input>
                                  <p style={{ color: "red", fontSize: "12px" }}>
                                    {requiredError.storeVisit}
                                  </p>
                                </Col>
                                <Col md="6">
                                  <label>Preferred Visit Time</label>
                                  <Input
                                    type="select"
                                    name="visit_time"
                                    className="admin-input"
                                    onChange={storeInputHandler}
                                    value={storeInputField.visit_time}
                                    required
                                    style={{...componentStyle.selectFieldStyle, height: "70%"}}
                                  >
                                    <option selected value="">
                                      Select Preferred Time
                                    </option>
                                    <option value="AM">AM</option>
                                    <option value="PM">PM</option>
                                  </Input>
                                </Col>
                                {storeInputField.visit_freq == "28" ? (
                                  <Col md="6">
                                    <label>
                                      If Monthly, Select Week
                                      <span style={{ color: "red" }}>
                                        {" "}
                                        *
                                      </span>{" "}
                                    </label>
                                    <Input
                                      type="select"
                                      name="visit_start_week_no"
                                      className="admin-input"
                                      onChange={storeInputHandler}
                                      value={
                                        storeInputField.visit_start_week_no
                                      }
                                    >
                                      <option selected value="">
                                        Select Week
                                      </option>
                                      <option value="1">Week 1</option>
                                      <option value="2">Week 2</option>
                                      <option value="3">Week 3</option>
                                      <option value="4">Week 4</option>
                                    </Input>
                                    <p
                                      style={{ color: "red", fontSize: "12px" }}
                                    >
                                      {requiredError.storeWeek}
                                    </p>
                                  </Col>
                                ) : null}
                              </Row>
                            </Col>
                          </Row>
                          {isViewModes.isViewMode_store == true ? (
                            <>
                              {Array.isArray(storeInputField.contact) &&
                              storeInputField.contact.length > 0
                                ? storeInputField.contact.map((rec) => (
                                    <Col>
                                      <Row
                                        style={{ padding: "30px 0px 0px 35px" }}
                                      >
                                        <Col>
                                          <h6>Contact Information</h6>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col
                                          // className="hide-scrollbar"
                                          style={{
                                            border: "2px solid #E6E6E6",
                                            borderRadius: "32px",
                                            padding: "30px",
                                            marginLeft: "20px",
                                            maxHeight: "250px",
                                            overflowY: "scroll",
                                          }}
                                        >
                                          <Row>
                                            <Col md="6">
                                              <label>
                                                Name
                                                <span
                                                  style={{ color: "red" }}
                                                ></span>{" "}
                                              </label>
                                              <Input
                                                type="text"
                                                name="store_size"
                                                className="admin-input"
                                                placeholder="Name"
                                                onChange={storeInputHandler}
                                                value={rec.name}
                                              />
                                            </Col>
                                            <Col md="6">
                                              <label>
                                                Position
                                                <span
                                                  style={{ color: "red" }}
                                                ></span>{" "}
                                              </label>
                                              <Input
                                                type="text"
                                                name="store_size"
                                                className="admin-input"
                                                placeholder="Position"
                                                onChange={storeInputHandler}
                                                value={rec.position}
                                              />
                                            </Col>
                                            <Col md="6">
                                              <label>
                                                Contact
                                                <span
                                                  style={{ color: "red" }}
                                                ></span>{" "}
                                              </label>
                                              <Input
                                                type="text"
                                                name="store_size"
                                                className="admin-input"
                                                placeholder="Contact"
                                                onChange={storeInputHandler}
                                                value={rec.phone}
                                              />
                                            </Col>
                                            <Col md="6">
                                              <label>
                                                Email
                                                <span
                                                  style={{ color: "red" }}
                                                ></span>{" "}
                                              </label>
                                              <Input
                                                type="text"
                                                name="store_size"
                                                className="admin-input"
                                                placeholder="Email"
                                                onChange={storeInputHandler}
                                                value={rec.email}
                                              />
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                    </Col>
                                  ))
                                : null}
                            </>
                          ) : null}

                          {/* {storeInputField.store_id == '' ||
                            storeInputField.store_id == null ? null : (
                            <Col>
                              <Row style={{ padding: '30px 0px 0px 35px' }}>
                                <Col>
                                  <h6>Contact Information</h6>
                                </Col>
                              </Row>
                              <Row>
                                <Col
                                  // className="hide-scrollbar"
                                  style={{
                                    border: '2px solid #E6E6E6',
                                    borderRadius: '32px',
                                    padding: '30px',
                                    marginLeft: '20px',
                                    maxHeight: '250px',
                                    overflowY: 'scroll',
                                  }}
                                >
                                  {Array.isArray(storeInputField.contact) &&
                                    storeInputField.contact.length > 0 ? (
                                    storeInputField.contact.map(rec => (
                                      <Row>
                                        <Col md="6">
                                          <label>
                                            Name
                                            <span
                                              style={{ color: 'red' }}
                                            ></span>{' '}
                                          </label>
                                          <Input
                                            type="text"
                                            name="store_size"
                                            className="admin-input"
                                            placeholder="Name"
                                            onChange={storeInputHandler}
                                            value={rec.name}
                                          />
                                        </Col>
                                        <Col md="6">
                                          <label>
                                            Position
                                            <span
                                              style={{ color: 'red' }}
                                            ></span>{' '}
                                          </label>
                                          <Input
                                            type="text"
                                            name="store_size"
                                            className="admin-input"
                                            placeholder="Position"
                                            onChange={storeInputHandler}
                                            value={rec.position}
                                          />
                                        </Col>
                                        <Col md="6">
                                          <label>
                                            Contact
                                            <span
                                              style={{ color: 'red' }}
                                            ></span>{' '}
                                          </label>
                                          <Input
                                            type="text"
                                            name="store_size"
                                            className="admin-input"
                                            placeholder="Contact"
                                            onChange={storeInputHandler}
                                            value={rec.phone}
                                          />
                                        </Col>
                                        <Col md="6">
                                          <label>
                                            Email
                                            <span
                                              style={{ color: 'red' }}
                                            ></span>{' '}
                                          </label>
                                          <Input
                                            type="text"
                                            name="store_size"
                                            className="admin-input"
                                            placeholder="Email"
                                            onChange={storeInputHandler}
                                            value={rec.email}
                                          />
                                        </Col>
                                      </Row>
                                    ))
                                  ) : (
                                    <Row>
                                      <Col md="6">
                                        <label>
                                          Name
                                          <span
                                            style={{ color: 'red' }}
                                          ></span>{' '}
                                        </label>
                                        <Input
                                          type="text"
                                          name="store_size"
                                          className="admin-input"
                                          placeholder="Name"
                                          onChange={storeInputHandler}
                                        />
                                      </Col>
                                      <Col md="6">
                                        <label>
                                          Position
                                          <span
                                            style={{ color: 'red' }}
                                          ></span>{' '}
                                        </label>
                                        <Input
                                          type="text"
                                          name="store_size"
                                          className="admin-input"
                                          placeholder="Position"
                                          onChange={storeInputHandler}
                                        />
                                      </Col>
                                      <Col md="6">
                                        <label>
                                          Contact
                                          <span
                                            style={{ color: 'red' }}
                                          ></span>{' '}
                                        </label>
                                        <Input
                                          type="text"
                                          name="store_size"
                                          className="admin-input"
                                          placeholder="Contact"
                                          onChange={storeInputHandler}
                                        />
                                      </Col>
                                      <Col md="6">
                                        <label>
                                          Email
                                          <span
                                            style={{ color: 'red' }}
                                          ></span>{' '}
                                        </label>
                                        <Input
                                          type="text"
                                          name="store_size"
                                          className="admin-input"
                                          placeholder="Email"
                                          onChange={storeInputHandler}
                                        />
                                      </Col>
                                    </Row>
                                  )}
                                </Col>
                              </Row>
                            </Col>
                          )} */}

                          <Row style={{ padding: "70px 0px 0px 30px" }}>
                            {isViewModes.isViewMode_store == false ? (
                              <Col md={12} className="martop">
                                <Button
                                  onClick={addStore}
                                  variant="outlined"
                                  color="primary"
                                >
                                  <span>
                                    {" "}
                                    <b> Add Store</b>{" "}
                                  </span>
                                </Button>
                                <Button
                                  onClick={resetInputField}
                                  variant="outlined"
                                  color="primary"
                                >
                                  <span>
                                    {" "}
                                    <b>Cancel</b>{" "}
                                  </span>
                                </Button>
                              </Col>
                            ) : (
                              <Col md={12} className="martop">
                                <Button
                                  onClick={updateStore}
                                  variant="outlined"
                                  color="primary"
                                >
                                  <span>
                                    {" "}
                                    <b> Update Store</b>{" "}
                                  </span>
                                </Button>
                                <Button
                                  onClick={resetInputField}
                                  variant="outlined"
                                  color="primary"
                                >
                                  <span>
                                    {" "}
                                    <b>Cancel</b>{" "}
                                  </span>
                                </Button>
                              </Col>
                            )}
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <Col lg="12" md="12">
                              <Row>
                                <Col
                                  style={{
                                    border: "2px solid #E6E6E6",
                                    borderRadius: "32px",
                                    padding: "30px",
                                    marginLeft: "20px",
                                  }}
                                >
                                  {" "}
                                  {storeInputField &&
                                    storeInputField.visit_days &&
                                    storeInputField.visit_days.map((item) => {
                                      return (
                                        <label style={{ width: "50%" }}>
                                          <Row style={{ marginLeft: "10px" }}>
                                            <Col md="7">{item.name}</Col>
                                            <Col md="5">
                                              <label className="switch">
                                                <Input
                                                  type="checkbox"
                                                  id="myCheck"
                                                  checked={item.checked}
                                                  name={`visit_days&${item.name}`}
                                                  onChange={storeInputHandler}
                                                />
                                                <span className="slider round"></span>
                                              </label>
                                            </Col>
                                          </Row>
                                        </label>
                                      );
                                    })}
                                  <p style={{ color: "red", fontSize: "12px" }}>
                                    {requiredError.storeVisitDays}
                                  </p>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row style={{ padding: "0px 0px 0px 35px" }}>
                            <Col>
                              <h6>Sales Rep</h6>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              style={{
                                border: "2px solid #E6E6E6",
                                borderRadius: "32px",
                                padding: "30px",
                                marginLeft: "20px",
                              }}
                            >
                              <Row>
                                <Col>
                                  <Row>
                                    <Col md="6" style={{ width: "50%" }}>
                                      <label>
                                        Sales Rep
                                        {/* <span style={{ color: 'red' }}>
                                          {' '}
                                          *
                                        </span>{' '} */}
                                      </label>
                                      <Input
                                        type="select"
                                        name="sales_rep_username"
                                        className="admin-input"
                                        onChange={storeInputHandler}
                                        value={
                                          storeInputField.sales_rep_username
                                        }
                                        required
                                        style={{...componentStyle.selectFieldStyle}}
                                      >
                                        <option selected value="">
                                          Select Sales Rep
                                        </option>
                                        {/* <option value="Rep 1">Rep 1</option>
                                    <option value="Rep 2">Rep 2</option> */}
                                        {repList.length > 0
                                          ? renderRepOptions()
                                          : null}
                                      </Input>
                                      <p
                                        style={{
                                          color: "red",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {/* {requiredError.storeSales} */}
                                      </p>
                                    </Col>
                                    <Col md="3">
                                      <p style={{ marginTop: "42px" }}>
                                        {storeInputField &&
                                        storeInputField.sales_status ==
                                          "inactive"
                                          ? "Inactive"
                                          : "Active"}
                                      </p>
                                    </Col>
                                    <Col md="3">
                                      <label
                                        style={{ marginTop: "36px" }}
                                        className="switch"
                                      >
                                        <Input
                                          type="checkbox"
                                          name="sales_status"
                                          id="myCheck1"
                                          // onClick={showContent}
                                          onChange={storeInputHandler}
                                          checked={
                                            storeInputField.sales_status ==
                                            "inactive"
                                              ? false
                                              : true
                                          }
                                        />
                                        <span className="slider round"></span>
                                      </label>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row style={{ padding: "0px 0px 0px 35px" }}>
                            <Col>
                              <h6> Merchandiser</h6>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              style={{
                                border: "2px solid #E6E6E6",
                                borderRadius: "32px",
                                padding: "30px",
                                marginLeft: "20px",
                              }}
                            >
                              <Row>
                                <Col>
                                  <Row>
                                    <Col md="6" style={{ width: "50%" }}>
                                      <label>
                                        Senior/Primary Merchandiser
                                        <span style={{ color: "red" }}>
                                          {" "}
                                          *
                                        </span>{" "}
                                      </label>
                                      <Input
                                        type="select"
                                        name="senior_rep_username"
                                        className="admin-input"
                                        onChange={storeInputHandler}
                                        value={
                                          storeInputField.senior_rep_username
                                        }
                                        required
                                        style={{...componentStyle.selectFieldStyle}}
                                      >
                                        <option selected value="">
                                          Select Primary Merchandiser
                                        </option>
                                        {/* <option value="Rep 1">Rep 1</option>
                                    <option value="Rep 2">Rep 2</option> */}
                                        {repList.length > 0
                                          ? renderMerchandiserOptions()
                                          : null}
                                      </Input>
                                      <p
                                        style={{
                                          color: "red",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {requiredError.storeSenior}
                                      </p>
                                    </Col>
                                    <Col md="3">
                                      <p style={{ marginTop: "42px" }}>
                                        {storeInputField &&
                                        storeInputField.senior_status ==
                                          "inactive"
                                          ? "Inactive"
                                          : "Active"}
                                      </p>
                                    </Col>
                                    <Col md="3">
                                      <label
                                        style={{ marginTop: "36px" }}
                                        className="switch"
                                      >
                                        <Input
                                          type="checkbox"
                                          name="senior_status"
                                          id="myCheck1"
                                          // onClick={showContent}
                                          onChange={storeInputHandler}
                                          checked={
                                            storeInputField.senior_status ==
                                            "inactive"
                                              ? false
                                              : true
                                          }
                                        />
                                        <span className="slider round"></span>
                                      </label>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md="6">
                                      <label>Leave Reason</label>
                                      <Input
                                        type="select"
                                        name="rep_leave"
                                        className="admin-input"
                                        onChange={storeInputHandler}
                                        value={storeInputField.rep_leave}
                                        style={{...componentStyle.selectFieldStyle}}
                                      >
                                        <option selected value="">
                                          Select Leave Reason
                                        </option>
                                        {/* <option value="Rep 1">Rep 1</option>
                                    <option value="Rep 2">Rep 2</option> */}
                                        <option>Sick Leave</option>
                                        <option>Annual Leave</option>
                                      </Input>
                                      <p
                                        style={{
                                          color: "red",
                                          fontSize: "12px",
                                        }}
                                      ></p>
                                    </Col>
                                    {storeInputField.rep_leave != "" ? (
                                      <Col lg="6" md="6">
                                        <label>
                                          Support Merchandiser Cover
                                          {storeInputField.additionalRep &&
                                          storeInputField.additionalRep[0]
                                            .rep_cover == "Primary Rep" ? (
                                            <span style={{ color: "red" }}>
                                              *
                                            </span>
                                          ) : null}
                                        </label>
                                        <Input
                                          type="select"
                                          name="rep_cover_username"
                                          className="admin-input"
                                          value={
                                            storeInputField.additionalRep &&
                                            storeInputField.additionalRep[0]
                                              .rep_cover_username
                                          }
                                          onChange={(e) => {
                                            storeInputField.additionalRep[0].rep_cover_username =
                                              e.target.value;
                                            setRequiredError({
                                              rep_cover_username: "",
                                            });

                                            setStoreInputField({
                                              ...storeInputField,
                                              additionalRep: [
                                                ...storeInputField.additionalRep,
                                              ],
                                            });
                                            // setAdditionalRep([...additionalRep]);
                                          }}
                                          required
                                          style={{...componentStyle.selectFieldStyle}}
                                        >
                                          {storeInputField.rep_leave === "" ||
                                          storeInputField.rep_leave ===
                                            undefined ? (
                                            <>
                                              <option selected value="">
                                                Select Cover Merchandiser
                                              </option>
                                            </>
                                          ) : (
                                            <>
                                              <option selected value="">
                                                Select Cover Merchandiser
                                              </option>
                                              {repList.length > 0
                                                ? renderMerchandiserOptions(
                                                    true
                                                  )
                                                : null}
                                            </>
                                          )}
                                        </Input>
                                        <p
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          {requiredError.rep_cover_username}
                                        </p>
                                      </Col>
                                    ) : null}
                                  </Row>
                                  {storeInputField.rep_leave !== "" ? (
                                    <Row>
                                      <Col md="6">
                                        <label>
                                          From{" "}
                                          {storeInputField.additionalRep &&
                                          storeInputField.additionalRep[0]
                                            .rep_cover == "Primary Rep" ? (
                                            <span style={{ color: "red" }}>
                                              *
                                            </span>
                                          ) : null}
                                        </label>
                                        <DatePicker
                                          readOnly={
                                            fieldDisabled.fieldDisabled_store
                                          }
                                          className="textBox admin-input"
                                          name="from_date"
                                          calendarStartDay={1}
                                          locale="en-gb"
                                          dateFormat={"dd/MM/yyyy"}
                                          style={{
                                            border: "1px solid #ced4da",
                                          }}
                                          selected={
                                            storeInputField.additionalRep &&
                                            storeInputField.additionalRep[0]
                                              .from_date
                                          }
                                          value={
                                            storeInputField.additionalRep &&
                                            storeInputField.additionalRep[0]
                                              .from_date
                                          }
                                          onChange={(date, e) => {
                                            storeInputField.additionalRep[0].from_date =
                                              date;
                                            setRequiredError({ from_date: "" });

                                            setStoreInputField({
                                              ...storeInputField,
                                              additionalRep: [
                                                ...storeInputField.additionalRep,
                                              ],
                                            });
                                            // setAdditionalRep([...additionalRep]);
                                            console.log(storeInputField);
                                          }}
                                        />
                                        <p
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          {requiredError.from_date}
                                        </p>
                                      </Col>
                                      <Col md="6">
                                        <label>
                                          To{" "}
                                          {storeInputField.additionalRep &&
                                          storeInputField.additionalRep[0]
                                            .rep_cover == "Primary Rep" ? (
                                            <span style={{ color: "red" }}>
                                              *
                                            </span>
                                          ) : null}
                                        </label>
                                        <DatePicker
                                          readOnly={
                                            fieldDisabled.fieldDisabled_store
                                          }
                                          className="textBox admin-input"
                                          name="to_date"
                                          calendarStartDay={1}
                                          locale="en-gb"
                                          dateFormat={"dd/MM/yyyy"}
                                          style={{
                                            border: "1px solid #ced4da",
                                          }}
                                          minDate={
                                            storeInputField.additionalRep &&
                                            storeInputField.additionalRep[0]
                                              .from_date
                                          }
                                          selected={
                                            storeInputField.additionalRep &&
                                            storeInputField.additionalRep[0]
                                              .to_date
                                          }
                                          value={
                                            storeInputField.additionalRep &&
                                            storeInputField.additionalRep[0]
                                              .to_date
                                          }
                                          onChange={(date1) => {
                                            storeInputField.additionalRep[0].to_date =
                                              date1;
                                            setRequiredError({ to_date: "" });
                                            setStoreInputField({
                                              ...storeInputField,
                                              additionalRep: [
                                                ...storeInputField.additionalRep,
                                              ],
                                            });
                                            // setAdditionalRep([...additionalRep]);
                                            console.log(storeInputField);
                                          }}
                                        />
                                        <p
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          {requiredError.to_date}
                                        </p>
                                      </Col>
                                    </Row>
                                  ) : null}
                                  <Row>
                                    <Col md="6" style={{ width: "50%" }}>
                                      <label>
                                        Support Merchandiser
                                        {/* <span
                                          style={{ color: 'red' }}
                                        > *</span>{' '} */}
                                      </label>
                                      <Input
                                        type="select"
                                        name="support_rep_username"
                                        className="admin-input"
                                        onChange={storeInputHandler}
                                        value={
                                          storeInputField.support_rep_username &&
                                          storeInputField.support_rep_username
                                        }
                                        required
                                        style={{...componentStyle.selectFieldStyle}}
                                      >
                                        <option selected value="">
                                          Select support Merchandiser
                                        </option>
                                        {repList.length > 0
                                          ? renderMerchandiserOptions(true)
                                          : null}
                                      </Input>
                                      {/* <p style={{ color: 'red', fontSize: '12px' }}>
                                        {requiredError.storeSupport}
                                      </p> */}
                                    </Col>
                                    <Col md="3">
                                      <p style={{ marginTop: "42px" }}>
                                        {storeInputField &&
                                        storeInputField.support_status ==
                                          "inactive"
                                          ? "Inactive"
                                          : "Active"}
                                      </p>
                                    </Col>
                                    <Col md="3">
                                      <label
                                        style={{ marginTop: "36px" }}
                                        className="switch"
                                      >
                                        <Input
                                          type="checkbox"
                                          name="support_status"
                                          id="myCheck2"
                                          // onClick={showContent}
                                          onChange={storeInputHandler}
                                          checked={
                                            storeInputField.support_status ==
                                            "inactive"
                                              ? false
                                              : true
                                          }
                                        />
                                        <span className="slider round"></span>
                                      </label>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          {/* <Row>
                            <Col
                              style={{
                                border: '2px solid #E6E6E6',
                                borderRadius: '32px',
                                padding: '30px',
                                marginLeft: '20px',
                              }}
                            > */}
                          {/* {Array.isArray(storeInputField.additionalRep) &&
                                storeInputField.additionalRep.map(
                                  (rec1, index) => ( */}
                          {/* <Row> */}
                          {/* <Col lg="6" md="6">
                                        <label>
                                          Merchandiser Cover
                                          <span
                                            style={{ color: 'red' }}
                                          ></span>{' '}
                                          {index + 1}
                                        </label>
                                        <Input
                                          type="select"
                                          name="rep_cover"
                                          className="admin-input"
                                          value={rec1.rep_cover}
                                          onChange={e => {
                                            storeInputField.additionalRep[
                                              index
                                            ].rep_cover = e.target.value;
                                            setStoreInputField({
                                              ...storeInputField,
                                              additionalRep: [
                                                ...storeInputField.additionalRep,
                                              ],
                                            });
                                          }}
                                          required
                                        >
                                          <option selected value="">
                                            Select Merchandiser Type
                                          </option>
                                          <option value="Primary Rep">
                                            Primary Merchandiser
                                          </option>
                                          <option value="Secondary Rep">
                                            Support Merchandiser
                                          </option>
                                         
                                        </Input>
                                      </Col> */}
                          {/* <Col lg="6" md="6">
                                        <label>
                                          Merchandiser Name
                                          {rec1.rep_cover == 'Primary Rep' ||
                                          rec1.rep_cover == 'Secondary Rep' ? (
                                            <span style={{ color: 'red' }}>
                                              *
                                            </span>
                                          ) : null}
                                        </label>
                                        <Input
                                          type="select"
                                          name="rep_cover_username"
                                          className="admin-input"
                                          value={rec1.rep_cover_username}
                                          onChange={e => {
                                            storeInputField.additionalRep[
                                              index
                                            ].rep_cover_username =
                                              e.target.value;
                                            setStoreInputField({
                                              ...storeInputField,
                                              additionalRep: [
                                                ...storeInputField.additionalRep,
                                              ],
                                            });
                                          }}
                                          required
                                        >
                                          <option selected value="">
                                            Select Cover Merchandiser
                                          </option>
                                          {repList.length > 0
                                            ? renderMerchandiserOptions()
                                            : null}
                                        </Input>
                                       
                                      </Col> */}
                          {/* <Col md="6">
                                        <label>
                                          From{' '}
                                          {rec1.rep_cover == 'Primary Rep' ||
                                          rec1.rep_cover == 'Secondary Rep' ? (
                                            <span style={{ color: 'red' }}>
                                              *
                                            </span>
                                          ) : null}
                                        </label>
                                        <DatePicker
                                          readOnly={
                                            fieldDisabled.fieldDisabled_store
                                          }
                                          className="textBox admin-input"
                                          name="from_date"
                                          calendarStartDay={1}
                                          locale="en-gb"
                                          dateFormat={'dd/MM/yyyy'}
                                          style={{
                                            border: '1px solid #ced4da',
                                          }}
                                          selected={rec1.from_date}
                                          value={rec1.from_date}
                                          onChange={(date, e) => {
                                            storeInputField.additionalRep[
                                              index
                                            ].from_date = date;
                                            setStoreInputField({
                                              ...storeInputField,
                                              additionalRep: [
                                                ...storeInputField.additionalRep,
                                              ],
                                            });
                                            console.log(storeInputField);
                                          }}
                                        />
                                      </Col> */}
                          {/* <Col md="6">
                                        <label>
                                          To{' '}
                                          {rec1.rep_cover == 'Primary Rep' ||
                                          rec1.rep_cover == 'Secondary Rep' ? (
                                            <span style={{ color: 'red' }}>
                                              *
                                            </span>
                                          ) : null}
                                        </label>
                                        <DatePicker
                                          readOnly={
                                            fieldDisabled.fieldDisabled_store
                                          }
                                          className="textBox admin-input"
                                          name="to_date"
                                          calendarStartDay={1}
                                          locale="en-gb"
                                          dateFormat={'dd/MM/yyyy'}
                                          style={{
                                            border: '1px solid #ced4da',
                                          }}
                                          minDate={
                                            storeInputField.additionalRep[index]
                                              .from_date
                                          }
                                          selected={rec1.to_date}
                                          value={rec1.to_date}
                                          onChange={date1 => {
                                            storeInputField.additionalRep[
                                              index
                                            ].to_date = date1;
                                            setStoreInputField({
                                              ...storeInputField,
                                              additionalRep: [
                                                ...storeInputField.additionalRep,
                                              ],
                                            });
                                            console.log(storeInputField);
                                          }}
                                        />
                                      </Col> */}
                          {/* <Col md="10"></Col>
                                      {index ==
                                      storeInputField.additionalRep.length -
                                        1 ? (
                                        <Col md="1" style={{ float: 'right' }}>
                                        
                                          <div
                                            class="action_btn"
                                            onClick={e => {
                                              addRepCover(e);
                                            }}
                                          >
                                            <b
                                              style={{
                                                fontSize: '34px',
                                                cursor: 'pointer',
                                                color: '#D71920',
                                              }}
                                            >
                                              +
                                            </b>
                                          </div>
                                        </Col>
                                      ) : null} */}
                          {/* {index > 0 &&
                                      index ==
                                        storeInputField.additionalRep.length -
                                          1 ? (
                                        <Col md="1">
                                        
                                          <div
                                            class="action_btn"
                                            onClick={e => {
                                              storeInputField.additionalRep.splice(
                                                index,
                                                1,
                                              );

                                              setStoreInputField({
                                                ...storeInputField,
                                                additionalRep: [
                                                  ...storeInputField.additionalRep,
                                                ],
                                              });
                                            }}
                                            style={{ float: 'right' }}
                                          >
                                            {' '}
                                            <b
                                              style={{
                                                fontSize: '34px',
                                                cursor: 'pointer',
                                                color: '#D71920',
                                              }}
                                            >
                                              -
                                            </b>{' '}
                                          </div>
                                        </Col>
                                      ) : null} */}
                          {/* </Row> */}
                          {/* ),
                                )} */}
                          {/* </Col>
                          </Row> */}
                        </Col>
                      </Row>
                    </FormGroup>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
    </div>
  );
};

export default TaskManagement;
